import { deleteDomain, registerDomain, updateDomainConfigDetails } from 'middleware/email';
import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { getSite } from 'utils/helpers';
import { CopyFormDataIcon } from 'utils/svg';
import { useDispatch, useSelector } from 'react-redux';
import { verifyDomain } from 'middleware/email';
import PropTypes from 'prop-types';
// import { getItemParse } from 'utils/cache';
// import { updateDomainData } from 'actions/email';
import { notification } from 'services/notification';
import { NOTIFICATION_TYPES } from 'constants/app';
import moment from 'moment';
import { getDomainData } from 'middleware/email';
import Select from 'react-select';
import { removeItem } from 'utils/cache';

const DomainConfig = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isVerifing, setIsVerifing ] = useState(false);
    const [ isDomainDelete, setDomainDelete ] = useState(false);
    const email = useSelector((state) => state.email);
    const [ domainInformation, setDomainInformation ] = useState(null);
    const dispatch = useDispatch();
    const { addressId, name, subdomain, businessAddress, domain,domainEmailAtDomail, domainEmail, emailDisplayName, state, txtRecord, cnameRecords, domainCity, domainState, domainZipcode, domainCountry } =
    domainInformation ?? {
        name: null,
        state: null,
        txtRecord: [],
        mxRecord: [],
        cnameRecords: [],
    };
    const [ sendingDomain, setSendingDomain ] = useState('');
    const [ subDomain, setSubDomain ] = useState('');
    const [ isRegistered, setIsRegistered ] = useState();
    const [ isVerifyBtnVisible, setIsVerifyBtnVisible ] = useState(true);
    const [ isUpdating, setIsUpdating ] = useState(false);
    const [ bussinesAddressValue, setBussinesAddressValue ] = useState( '' );
    const [ displayName, setDisplayName ] = useState('');
    const [ city, setCity ] = useState('');
    const [ addressState, setAddressState ] = useState('');
    const [ zipcode, setZipcode ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ replyToName, setReplyToName ] = useState('');
    const [ replyTo, setReplyTo ] = useState('');
    // const [ addressBusiness, setAddressBusiness ] = useState('');
    const [ prefferedEmail, setPrefferedEmail ] = useState('');
    const [ options, setOptions ] = useState([])
    const [ selected, setSelected ] = useState(options[ 1 ]);
    const [ errors, setErrors ] = useState({
        sendingDomain: '',
        domainEmail: '',
        business_address: '',
        domain: '',
        city: '',
        addressState: '',
        zipcode: '',
        country: '',
        displayName: '',
        replyTo: '',
        replyToName: ''
        // addressBusiness: ''
    });
    const handleCopy = (data) => {
        navigator.clipboard.writeText(data);
        notification(NOTIFICATION_TYPES.SUCCESS, 'Copied');
    };

    console.log('selected',selected?.value);

    const reset = (data) => {
        setIsRegistered(data?.data?.domain?.name);
        setSendingDomain('');
        setSubDomain('');
        setBussinesAddressValue('');
        setDisplayName('');
        setPrefferedEmail('');
        setAddressState('');
        setCity('');
        setZipcode('');
        setCountry('');
        setReplyTo('');
        setReplyToName('');
        setErrors({
            sendingDomain: '',
            domainEmail: '',
            displayName: '',
            business_address: '',
            domain: '',
            city: '',
            addressState: '',
            zipcode: '',
            country: '',
            replyTo: '',
            replyToName: ''
            // addressBusiness: ''
        });
    }
    const handleSetDomainOptions = () =>{
        const options = []
        if(sendingDomain){
            options.push({ value: `@${ sendingDomain }`, label: `@${ sendingDomain }` })
        }
        if(subDomain){
            options.push({ value: `@${ subDomain }.${ sendingDomain }`, label: `@${ subDomain }.${ sendingDomain }` })
        }
        setOptions([ ...options ])
    }
    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        const newErrors = {};

        if (sendingDomain.trim() === '') {
            newErrors.sendingDomain = 'Sending Domain is required';
        } else if (!sendingDomain.includes('.')) {
            newErrors.sendingDomain = 'Please enter a domain suffix';
        }

        if (bussinesAddressValue.trim() === '') {
            newErrors.business_address = 'Business is required';
        }
        if (displayName.trim() === '') {
            newErrors.domain = 'Display name is required';
        }
        if (prefferedEmail.trim() === '') {
            newErrors.domainEmail = 'Prefered email is required';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            setIsLoading(false);
            return
        }

        const request = {
            subdomain: subDomain,
            name:  subDomain === '' ? sendingDomain : subDomain + '.' + sendingDomain,
            domainEmail: domainEmail ? domainEmail + '@' + domainEmailAtDomail : `${ prefferedEmail + '@' + subDomain  + '.' + sendingDomain }` ,
            emailDisplayName: displayName,
            businessAddress: businessAddress ?? bussinesAddressValue,
            domain: domain ?? sendingDomain,
            userSiteId: getSite()?.id,
            replyTo: replyTo + selected?.value,
            replyToName: replyToName,
            address: {
                city: city,
                state: addressState,
                zipcode: zipcode,
                country: country,
                businessAddress: bussinesAddressValue
            }
        };

        dispatch(registerDomain(request, reset, ()=>setIsLoading(false)));
    };

    const handleVerification = () => {
        dispatch(verifyDomain(setIsVerifing));
    };

    const handleDomainData = (data) => {
        console.log('handleDomainData', data);
        if(data) {
            if (Object.keys(data)?.length && data?.data?.domain) {
                if (data?.data?.domain?.state) {
                    const txtRecord =
              data.data?.sending_dns_records?.filter(
                  (i) => i.record_type === 'TXT'
              ) ?? [];
                    const mxRecord =
              data.data?.receiving_dns_records?.filter(
                  (i) => i.record_type === 'MX'
              ) ?? [];
                    const cnameRecords =
              data.data?.sending_dns_records?.filter(
                  (i) => i.record_type.toLowerCase() == 'cname'
              ) ?? [];
                    const domainEmailTitle = data?.data?.domain?.domainEmail.split('@')[ 0 ]
                    const domainEmailAtDomail = data?.data?.domain?.domainEmail.split('@')[ 1 ]
                    setOptions([
                        { value: `@${ data?.data?.domain?.name }`, label: `@${ data?.data?.domain?.name }` },
                        { value: `@${ data?.data?.domain?.domainName }`, label: `@${ data?.data?.domain?.domainName }` }
                    ])

                    setSelected({ value: `@${ data?.data?.domain?.replyTo?.split('@')?.[ 1 ] }`, label: `@${ data?.data?.domain?.replyTo?.split('@')?.[ 1 ] }` },)

                    setReplyToName(data?.data?.domain?.replyToName)
                    setReplyTo(data?.data?.domain?.replyTo?.split('@')?.[ 0 ] ?? '')

                    setDomainInformation({
                        domain: data?.data?.domain?.domainName ?? '',
                        name: data?.data?.domain?.name ?? '',
                        // domain: data?.data?.domain?.domainName ?? '',
                        // subdomain: data?.data?.domain?.subdomain ?? '',
                        domainEmailAtDomail: domainEmailAtDomail ?? '',
                        businessAddress: data?.data?.domain?.businessAddress ?? '',
                        domainEmail: domainEmailTitle ?? '',
                        emailDisplayName: data?.data?.domain?.emailDisplayName ?? '',
                        txtRecord,
                        mxRecord,
                        cnameRecords,
                        state: data?.data?.domain?.state,
                        domainReplyTo: data?.data?.domain?.replyTo,
                        subdomain: data?.data?.domain?.subdomain,
                        addressId: data?.data?.domain?.address?.id,
                        domainCity: data?.data?.domain?.address?.city,
                        domainState: data?.data?.domain?.address?.state,
                        domainCountry: data?.data?.domain?.address?.country,
                        domainZipcode: data?.data?.domain?.address?.zipcode,
                        domainBusinessAddress: data?.data?.domain?.address?.businessAddress
                    });
                    setIsVerifyBtnVisible(data?.data?.domain?.lastVerifiedAt ? moment.duration(moment(new Date()).diff(moment(data?.data?.domain?.lastVerifiedAt)))?.asHours() > 6 ? true : false : true)

                }
            }
        }
    }

    const extractSubdomain = (host, domain) => {
        const hostname = host.replace(`.${ domain }`, '');
        return hostname;
    };

    const handleSelectChange = (selectedOption) => {
        setSelected(selectedOption);
        console.log('Option selected:', selectedOption);
    };

    const handleResetDomain = () =>{
        removeItem('domain')
        dispatch(deleteDomain(setDomainDelete));
    }
    const updateDomainData = () =>{
        const request = {
            subdomain: subdomain ? subdomain :subDomain || '',
            name: name ? name : subDomain === '' ? sendingDomain : subDomain + '.' + sendingDomain,
            domainEmail: domainEmail ? domainEmail + '@' + domainEmailAtDomail : `${ prefferedEmail + '@' + subDomain  + '.' + sendingDomain }` ,
            emailDisplayName: emailDisplayName ? emailDisplayName : displayName,
            businessAddress: businessAddress,
            domain: domain,
            userSiteId: getSite()?.id,
            replyTo: selected?.value ? replyTo + selected?.value : replyTo,
            replyToName: replyToName,
            address: {
                id: addressId,
                city: city === '' ? domainCity :  city,
                state: addressState === '' ? domainState : addressState,
                zipcode: zipcode === '' ? domainZipcode : zipcode,
                country: country === '' ? domainCountry : country,
                businessAddress: bussinesAddressValue === '' ? businessAddress : bussinesAddressValue
            }
        };
        console.log('request to save the domain', request);
        dispatch(updateDomainConfigDetails(request, setIsUpdating));
    }

    // useEffect(() => {
    //     if(!email?.domainRequestSuccess){
    //         alert("i am in")
    //         const domainData = email?.domainData ?? getItemParse('domain')
    //         console.log('domainData',domainData);
    //         if(!email?.domainData?.data?.length){
    //             dispatch(updateDomainData(domainData?.data))
    //         }
    //         handleDomainData(domainData)
    //     }
    // }, [ email?.domainRequestSuccess ])

    useEffect(() => {
        console.log('email new', email);
        if (!email?.domainData?.data ) {
            dispatch(getDomainData(handleDomainData));
        }else{
            handleDomainData(email?.domainData)
        }
    }, [ ])

    return (
        <>
            <div className=" email-setup-wrapper ">
                <div className="domain-step domain-step-1">
                    <div className="domain-step-wrap">
                        <div className="domain-step-content d-flex flex-column align-items-start">
                            <div className='mt-5'>
                                <div>
                                    <h3 className="stepTitle text-start">
                                        Step 1: Enter your information
                                    </h3>
                                    <span className='note-text' style={ { fontSize: '16px', color: '#000' } }>
                                        <strong>IMPORTANT:</strong> You will need a business email before using this feature.
                                        If you don&apos;t have one, we recommend: <strong><a href="https://workspace.google.com/solutions/business-email/" target="_blank" rel="noreferrer">Google Workspace</a></strong>
                                    </span>
                                    <div className="input-title-wrapper d-flex align-items-center mt-3 justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Domain
                                            </div>
                                            <div
                                                className="register-input-wrapper mt-2 d-flex w-100"
                                            >
                                                <input
                                                    style={ { color: '#000' } }
                                                    placeholder="Domain"
                                                    disabled={ name !== null ?? true }
                                                    className='w-100 register-input'
                                                    value={ domain ?? sendingDomain }
                                                    onBlur={ handleSetDomainOptions }
                                                    onChange={ (e) => {
                                                        if(/^[a-zA-Z0-9-]*\.?[a-zA-Z0-9]*\.?[a-zA-Z0-9]*$/.test(e.target.value)){setSendingDomain(e.target.value);}
                                                    } }
                                                />
                                                <span
                                                    style={ {
                                                        background: `${
                                                            ((state === 'active' || state === 'verified' ) &&
                                                                'green') ||
                                                            (state === 'pending' || isVerifyBtnVisible ? 'transparent' : '#ffcc00' ) ||
                                                            (state === null || !state ? 'transparent': 'transparent' )
                                                        }`,
                                                        color: state === null || state === 'pending' || state === 'unverified'? '#000' : '#ffffff',
                                                        fontWeight: 700
                                                    } }
                                                >
                                                    {isVerifyBtnVisible || state === 'active' ? state?.toString()?.toUpperCase() ?? 'Unverified' : 'Pending'}
                                                </span>
                                            </div>
                                            {errors.sendingDomain && (
                                                <span className='error'>
                                                    {errors.sendingDomain}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Sub-Domain (Required. We recommend &quot;mail&quot;)
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="display name"
                                                    className='w-100 display-name-input'
                                                    value={ subdomain ?? subDomain }
                                                    onBlur={ handleSetDomainOptions }
                                                    onChange={ (e) => {
                                                        if(/^[a-zA-Z0-9]*\.?[a-zA-Z0-9]*\.?[a-zA-Z0-9]*\.?[a-zA-Z0-9]*$/.test(e.target.value)){setSubDomain(e.target.value);}
                                                    } }
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Display Name
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="display name"
                                                    className='w-100 display-name-input'
                                                    value={ emailDisplayName ?? displayName }
                                                    onChange={ (e) => {
                                                        setDisplayName(e.target.value);
                                                    } }
                                                />
                                            </div>
                                            {errors.domain && (
                                                <span className='error'>
                                                    {errors.domain}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Preferred Email
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="eg: support, help"
                                                    className='w-100 register-input'
                                                    value={ domainEmail ?? prefferedEmail }
                                                    onChange={ (e) => {
                                                        setPrefferedEmail(e.target.value);
                                                    } }
                                                />
                                                <span>
                                                    {sendingDomain === '' ? `@${ name ?? 'yourdomain.com' }` : `@${ subDomain + '.' + sendingDomain }`}
                                                    {/* {sendingDomain === '' ? `@${ subdomain ? subdomain + '.' + domain : domain }` ?? '@yourdomain.com'  : `@${ subDomain ? subDomain + '.' + sendingDomain : sendingDomain }`} */}
                                                </span>
                                            </div>
                                            {errors.domainEmail && (
                                                <span className='error' style={ { fontSize: '14px', color: 'red' } }>
                                                    {errors.domainEmail}
                                                </span>
                                            )}

                                        </div>
                                    </div>

                                    <div className="input-title-wrapper d-flex align-items-center justify-content-start">
                                        <div className="mt-3" style={ { width: '50%' } }>
                                            <div
                                                className='input-title'
                                            >
                                                Business Street Address (Required)
                                            </div>
                                            <textarea
                                                className="mt-2 w-100"
                                                placeholder="Enter Bussiness Address"
                                                value={ businessAddress ?? bussinesAddressValue }
                                                onChange={ (e) => setBussinesAddressValue(e.target.value) }
                                            />
                                            {errors.business_address && (
                                                <span className='error'>
                                                    {errors.business_address}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                City
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="Enter city name"
                                                    className='w-100 display-name-input'
                                                    value={ domainCity ?? city }
                                                    onChange={ (e) => {
                                                        setCity(e.target.value);
                                                    } }
                                                />
                                            </div>
                                            {errors.city && (
                                                <span className='error'>
                                                    {errors.city}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                State
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="Enter state name"
                                                    className='w-100 display-name-input'
                                                    value={ domainState ?? addressState }
                                                    onChange={ (e) => {
                                                        setAddressState(e.target.value);
                                                    } }
                                                />
                                            </div>
                                            {errors.addressState && (
                                                <span className='error'>
                                                    {errors.addressState}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Zipcode
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="Enter zipcode"
                                                    className='w-100 display-name-input'
                                                    value={ domainZipcode ?? zipcode }
                                                    onChange={ (e) => {
                                                        setZipcode(e.target.value);
                                                    } }
                                                />
                                            </div>
                                            {errors.zipcode && (
                                                <span className='error'>
                                                    {errors.zipcode}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Country
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="Enter country name"
                                                    className='w-100 display-name-input'
                                                    value={ domainCountry ?? country }
                                                    onChange={ (e) => {
                                                        setCountry(e.target.value);
                                                    } }
                                                />
                                            </div>
                                            {errors.country && (
                                                <span className='error'>
                                                    {errors.country}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Reply To - Display Name
                                            </div>
                                            <div
                                                className="mt-2 d-flex w-100 register-input-wrapper"
                                            >
                                                <input
                                                    placeholder="Enter name"
                                                    className='w-100 display-name-input'
                                                    value={ replyToName  }
                                                    onChange={ (e) => {
                                                        setReplyToName(e.target.value);
                                                    } }
                                                />
                                            </div>
                                            {errors.replyToName && (
                                                <span className='error'>
                                                    {errors.replyToName}
                                                </span>
                                            )}

                                        </div>
                                    </div>
                                    <div className="input-title-wrapper mt-3 d-flex align-items-center justify-content-start">
                                        <div className='w-50'>
                                            <div
                                                className='input-title'
                                            >
                                                Reply To - Email Address
                                            </div>
                                            <div className="bg-white register-input-wrapper d-flex align-items-center justify-content-between reply-to-input mt-3">

                                                <input
                                                    placeholder="Enter name"
                                                    className='w-100 display-name-input'
                                                    value={ replyTo }
                                                    onChange={ (e) => {
                                                        if(/^\S*$/.test(e.target.value)){setReplyTo(e.target.value)}
                                                    } }
                                                />
                                                <Select
                                                    className={ 'reply-to-dropdown' }
                                                    value={ selected }
                                                    options={ options }
                                                    onChange={ handleSelectChange }
                                                    // value={ selectedOption }
                                                    // // options !== ''
                                                    // //         ? `@${ replyToDomain }`
                                                    // //         : replyToDomain
                                                    // onChange={ (e)=>setSelectedOption(e.target.value) }
                                                    // formatGroupLabel={formatGroupLabel}
                                                />
                                            </div>

                                            {errors.replyTo && (
                                                <span className='error'>
                                                    {errors.replyTo}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {name == null && (
                                        <div className="d-flex mt-3 justify-content-end">
                                            <button
                                                className='proceed-btn'
                                                onClick={ handleSubmit }
                                            >
                                                {isLoading ? (
                                                    <Spinner
                                                        animation="border"
                                                        size={ 'sm' }
                                                        role="status"
                                                    />
                                                ) : (
                                                    'Proceed'
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {name !== null || isRegistered?.data?.domain?.name ? (
                                    <>
                                        <h3 className="stepTitle text-start mt-5">
                                            Step 2: Add DNS records to domain account
                                        </h3>
                                        <ul className="list-step">
                                            <li>
                                                Login to your domain provider (GoDaddy, Namecheap, etc)
                                            </li>
                                            <li>
                                                Find the domain you want to use, then click &quot;Manage
                                                Domain&quot;
                                            </li>
                                            <li>
                                                Now find the &quot;DNS&quot; or &quot;Advanced DNS&quot;
                                                settings page (also called &quot;Manage Zones&quot;)
                                            </li>
                                            <li>
                                                Click &quot;Add New Record&quot; or &quot;Add&quot;, and
                                                add ALL of the records below
                                            </li>
                                        </ul>
                                        {txtRecord?.length ? <div
                                            className=" mt-4 record-title"
                                        >
                                            TXT Records
                                        </div>: null}
                                        {txtRecord?.map((rec, i) => {
                                            const hostname = extractSubdomain(rec?.name, domain);
                                            return (
                                                <div className="cname-example" key={ i }>
                                                    <div className="cname-eg-inputs">
                                                        <Form.Group>
                                                            <Form.Label>Type</Form.Label>
                                                            <Form.Text className="cname-label">{rec?.record_type}</Form.Text>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Host</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={ hostname !== domain ? hostname : '@' }
                                                                className="host_input"
                                                            />
                                                            { console.log('hostname',hostname) }
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Text
                                                                onClick={ () => handleCopy(hostname !== domain ? hostname : '@') }
                                                                className="cname-copy"
                                                            >
                                                                <span className="cname-copy-icon">
                                                                    <CopyFormDataIcon />
                                                                </span>
                                                                <span>Copy</span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Value</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={
                                                                    rec?.value
                                                                }
                                                                className="cname_value_input"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Text
                                                                onClick={ () =>
                                                                    handleCopy(
                                                                        rec?.value
                                                                    )
                                                                }
                                                                className="cname-copy"
                                                            >
                                                                <span className="cname-copy-icon">
                                                                    <CopyFormDataIcon />
                                                                </span>
                                                                <span>Copy</span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        {/* <div
                                            className=" mt-4 record-title "
                                        >
                                            MX Records
                                        </div>
                                        {mxRecord?.map((rec, i) => {
                                            return (
                                                <div className="cname-example" key={ i }>
                                                    <div className="cname-eg-inputs">
                                                        <Form.Group>
                                                            <Form.Label>Type</Form.Label>
                                                            <Form.Text className="cname-label">{rec?.record_type}</Form.Text>
                                                        </Form.Group>
                                                        <Form.Group className=" form-host">
                                                            <Form.Label>Host</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value="@"
                                                                className="host_input"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="">
                                                            <Form.Text
                                                                onClick={ () => handleCopy('@') }
                                                                className="cname-copy"
                                                            >
                                                                <span className="cname-copy-icon">
                                                                    <CopyFormDataIcon />
                                                                </span>
                                                                <span>Copy</span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group className="">
                                                            <Form.Label>Value</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={
                                                                    rec?.value
                                                                }
                                                                className="cname_value_input"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="">
                                                            <Form.Text
                                                                onClick={ () =>
                                                                    handleCopy(
                                                                        rec?.value
                                                                    )
                                                                }
                                                                className="cname-copy"
                                                            >
                                                                <span className="cname-copy-icon">
                                                                    <CopyFormDataIcon />
                                                                </span>
                                                                <span>Copy</span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group className="">
                                                            <Form.Label>Priority</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={ rec?.priority }
                                                                className="cname_value_input"
                                                                onChange={ () => {} }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="">
                                                            <Form.Text
                                                                onClick={ () =>
                                                                    handleCopy(
                                                                        rec?.priority
                                                                    )
                                                                }
                                                                className="cname-copy"
                                                            >
                                                                <span className="cname-copy-icon">
                                                                    <CopyFormDataIcon />
                                                                </span>
                                                                <span>Copy</span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            );
                                        })} */}

                                        {cnameRecords?.length ? <div
                                            className=" mt-4 record-title "
                                        >
                                            CNAME Records
                                        </div> : null}
                                        {cnameRecords?.map((rec, i) => {
                                            const hostname = extractSubdomain(rec?.name, domain);
                                            return (
                                                <div className="cname-example" key={ i }>
                                                    <div className="cname-eg-inputs">
                                                        <Form.Group>
                                                            <Form.Label>Type</Form.Label>
                                                            <Form.Text className="cname-label">
                                                                {rec?.record_type}
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Host</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value= { hostname !== domain ? hostname : '@' }
                                                                className="host_input"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Text
                                                                onClick={ () => handleCopy(hostname !== domain ? hostname : '@') }
                                                                className="cname-copy"
                                                            >
                                                                <span className="cname-copy-icon">
                                                                    <CopyFormDataIcon />
                                                                </span>
                                                                <span>Copy</span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Value</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={
                                                                    rec?.value
                                                                }
                                                                className="cname_value_input"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Text
                                                                onClick={ () =>
                                                                    handleCopy(
                                                                        rec?.value
                                                                    )
                                                                }
                                                                className="cname-copy"
                                                            >
                                                                <span className="cname-copy-icon">
                                                                    <CopyFormDataIcon />
                                                                </span>
                                                                <span>Copy</span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <h3 className="stepTitle text-start mt-5">
                                            Step 3: Verify your domain
                                        </h3>
                                        <ul className="list-step">
                                            <li>
                                                Once you&apos;ve added ALL of the records above, <strong>please wait at least 4 hours </strong>before clicking the
                                                &quot;Verify&quot; button below.
                                            </li>
                                            <li>
                                                If it is successful, a green &quot;ACTIVE&quot; badge
                                                will appear at the top of this page next to Domain, and you will be redirected to the Email page.
                                            </li>
                                            <li>
                                                If the Verify button re-appears, it has failed. Sometimes it can take up to 24 hours for the records to update, so try again tomorrow.
                                            </li>
                                            <li>
                                                If it still fails after 24 hours, contact support.
                                            </li>
                                        </ul>
                                        <div style={ { gap: 10 } } className="d-flex align-items-center justify-content-center mt-3">
                                            {isVerifyBtnVisible && state !== 'active' ? (
                                                <button
                                                    className="email-setup-veryfy-btn"
                                                    onClick={ handleVerification }
                                                >
                                                    {isVerifing ? (
                                                        <Spinner
                                                            animation="border"
                                                            size={ 'sm' }
                                                            role="status"
                                                        />
                                                    ) : (
                                                        'Verify'
                                                    )}
                                                </button>
                                            ) : null}
                                            {state === 'active' ? (
                                                <button
                                                    disabled={ isUpdating }
                                                    className="email-setup-veryfy-btn"
                                                    onClick={ updateDomainData }
                                                >
                                                    {isUpdating ? (
                                                        <Spinner
                                                            animation="border"
                                                            size={ 'sm' }
                                                            role="status"
                                                        />
                                                    ) : (
                                                        'Update'
                                                    )}
                                                </button>
                                            ) : null}
                                            {state === 'active' || state === 'unverified' ? (
                                                <button
                                                    disabled={ isDomainDelete }
                                                    className="email-setup-veryfy-btn"
                                                    onClick={ handleResetDomain }
                                                >
                                                    {isDomainDelete ? (
                                                        <Spinner
                                                            animation="border"
                                                            size={ 'sm' }
                                                            role="status"
                                                        />
                                                    ) : (
                                                        'Reset'
                                                    )}
                                                </button>
                                            ) : null}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

DomainConfig.propTypes = {
    domainInformation: PropTypes.object
};

export default DomainConfig;
