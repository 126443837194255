import React, { useEffect, useState } from 'react';
import EditUser from './editUser';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'constants/appRoutes';
import '../contacts.sass';
import {
    deleteSubscriber,
    getGroupList,
    getSubscribersList,
} from 'middleware/email';
import searchIcon from 'images/searchIcon.svg';
import NewList from './newList';
import closeIcon from 'images/close.svg';
import ContactTable from './contactTable';
import { Spinner } from 'react-bootstrap';
import EditList from './editList';
import ConfirmAlert from 'components/core/confirm-alert';
import { confirmAlert } from 'react-confirm-alert';

export const extractFirstAndLastName = (fullName) =>
    fullName
        ? fullName.split(' ').length === 1
            ? { firstName: fullName, lastName: '' }
            : {
                firstName: fullName.split(' ').slice(0, -1).join(' '),
                lastName: fullName.split(' ').pop(),
            }
        : '';

const ContactList = () => {
    const dispatch = useDispatch();
    const email = useSelector((state) => state.email);

    const [ open, setOpen ] = useState(false);
    const [ isDeleteLoading, setIsDeleteLoading ] = useState(false);
    const [ selsectedGrp, setSelectedGrp ] = useState(null);
    const [ selectedRow, setSelectedRow ] = useState(null);
    const [ search, setSearch ] = useState('');
    const [ filteredItems, setFilteredItems ] = useState([]);
    const [ selectedList, setSelectedList ] = useState([]);

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearch(query);
        const filteredArray = email.subscribersList.filter(obj => {
            return Object.values(obj).some(value =>
                value.toString().toLowerCase().includes(query.toLowerCase())
            );
        });
        setFilteredItems(filteredArray);
    };
    const handleClose = () => {
        setSearch('');
        setFilteredItems([]);
    };

    const handleOpenDialog = (modalName) => {
        setOpen(modalName);
    };

    const handleModal = () => {
        setSelectedRow(null);
        setOpen(null);
    };

    const handleDeleteSubscriber = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlert
                        key={ 'box' }
                        onClose={ onClose }
                        handleAction={ () =>
                            dispatch(deleteSubscriber(selectedList,selsectedGrp,setIsDeleteLoading,setSelectedList))
                        }
                        subHeading="By confirming this page & information will be deleted forever."
                    />
                );
            },
        });

    };

    const getSubscribers = (id) => {
        dispatch(getSubscribersList(id));
    };

    useEffect(() => {
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: ROUTES.CONTACTS,
        });
        dispatch(getGroupList(getSubscribers));

    }, []);

    const groupClickHandler = (group) => {
        setSelectedGrp(group);
        getSubscribers(group.id);
    };

    const handleEdit = (row) => {
        setSelectedRow(row);
        handleOpenDialog('EDIT_USER');
    };

    const handleGetAllSubscriber = () => {
        dispatch(getSubscribersList());
        setSelectedGrp(null);
    };
    console.log('contcat-email>>>>>',email);
    return (
        <>
            <main style={ {  } } className="dashboard-data createNewBlog createNewPage">
                {email.listLoading ? (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={ {
                            position: 'absolute',
                            background: 'transparent',
                            opacity: 0.6,
                            width: '96%',
                            height: '92vh',
                            margin: 'auto',
                        } }
                    >
                        <Spinner
                            variant="primary"
                            animation="border"
                            size={ 'md' }
                            role="status"
                        />
                    </div>
                ) : null}

                <section data-tut="reactour__iso" className="dashboard-body">
                    <div className="contacts-container">
                        <div className="contacts-header">
                            <div className="contact-title">Contacts</div>
                            <div className="contacts-header-input-wrapper justify-content-between">
                                <div className="d-flex align-items-center" style={ { gap: 10 } }>
                                    <span style={ { width: '20px' } }>
                                        <img width={ '20px' } height={ '20px' } src={ searchIcon } />
                                    </span>
                                    <input
                                        type='text'
                                        name="search"
                                        value={ search }
                                        onChange={ handleSearch }
                                        className="header-input"
                                        placeholder="Search.."
                                    />
                                </div>
                                {search.length ? (
                                    <img
                                        className="list-close-icon"
                                        onClick={ ()=>handleClose() }
                                        src={ closeIcon }
                                        width={ 18 }
                                        height={ 18 }
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div className="custom-tabs">
                            <ul className="tab-list">
                                <li
                                    onClick={ handleGetAllSubscriber }
                                    className={ !selsectedGrp ? 'active-list-items' : 'list-items' }
                                >
                                    All Contacts
                                </li>
                                {email.groupList?.length
                                    ? email.groupList.map((group, i) => {
                                        return (
                                            <li
                                                className={
                                                    selsectedGrp?.id === group.id
                                                        ? 'active-list-items'
                                                        : 'list-items'
                                                }
                                                key={ `${ group.listName }-${ i }` }
                                                onClick={ () => groupClickHandler(group) }
                                            >
                                                {`${ group.listName } (${ group?.subscribersCount ?? '' })`}
                                            </li>
                                        );
                                    })
                                    : null}
                                <li
                                    className="add-new-list"
                                    onClick={ () => {
                                        handleOpenDialog('NEW_LIST');
                                    } }
                                >
                                    + New List
                                </li>
                                <li
                                    className="add-new-list"
                                    onClick={ () => {
                                        handleOpenDialog('EDIT_LIST');
                                    } }
                                >
                                    Edit List
                                </li>
                            </ul>
                            <button
                                className="add-user"
                                onClick={ () => handleOpenDialog('NEW_USER') }
                            >
                                + Add Users
                            </button>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <span className="d-flex">
                                <div>
                                    <div className="total-users">
                                        Total Users in List: {email.subscribersList?.length ?? 0}
                                    </div>
                                    <div className="total-users mt-2">Users Selected: { selectedList?.length }</div>
                                </div>
                            </span>
                            <div className='d-flex flex-column' style={ { gap: '10px' } }>
                                {/* <div className="view-users">
                                    <span
                                        style={ { textDecorationLine: 'none' } }
                                        className="view-user-items"
                                    >
                                        View
                                    </span>
                                    <span className="view-user-items">10</span>
                                    <span className="view-user-items">50</span>
                                    <span className="view-user-items">All</span>
                                </div> */}
                                <button
                                    className="delete-btn w-100 ml-2 align-self-end"
                                    disabled={ !selectedList?.length ? true : false }
                                    style={ !selectedList?.length ? { cursor: 'not-allowed', background:'lightgrey', color: '#000' } : {} }
                                    onClick={ handleDeleteSubscriber }
                                >
                                    {isDeleteLoading ? <Spinner
                                        variant="light"
                                        animation="border"
                                        size={ 'sm' }
                                        role="status"
                                    />: 'Delete'}
                                </button>
                            </div>
                        </div>
                        <ContactTable
                            filteredItems={ filteredItems }
                            extractFirstAndLastName={ extractFirstAndLastName }
                            handleEdit={ handleEdit }
                            email={ email }
                            selectedList= { selectedList }
                            setSelectedList={ setSelectedList }
                            selsectedGrp={ selsectedGrp }
                            handleDelete={ handleDeleteSubscriber }
                        />
                    </div>
                </section>
                <NewList handleModal={ handleModal } open={ !!(open === 'NEW_LIST') } />
                <EditUser
                    handleModal={ handleModal }
                    open={ !!(open === 'EDIT_USER' || open === 'NEW_USER') }
                    selectedRow={ selectedRow }
                    setSelectedRow={ setSelectedRow }
                    list={ email.groupList }
                />
                <EditList emailGroupList= { email.groupList } handleModal={ handleModal } open={ !!(open === 'EDIT_LIST') } />
            </main>
        </>
    );
};

export default ContactList;
