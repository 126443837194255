import { MESSAGE } from '../constants/app'
import _ from 'lodash'
import { SubmissionError } from 'redux-form'

export const loginValidate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = MESSAGE.VALID_ENTER('email');
    }
    if (!values.password) {
        errors.password = MESSAGE.VALID_ENTER('password');
    }
    return errors;
}

export const registerValidate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = MESSAGE.VALID_ENTER('email');
    }
    if(values.email && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))){
        errors.email = MESSAGE.INVALID_EMAIL;
    }
    if (!values.password) {
        errors.password = MESSAGE.VALID_ENTER('password');
    }
    if (values.password && values.password.length < 8 ) {
        errors.password = MESSAGE.SHOULD_BE_LENGTH('password',8);
    }

    return errors;
}
export const forgetPasswordValidate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = MESSAGE.VALID_ENTER('email');
    }
    if (!values.code) {
        errors.code = MESSAGE.VALID_ENTER('OTP');
    }
    if (!values.password) {
        errors.password = MESSAGE.VALID_ENTER('password');
    }
    if (values.password && values.password.length < 8 ) {
        errors.password = MESSAGE.SHOULD_BE_LENGTH('password',8);
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = MESSAGE.VALID_ENTER('confirm password');
    }
    if (values.confirmPassword && values.confirmPassword.length < 8 ) {
        errors.confirmPassword = MESSAGE.SHOULD_BE_LENGTH('confirm password',8);
    }
    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = MESSAGE.PASSWORD_MATCH
    }
    return errors;
}

export const assessmentFormValidate = values => {
    const errors = {};
    const letter = /[^a-zA-Z0-9 ]/gi;
    if (!values.websiteName) {
        errors.websiteName = MESSAGE.REQUIRED;
    } else if(values.websiteName && (values.websiteName.match(letter))) {
        errors.websiteName = MESSAGE.SHOULD_BE_CHARACTER;
    }
    if (!values.colors) {
        errors.colors = MESSAGE.REQUIRED;
    }
    if(!values.domain || values.domain === 'null'){
        errors.domain = MESSAGE.VALID_SELECT('domain');
    }
    return errors;
}

export const blogValidate = values => {
    const errors = {};
    if (!values.title) {
        errors.title = MESSAGE.VALID_ENTER('title');
    }
    if (!values.blogUrl) {
        errors.blogUrl = 'Please add image';
    }
    if (!values.description) {
        errors.description = 'Please enter description';
    }
    if (!values.seoDescription) {
        errors.seoDescription = 'Please enter SEO description';
    }
    if (!values.seoTitle) {
        errors.seoTitle = 'Please enter SEO title';
    }

    return errors;
}
export const pageValidate = values => {
    const errors = {};
    if (!values.title) {
        errors.title = MESSAGE.VALID_ENTER('title');
    }
    if (!values.description) {
        errors.description = 'Please enter description';
    }
    if(values.slug && values.slug.match(/[&/: \\#,+!()^@$~%'";*?<>{}]/g)){
        errors.slug = 'invalid url';
    }

    return errors;
}
export const builderPageValidate = values => {
    const errors = {};
    if (!values.title) {
        errors.title = MESSAGE.VALID_ENTER('title');
    }
    if (!values.description) {
        errors.description = 'Please enter description';
    }
    if(values.gjsSlug && values.gjsSlug.match(/[&/: \\#,+!()^@$~%'";*?<>{}]/g)){
        errors.gjsSlug = 'invalid url';
    }

    return errors;
}
export const updateUserProfileValidate = values => {
    const errors = {};
    const letter = /^[a-zA-Z][a-zA-Z\s]*$/;
    if(!values.firstName){
        errors.firstName = MESSAGE.VALID_ENTER('first name');
    }
    if(!values.lastName){
        errors.lastName = MESSAGE.VALID_ENTER('last name');
    }
    if(values.firstName && !values.firstName.match(letter)){
        errors.firstName = MESSAGE.SHOULD_CHAR;
    }
    if(values.lastName && !values.lastName.match(letter)){
        errors.lastName = MESSAGE.SHOULD_CHAR;
    }
    if(values.phone && (values.phone.length < 12 ) ){
        errors.phone = MESSAGE.INVALID_MOBILE;
    }
    return errors;
}

export const contactValidate = values => {
    const errors = {};
    if(values.phone && (values.phone.length < 12 ) ){
        errors.phone = MESSAGE.INVALID_MOBILE;
    }
    return errors
}

const throwError = (type,error) => {
    throw new SubmissionError({
        [ type ]: error,
        _error: error
    })
}
export const retargetValidate = (values,modalType) => {
    const errors = {}
    const type = modalType || null
    const value =  values[ modalType ] || null
    const headValue = values[ 'googleTagManagerHead' ]
    const bodyValue = values[ 'googleTagManagerBody' ]
    if(value || (headValue && bodyValue)){
        switch(type){
        case 'googleAnalytics':
            errors[ type ] = value.match('<script>') && (value.match('https://www.google-analytics.com/analytics.js') || value.match('https://www.googletagmanager.com/gtag/js')) ? null : 'Invalid google analytics script'
            if (errors[ type ]) { throwError( modalType,'Invalid google analytics script' ) }
            return true
        case 'googleTagManager':
            errors[ 'googleTagManagerHead' ] = headValue.match('<script>') && headValue.match('https://www.googletagmanager.com/gtm.js') ? null : 'Invalid google tag mangager head script'
            errors[ 'googleTagManagerBody' ] = bodyValue.match('https://www.googletagmanager.com') ? null : 'Invalid google tag mangager body script'
            if (errors[ 'googleTagManagerHead' ]) { throwError( 'googleTagManagerHead','Invalid google tag mangager head script' ) }
            if (errors[ 'googleTagManagerBody' ]) { throwError( 'googleTagManagerBody','Invalid google tag mangager body script' ) }
            return true
        case 'facebookPixel':
            errors[ type ] =value.match(/https?:\/\/connect.facebook.net\/[a-zA-Z_]{1,20}\/fbevents.js\/*/) ? null : 'Invalid facebook pixel script'
            if (errors[ type ]) { throwError( modalType,'Invalid facebook pixel script' )}
            return true
        // case 'googleSearchConsole':
        //     errors[ type ] =value.match('google-site-verification') ? null : 'Invalid google search console tag'
        //     if (errors[ type ]) { throwError( modalType,'Invalid google search console tag')}
        //     return true
        case 'googleAdSense':
            errors[ type ] = value.match('<script') && (value.match('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')) ? null : 'Invalid google AdSense script'
            if (errors[ type ]) { throwError( modalType,'Invalid google AdSense script' ) }
            return true
        case 'pinterest':
            errors[ type ] = value.match('<meta') && value.match('p:domain_verify') ? null : 'Invalid Pinterest script'
            if (errors[ type ]) { throwError( modalType,'Invalid Pinterest script' ) }
            return true
        case 'headCode':
            let msg = '';
            const html = value.toString().replace(/\n/g, '');
            const scriptTag = html.toString().match(/<script(.*?)<\/script>/g);
            const metaTag = html.toString().match(/<meta(.*?)>/g);
            if(!scriptTag?.length && !metaTag?.length){
                errors[ type ] = 'headCode'
                msg = 'Invalid Head code, script or meta has to be provided'
            }

            scriptTag?.forEach(element => {
                if(!/<script([^>]*)?>.*?<\/script>/.test(element)){
                    errors[ type ] = 'headCode'
                    msg = 'Invalid script tag'
                }
            });
            metaTag?.forEach(element => {
                if(!/<meta([^>]*)?>/.test(element)){
                    errors[ type ] = 'headCode'
                    msg = 'Invalid meta tag'
                }
            });

            if (errors[ type ]) { throwError( modalType,msg ) }
            return true
        case 'ads':
            return true
        }
    }else{
        return true
    }
}
export const paymentFormValidate = values => {
    const errors = {};
    if(!values.name){
        errors.name = MESSAGE.VALID_ENTER('name');
    }
    if(_.isEmpty(values.cardNumber)){
        errors.cardNumber =  MESSAGE.VALID_ENTER('Card Number');
    }else if(values.cardNumber?.cardNumber){
        errors.cardNumber =  values.cardNumber?.cardNumber;
    }
    if(_.isEmpty(values.cardExpiry)){
        errors.cardExpiry = MESSAGE.VALID_ENTER('Card Expiry');
    }else if(values.cardExpiry?.cardExpiry){
        errors.cardExpiry =  values.cardExpiry?.cardExpiry;
    }

    if(_.isEmpty(values.cardCvc)){
        errors.cardCvc =  MESSAGE.VALID_ENTER('Card CVV');
    }else if(values.cardCvc?.cardCvc){
        errors.cardCvc =  values.cardCvc?.cardCvc;
    }
    return errors;
}

export const emailMarketingFormValidate = values => {
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);
    const errors = {};

    if (!values.url) {
        errors.url = 'Please enter URL';
    }
    if (!values.key) {
        errors.key = 'Please enter Key';
    }

    if (!values.activeCampaignAccount) {
        errors.activeCampaignAccount = 'Select an account';
    }

    if(values.url && !(values.url.match(regex))){
        errors.url = 'Invalid URL';
    }

    return errors;
}