import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import ButtonLoader from 'components/core/loader/button-loader'
import { change as reduxChange } from 'redux-form'
import { MESSAGE } from 'constants/app';
import '../assessment/style.sass';

const ActiveCampaign = (props) => {
    const dispatch = useDispatch();
    const { onClose, loading, reduxFormValues, site } = props
    const [ isNameField, setNameField ] = useState(reduxFormValues?.activeCampaign?.isNameField || true)
    const [ useExternalLink, setExternalLink ] = useState(reduxFormValues?.activeCampaign?.useExternalLink || false)
    const [ isOpenNewTab, setOpenNewTab ] = useState(reduxFormValues?.activeCampaign?.isOpenNewTab || false)
    const [ formValues, setFormValues ] = useState({ ...reduxFormValues?.activeCampaign } || { })
    const [ fieldErrors, setFieldErrors ] = useState({})
    const [ btnText, setBtnText ] = useState(reduxFormValues?.activeCampaign?.buttonText || '');
    const [ externalLinkUrl, setExternalLinkUrl ] = useState(reduxFormValues?.activeCampaign?.externalLink || '');
    const [ previewBtnColor, setPreviewButtonColor ] = useState('');
    const [ previewBtnTextColor, setPreviewBtnTextColor ] = useState('');
    const [ subBtnShape, setSubBtnShape ] = useState(reduxFormValues?.activeCampaign?.btnShape || 'Square')

    const BTN_SHAPE_TYPES = [ 'Square', 'Rounded', 'Round' ];

    const BTN_BORDER_RADIUS = {
        'Square': '0px',
        'Rounded': '8px',
        'Round': '100px',
    };

    useEffect(() => {
        setNameField(reduxFormValues?.activeCampaign?.isNameField ?? true);
    }, [ reduxFormValues?.activeCampaign?.isNameField ]);

    useEffect(() => {
        if (site?.colors) {
            const btnColor = JSON.parse(site.colors)[ 'button' ];
            const btnTextColor = JSON.parse(site.colors)[ 'button-font' ];
            setPreviewButtonColor(btnColor || '');
            setPreviewBtnTextColor(btnTextColor || '');
        }
    }, [ site ]);

    const handleChange = (event) => {
        formValues[ event.target.name ] = event.target.value
        setFormValues({ ...formValues })
        setFieldErrors({})
    }

    const handleRadioChange = (event) => {
        formValues[ event.target.name ] = event.target.value
        setFormValues({ ...formValues })
        setFieldErrors({})
    }
    const validateData = () => {
        if (formValues?.useExternalLink) {
            if (!formValues?.externalLink) {
                fieldErrors[ 'externalLink' ] = MESSAGE.VALID_ENTER('url')
                setFieldErrors({ ...fieldErrors })
                return false
                // eslint-disable-next-line no-useless-escape
            } else if (!(formValues?.externalLink.match(new RegExp(/https?:\/\//)))) {
                fieldErrors[ 'externalLink' ] = MESSAGE.INVALID_URL
                setFieldErrors({ ...fieldErrors })
                return false
            } else {
                setFieldErrors({ ...fieldErrors })
                return true
            }
        } else {
            return true

        }
    }

    const handleSubmit = () => {
        const formValuesObj = { ...formValues }
        formValuesObj[ 'isOpenNewTab' ] = isOpenNewTab
        formValuesObj[ 'isNameField' ] = isNameField
        formValuesObj[ 'useExternalLink' ] = useExternalLink;
        formValuesObj[ 'buttonText' ] = btnText;
        formValuesObj[ 'externalLink' ] = externalLinkUrl;
        formValuesObj[ 'btnShape' ] = subBtnShape;
        if(!formValuesObj?.actionName){
            formValuesObj[ 'actionName' ] = '';
        }
        if(!formValuesObj?.tagline){
            formValuesObj[ 'tagline' ] = '';
        }
        setFormValues({ ...formValuesObj })
        if (validateData()) {
            dispatch(reduxChange('assessmentUpdateForm', 'activeCampaign', formValuesObj))
            onClose()
        }
    }

    return (
        <Form onSubmit={ () => { } }>
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>Action Button</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="active-campaign-selection template-form-group">
                    <Row>
                        <Col xs={ 12 } md={ 7 }>
                            <div className="active-campaign-template-selection">
                                <div className='left-top-section'>
                                    <Form.Group>
                                        <label>Call to action </label>
                                        <input className="form-control" defaultValue={ formValues?.actionName } onChange={ handleChange } type='text' name='actionName' placeholder='Subscribe for more!' />
                                    </Form.Group>
                                    <Form.Group>
                                        <label>Tagline</label>
                                        <input
                                            className="form-control"
                                            defaultValue={ formValues?.tagline }
                                            onChange={ (e) => {
                                                handleChange(e);
                                                // setBtnText(e.target.value);
                                            } }
                                            type='text'
                                            name='tagline'
                                            placeholder='Tagline'
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor='isNameField' className="active-campaign-checkbox">
                                            <input id='isNameField' name='isNameField' type="checkbox" checked={ isNameField } onChange={ (event) => {
                                                handleRadioChange(event)
                                                setNameField(event.target.checked)
                                            }
                                            } />
                                            <span className='ml-1'>{"Add 'Name' field"}</span>
                                        </label>

                                    </Form.Group>
                                    <Form.Group>
                                        <label>Button Text </label>
                                        <input
                                            className="form-control"
                                            defaultValue={ formValues?.buttonText }
                                            onChange={ (e) => {
                                                handleChange(e);
                                                setBtnText(e.target.value);
                                            } }
                                            type='text'
                                            name='buttonText'
                                            placeholder='Subscribe'
                                        />
                                    </Form.Group>
                                </div>
                                <div className='external-link-box'>
                                    <Form.Group>
                                        <label htmlFor='useExternalLink' className="active-campaign-checkbox">
                                            <input id='useExternalLink' name='useExternalLink' type="checkbox" checked={ useExternalLink } onChange={ (event) => {
                                                handleRadioChange(event)
                                                setExternalLink(event.target.checked)
                                            }
                                            } />
                                            <span className='ml-1'>Use external link</span>
                                        </label>

                                    </Form.Group>
                                    {useExternalLink && <>
                                        <Form.Group>
                                            <label>External link </label>
                                            <input
                                                className="form-control"
                                                defaultValue={ formValues?.externalLink }
                                                onChange={ (e) => {
                                                    handleChange(e);
                                                    setExternalLinkUrl(e.target.value);
                                                } }
                                                type='text'
                                                name='externalLink'
                                            />
                                            {fieldErrors?.externalLink && <span className="field_error"> {fieldErrors.externalLink}</span>}
                                        </Form.Group>
                                        <Form.Group>
                                            <label htmlFor='isOpenNewTab' className="active-campaign-checkbox">
                                                <input id='isOpenNewTab' name='isOpenNewTab' type="checkbox" checked={ isOpenNewTab } onChange={ (event) => {
                                                    setOpenNewTab(event.target.checked)
                                                    handleRadioChange(event)
                                                } } />
                                                <span className="ml-1">Open link in new tab</span>
                                            </label>

                                        </Form.Group></>

                                    }
                                </div>
                                <div className='btn-shapes-container'>
                                    <label>Button Shape</label>
                                    <div className="shape-btn-group">
                                        {
                                            BTN_SHAPE_TYPES.map((btnType) => (
                                                <>
                                                    <Button
                                                        className={ btnType === subBtnShape ? `${ btnType }-btn activecls` : `${ btnType }-btn` }
                                                        type='button'
                                                        onClick={ () => {
                                                            setSubBtnShape(btnType);
                                                        } }
                                                        variant="primary"
                                                        name="btnShape"
                                                    >
                                                        {btnType}
                                                    </Button>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={ 12 } md={ 5 }>
                            <div className="active-campaign-template-preview">
                                <div className='active-campaign-palate header-text-edit wizrd-blog-preview wizrd-inner-preview'>
                                    <h5 className='preview-title'>{'Preview'}</h5>
                                    <div className='preview-section'>
                                        <h5 className='action-name'>{formValues?.actionName || 'Subscribe for more content'}</h5>
                                        <h6 className='action-tagline'> {formValues?.tagline || 'Sign up below to receive email updates and news!'} </h6>
                                        {isNameField && <Form.Group>
                                            <label>Name</label>
                                            <input className="form-control" type='text' />
                                        </Form.Group>}
                                        <Form.Group>
                                            <label>Email</label>
                                            <input className="form-control" type='text' />
                                        </Form.Group>
                                        <div className="font-color-selector mt-3">
                                            <button
                                                className='btn btn-primary'
                                                style={ {
                                                    backgroundColor: previewBtnColor,
                                                    borderColor: previewBtnColor,
                                                    color: previewBtnTextColor,
                                                    borderRadius: BTN_BORDER_RADIUS[ subBtnShape ],

                                                } }
                                            >
                                                {formValues?.buttonText || 'subscribe'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-btns">
                                <Button className="btn-primary" type='button' onClick={ onClose } variant="primary">Close</Button>
                                <ButtonLoader
                                    button={ <Button className="btn-primary" type='button' onClick={ handleSubmit } variant="primary">confirm</Button> }
                                    loadingText='Saving'
                                    loading={ loading }
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Form>)
}

ActiveCampaign.propTypes = {
    onClose: PropTypes.func,
    loading: PropTypes.boolean,
    reduxFormValues: PropTypes.object,
    site: PropTypes.object,
};

export default ActiveCampaign