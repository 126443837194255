import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import closeIcon from 'images/close.svg';
import { extractFirstAndLastName } from './contact';
import { useDispatch, useSelector } from 'react-redux';
import { addNewSubscriber, updateUser } from 'middleware/email';
import PropTypes from 'prop-types';
import { ALPHAREG } from 'constants/validatorRegex';

const EditUser = ({ open, handleModal, selectedRow, list, setSelectedRow }) => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.email);
    const [ activeTab, setActiveTab ] = useState(0);
    const [ currentList, setCurrentList ] = useState([]);
    const [ groupList, setGroupList ] = useState([]);

    const addToCurrentList = (item) => {
        setCurrentList([ ...currentList, item ]);
        setGroupList(groupList.filter((listItem) => listItem?.id !== item?.id));
    };
    const removeFromCurrentList = (item) => {
        setGroupList([ ...groupList, item ]);
        setCurrentList(currentList.filter((listItem) => listItem?.id !== item?.id));
    };
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ errors, setErrors ] = useState({
        firstName: '',
        lastName: '',
        email: '',
        currentList: ''
    });

    const handleReset = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setErrors({
            firstName: '',
            lastName: '',
            email: '',
            currentList: ''
        });
        setCurrentList([]);
        setGroupList(list);
        setSelectedRow(null);
        handleModal(null);
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // add validation
        const newErrors = {};
        if (firstName.trim() === '' && !selectedRow?.id) {
            newErrors.firstName = 'First name is required';
        }
        if (lastName.trim() === '' && !selectedRow?.id) {
            newErrors.lastName = 'Last name is required';
        }
        if (email.trim() === '') {
            newErrors.email = 'Email is required';
        }
        if (currentList?.length === 0) {
            newErrors.currentList = 'Atleast one list should be added';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length !== 0) {
            return;
        }

        const listIds = currentList?.map((i) => i.id);
        const removedIds =
        (selectedRow &&
            selectedRow?.subscribersListNames
                ?.filter((i) => listIds.indexOf(i?.id) === -1)
                ?.map((i) => i.id)) ??
        [];

        const subscriptionId = selectedRow?.id ?? null;

        const payload = {
            subscribersListIds: listIds,
            removedListIds: removedIds,
            email: email,
            name: `${ firstName } ${ lastName }`,
        };
        if (selectedRow?.id) {
            // edit
            dispatch(updateUser(payload, subscriptionId, handleReset));
        } else {
            // add
            dispatch(addNewSubscriber(payload, handleReset));
        }
    };

    useEffect(() => {
        if (selectedRow) {
            setFirstName(extractFirstAndLastName(selectedRow?.name)?.firstName ?? '');
            setLastName(extractFirstAndLastName(selectedRow?.name)?.lastName ?? '');
            setEmail(selectedRow?.email);
            setCurrentList(selectedRow?.subscribersListNames ?? []);
            const currentSubscribed =
            selectedRow?.subscribersListNames?.map((i) => i?.id) ?? [];
            setGroupList(list.filter((i) => currentSubscribed.indexOf(i.id) === -1));
        } else {
            setGroupList(list);
        }
    }, [ selectedRow, list ]);

    return (
        <Modal
            show={ open }
            onHide={ handleReset }
            className="image-upload-modal contact-modal-width new-modal embed-link-modal"
        >
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>
                        {selectedRow?.id ? 'Edit User' : 'Add User'}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="content-generator-wrapper">
                <form className="add-user-form-container">
                    <div className="add-user-form-tabs">
                        <div className={ activeTab === 0 ? 'add-user-tab active-tab' : 'add-user-tab' } onClick={ () => handleTabClick(0) }>Single User</div>
                        <div className={ activeTab === 1 ? 'add-user-tab active-tab' : 'add-user-tab' } onClick={ () => handleTabClick(1) }>Bulk Import</div>
                    </div>
                    {activeTab === 0 &&
                        <div className="add-form-content d-flex flex-column justify-content-center align-items-start w-100">
                            <div className="form-fields w-100">
                                <div
                                    className="d-flex align-items-start flex-column w-100"
                                    style={ { gap: '8px' } }
                                >
                                    <div className="field-label">First Name</div>
                                    <input
                                        className="field-input"
                                        name="firstName"
                                        value={ firstName }
                                        onChange={ (e) => setFirstName(e.target.value) }
                                        placeholder="Enter First Name"
                                        onKeyDown={ (e)=>{
                                            if (!ALPHAREG.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                            }
                                        } }
                                    />
                                    {errors.firstName && (
                                        <span style={ { fontSize: '14px', color: 'red' } }>
                                            {errors.firstName}
                                        </span>
                                    )}
                                </div>
                                <div
                                    className="d-flex align-items-start flex-column w-100"
                                    style={ { gap: '8px' } }
                                >
                                    <div className="field-label">Last Name</div>
                                    <input
                                        className="field-input"
                                        name="lastName"
                                        value={ lastName }
                                        onChange={ (e) => setLastName(e.target.value) }
                                        placeholder="Enter Last Name"
                                        onKeyDown={ (e)=>{
                                            if (!ALPHAREG.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                            }
                                        } }
                                    />
                                    {errors.lastName && (
                                        <span style={ { fontSize: '14px', color: 'red' } }>
                                            {errors.lastName}
                                        </span>
                                    )}
                                </div>
                                <div
                                    className="d-flex align-items-start flex-column w-100"
                                    style={ { gap: '8px' } }
                                >
                                    <div className="field-label">User Email</div>
                                    <input
                                        className="field-input"
                                        name="email"
                                        value={ email }
                                        onChange={ (e) => setEmail(e.target.value) }
                                        placeholder="Enter User Email"
                                    />
                                    {errors.email && (
                                        <span style={ { fontSize: '14px', color: 'red' } }>
                                            {errors.email}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="select-list d-flex align-items-self flex-column w-100">
                                <div className='d-flex flex-column' style={ { gap:'5px' } }>
                                    { selectedRow ?
                                        (<div className="select-list-label mb-2">Current Lists</div>) :
                                        (<div className="select-list-label">
                                            {currentList.length > 1
                                                ? 'User will be added to these products'
                                                : 'Enrolled In'}
                                        </div> )}
                                    <ul
                                        className="d-flex mb-0"
                                        style={ { gap: '8px', flexWrap: 'wrap' } }
                                    >
                                        {currentList.map((item, index) => {
                                            return (
                                                <li
                                                    className="d-flex align-items-center"
                                                    style={ { gap: 5 } }
                                                    key={ index }
                                                >
                                                    {`${ item?.listName } (${ item?.subscribersCount ?? '' })`}
                                                    <img
                                                        className="list-close-icon"
                                                        src={ closeIcon }
                                                        width={ 14 }
                                                        height={ 14 }
                                                        onClick={ () =>  removeFromCurrentList(item) }
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    {errors.currentList && (
                                        <span style={ { fontSize: '14px', color: 'red' } }>
                                            {errors.currentList}
                                        </span>
                                    )}
                                </div>

                                <div className="select-list-label">Add to Lists</div>
                                <ul
                                    className="d-flex mb-0"
                                    style={ { gap: '8px', flexWrap: 'wrap' } }
                                >
                                    {groupList.map((item, index) => {
                                        return (
                                            <li
                                                key={ index }
                                                onClick={ () =>  addToCurrentList(item) }
                                            >
                                                {`${ item?.listName } (${ item?.subscribersCount ?? '' })`}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    }
                    {activeTab === 1 &&
                        <>
                            <div className='upload-svg-title align-self-center'>Upload a CSV of your subscribers</div>
                            <div className='drag-file'>
                                Drag your file here
                            </div>
                            <div className='file-type align-self-center'>File Type Supported: .csv</div>
                            <div className='upload-file-btn align-self-center'><input className='csv-input position-absolute' type="file"/><span className='plus-style'>+</span><span className='upload-file-btn-text'>Upload File</span></div>
                            <div className="add-form-content d-flex flex-column justify-content-center align-items-start">

                                <div className="select-list d-flex align-items-self flex-column">
                                    <div>

                                        <div className="select-list-label mb-2">Current Lists</div>
                                        {/* (<div className="select-list-label">
                                                {currentList.length > 1
                                                    ? 'User will be added to these products'
                                                    : 'Enrolled In'}
                                            </div>  */}
                                        <ul
                                            className="d-flex mb-0"
                                            style={ { gap: '8px', flexWrap: 'wrap' } }
                                        >
                                            {currentList.map((item, index) => {
                                                return (
                                                    <li
                                                        className="d-flex align-items-center"
                                                        style={ { gap: 5 } }
                                                        key={ index }
                                                    >
                                                        {item?.listName}
                                                        <img
                                                            className="list-close-icon"
                                                            src={ closeIcon }
                                                            width={ 14 }
                                                            height={ 14 }
                                                            onClick={ () =>  removeFromCurrentList(item) }
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className="select-list-label">Add to Lists</div>
                                    <ul
                                        className="d-flex mb-0"
                                        style={ { gap: '8px', flexWrap: 'wrap' } }
                                    >
                                        {groupList.map((item, index) => {
                                            return (
                                                <li
                                                    key={ index }
                                                    onClick={ () =>  addToCurrentList(item) }
                                                >
                                                    {item?.listName}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                    {activeTab === 0 ? <button type="button" onClick={ handleSubmit } className="confirm-btn" disabled={ loading }>
                        {loading ? (
                            <Spinner animation="border" size={ 'sm' } role="status" />
                        ) : (
                            'Confirm'
                        )}
                    </button> : <button type="button" className="confirm-btn align-self-center">
                        Confirm
                    </button>}
                </form>
            </Modal.Body>
        </Modal>
    );
};

EditUser.propTypes = {
    open: PropTypes.bool,
    handleModal: PropTypes.func,
    setSelectedRow: PropTypes.func,
    selectedRow: PropTypes.array,
    list: PropTypes.array,
};

export default EditUser;
