import React, {  useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Form } from 'react-bootstrap';

import { PLAN_TYPE } from 'constants/app';
import PlanList from './planList';

const PlanDetail = (props) =>  {
    const { planSelectionHandler, selectedPlanId, heading, subHeading, secondHeading } = props;
    const [ isMonthlyPlan, setMonthlyPlan ] = useState(false);

    const handlePlanType = (event) => {
        setMonthlyPlan(event.target.checked)
    }

    return(<section className="website-plan pt-3 pb-5">
        <Container  className="home-container">
            {heading && <h3 className={ 'titleHeading mb-0' }>{heading}</h3>}
            {secondHeading && <h4 className={ 'subheading mb-0' }>{secondHeading}</h4>}
            <Form className="select_plan">
                <p className="switch_text">Annually</p>
                <Form.Check type="switch" defaultChecked={ isMonthlyPlan } onChange={ handlePlanType } id="custom-switch" label="" />
                <p className="">Monthly</p>
            </Form>
            {subHeading && <h4 className={ 'subheading mb-0' }>{subHeading}</h4>}
            <div className="price-slider">
                <PlanList
                    planType={ isMonthlyPlan ? PLAN_TYPE.MONTHLY_PLAN : PLAN_TYPE.ANNUAL_PLAN }
                    planSelectionHandler={ planSelectionHandler }
                    selectedPlanId={ selectedPlanId }
                />
            </div>
            <div className="pricePlanText">
                <h3>Setup your website, pick a plan later</h3>
                <h4>Try Wizrd FREE for 14-days, No Credit Card required</h4>
            </div>
        </Container>
    </section>)
}

PlanDetail.propTypes = {
    heading: PropTypes.any,
    secondHeading: PropTypes.string,
    subHeading: PropTypes.string,
    planSelectionHandler: PropTypes.func,
    selectedPlanId: PropTypes.number
};

export default PlanDetail
