import { ActionTypes } from './actionTypes';
export const publishListRequest = () => {
    return {
        type: ActionTypes.PUBLISH_LIST_REQUEST,
    };
};

export const publishListFailed = (error) => {
    return {
        type: ActionTypes.PUBLISH_LIST_FAILURE,
        error: error
    };
};

// add and edit
export const publishRequest = () => {
    return {
        type: ActionTypes.PUBLISH_REQUEST,
    };
};

export const publishGroupList = (data) => {
    return {
        type: ActionTypes.PUBLISH_GROUP_LIST,
        payload: data
    };
};
export const publishAllEmailList = (data) => {
    return {
        type: ActionTypes.PUBLISH_ALL_EMAIL_LIST,
        payload: data
    };
};

export const publishSubscribersList = (data) => {
    return {
        type: ActionTypes.PUBLISH_SUBSCRIBERS_LIST,
        payload: data
    };
};

export const publishFailed = (error) => {
    return {
        type: ActionTypes.PUBLISH_FAILURE,
        error: error
    };
};

export const publishSuccess = () => {
    return {
        type: ActionTypes.PUBLISH_SUCCESS,
    };
};
export const updateDomainData = (data) => {
    return {
        type: ActionTypes.PUBLISH_DOMAIN_DATA,
        payload: data
    };
};
export const publishDomainRequestFailed = () => {
    return {
        type: ActionTypes.PUBLISH_DOMAIN_REQUEST_FAILURE,
    };
};
export const initiateDomainRequest = () => {
    return {
        type: ActionTypes.INITIATE_DOMAIN_REQUEST,
    };
};