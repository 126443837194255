import React from 'react'
import {
    Container
} from 'react-bootstrap';
import copyrightLogo from '../../../images/powered-by-wizrd.svg';
const AssessmentStepFooter = () => {
    return (
        <div className="copyright-footer">
            <Container>
                <div className="copyright-img">
                    <img src={ copyrightLogo } />
                </div>
            </Container>
        </div>
    )
}

export default AssessmentStepFooter