/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import { renderOTPField } from '../../utils/formUtils'
import PropTypes from 'prop-types';
import { passwordResendCode } from '../../middleware/auth'
import { useDispatch, useSelector } from 'react-redux'
import ButtonLoader from 'components/core/loader/button-loader'
import
{
    Form,
    Button
} from 'react-bootstrap';
const OtpForm = (props) => {
    const { handleSubmit, submitData } = props;
    const form  = useSelector((state) => state.form.forgetPassword)
    const loading = useSelector((state)=> state.user.loading)
    const dispatch = useDispatch()
    const resendCode = () => {
        dispatch(passwordResendCode(form.values.email))
    }
    return(
        <div className="forget-forms signup-forms">
            <Form onSubmit={ handleSubmit(submitData) }>
                <div className="forget-form-inner">
                    <div className="forget-forms-element">
                        <h1 className="section-heading">
                            Reset password
                        </h1>
                        <p className="heading-detail">
                            insert the verification code you receive in your mail.
                        </p>
                        <div className="small-wrapper">
                            <Field
                                label="Code"
                                name="code"
                                type="text"
                                numInputs={ 6 }
                                component={ renderOTPField }
                                formClass="otpFieldsInput"
                            />

                            <Form.Text className="form-text">
                                Review your email!
                            </Form.Text>
                        </div>
                    </div>
                    <div className="forgot-btns">
                        <div className="small-wrapper">
                            <ButtonLoader
                                button={ <Button className="btn btn-primary" type="submit">
                                    Continue
                                </Button> }
                                loadingText={ 'Continue' }
                                loading={ loading }
                                className="btn btn-primary"
                            />
                            <p className="heading-detail">
                                Didn't receive code?  <Link to="#" onClick={ resendCode }>Send again</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </Form>
        </div>

    )
}
OtpForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitData: PropTypes.func
};
export default OtpForm;