import { ROUTES } from '../constants/appRoutes';
import { NOTIFICATION_TYPES,MESSAGE } from '../constants/app';
import { notification } from '../services/notification';
import { createAssessment, imageUpload } from './assessments/'
import axiosInstance from '../services/api';
import { getLocalItem,setItem, getLocalSessionItem, setLocalItem } from '../utils/cache';
import * as Sentry from '@sentry/react';
import history from '../utils/history'
import { getDomain, getResizeImage, getUser ,isBlockedSite } from 'utils/helpers'
import _ from 'lodash';
import {
    loginRequest,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    registrationRequest,
    registrationSuccess,
    registrationFailure,
    emailVerificationSuccess,
    emailVerificationFailure,
    resentCodeSuccess,
    resendCodeFailure,
    resendCodeRequest,
    forgetPasswordRequest,
    forgetPasswordSuccess,
    forgetPasswordFailure,
    updateUserProfileRequest,
    updateUserProfileFailure,
    updateUserProfileSuccess,
    getUserSuccess,
    getStatsRequest,
    getStatsSuccess,
    getStatsError,
    getFaqsRequest,
    getFaqsSuccess,
    getFaqsError

} from '../actions/user/auth'
import { dataURLtoFile, uId , setTour } from 'utils/helpers';

export const setWebsiteById = async(id) => {
    const response = await axiosInstance.get(`/user/sites/${ id }`)
    setLocalItem('site',response.data)
}

export const loginUser = (data, handleEvents) => {
    data[ 'origin' ] = process.env.REACT_APP_WEBSITE_TYPE
    return (dispatch) => {
        dispatch(loginRequest())
        axiosInstance.post('/auth/login', data).then(async(response)=>{
            response.data.data[ 'siteId' ] && await setWebsiteById(response.data.data[ 'siteId' ])
            response.data.data[ 'accessToken' ] = response.data.accessToken
            setLocalItem('user', response.data.data)
            Sentry.setContext('extra', {
                email: response.data.data.email,
                env: process.env.REACT_APP_NODE_ENV
            });
            dispatch(loginSuccess(response.data.data))
            handleEvents && handleEvents(response.data.data ,isBlockedSite(response.data.data.status) )
            isBlockedSite(response.data.data.status) ? history.push(ROUTES.BILLING) : history.push(ROUTES.DASHBOARD)
            // assessmentData && dispatch(createAssessment(assessmentData))
        }).catch((error) => {
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message)
            dispatch(loginFailure(error?.response?.data?.message))
        })
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch(loginRequest())
        sessionStorage.clear()
        localStorage.clear()
        dispatch({
            type: 'USER_LOGOUT'
        })
        dispatch(logoutSuccess())
        history.push(ROUTES.LOGIN)
    };
};

export const registrationUser = (data, assessmentData, handleEvents ) => {
    data[ 'origin' ] = process.env.REACT_APP_WEBSITE_TYPE
    return (dispatch) => {
        dispatch(registrationRequest())
        axiosInstance.post('/auth/signup', data)
            .then((response) => {
                response.data.data[ 'accessToken' ] = response.data.accessToken
                response.data.data[ 'test' ] = false
                setItem('user', response.data.data)
                Sentry.setContext('extra', {
                    email: response.data.data.email,
                    env: process.env.REACT_APP_NODE_ENV
                });
                handleEvents && handleEvents(response.data.data)
                dispatch(registrationSuccess(response.data.data))
                !_.isEmpty(assessmentData) && dispatch(createAssessment(assessmentData,true,false))
                setTour()

            })
            .catch((error) => {
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message)
                dispatch(registrationFailure(error?.response?.data?.message));
            });

    };
};

export const emailVerification = (data,handleEvents) => {
    data[ 'origin' ] = process.env.REACT_APP_WEBSITE_TYPE
    return (dispatch) => {
        dispatch(registrationRequest())
        axiosInstance.get(`/user/verify?code=${ data.code }`)
            .then((response) => {
                let  user = getLocalSessionItem('user');
                user = JSON.parse(user)
                user[ 'enabled' ] = true
                setLocalItem('user', user)
                sessionStorage.removeItem('user')
                history.push(ROUTES.DASHBOARD)
                dispatch(emailVerificationSuccess(response))
                handleEvents && handleEvents(data)
            })
            .catch((error) => {

                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message)
                dispatch(emailVerificationFailure(error?.response?.data?.message));
            });

    };
};

export const resendCode = (data) => {
    return (dispatch) => {
        dispatch(resendCodeRequest())
        axiosInstance.get('/user/verify/resend', data)
            .then((response) => {
                dispatch(resentCodeSuccess(response))
            })
            .catch((error) => {
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message)
                dispatch(resendCodeFailure(error?.response?.data?.message));
            });

    };
};

export const passwordResendCode = (email) => {
    return (dispatch) => {
        dispatch(resendCodeRequest())
        axiosInstance.post('/password/resend', { email: email , origin: process.env.REACT_APP_WEBSITE_TYPE })
            .then((response) => {
                dispatch(resentCodeSuccess(response))
            })
            .catch((error) => {
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message)
                dispatch(resendCodeFailure(error?.response?.data?.message));
            });

    };
};

export const forgetPassword = (step, setStep, data) =>{
    return(dispatch) => {
        dispatch(forgetPasswordRequest())
        const url = step === 1 ? '/password' : (step === 2 ) ? '/password/verify' : '/password/reset'
        const method = step === 3 ? 'put' : 'post'
        const message = step === 1 ? MESSAGE.CODE_SEND : (step === 3 ? 'Password updated.' : 'Verification success!' )
        data[ 'origin' ] = process.env.REACT_APP_WEBSITE_TYPE
        axiosInstance({
            method: method,
            url: url,
            data: data
        }).then((response) => {
            dispatch(forgetPasswordSuccess(response, step))
            notification(NOTIFICATION_TYPES.SUCCESS, message);
            if(step !== 3){
                setStep(step+1);
            }
        })
            .catch((error) => {
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message)
                dispatch(forgetPasswordFailure(error?.response?.data?.message));
            });
    }
}

export const getCurrentUser = () => {
    return (dispatch) => {
        axiosInstance.get('/user')
            .then((response) => {
                const user = getUser();
                if (response?.data?.data?.user) {
                    const subscription = { subscription: response.data.data.subscription }
                    const gtpTokens = { textGenerationUsage: response.data.data.user.textGenerationUsage }
                    setLocalItem('user', { ...user, ...response.data.data.user, ...subscription, ...gtpTokens  })
                }
                dispatch(getUserSuccess(response));
            })
            .catch((error) => {
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            });

    };
};

export const updateCurrentUser = (data, isShowSuccess, callback, eventName, noNotification) => {
    return async (dispatch) => {
        dispatch(updateUserProfileRequest());
        try {
            if(data.profileImageUrl && !data.profileImageUrl.match('^(http|https)://')){
                const file = dataURLtoFile(data.profileImageUrl,uId()+'.png')
                const compressedFile = await getResizeImage(file, 100, 100, 'WEBP', 'file',70);
                data[ 'profileImageUrl' ] = await imageUpload(getDomain(),'profile',compressedFile);
            }
            axiosInstance.put('/user', data)
                .then((response) => {
                    const user = JSON.parse(getLocalItem('user'));
                    setLocalItem('user', { ...user, ...response.data })
                    dispatch(getCurrentUser())
                    if (isShowSuccess) {
                        !noNotification && notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.USER_PROFILE_UPDATE_SUCCESS);
                    }
                    if (callback) {
                        callback(eventName, { id: user?.id, email: user?.email, userName: user?.userName, 'user identifier': response?.data?.id })
                    }
                    dispatch(updateUserProfileSuccess(response.data));
                })
                .catch((error) => {
                    notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
                    dispatch(updateUserProfileFailure(error?.response?.data?.message));
                });
        } catch(error) {
            dispatch(updateUserProfileFailure(error?.response?.data?.message));
        }
    };
};

export const trackDomainStatus = async(domain) => {
    const response  = await axiosInstance.get(`/build-status?domain=${ domain }`)
    return response

}

export const getStats = (siteId) => {
    return (dispatch) => {
        dispatch(getStatsRequest())
        axiosInstance.get(`/user/sites/${ siteId }/stats`).then((response) => {
            dispatch(getStatsSuccess(response.data))
        }).catch(() => {
            dispatch(getStatsError())
        })
    }
}

export const getFaqs = () => {
    return (dispatch) => {
        dispatch(getFaqsRequest())
        axiosInstance.get('/faq').then((response) => {
            dispatch(getFaqsSuccess(response.data))
        }).catch((error) => {
            dispatch(getFaqsError(error))
        })
    }
}