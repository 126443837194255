import React , { useState,useEffect, useContext } from 'react'
import { Modal,Button, Form } from 'react-bootstrap'
import GoogleAnalyticsImg from 'images/Google_analytics.png'
import FacebookPixelImg from 'images/Facebook_pixel.png'
import GoogleTagManagerImg from 'images/Google_tag_manager.png'
import GoogleSearchConsoleImg from 'images/Google_search_console.png'
import PropTypes  from 'prop-types'
import { mixpanelCommonData } from 'utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import { retargetValidate as validate } from 'utils/validates';
import ButtonLoader from 'components/core/loader/button-loader'
import { renderTextArea } from 'utils/formUtils'
import { updateRetargetData } from 'middleware/assessments'
import { MixpanelContext } from 'utils/tracking';
import _ from 'lodash'
import GoogleAdSenseImg from 'images/GoogleAdsense.png';
import PinterestImg from 'images/Pinterest.png';

const analyticsTypesTitle = {
    headCode : 'Advanced',
    ads : 'Ads.txt'
}

const Retarget = ( props ) => {
    const analyticsTypes = [ 'googleAnalytics','googleTagManager', 'facebookPixel', 'googleSearchConsole', 'googleAdSense', 'pinterest','headCode', 'ads'  ]
    const { handleSubmit, initialize , site } = props
    const analyticsHeaderData = site?.analyticsHeader || {}
    const dispatch = useDispatch()
    const mixpanel = useContext(MixpanelContext);

    const [ open, setOpen ] = useState(false)
    const retargetLoading = useSelector((state) => state.assessment.retargetLoading )
    const [ modalType , setModalType ] = useState(null)
    const getData = (data) => {
        const analyticsHeader = _.merge(analyticsHeaderData, data )
        return { googleAnalytics: analyticsHeader?.googleAnalytics || '',googleTagManagerBody: analyticsHeader?.googleTagManagerBody || '' , googleTagManagerHead: analyticsHeader?.googleTagManagerHead || '',
            facebookPixel: analyticsHeader?.facebookPixel || '', googleSearchConsole: analyticsHeader?.googleSearchConsole || '',
            googleAdSense: analyticsHeader?.googleAdSense || '', pinterest: analyticsHeader?.pinterest || '',headCode: analyticsHeader?.headCode || '',ads: analyticsHeader?.ads || ''
        }
    }
    useEffect(() => {
        initialize(getData({}))
    },[ site ])
    const handleModal = (event, type) => {
        event.preventDefault()
        setOpen(!open)
        setModalType(type)
    }
    const handleCloseModal = () =>{
        setOpen(false)
        setModalType(null)
    }
    const handleEvent = (eventName,data) => {
        handleCloseModal()
        mixpanel.track(eventName, mixpanelCommonData( data ));

    }
    const submitData = (data) =>{
        if(validate(getData(data), modalType)){
            dispatch(updateRetargetData(site.id, getData(data), handleEvent, 'Upadate analytics data'))
        }
    }

    const renderGoogleSearchConsole = () => {
        return(<>
            <div className='text-center mb-3'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Q20Zi63LNo0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
            </div>
            <div className="paste-code-modal-footer">
                <a href='#' className="btn btn-outline-secondary" onClick={ () => handleCloseModal() }>cancel</a>
            </div>
        </>)
    }

    const renderGoogleTagManager = () =>{
        return( <>
            <Field
                name={ 'googleTagManagerHead' }
                label="Head tag"
                component={ renderTextArea }
                placeholder=''
                rows='3'
            />
            <Field
                name={ 'googleTagManagerBody' }
                label="Body tag"
                component={ renderTextArea }
                placeholder=''
                rows='3'
            />
            <div className="paste-code-modal-footer">
                <a href='#' className="btn btn-outline-secondary" onClick={ () => handleCloseModal() }>cancel</a>
                <ButtonLoader
                    button={ <Button type='submit' className="btn btn-primary" variant="primary" >confirm</Button> }
                    loadingText={ 'Connecting...' }
                    loading={ retargetLoading }

                />
            </div></>)
    }

    const renderBodyView = () => {
        return(<>
            <Field
                name={ modalType }
                label=""
                component={ renderTextArea }
                placeholder=''
            />
            <div className="paste-code-modal-footer">
                <a href='#' className="btn btn-outline-secondary" onClick={ () => handleCloseModal() }>cancel</a>
                <ButtonLoader
                    button={ <Button type='submit' className="btn btn-primary" variant="primary" >confirm</Button> }
                    loadingText={ 'Connecting...' }
                    loading={ retargetLoading }

                />
            </div>
        </>)
    }
    const renderModalView = () => {
        return <div className={ modalType }>
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>{ modalType === 'googleSearchConsole' ? '' : 'Paste Code Below' } </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={ handleSubmit(submitData) }>
                    { modalType === 'googleTagManager' ?  renderGoogleTagManager() :

                        (modalType === 'googleSearchConsole' ? renderGoogleSearchConsole() : renderBodyView() ) }
                </Form>
            </Modal.Body>
        </div>
    }

    const getImage = (item) => {
        switch(item){
        case 'googleAnalytics':
            return GoogleAnalyticsImg
        case 'googleTagManager':
            return GoogleTagManagerImg
        case 'facebookPixel':
            return FacebookPixelImg
        case 'googleSearchConsole':
            return GoogleSearchConsoleImg
        case 'googleAdSense':
            return GoogleAdSenseImg;
        case 'pinterest':
            return PinterestImg;
        case 'headCode':
            return null;
        case 'ads':
            return null;
        }
    }
    const renderFields = (item, index) => {
        return(
            <Form.Group key={ index } controlId="formBasicEmail">
                <div className={ 'analytics-site-btn' } onClick={ (event) => handleModal(event,item) }>
                    { getImage(item) ? <img src={ getImage(item) }/> : <span>{analyticsTypesTitle[ item ]}</span>}
                </div>
                { item == 'googleTagManager' ? <span className='text-green' >{ (analyticsHeaderData[ 'googleTagManagerHead' ] && analyticsHeaderData[ 'googleTagManagerBody' ]) && 'connected' }</span> : <span className='text-green'>{ analyticsHeaderData[ item ] && 'connected' }</span> }
            </Form.Group>
        )
    }
    return(   <section className="dashboard-body w-100">
        <div className="dashboard-header">
            <div className="dashboard-title edit-site-title">
                <h1>Analytics / Advertising</h1>
            </div>
        </div>
        <div className='analytics-site-panel'>
            <div className="analytics-site-btns">
                { analyticsTypes.map((item, index) => {
                    return renderFields(item, index )
                })}

            </div>
            <Modal show={ open } onHide={ handleCloseModal } className= 'new-modal custom-color-modal paste-code-modal'>
                {renderModalView()}
            </Modal>
        </div>
    </section>)
}
Retarget.propTypes = {
    handleSubmit: PropTypes.func,
    site: PropTypes.object,
    initialize: PropTypes.func
}
String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);

};

export default reduxForm({
    form: 'retargetForm',
    destroyOnUnmount: false,
})(Retarget);
