/* eslint-disable quotes */
import React from 'react'
import twitter  from 'images/twitter-color.png'
import facebook  from 'images/facebook-color.png'
import tiktok  from 'images/tiktok-color.png'
import pinterest from 'images/pinterest_color.png'
import youtube  from 'images/youtube-color.png'
import instagram  from 'images/instagram-color.png'
import linkedin  from 'images/linkedin-color.png'
import blogUser from 'images/blog-user.png'
import sampleBlog from 'images/sample-blog.png'
import sampleBlog2 from 'images/mobile-insta.png'

import yelp from 'images/partner-1.png'
import googleBusiness from 'images/partner-2.png'
import facebookBusiness from 'images/partner-3.png'

import yelpCircle from 'images/yelp-color.png'
import googleBusinessCircle from 'images/google-business-color.png'

export const NOTIFICATION_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info'
}

export const MESSAGE = {
    REGISTRATION_SUCCESS: 'Registration complete!',
    USER_PROFILE_UPDATE_SUCCESS: 'Profile update complete!',
    LOGIN_SUCCESS: 'Welcome! Login successful.',
    BLOG_DELETED: 'Blog post deleted.',
    BLOG_UPDATE: 'Blog post updated.',
    BLOG_SUCCESS: 'Blog post created.',
    BLOG_FAILD: 'Something went wrong',
    EMAIL_ACTIVATE: 'Email is activate Successfully',
    CODE_SEND: 'Code sent. Please check your email.',
    CREATE_ASSESSMENT: 'Website creation success!',
    SOMETHING_WRONG: 'Something went wrong, try refreshing page.',
    REQUIRED: 'Required',
    PASSWORD_MATCH: 'Password does not match',
    SHOULD_CHAR: 'Characters only',
    INVALID_EMAIL: 'Invalid Email Address',
    INVALID_MOBILE: 'Invalid Mobile Phone Number',
    SHOULD_BE_CHARACTER: 'Must be alphanumeric (A-Z, a-z, 0-9)',
    PUBLISH_SUCCESS: 'Published!',
    SET_DEFAULT_CATEGORY: 'Please select default category (click category from choosen categories)',
    VALID_ENTER: (value) => `Please enter ${ value }`,
    VALID_SELECT: (value) => `Please select ${ value }`,
    SHOULD_BE_LENGTH: (value, number) => `${ value } should be ${ number } character`,
    SUBSCRIPTION_CANCELLED_SUCCESS: 'Subscription Cancelled Successfully',
    SUBSCRIPTION_FREEZE_SUCCESS: 'Your sites will be frozen in a few minutes.',
    APPLIED_DISCOUNT_SUCCESS: '20% Discount will be applied starting next month',
    UPGRADE_MESSAGE1: ' in your free trial! ',
    UPGRADE_MESSAGE2: ' and get <span class="bold">2 months FREE</span> with any annual plan',
    UPDATE_ASSESSMENT: 'Changes Saved!',
    SUBSCRIPTION_UNFREEZE_SUCCESS: 'Your sites will be Unfreezed in a few minutes.',
    INVALID_URL: 'Invalid Url',
    SET_HOME_SUCCESSFULL : 'Your home page is updated successfully',
    GPT_SUCCESSFULL : 'Successfully generated !'
}

export const SOCIAL_MEDIA = [
    { name: 'Facebook',value: 'facebook', imgUrl: `<img src=${ facebook } />` },
    { name: 'LinkedIn',value: 'linkedin', imgUrl: `<img src=${ linkedin } />` },
    { name: 'Instagram',value: 'instagram', imgUrl: `<img src=${ instagram } />` },
    { name: 'Twitter',value: 'twitter', imgUrl: `<img src=${ twitter } />` },
    { name: 'Youtube',value: 'youtube', imgUrl: `<img src=${ youtube } />` },
    { name: 'TikTok',value: 'tiktok', imgUrl: `<img src=${ tiktok } />` },
    { name: 'Pinterest',value: 'pinterest', imgUrl: `<img src=${ pinterest } />` }
]

export const SOCIAL_BUSINESS = [
    { name: 'Yelp',value: 'yelp-business',circleImg: `<img src=${ yelpCircle } />` , imgUrl: `<img src=${ yelp } />` },
    { name: 'Google Business',value: 'google-business',circleImg: `<img src=${ googleBusinessCircle } />`, imgUrl: `<img src=${ googleBusiness } />` },
    { name: 'Facebook Business',value: 'facebook-business', circleImg: `<img src=${ facebook || facebookBusiness } />`, imgUrl: `<img src=${ facebookBusiness } />` },
]

export const BLOG_STATUS = {
    DRAFT: 'DRAFT',
    DELETED: 'DELETED',
    PUBLISHED: 'PUBLISHED'
}
export const PAGE_STATUS = {
    DRAFT: 'DRAFT',
    DELETED: 'DELETED',
    PUBLISHED: 'PUBLISHED'
}
export const HEADER = {
    HEADING: 'Welcome to the most reliable \n source for healthy recipes!',
    SUB_HEADING: 'Massa viverra malesuada imperdiet facilisis egestas sem. Consectetur a, tempus sed ultrices. Aenean gravida lobortis elit accumsan dignissim. Mauris id donec odio sit arcu ipsum platea dignissim.',
    TRAVEL_HEADING: 'Welcome to my new website',
    TRAVEL_SUBHEADING: 'Your One-stop Shop For All Your Travel Service!'
}

export const SAMPLE_BLOG = {
    HEADING: 'Welcome to the most reliable source for healthy recipes!',
    SUB_HEADING: 'Massa viverra malesuada imperdiet facilisis egestas sem. Consectetur a, tempus sed ultrices. Aenean gravida lobortis elit accumsan dignissim. Mauris id donec odio sit arcu ipsum platea dignissim.',
    BLOG_NAME: <><span className='custom-Skeleton-loader' /><span className='custom-Skeleton-loader' /></>|| 'Learn from These 6 Travel Agency Popup Examples to Boost Leads by 162 Percent',
    USER_NAME: <span className='custom-Skeleton-loader' /> || 'Json Miller',
    DATE: <span className='custom-Skeleton-loader' /> || 'March  09, 2021',
    BLOG_IMAGE: sampleBlog,
    BLOG_IMAGE2: sampleBlog2,
    USER_IMAGE: blogUser
}

export const SHARING_URLS = {
    FACEBOOK: 'https://www.facebook.com/sharer/sharer.php?u=',
    TWITTER: 'https://twitter.com/intent/tweet/?url=',
    EMAIL: (subject, body) => `mailto:?subject=${ subject }&body=${ body }`
}

export const PLAN_TYPE = {
    ANNUAL_PLAN: 'annualPlans',
    MONTHLY_PLAN: 'monthlyPlans'
}

export const TEMPLATE_TYPE = {
    TRAVEL: 'TRAVEL',
    BLOG: 'BLOG'
}

export const PLAN_STATUS = {
    TRIAL: 'TRIAL',
    GRACE_PERIOD: 'GRACE_PERIOD',
    ARCHIVED: 'ARCHIVED',
    DORMANT: 'DORMANT',
    FROZEN: 'FROZEN',
    SUBSCRIBED: 'SUBSCRIBED',
    SUBSCRIPTION_CANCELLED: 'SUBSCRIPTION_CANCELLED',
    VIEWS_LIMIT_EXHAUSTED: 'VIEWS_LIMIT_EXHAUSTED'
}

export const DOMAIN_CONNECT_STATUS = {
    ISSUED: 'ISSUED',
    COMPLETED: 'COMPLETED',
    CONNECTED: 'CONNECTED',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_VALIDATION: 'PENDING_VALIDATION',
    VALIDATION_TIMED_OUT: 'VALIDATION_TIMED_OUT',
    INACTIVE: 'INACTIVE',
    EXPIRED: 'EXPIRED',
    FAILED: 'FAILED'
}

export const DOMAIN_DISCONNECT_STATUS = {
    DISABLED: 'DISABLED',
    IN_PROGRESS: 'IN_PROGRESS',
    DELETED: 'DELETED',
    INVALID: 'INVALID'
}

export const DASHBOARD_TOUR = {
    1: 'Your site will be ready soon! Click the link to View your website anytime.',
    2: 'In the meantime, fill out your Author box. Choose a picture, name, and write an about me. Then connect your social media accounts.',
    3: 'Here you can connect wings, add new page or share website.',
    4: "Here you'll see your site's Views and Email Subscribers. You can export your email subscriber list as a CSV file anytime.",
    5: 'Under "My Account", you can see your websites, connect a custom domain, manage your subscription plan in "Billing", or edit your account settings in "Profile" '
}

export const BLOG_TOUR = {
    1: "Ok, let's start with create new blog.",
    2: 'Here enter title for new blog',
    3: "Let's upload feature image here thousand images available",
    4: 'Here write blog content using extra features of RICH TEXT EDITOR ',
    5: "Now it's time to publishe or save as draft your blog "
}

export const EVENTS = {
    clickWriteWhyJetPage: 'Click Write Why JetPage',
    clickWriteABlog: 'Click Write A Blog',
    clickEditSite: 'Click Edit Site',
    clickWriteNiche: 'Click Write Niche',
    clickConnectSocial: 'Click Connect Social',
    download: 'Export CSV',
    customDomainError: 'Custom Domain Error',
    desiredDomain: 'Desired domain',
    clickDomain: 'Click domain',
    numberOfPublishBlog: 'Number Of Published Blog',
    numberOfDraftBlog: 'Number Of Drafts Blog',
    visitTryforFree: 'VISIT STEP 1 (Try For Free )',
    clickonNext: 'VISIT STEP 2 (Click on Next from HOMEPAGE )',
    clickonNext2: 'VISIT STEP 2 (Click on Next from STEP 1 )',
    clickonCustomColor: 'Click on custom color',
    clickOnPremadeColor: 'Click on premade color',
    clickOnLogin: 'Click on Login (HOMEPAGE) ',
    clickOnBlogs: 'Click on Blogs (HOMEPAGE) ',
    clickOnPricing: 'Click on Pricing (HOMEPAGE)',
    clickOnReviews: 'Click on Reviews (HOMEPAGE)',
    clickOnFAQ: 'Click on FAQ (HOMEPAGE)',
    uploadLogo: 'Upload logo for website (Assessment Flow)',
    headerImage: 'Upload Header image for website (Assessment Flow)',
    visitRegistrationVia: 'VISIT Registration page via assessment flow',
    completeRegistration: 'Complete Registration (Email and password)',
    confirmAccount: 'Confirmation: Enter 6-digit Verification Code',
    loginUser: 'Completed login',
    dashboardVisit: 'VISIT on dashboard',
    clickOnBilling: 'Click on Billing',
    clickFooterWizard: 'Click on Powered by wizrd',
    clickOnEditSite: 'Click on Edit site',
    clickOnMarketing: 'Click on Marketing',
    clickOnAnalytics: 'Click on Analytics',
    firstLogin: 'First Successfull Login',
    tourCompleted: 'Product Tour Completed',
    tourClosed: 'Product Tour Closed',
    siteVisit: 'Site Visit (Homepage)',
    clickOnCancelModal: 'Click on cancel account',
    contactSupportOpen: 'Click to open contact support',
    contactUpdate: 'Contact Number update',
    connectWings: 'Connect Wings',
    newPage: 'Click on new page',
    copyWebsite: 'Copy website',
    createSubscription: 'Subscription Created',
    updateSubscription: 'Subscription Updated',
}
export const TRIAL_PERIOD = '14-Day Free Trial'
export const RESPONSIVE_CAROUSEL = {

    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const DEFAULT_TRAVEL_SCRIPT = {
    script1: '<script src="https://widget.mybookingplatform.com/widget.js" type="text/javascript"></script>',
    // script1: '<script src="https://sandstravelagency.mybookingplatform.com/assets/default/js/vendor/custom/widget/widget.js" type="text/javascript"></script>',
    script2: (siteUrl) =>  `<script> var widget = new SSTWidget({ container: 'widgetWrap', SiteUrl: '${ siteUrl }', TabFontFamily: 'Arial, Helvetica, sans-serif', Disable: { Hotel: false, Tour: false, Car: false, Activity: false, Cruise: false, Flight : false }, }); widget.init(); </script>`,
    testscript2: '<script> var widget = new SSTWidget({ container: `widgetWrap`, SiteUrl: `https://tanmaymandal.mywingsbooking.com`, TabFontFamily: `Arial, Helvetica, sans-serif`, Disable: { Hotel: false, Tour: true, Car: true, Activity: true, Cruise: true, Flight : false }, }); widget.init(); </script>'
}

export const MOST_OUT_WIZRD = {
    tutorial: 'https://www.youtube.com/watch?v=DxyV23QZv3A&list=PLPFj8-n_VVLcFpMlW2wvnn8ZqCOVfiEAa',
    community: 'https://www.facebook.com/groups/jetpage/',
    follow: 'https://twitter.com/JetPage_co'
}

export const WEB_SHARE_URL = {
    youtube: 'https://www.youtube.com/channel/UCLw8zN8w1nzakcspjbWsMkw',
    facebook: 'https://www.facebook.com/groups/jetpage/',
    twitter: 'https://twitter.com/JetPage_co',
    instragram: 'https://www.instagram.com/jetpage.co',
    linkedin: 'https://www.linkedin.com/company/jetpage'
}
export const WEB_SHARE_URLS = [
    'Youtube','Facebook','Twitter','Instragram','Linkedin'
]

export const DOMAIN_CANCEL_WARNINGS = [
    `You received an email from us telling you your domain failed to connect
    and the instructions are to hit the Cancel button`,
    `You need to connect a DIFFERENT domain than what you intially
    submitted.`,
];

export const EMAIL_MARKETING_INTEGRATIONS = [
    'ACTIVE_CAMPAIGN',
];

export const ACTIVE_CAMPAIGN_STEPS = [
    '1. Login to your ActiveCampaign Account',
    `2. Go to the left-hand sidebar menu and click "Settings"`,
    `3.  On the "Settings" menu click "Developer" `,
    `4. Here you will find the "URL" and the "Key", copy and paste both these values into the fields below.`,
    `5.  Click "Connect" URL`,
];

export const CASE_STYDY_LINK = "https://jetpage.co/blog/case-study-affiliate-marketing-website-1";
export const WHY_JETPAGE_LINK = "https://jetpage.co/blog/best-website-builder";

export const DISCLOSURETEXT = "Some of the links in this article may be affiliate links, which can provide compensation to me at no cost to you if you decide to purchase. This site is not intended to provide financial advice and is for entertainment only.";

export const TITLES = {
    '/reviews' : 'JetPage website builder | Customer Reviews and Testimonials',
    '/pricing' : 'JetPage website builder | Pricing Plans, Packages, and Cost'
}

export const DESCRIPTIONS = {
    '/reviews' : 'Read and watch real customer reviews and testimonials to help you decide if JetPage is the best website builder for you.',
    '/pricing' : 'Here is a list of JetPage Pricing Plans and Packages. Start your 14-day free trial today!'
}

export const CANONICALS= {
    '/reviews' : 'https://jetpage.co/reviews',
    '/pricing' : 'https://jetpage.co/pricing'
}
