import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {
    Button,
    Accordion,
    Card,
    Form,
} from 'react-bootstrap'
import { OpenArrow, EditSeoIcon } from 'utils/svg'

const Disclosure = ({ isDisclosureEnabled, disclosure, isDisclosureEditable,
    setDisclosureEditable = () => { }, setDisclosure = () => { }, setDisclosureEnabled = () => { } }) => {

    const [ disclosureText,setDisclosureText ] = useState(disclosure);

    const handleDisclosure = () => {
        setDisclosureEnabled(!isDisclosureEnabled)
        setDisclosureEditable(false)
    }

    const updateDisclosureText = () => {
        setDisclosure(disclosureText)
        setDisclosureEditable()
    }

    return (
        <div className="discloser-section">
            <Accordion defaultActiveKey="disclosure">
                <Card>
                    <Card.Header style={ { padding: 0 } }>
                        <Accordion.Toggle as={ Button } variant="link" eventKey="disclosure" className='accordion-header'>
                            <Form className="header-form">
                                <Form.Check type="switch" checked={ isDisclosureEnabled } onChange={ handleDisclosure } id="disclosure-custom-switch" label="" />
                                <p>Affiliate Disclaimer</p>
                            </Form>
                            <OpenArrow width={ 10 } height={ 7 } />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="disclosure">
                        <Card.Body>
                            {isDisclosureEnabled && <div className="content-wrapper">
                                <textarea value={ isDisclosureEditable ? disclosureText : disclosure } onChange={ (e) => setDisclosureText(e.target.value) } disabled={ !isDisclosureEditable } maxLength='300'/>
                                {isDisclosureEditable && <div className='disclosure-word-count'><span>{`${ disclosureText.length }/300`}</span></div>}
                                <div className='disclosure-btns'>
                                    {
                                        isDisclosureEditable ?
                                            <button className='btn btn-primary' onClick={ updateDisclosureText  }> Save </button>
                                            :
                                            <a className="edit-btn btn btn-primary" href='javascript:void(0)' onClick={ () => setDisclosureEditable(true) }><EditSeoIcon />Edit</a>
                                    }
                                </div>
                            </div>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </div>
    )
}

Disclosure.propTypes = {
    isDisclosureEnabled: PropTypes.bool,
    disclosure: PropTypes.string,
    isDisclosureEditable: PropTypes.bool,
    setDisclosure: PropTypes.func,
    setDisclosureEnabled: PropTypes.func,

    setDisclosureEditable: PropTypes.func
};

export default Disclosure