import React from 'react';
import { Container } from 'react-bootstrap';
import './style.sass';
const WhatIncluded = () => {
    return (
        <section className="publisher-whta-included">
            <Container className="what-included">
                <h2>What’s Included</h2>
                <div className="publisher-whta-included-row">
                    <ul className="whta-included-left">
                        <li className="what-included-text">
                            The #1 Cloud Hosting Included{' '}
                        </li>
                        <li className="what-included-text">
                            No maintenance or plugin updates ever again!{' '}
                        </li>
                        <li className="what-included-text">
                            No Code simple and easy-to-use editor{' '}
                        </li>
                        <li className="what-included-text">Connect ANY custom domain </li>
                        <li className="what-included-text">Keyword Research Tool </li>
                        <li
                            style={ {
                                background:
                  'linear-gradient(to right, #F87700 0%, #E32093 38.96%, #9747FF 52.19%)',
                                '-webkit-background-clip': 'text',
                                '-webkit-text-fill-color': 'transparent',
                            } }
                            className="gradient-text"
                        >
                            SEO-Friendly Blog Ideas and Titles Generator{' '}
                        </li>
                        <li
                            style={ {
                                background:
                  'linear-gradient(to right, #F87700 0%, #E32093 38.96%, #9747FF 52.19%)',
                                '-webkit-background-clip': 'text',
                                '-webkit-text-fill-color': 'transparent',
                            } }
                            className="gradient-text"
                        >
                            1-click AI Blog Writing{' '}
                        </li>
                        <li className="what-included-text">No web design needed </li>
                        <li className="what-included-text">
                            Blazing Fast Load Times (Passes Core Web Vitals){' '}
                        </li>
                    </ul>
                    <ul className="whta-included-right">
                        <li className="what-included-text">
                            Mobile-Responsive on ANY device (phone, tablet, desktop){' '}
                        </li>
                        <li className="what-included-text">
                            Security: SSL Certificate Included{' '}
                        </li>
                        <li className="what-included-text">
                            Collect customer emails right away{' '}
                        </li>
                        <li className="what-included-text">
                            Facebook Pixel & Google Tag Manager Integration{' '}
                        </li>
                        <li className="what-included-text">
                            Next-Gen Image Compression and Resizing{' '}
                        </li>
                        <li className="what-included-text">
                            Copy & Paste directly from Google Docs and maintain formatting{' '}
                        </li>
                        <li className="what-included-text">Connect Google Analytics </li>
                        <li className="what-included-text">CDN included </li>
                    </ul>
                </div>
            </Container>
        </section>
    );
};

export default WhatIncluded;
