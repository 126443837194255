// import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

const EmailDatePicker = ( { handleDateChange } ) => {
    const [ startDate, setStartDate ] = useState(new Date());
    return (
        <>
            <DatePicker
                selected={ startDate }
                onChange={ (date) => {
                    setStartDate(date);
                    handleDateChange(date.getTime());
                    // setOpenDate(false);
                } }
            />
        </>
    );
};
export default EmailDatePicker;

EmailDatePicker.propTypes = {
    handleDateChange: PropTypes.func
};
