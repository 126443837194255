import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import webFinLogo from '../../public/images/header/wizrd-logo.png ';
import { ROUTES } from '../../constants/appRoutes';
import webFinLogo from '../../images/header/jetpage-logo-black.png';
import { mixpanelCommonData } from 'utils/helpers';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS , WEB_SHARE_URLS , WEB_SHARE_URL } from 'constants/app'
import { HSYoutube, HSFacebook ,HSTwitter ,HSInstragram, HSLinkedin } from 'utils/svg'

const Footer = () => {
    const mixpanel = useContext(MixpanelContext)
    const handleClick = () =>{
        mixpanel.track(EVENTS.clickFooterWizard,mixpanelCommonData({}))
    }
    const renderSvg = (item) =>{
        switch(item){
        case 'Youtube':
            return HSYoutube()
        case 'Facebook':
            return HSFacebook()
        case 'Twitter':
            return HSTwitter()
        case 'Instragram':
            return HSInstragram()
        case 'Linkedin':
            return HSLinkedin()
        }
    }
    return(
        <footer className="site-footer">
            <Container>
                <Row className="footer-top">
                    <Col className="col-xl-8 col-sm-12 footer-logo-copyright">
                        <Link to="/" title="Logo">
                            <img src={ webFinLogo } alt="WebFin" />
                        </Link>
                        <ul>
                            <li>
                                <a href='#home'>
                                    Home
                                </a>
                            </li>
                            <li>
                                <Link to={ ROUTES.PRICING }>
                                    Pricing
                                </Link>
                            </li>
                            <li>
                                <Link to={ ROUTES.PRIVACY_POLICY }>
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to={ ROUTES.TERMS_CONDITIONS }>
                                    Terms & Conditions
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col className="col-xl-4 col-sm-12 footer-social-icon-menu text-right">
                        <ul className="social-links">
                            { WEB_SHARE_URLS.map((item,index) =>{
                                return( <li key={ index }>
                                    <a href={ WEB_SHARE_URL[ item.toLowerCase() ] } rel="noreferrer" target='_blank' >
                                        {renderSvg(item)}
                                    </a>
                                </li>)
                            } ) }
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <div className="copyright text-center col-sm-12" onClick={ handleClick } >
                        &copy; 2023 Powered by JetPage
                    </div>
                </Row>
            </Container>
        </footer>
    )
}
export default Footer
