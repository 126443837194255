import React from 'react';
import './style.sass';
import facebook from '../../../images/facebook-theme.svg';
import twitter from '../../../images/twitter-theme.svg';
import linkedIn from '../../../images/linkedin-theme.svg';
import instagram from '../../../images/instagram-theme.svg';
import userIcon from '../../../images/user-avatar.png';
import PropTypes from 'prop-types';
import blogBanner from 'images/blog-banner.png';
import blog1 from '../../../images/theme-builder/stylish-upper-blog.svg';
import blog2 from '../../../images/theme-builder/blog2.svg';
import blog3 from '../../../images/theme-builder/blog3.svg';
import blog4 from '../../../images/theme-builder/blog4.svg';
import blog5 from '../../../images/theme-builder/blog5.svg';
import blog6 from '../../../images/theme-builder/blog6.svg';

const ModernView = ({ data }) => {
    const {
        headlineColor,
        headerData = '',
        optIn,
        headerTextColor,
        buttonTextColor,
        headerBgColor,
        buttonBgColor,
        hideSubscribeButton,
        isHeaderTransparent,
        backgroundType,
        bannerBgColor,
        overlay,
        previewFile
    } = data;
    const backgroundStyle =
    backgroundType === 'COLOR'
        ? { backgroundColor: bannerBgColor }
        : { backgroundImage: `url(${ previewFile || blogBanner })` };
    return (
        <div className="bg-light">
            <div style={ { position:'relative' } }>
                <div style={ {
                    ...backgroundStyle,
                    // backgroundImage: `url(${ previewFile || blogBanner })`,
                    backgroundSize: '100% 100%',
                    // backgroundPosition: '50%',
                    backgroundRepeat: 'no-repeat',
                    overflowWrap: 'anywhere',
                    minHeight: '210px'
                } }>
                    <div
                        style={ {
                            background: isHeaderTransparent
                                ? 'transparent'
                                : headerBgColor,
                        } }
                        className="theme-header d-flex justify-content-between"
                    >
                        <div className="header-left d-flex align-items-center">
                            <img
                                className="header-profile"
                                src={ userIcon }
                                width={ '25px' }
                                height={ '25px' }
                            />
                            <div
                                style={ { color: headerTextColor } }
                                className="header-text-classic"
                            >
                                Logo
                            </div>
                        </div>
                        <div className="header-center d-flex align-items-center">
                            <div
                                style={ { color: headerTextColor } }
                                className="header-text-classic"
                            >
                                Home
                            </div>
                            <div
                                style={ { color: headerTextColor } }
                                className="header-text-classic"
                            >
                                Blogs
                            </div>
                            <div
                                style={ { color: headerTextColor } }
                                className="header-text-classic"
                            >
                                About Us
                            </div>
                            <div
                                style={ { color: headerTextColor } }
                                className="header-text-classic"
                            >
                                Contact
                            </div>
                        </div>
                        <div className="header-right d-flex align-items-center">
                            <img src={ facebook } width={ '15px' } height={ '15px' } />
                            <img src={ twitter } width={ '15px' } height={ '15px' } />
                            <img src={ linkedIn } width={ '15px' } height={ '15px' } />
                            <img src={ instagram } width={ '15px' } height={ '15px' } />
                            {!hideSubscribeButton ? (
                                <button
                                    style={ { color: buttonTextColor, background: buttonBgColor } }
                                    className="subscribe-btn "
                                >
                                    Subscribe
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <div
                        style={ {
                            // ...backgroundStyle,
                            // backgroundImage: `url(${ previewFile || blogBanner })`,
                            // backgroundSize: '100% 100%',
                            // backgroundRepeat: 'no-repeat',
                            padding: '20px 0',
                            overflowWrap: 'anywhere',
                            minHeight: 'inherit',
                            paddingLeft: '100px',
                            paddingRight: '100px'
                        } }
                        className={ 'banner-container d-flex flex-column justify-content-center' }
                    >
                        {headerData ? (
                            <div
                                className='healine-text-responsive'
                                style={ { color: headlineColor, zIndex: 2 } }
                                dangerouslySetInnerHTML={ { __html: headerData } }
                            />
                        ) : (
                            <h2 className="healine-text-responsive" style={ { color: headlineColor, zIndex: 2 } }>
                                Welcome to the most reliable source for{' '}
                                <span className="healthy">healthy</span> recipes!
                            </h2>
                        )}

                        {optIn ? (
                            <div style={ { zIndex: 2 } } className="theme-form-wrapper d-flex mt-4 justify-content-center">
                                <form className="theme-newsletter input-box-shadow m-0 ">
                                    <div className="theme-form-group d-flex align-items-center">
                                        {/* <input
                                            className="form-input"
                                            placeholder="enter your email"
                                            type="text"
                                        /> */}
                                        <button
                                            style={ { color: buttonTextColor, background: buttonBgColor } }
                                            type="submit"
                                            className="subscribe-btn "
                                        >
                                            Subscribe
                                        </button>
                                    </div>
                                </form>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={ !overlay || backgroundType === 'COLOR' ? '' : headlineColor === '#FFFFFF' ? 'overlay-dark': 'overlay' }></div>
            </div>
            <div className=" justify-content-between mx-4 modern-view-cards-container">
                <div className="card-wrapper">
                    <div className="card-bg d-flex justify-content-center align-items-center"><img src={ blog1 } width={ '50%' } height={ '50%' }/></div>
                    <span className="card-title">
                        Blog Post Title
                    </span>{' '}
                    <br />
                    <span className="date">Jan 1, 2024</span> <br />
                    <span className="status">Related</span> <br />
                </div>
                <div className="card-wrapper">
                    <div className="card-bg d-flex justify-content-center align-items-center"><img src={ blog2 } width={ '50%' } height={ '50%' }/></div>
                    <span className="card-title">
                        Blog Post Title
                    </span>{' '}
                    <br />
                    <span className="date">Jan 1, 2024</span> <br />
                    <span className="status">Related</span> <br />
                </div>
                <div className="card-wrapper">
                    <div className="card-bg d-flex justify-content-center align-items-center"><img src={ blog3 } width={ '50%' } height={ '50%' }/></div>
                    <span className="card-title">
                        Blog Post Title
                    </span>{' '}
                    <br />
                    <span className="date">Jan 1, 2024</span> <br />
                    <span className="status">Related</span> <br />
                </div>
                <div className="card-wrapper">
                    <div className="card-bg d-flex justify-content-center align-items-center"><img src={ blog4 } width={ '50%' } height={ '50%' }/></div>
                    <span className="card-title">
                        Blog Post Title
                    </span>{' '}
                    <br />
                    <span className="date">Jan 1, 2024</span> <br />
                    <span className="status">Related</span> <br />
                </div>
                <div className="card-wrapper">
                    <div className="card-bg d-flex justify-content-center align-items-center"><img src={ blog5 } width={ '50%' } height={ '50%' }/></div>
                    <span className="card-title">
                        Blog Post Title
                    </span>{' '}
                    <br />
                    <span className="date">Jan 1, 2024</span> <br />
                    <span className="status">Related</span> <br />
                </div>
                <div className="card-wrapper">
                    <div className="card-bg d-flex justify-content-center align-items-center"><img src={ blog6 } width={ '50%' } height={ '50%' }/></div>
                    <span className="card-title">
                        Blog Post Title
                    </span>{' '}
                    <br />
                    <span className="date">Jan 1, 2024</span> <br />
                    <span className="status">Related</span> <br />
                </div>
            </div>

            <div className="d-flex justify-content-center py-5 load-more-btn-wrapper">
                <div className="load-more-btn">Load More</div>
            </div>
        </div>
    );
};

ModernView.propTypes = {
    data: PropTypes.object,
    headlineColor: PropTypes.string,
    headerData: PropTypes.string,
    headerTextColor: PropTypes.string,
    headerBgColor: PropTypes.string,
    buttonBgColor: PropTypes.string,
    previewFile: PropTypes.string,
    hideSubscribeButton: PropTypes.bool,
    isHeaderTransparent: PropTypes.bool,
    optIn: PropTypes.bool,
    overlay: PropTypes.bool,
    buttonTextColor: PropTypes.string,
    bannerBgColor: PropTypes.string,
    blogBanner: PropTypes.string,
};

export default ModernView;
