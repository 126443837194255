import { publishFailed, publishRequest } from 'actions/blog';
import {
    publishListRequest,
    publishListFailed,
    publishGroupList,
    publishSubscribersList,
    publishSuccess,
    initiateDomainRequest,
    publishDomainRequestFailed,
    updateDomainData,
} from '../actions/email';
import axiosInstance from '../services/api';
import { getSite } from '../utils/helpers';
import { notification } from 'services/notification';
import { NOTIFICATION_TYPES } from 'constants/app';
import { setItem } from 'utils/cache';
import { ROUTES } from 'constants/appRoutes';

export const getGroupList = (cb) => {
    return async (dispatch) => {
        try {
            dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.get(
                `/email-subscribers/sites/${ site?.id }/allLists`
            );

            if ([ 200, 203 ].includes(response.status)) {
                dispatch(publishGroupList(response?.data?.data));

                if (cb) {
                    cb();
                }
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Issue fetching group list, please refresh page and try again'
                );
                dispatch(publishListFailed());
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
          'Issue fetching group list, please refresh page and try again'
            );
            dispatch(publishListFailed(e));
        }
    };
};

export const getSubscribersList = (id) => {
    return async (dispatch) => {
        dispatch(publishListRequest());
        const site = getSite();
        const url = id
            ? `/email-subscribers/subscription-lists/sites/${ site?.id }/allEmails?subscribersListIds=${ id }&allList=true`
            : `/email-subscribers/sites/${ site?.id }`;
        axiosInstance
            .get(url)
            .then((response) => {
                if ([ 200, 203 ].includes(response.status)) {
                    dispatch(
                        publishSubscribersList(response?.data?.data?.subscribers)
                    );
                } else {
                    notification(
                        NOTIFICATION_TYPES.ERROR,
                        'Issue fetching subscriber list, please refresh page and try again'
                    );
                    dispatch(publishListFailed());
                }
            })
            .catch((error) => {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Issue fetching subscriber list, please refresh page and try again'
                );
                dispatch(publishListFailed(error));
            });
    };
};

export const updateUser = (payload, subscriptionId, cb) => {
    return async (dispatch) => {
        dispatch(publishRequest());
        const site = getSite();
        axiosInstance
            .put(`/email-subscribers/sites/${ site?.id }/subscriber/${ subscriptionId }/update`, payload)
            .then((response) => {
                console.log(response);
                dispatch(publishSuccess(response));
                if (cb) {
                    cb();
                }
                dispatch(getGroupList());
                dispatch(getSubscribersList());
            })
            .catch((error) => {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    error?.response?.data?.message ??
            'Something went wrong while updating subscriber, please try after some time'
                );
                dispatch(publishFailed(error));
            });
    };
};

export const addNewList = (data, cb) => {
    return async (dispatch) => {
        try {
            dispatch(publishRequest());
            const site = getSite();
            const response = await axiosInstance.post(
                `/email-subscribers/sites/${ site?.id }/subscription-list/create`,
                data
            );

            if ([ 200, 203 ].includes(response.status)) {
                dispatch(publishSuccess());
                dispatch(getGroupList(null));
                if (cb) {
                    cb();
                }
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while creating list, please refresh page and try again'
                );
                dispatch(publishFailed());
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                'Something went wrong while  creating list, please refresh page and try again'
            );
            dispatch(publishFailed(e));
        }
    };
};
export const addNewSubscriber = (data, cb) => {
    return async (dispatch) => {
        try {
            dispatch(publishRequest());
            const response = await axiosInstance.post('email-subscribers/subscribe', {
                ...data,
                siteId: getSite()?.id,
            });

            if ([ 200, 203 ].includes(response.status)) {
                dispatch(publishSuccess());
                dispatch(getSubscribersList());
                dispatch(getGroupList());
                if (cb) {
                    cb();
                }
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while adding subscriber, please refresh page and try again'
                );
                dispatch(publishFailed());
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                'Something went wrong while adding subscriber, please refresh page and try again'
            );
            dispatch(publishFailed(e));
        }
    };
};

export const getDomainData = (cb) => {
    return async (dispatch) => {
        if(!cb) dispatch(initiateDomainRequest());
        const site = getSite();
        const url = `/email-domain/${ site?.id }`
        axiosInstance
            .get(url)
            .then((response) => {
                if ([ 200, 203 ].includes(response.status)) {
                    setItem('domain',response?.data)
                    dispatch(
                        updateDomainData(response?.data)
                    );
                    if(cb){
                        cb(response?.data)
                    }
                    console.log('response?.data',response?.data);
                } else {
                    notification(
                        NOTIFICATION_TYPES.ERROR,
                        'Issue fetching domain details, please refresh page and try again'
                    );
                    dispatch(publishDomainRequestFailed());
                }
            })
            .catch((error) => {
                // notification(
                //     NOTIFICATION_TYPES.ERROR,
                //     'Issue fetching domain details, please refresh page and try again'
                // );
                setItem('domain',null)
                dispatch(publishDomainRequestFailed(error));
            });
    };
};

export const registerDomain = (payload, cb, loadingcb) => {
    const site = getSite();
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post(
                `/email-domain/register/${ site?.id }`,
                payload
            );
            console.log('register-res',response);
            if (response.status == 200) {

                cb(response.data);
                loadingcb();
                dispatch(
                    initiateDomainRequest()
                );
                dispatch(
                    updateDomainData(response?.data)
                );

            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while registering domain , please refresh page and try again'
                );
                loadingcb();
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
          'Something went wrong while registering domain , please refresh page and try again'
            );
            loadingcb();
        }
    };
};

export const verifyDomain = (cb) => {
    return async (dispatch) => {
        const site = getSite();
        cb(true);
        try {
            const response = await axiosInstance.put(
                `/email-domain/verify/${ site?.id }`,
                {}
            );

            if ([ 200, 203 ].includes(response.status)) {
                dispatch(
                    initiateDomainRequest()
                );
                dispatch(
                    updateDomainData(response?.data)
                );
                cb(false);

            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while registering domain , please refresh page and try again'
                );
                if (cb) {
                    cb(false);
                }
            }
        } catch (e) {
            console.log('catch',e);
            notification(
                NOTIFICATION_TYPES.ERROR,
                'Something went wrong while registering domain , please refresh page and try again'
            );
            if (cb) {
                cb(false);
            }
        }
    };
};
export const sendMail = (payload, fcb) => {
    return async () => {
        try {
            const site = getSite();
            const response = await axiosInstance.post(
                `/email-subscribers/send-mail/sites/${ site?.id }/users`,
                payload
            );

            if ([ 200, 203 ].includes(response.status)) {
                // cb();
                fcb();
                notification(
                    NOTIFICATION_TYPES.SUCCESS,
                    'Mail Send Successfully!'
                );
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while registering domain , please refresh page and try again'
                );
                fcb();
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
                'Something went wrong while registering domain , please refresh page and try again'
            );
            fcb();
        }
    };
};

export const deleteSubscriber = (sId,lId, loadingcb,selectedcb) => {
    return async (dispatch) => {
        loadingcb(true)
        try {
            dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.put(
                `/email-subscribers/subscription-emails/remove/sites/${ site?.id }?subscriptionId=${ sId }`,
                {}
            );

            if ([ 200, 203 ].includes(response.status)) {
                dispatch(getSubscribersList(lId));
                dispatch(getGroupList());
                selectedcb([])
                loadingcb(false)
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while deleteing subscriber , please refresh page and try again'
                );
                dispatch(publishListFailed());
                loadingcb(false)
            }
        } catch (e) {
            console.log(e)
            notification(
                NOTIFICATION_TYPES.ERROR,
                'Something went wrong while deleteing subscriber , please refresh page and try again'
            );
            dispatch(publishListFailed(e));
            loadingcb(false)
        }
    };
};

export const sendMailToList = (payload, cb = () => {}, fcb = () => {} ) => {
    return async () => {
        fcb(true)
        try {
            const site = getSite();
            const response = await axiosInstance.post(
                `email-subscribers/send-mail/sites/${ site?.id }/lists`,
                payload
            );

            if ([ 200, 203 ].includes(response.status)) {
                cb();
                fcb(false);
                notification(
                    NOTIFICATION_TYPES.SUCCESS,
                    'Mail Sent Successfully'
                );
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while sending mail to list , please refresh page and try again'
                );
                fcb(false);
            }
        } catch (e) {
            console.log(e)
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
                'Something went wrong while sending mail to list , please refresh page and try again'
            );
            fcb(false);
        }
    };
};

export const getAllEmailList = (bool,cb, loadingcb) => {
    return async (dispatch) => {
        loadingcb && loadingcb(true)
        try {
            loadingcb(true);
            dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.get(
                `/email-subscribers/send-mail/sites/${ site?.id }?isDraft=${ bool }`
            );

            console.log(response);

            if ([ 200, 203 ].includes(response.status)) {
                if (cb) {
                    cb(response.data.data);
                }
                loadingcb(false);
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    response?.data?.message ??
            'Something went wrong while fetching email list, please refresh page and try again'
                );
                if (cb) {
                    cb({});
                }
                // setIsEmailLoading(false);
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
          'Something went wrong while fetching email list, please refresh page and try again'
            );
            dispatch(publishListFailed(e));
            // setIsEmailLoading(false);
        }
    };
};

export const deleteList = (lId, cb) => {
    return async (dispatch) => {
        cb(lId);
        try {
            dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.put(
                `/email-subscribers/sites/${ site?.id }/subscription-list/delete?listId=${ lId }`,
                {}
            );

            if ([ 200, 203 ].includes(response.status)) {
                dispatch(getGroupList());
                dispatch(getSubscribersList());
                cb();
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while deleteing list , please refresh page and try again'
                );
                dispatch(publishListFailed());
                cb();
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                'Something went wrong while deleteing list , please refresh page and try again'
            );
            dispatch(publishListFailed(e));
            cb();
        }
    };
};

export const deleteEmailList = (bool,emailId,cb,emailList,draftList) => {
    return async (dispatch) => {
        try {
            dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.delete(
                `/email-subscribers/send-mail/sites/${ site?.id }/email/${ emailId }/delete?isDraft=${ bool }`
            );

            console.log('delete',response);

            if ([ 200, 203 ].includes(response.status)) {
                dispatch(getAllEmailList(bool,bool===true? draftList:emailList));
                cb(null);
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    response?.data?.message ??
            'Something went wrong while deleting list, please refresh page and try again'
                );
                cb(null);

            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
          'Something went wrong while deleting list, please refresh page and try again'
            );
            cb(null);
            dispatch(publishListFailed(e));
        }
    };
};

export const updateGroupList = (payload, id, cb=()=>{}, loadingcb) => {
    return async (dispatch) => {
        loadingcb(true)
        const site = getSite();
        axiosInstance
            .put(`/email-subscribers/sites/${ site?.id }/subscription-list/${ id }/update`, payload)
            .then((response) => {
                console.log(response);
                dispatch(publishSuccess(response));
                loadingcb(false)
                dispatch(getGroupList());
                cb(null);
            })
            .catch((error) => {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    error?.response?.data?.message ??
            'Something went wrong while updating subscriber, please try after some time'
                );
                dispatch(publishFailed(error));
                loadingcb(false)
            });
    };
};

export const getAllEmailById = (id, history, isView) => {
    return async (dispatch) => {
        try {
            dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.get(
                `/email-subscribers/send-mail/sites/${ site?.id }/email?mailId=${ id }`
            );

            console.log('getemailbyid',response);

            if (response.status == 200) {
                history.push({
                    pathname: ROUTES.SEND_EMAILS,
                    state: { responseData: response?.data?.data, isEmailView: isView },
                });
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    response?.data?.message ??
            'Something went wrong while fetching email list, please refresh page and try again'
                );
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
                'Something went wrong while fetching email list, please refresh page and try again'
            );
            dispatch(publishListFailed(e));
        }
    };
};
export const emailPagination = (args, bool, setter) => {
    return async (dispatch) => {
        try {
            dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.get(
                `/email-subscribers/send-mail/sites/${ site?.id }?isDraft=${ bool }&${ args }`
            );

            if (response.status == 200) {
                // console.log(response);
                setter(response?.data?.data)
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    response?.data?.message ??
            'Something went wrong while fetching email list, please refresh page and try again'
                );
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
          'Something went wrong while fetching email list, please refresh page and try again'
            );
            dispatch(publishListFailed(e));
        }
    };
};

export const updateDomainConfigDetails = (data, setIsUpdating) => {
    return async () => {
        setIsUpdating(true)
        try {
            const site = getSite();
            const response = await axiosInstance.patch(
                `/email-domain/update/${ site?.id }`, data
            );

            if ([ 200, 203 ].includes(response.status)) {
                setIsUpdating(false)
                notification(
                    NOTIFICATION_TYPES.SUCCESS,
                    'Updated SuccessFully'
                );
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while creating list, please refresh page and try again'
                );
                setIsUpdating(false)

            }
        } catch (e) {
            setIsUpdating(false)
            notification(
                NOTIFICATION_TYPES.ERROR,
                'Something went wrong while  creating list, please refresh page and try again'
            );
        }
    };
};

export const deleteDomain = (cb) => {
    return async (dispatch) => {
        cb(true);
        try {
            // dispatch(publishListRequest());
            const site = getSite();
            const response = await axiosInstance.delete(
                `/email-domain/delete/${ site?.id }`
            );

            console.log('delete res',response);

            if ([ 200, 203 ].includes(response.status)) {
                cb(false);
                dispatch(
                    getDomainData()
                );
            } else {
                cb(false);
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    response?.data?.message ??
            'Something went wrong while deleting domain, please refresh page and try again'
                );

            }
        } catch (e) {
            cb(false);
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
          'Something went wrong while deleting domain, please refresh page and try again'
            );
        }
    };
};
