import React from 'react'
import PropTypes from 'prop-types';
const LayoutChildren = (props) => {
    return (
        <> { props.children }</>
    )
}
LayoutChildren.propTypes = {
    children: PropTypes.any,
}
export default LayoutChildren