import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'services/api'
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { getPlans } from 'middleware/plan';
import { getAllSitesWP } from 'middleware/assessments'
import { PLAN_TYPE } from 'constants/app';
import ButtonLoader from 'components/core/loader/button-loader';
import { convertCurrencyWithOutFraction } from 'services/currency';
import { formatNumber } from 'services/number';
import { convertPriceInDollerAndCent, isAnnualPlan, isBusinessPlan, numericFormater } from 'utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { MixpanelContext } from 'utils/tracking';
import PlanDowngradeModal from './planDowngradeModal'
import { getUser } from 'utils/helpers';
import { EVENTS } from 'constants/app';
import { mixpanelCommonData } from 'utils/helpers';

const PlanList = (props) =>  {
    const mixpanel = useContext(MixpanelContext)
    const { planSelectionHandler, selectedPlanId,redirectAssessment, planType = PLAN_TYPE.ANNUAL_PLAN } = props;
    const dispatch = useDispatch();
    const [ cbInstance, setCbInstance ] = useState(window.Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
    }))
    const [ open, setOpen ] = useState(false)
    const [ currentPlan, setCurrentPlan ] = useState(null)
    const planData = useSelector((state) => state.plan.plans)
    const sites = useSelector((state) => state.assessment.sites)
    const plansLoading = useSelector((state) => state.plan.plansLoading )
    const subscriptionData = useSelector((state) => state.subscription)
    const selectedPlan = (planData[ planType ] || [])?.filter((item) => selectedPlanId === item.id)[ 0 ]
    const selectorData = getUser();

    useEffect(() => {
        if (!planData?.length) {
            dispatch(getPlans());
        }
        dispatch(getAllSitesWP())
    }, []);

    // const urlEncode = function(data) {
    //     var str = [];
    //     for (var p in data) {
    //         // eslint-disable-next-line no-prototype-builtins
    //         if (data.hasOwnProperty(p) && (!(data[ p ] == undefined || data[ p ] == null))) {
    //             str.push(encodeURIComponent(p) + '=' + (data[ p ] ? encodeURIComponent(data[ p ]) : ''));
    //         }
    //     }
    //     return str.join('&');
    // }
    //     const handleEvent = (eventName, data) => {
    //     if(eventName=== 'Purchase plan'){
    //         debugger
    //         const tp = window.__tap;
    //         tp.create(process.env.REACT_APP_TAPFILIATE, { integration: 'stripe' });
    //         tp('conversion', data?.chargeId, data?.planAmount, { customer_id: data?.customerId, interval: data?.interval, email: selectorData?.email, planId: data?.planId, siteId: data?.siteId });
    //     }
    //     // if(eventName === 'Purchase plan' || eventName === 'Update Purchase plan'){
    //     //     setModalShow(true)
    //     // }
    //     mixpanel.track(eventName, mixpanelCommonData(data));
    // }
    const chargeBeePlan = (plan) => {
        cbInstance.openCheckout({
            hostedPage: async () => {
                var data = {
                    first_name: 'test',
                    last_name: 'test',
                    email: selectorData?.email,
                    userid: selectorData?.id,
                    planId: plan?.id,
                    subscriptionId: selectorData?.subscription?.id,
                };
                const response = await axiosInstance.put('/checkout', (data));
                return response.data;
            },
            success(hostedId) {
                // tapfiliate on subscription success
                try {
                    const tp = window.__tap;
                    tp.create(process.env.REACT_APP_TAPFILIATE, { integration: 'chargebee' });
                    // tp('conversion', hostedId, plan?.price, { customer_id: plan?.customerId, interval: plan?.interval, email: selectorData?.email, planId: plan?.planId, siteId: plan?.siteId });
                    tp('conversion', hostedId, plan?.price, { email: selectorData?.email, planId: plan?.id, siteId: selectorData?.siteId });
                } catch (err) {
                    console.log('TAPFILIATE FAILED -', err);
                }
                // tapfiliate on subscription ends
                cbInstance.closeAll()
                planSelectionHandler(hostedId,plan)
                if (selectorData?.status === 'TRIAL') {
                    mixpanel.track(
                        EVENTS.createSubscription,
                        mixpanelCommonData({ newPlan: plan, currentPlan: { name: 'TRIAL' } })
                    );
                } else {
                    mixpanel.track(
                        EVENTS.updateSubscription,
                        mixpanelCommonData({ newPlan: plan, currentPlan: selectedPlan })
                    );
                }
            },
            close:() => {
                console.log('checkout new closed');
            },
            step(step) {
                console.log('checkout', step);
            }
        });
        console.log(setCbInstance)
    }
    const currentPlanSelection = (plan) => {
        if(plan.numberOfSites < sites?.length ){
            setOpen(true)
        }else{
            mixpanel.track(EVENTS.clickOnPlan,{ plan: plan })
            //planSelectionHandler(plan);
            chargeBeePlan(plan)
        }

    }
    const hideModal = () => {
        setOpen(false)
        setCurrentPlan(null)
    }

    const renderFreePlan = (plan) => {
        return (<>
            <li>Get a beautiyful blog in seconds!</li>
            <li>{plan?.numberOfSites || 0} Website</li>
            <li>1 Custom Sub-Domain</li>
            <li>{formatNumber(plan?.viewsPerMonth)} Views</li>
            <li>{+plan?.storageInMb || 100} MB of space </li>
        </>)
    }

    const renderPaidPlan = (plan) => {
        const viewsPerMonth = plan?.viewsPerMonth ? `${ formatNumber(plan?.viewsPerMonth) } Views` : 'Unlimited Views / Months';

        return (<><li>{plan?.numberOfSites || 0} Websites</li>
            <li>Connect Custom Domain</li>
            <li>{viewsPerMonth}</li>
            <li>{(+plan?.storageInMb / 1024) || 0} GB space </li>
            <li>
                <span
                    style={ {
                        // color: 'red'
                        textTransform: 'uppercase',
                        background:
                        'linear-gradient(90deg, #F87700 0%, #E32093 38.96%, #9747FF 52.19%)',
                        '-webkit-background-clip': 'text',
                        '-webkit-text-fill-color': 'transparent',
                    } }
                >
                    {plan?.textTokensPerMonth === 10000000 ? 'Unlimited AI Credits' :  `${ numericFormater(plan?.textTokensPerMonth ?? 0) } monthly AI Credits` }
                </span>
            </li>
            <li><b>Collect email subscribers<span className={ 'w-100 d-flex' }>(Up to {formatNumber(plan?.emailSubscribers)})</span></b></li>
        </>)
    }
    const wizardPlan = (plan) => {
        return <Col key={ plan.id } sm={ 12 } xl={ 6 } md={ 6 }>
            <div className={  'new_plan_box' }>
                <div className="plan-detail">
                    {isAnnualPlan(planType) && <span className="annual-box">Annual</span>}
                    <div className="plan-detail-inner">
                        <h4 className={ 'plan-name businessPlan' }>Business</h4>
                        <div className="plan-price wizard-plan-price">For agencies and enterprise</div>
                        <ul className="plan-feature">
                            <li>Bulk discount pricing</li>
                            <li>Customised solutions & integrations tailored <br/>for your brand</li>
                            <li>Done-for-you SEO research + content writing services available</li>
                        </ul>
                        <div className='plan-action'>
                            <Button onClick={ () => {
                                window.open('https://jetpage.co/page/contact')
                            } } style={ { width: '50%' } }className="btn-primary-2" >Contact Us</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    }

    const renderPlanList = () => {
        // const plansData = (planData[ planType ] || [])
        // plansData.splice(-1)
        // plansData.push({ name: 'Wizrd' })
        const plans = (planData[ planType ] || [])?.map(plan => {
            const amountFactor = convertPriceInDollerAndCent(plan?.price);
            const annualPrice = convertCurrencyWithOutFraction(plan?.price)
            let price = '';
            if (isAnnualPlan(planType)) {
                // price = <>${amountFactor[ 0 ]} <sup>{amountFactor[ 1 ]}</sup><sub>/mo</sub></>
                price = <>${amountFactor[ 0 ]} <sub>/mo</sub></>
            } else {
                price = <>{annualPrice} <sub>/mo</sub></>
            }

            return (plan?.name === 'Wizrd' ?  wizardPlan(plan) : (plan?.price ? <Col key={ plan.id } sm={ 12 } xl={ 6 } md={ 6 }>
                <div className={ selectedPlanId == plan?.id ? 'active new_plan_box' : 'new_plan_box' }>
                    <div className="plan-detail">
                        {isAnnualPlan(planType) && <span className="annual-box">Annual</span>}
                        <div className="plan-detail-inner">
                            <h4 className={ 'plan-name' + (isBusinessPlan(plan?.name) ? ' businessPlan' : '') }>{plan?.name}</h4>
                            <ul className="plan-feature">
                                {plan?.price ? renderPaidPlan(plan) : renderFreePlan(plan)}
                            </ul>
                            <div className="plan-price">{price}</div>
                            {isAnnualPlan(planType) && <div className="billBYyear">Billed at ${plan?.price} / yr.</div>}
                            <div className="plan-action">
                                {!planSelectionHandler && <button className="btn btn-primary" onClick={ () => redirectAssessment() } >Try Now</button>}
                                {planSelectionHandler && <ButtonLoader
                                    button={ <Button disabled={ selectedPlanId == plan?.id } onClick={ () =>{
                                        setCurrentPlan(plan)
                                        currentPlanSelection(plan)
                                    } } variant="primary">{selectedPlanId == plan?.id ? 'Active' : 'Choose this plan'}</Button> }
                                    loading={ subscriptionData.isLoading && (currentPlan?.id === plan?.id) }
                                    loadingText={ 'Changing' }
                                    className={ 'removeHover' }

                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </Col> : null))
        })

        return <>{plans}<PlanDowngradeModal chargeBeePlan={ chargeBeePlan } planSelectionHandler={ planSelectionHandler }  sites={ sites }  selectedPlan={ selectedPlan }  subscriptionData={ subscriptionData } hideModal={ hideModal } plan={ currentPlan }  open={ open }  /></>;
    }

    return <Row className="no-gutters">{plansLoading && _.isEmpty(planData) ? <PlanListSkelton loading={ plansLoading } /> : renderPlanList() }</Row>
}

const PlanListSkelton = (props) => {
    const { loading } = props
    if(loading){
        const arr  = [ ...Array(4).keys() ]
        return arr.map((item) => {
            return(<Col key={ item } sm={ 12 } xl={ 6 } md={ 6 }>
                <div className={ 'new_plan_box' }>
                    <div className="plan-detail">
                        <div className="plan-detail-inner">
                            <h4 className='plan-name'><Skeleton count={ 1 } width={ '100px' } /></h4>
                            <ul className="plan-feature">
                                <Skeleton count={ 1 } width={ '100%' } />
                                <Skeleton count={ 1 } width={ '100%' } />
                                <Skeleton count={ 1 } width={ '100%' } />
                                <Skeleton count={ 1 } width={ '100%' } />
                            </ul>
                            <div className="plan-price"><Skeleton count={ 1 } width={ '80px' } /></div>
                            <div className="billBYyear"><Skeleton count={ 1 } width={ '100px' } /></div>
                            <div className="plan-action">
                                <Skeleton count={ 1 } width={ 200 } className="buttonSkel"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>)
        })
    }
    return null
}
PlanListSkelton.propTypes = {
    loading: PropTypes.bool,
}

PlanList.propTypes = {
    redirectAssessment: PropTypes.func,
    planSelectionHandler: PropTypes.func,
    selectedPlanId: PropTypes.number,
    planType: PropTypes.string
};

export default PlanList
