import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'
import { getUser } from 'utils/helpers'
import axiosInstance from 'services/api'
import { getCurrentUser } from 'middleware/auth'
const ManageSubscription = () => {
    const dispatch = useDispatch();
    const subscriptionData = useSelector((state) => state.subscription)
    const [ cbInstance, setCbInstance ] = useState(window.Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
    }))
    console.log(setCbInstance)

    const handleManageSubscription = () => {
        cbInstance.openCheckout({
            hostedPage: () => {
                var data = {
                    subscriptionId: subscriptionData?.subscription && subscriptionData?.subscription?.id || getUser().subscription && getUser().subscription?.id
                };
                return axiosInstance.put('/portal', (data)).then((response) => response.data)
            },
            success() {
                cbInstance.closeAll()
            },
            close:() => {
                dispatch(getCurrentUser())
                cbInstance.closeAll()
                console.log('checkout new closed');
            },
            step(step) {
                console.log('checkout', step);
            }
        });

    }

    return (

        <div className="profile-cards-sec col-sm-6">
            <div className="profile-subhead-wrap justify-content-center">
                <h4 className="profile-subhead">Manage Subscription</h4>
            </div>
            <div className="profile-cards">
                <div className="profile-right">
                    <ul>
                        <li>Edit Payment Method</li>
                        <li>View Billing History/Invoices</li>
                        <li>Cancel Paid Subscription</li>
                    </ul>
                    <button className='btn btn-primary' onClick={ handleManageSubscription } >Manage Subscription</button>

                </div>
            </div>
        </div>)
}

ManageSubscription.propTypes = {
    loadTransactions: PropTypes.func,
    isLoading: PropTypes.bool
};

export default ManageSubscription;
