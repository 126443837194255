import React from 'react';
import {
    Container
} from 'react-bootstrap';
// import Heading from '../../../components/core/heading';
import PlanDetail from './planDetail';
import PropTypes from 'prop-types';
import Reviews from 'pages/reviews';
import './style.sass'
import ONBoardingLine from './onBoardingLine';
const LandingPricing = (props) => {
    // eslint-disable-next-line react/prop-types
    const { redirectAssessment, showReview = true } = props
    const getHeading = () => <>Switch to Annual and get<strong> up to 25% OFF</strong></>;
    return (
        <>
            <section className="publisher-pricing mb-5">
                <Container>
                    <div className="publisher-pricing-inner" id="publisher-pricing-inner">
                        {/* <Heading
                        className="dark-heading"
                        heading={ <>Pricing</> }
                    /> */}
                        <div className="publisher-pricing-boxes">
                            <PlanDetail subHeading={ getHeading() } heading={ 'Pricing Plans' } />
                        </div>
                        {/* <div className="publisher-pricing-detail">
                            <h3>Setup your website now, pick a plan later</h3>
                            <h6>Try JetPage FREE for 14-days. No Credit Card required.</h6>
                            <div className="publisher-pricing-action">
                                <Button onClick={ () => redirectAssessment() } className="home-try-for-free">Try for Free</Button>

                            </div>
                        </div> */}
                        <ONBoardingLine redirectAssessment={ redirectAssessment }/>
                    </div>
                </Container>
            </section>
            {showReview && <Reviews renderTitle={ false }/>}
        </>
    )
}
LandingPricing.propTypes = {
    redirectAssessment: PropTypes.func
};
export default LandingPricing;