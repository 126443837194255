import React, { useContext, useEffect } from 'react';
import './home.sass';
import { mixpanelCommonData } from 'utils/helpers';

import history from 'utils/history';
import { ROUTES } from 'constants/appRoutes';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app';

import LandingHero from './landing-hero';
// import ShortReview from './short-review';
// import ColorScheme from './color-scheme';
import HowItWork from './how-it-work';
// import SeoCompare from './seo-compare';
// import KeyFeatures from './key-feature';
import CaseStudy from './jet-page';
// import LandingPricing from './home-pricing';
// import MoneyTimeSaving from './mone-time-saving';
import WhatIncluded from './what-Included';
import Faq from './faq';
import { Container } from 'react-bootstrap';
import ONBoardingLine from './home-pricing/onBoardingLine';
// import { Container } from 'react-bootstrap';

// const LandingHero = React.lazy(() => import('./landing-hero'));
// const ColorScheme = React.lazy(() => import('./color-scheme'));
// const HowItWork = React.lazy(() => import('./how-it-work'));
// const SeoCompare = React.lazy(() => import('./seo-compare'));
// const KeyFeatures = React.lazy(() => import('./key-feature'));
// const LandingPricing = React.lazy(() => import('./home-pricing'));
// const MoneyTimeSaving = React.lazy(() => import('./mone-time-saving'));
// const WhatIncluded = React.lazy(() => import('./what-Included'));
// const Faq = React.lazy(() => import('./faq'));

const HomePage = () => {
    const mixpanel = useContext(MixpanelContext);
    const redirectAssessment = () => {
        mixpanel.track(EVENTS.visitTryforFree, mixpanelCommonData({}));
        history.push(ROUTES.ASSESSMENT);
    };

    useEffect(() => {
        mixpanel.track(EVENTS.siteVisit, mixpanelCommonData({}));
    }, []);
    return (
        <section id="home" className="publisher-landing">
            <LandingHero redirectAssessment={ redirectAssessment } />
            {/* <Container> */}
            <section>
                <Container className='people-love-jetpage' fluid>
                    <h2>Why people love JetPage...</h2>
                    <iframe id="testimonialto-wizrd-org-reviews-testimonials-tag-homepage-light" src="https://embed-v2.testimonial.to/w/wizrd-org-reviews-testimonials?theme=light&card=base&loadMore=on&initialCount=20&hideDate=on&tag=homepage" frameBorder="0" scrolling="no" width="100%" onLoad={ ()=>iFrameResize({ log: true, warningTimeout: 1000000, heightCalculationMethod: 'lowestElement' }, this) }></iframe>
                    <div className='see-more-reviews'>
                        <a href="#" onClick={ ()=> history.push(ROUTES.REVIEWS) }>See more JetPage reviews<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg></a>
                    </div>
                </Container>
            </section>
            <HowItWork redirectAssessment={ redirectAssessment } />
            <WhatIncluded redirectAssessment={ redirectAssessment } />

            {/* </Container> */}
            {/* <ShortReview redirectAssessment={ redirectAssessment } /> */}
            <CaseStudy />
            <ONBoardingLine redirectAssessment={ redirectAssessment }/>
            {/* <ColorScheme redirectAssessment={ redirectAssessment } /> */}
            {/* <SeoCompare redirectAssessment={ redirectAssessment } /> */}
            {/* <KeyFeatures redirectAssessment={ redirectAssessment } /> */}
            {/* <LandingPricing showReview={ false } redirectAssessment={ redirectAssessment } /> */}
            {/* <MoneyTimeSaving redirectAssessment={ redirectAssessment } /> */}
            <Faq />
        </section>
    );
};

export default HomePage;
