import BlogPage from './pages/blog';
import BlogsPage from './pages/blogs';
import Niche from 'pages/blog/niche';
import TravelAgency from './pages/travel-agency';
import { ROUTES } from './constants/appRoutes';
import { isTravelTemplate , isSuperAdmin } from 'utils/helpers'
import Sites from './components/sites/';
import BillingPage from './pages/billing';
import DomainPage from './pages/domain';

import _ from 'lodash';
import Emails from 'pages/email/emails/sendEmails';
import ContactList from 'pages/email/contact/contact';
import EmailDomain from 'pages/email/domain';
import GrapesJsEditor from 'pages/grapesjs';

var routes = []
const AppRoutes = isTravelTemplate() ? _.concat(routes,[ {
    path: ROUTES.TRAVEL_AGENCY,
    component: TravelAgency,
    type: 'PrivateRoute',
} ]) : _.merge(routes,[ {
    path: ROUTES.BLOG,
    component: BlogPage,
    type: 'PrivateRoute',
},{
    path: ROUTES.EDIT_BLOG,
    component: BlogPage,
    type: 'PrivateRoute',
},{
    path: ROUTES.BLOGS,
    component: BlogsPage,
    type: 'PrivateRoute',
},{
    path: ROUTES.NICHE,
    component: Niche,
    type: 'PrivateRoute',
},{
    path: ROUTES.EMAIL_PAGE,
    component: EmailDomain,
    type: 'PrivateRoute',
},{
    path: ROUTES.EMAILS,
    component: Emails,
    type: 'PrivateRoute',
},{
    path: ROUTES.CONTACTS,
    component: ContactList,
    type: 'PrivateRoute',
},{
    path: ROUTES.GRAPES,
    component: GrapesJsEditor,
    type: 'PrivateRoute',
}
]
)
var routesData = []

const RestrictAdminRoutes = isSuperAdmin() ? _.concat(routesData,[]) : _.merge(routesData,[ {
    path: ROUTES.SITES,
    component: Sites,
    type: 'PrivateRouteWOS',
},{
    path: ROUTES.BILLING,
    component: BillingPage,
    type: 'PrivateRouteWOS',
},{
    path: ROUTES.DOMAIN,
    component: DomainPage,
    type: 'PrivateRouteWOS',
} ]
)
export { RestrictAdminRoutes , AppRoutes };