import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getPlans } from 'middleware/plan';
import { PLAN_TYPE } from 'constants/app';
import ButtonLoader from 'components/core/loader/button-loader';
import { convertCurrencyWithOutFraction } from 'services/currency';
import { formatNumber } from 'services/number';
import {
    convertPriceInDollerAndCent,
    isAnnualPlan,
    isBusinessPlan,
    numericFormater,
} from 'utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app';
const PlanList = (props) => {
    const mixpanel = useContext(MixpanelContext);
    const {
        planSelectionHandler,
        selectedPlanId,
        redirectAssessment,
        planType = PLAN_TYPE.ANNUAL_PLAN,
    } = props;
    const dispatch = useDispatch();
    const [ currentPlanId, setCurrentPlanId ] = useState(null);
    const planData = useSelector((state) => state.plan.plans);
    const plansLoading = useSelector((state) => state.plan.plansLoading);
    const subscriptionData = useSelector((state) => state.subscription);

    useEffect(() => {
        if (!planData?.length) {
            dispatch(getPlans());
        }
    }, []);

    const currentPlanSelection = (plan) => {
        mixpanel.track(EVENTS.clickOnPlan, { plan: plan });
        setCurrentPlanId(plan?.id);
        planSelectionHandler(plan);
    };

    const renderFreePlan = (plan) => {
        return (
            <>
                <li>Get a beautiyful blog in seconds!</li>
                <li>{plan?.numberOfSites || 0} Website</li>
                <li>1 Custom Sub-Domain</li>
                <li>{formatNumber(plan?.viewsPerMonth)} Views</li>
                <li>{+plan?.storageInMb || 100} MB of space </li>
            </>
        );
    };

    const renderPaidPlan = (plan) => {
    // const viewsPerMonth = plan?.viewsPerMonth
    //     ? `${ formatNumber(plan?.viewsPerMonth) } Views/mo`
    //     : 'Unlimited Views / Months';

        return (
            <>
                <li>{plan?.numberOfSites || 0} Websites</li>
                <li>#1 Cloud Hosting Included</li>
                <li>Unlimited Post and Pages</li>

                <li>
                    <span
                        style={ {
                            // color: 'red'
                            textTransform: 'uppercase',
                            background:
                            'linear-gradient(90deg, #F87700 0%, #E32093 38.96%, #9747FF 52.19%)',
                            '-webkit-background-clip': 'text',
                            '-webkit-text-fill-color': 'transparent',
                        } }
                    >
                        {plan?.textTokensPerMonth === 10000000 ? 'Unlimited AI Credits' :  `${ numericFormater(plan?.textTokensPerMonth ?? 0) } monthly AI Credits` }
                    </span>
                </li>
                <li>Connect Custom Domain</li>
                <li>Unlimited Views</li>
                {/* <li>{formatNumber(plan?.emailSubscribers)} Email Subscribers</li> */}
                <li>Unlimited Email Subscribers </li>
                <li>Priority Support </li>
                {/* <li>{viewsPerMonth}</li> */}
                <li>{+plan?.storageInMb / 1024 || 0} GB space </li>
                {/* <li><b>Collect email subscribers<span className={ 'w-100 d-flex' }>(Up to {formatNumber(plan?.emailSubscribers)})</span></b></li> */}
            </>
        );
    };

    const wizardPlan = (plan) => {
        return <Col key={ plan.id } sm={ 12 } xl={ 3 } md={ 3 }>
            <div className={  'new_plan_box' }>
                <div className="plan-detail">
                    {isAnnualPlan(planType) && <span className="annual-box">Annual</span>}
                    <div className="plan-detail-inner">
                        <h4 className={ 'plan-name' }>{plan?.name}<span className="businessPlan">Business</span></h4>
                        <div className="plan-price wizard-plan-price">For agencies and enterprices</div>
                        <ul className="plan-feature">
                            <li>Bulk discount pricing</li>
                            <li>Customised solutions & integrations tailored for your brand</li>
                            <li>Done-for-you SEO research + content writing services available</li>
                        </ul>
                        <div className='plan-action'>
                            <Button onClick={ () => {
                                window.open('https://form.typeform.com/to/bYMyGN0k')
                            } } className="btn-primary-2" >Contact Us</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    };

    const renderPlanList = () => {
        const plansData = planData[ planType ] || [];
        // plansData.splice(-1);
        // plansData.push({ name: 'Jetpage' });
        const plans = plansData?.map((plan) => {
            const amountFactor = convertPriceInDollerAndCent(plan?.price);
            const annualPrice = convertCurrencyWithOutFraction(plan?.price);
            let price = '';
            if (isAnnualPlan(planType)) {
                price = (
                    <>
                        ${amountFactor[ 0 ]}
                        <sub> / Month</sub>
                    </> //<sup>{amountFactor[ 1 ]}</sup>
                );
            } else {
                price = (
                    <>
                        {annualPrice} <sub> / Month</sub>
                    </>
                );
            }

            return plan?.name === 'Jetpage' ? (
                wizardPlan(plan)
            ) : plan?.price ? (
                <Col key={ plan.id } sm={ 12 } xl={ 3 } md={ 3 }>
                    <div
                        className={
                            selectedPlanId == plan?.id
                                ? 'active new_plan_box'
                                : 'new_plan_box'
                        }
                    >
                        <div className="plan-detail">
                            {isAnnualPlan(planType) && (
                                <span className="annual-box">Annual</span>
                            )}
                            <div className="plan-detail-inner">
                                <h4
                                    className={
                                        'plan-name' +
                    (isBusinessPlan(plan?.name) ? ' businessPlan' : '')
                                    }
                                >
                                    {plan?.name}
                                </h4>
                                <div className="plan-price">{price}</div>
                                {isAnnualPlan(planType) && (
                                    <div className="billBYyear">
                                        Billed at ${plan?.price} / yr.
                                    </div>
                                )}
                                <ul className="plan-feature">
                                    {plan?.price ? renderPaidPlan(plan) : renderFreePlan(plan)}
                                </ul>
                                <div className="plan-action" style={ { display: 'none' } }>
                                    {!planSelectionHandler && (
                                        <button
                                            className="btn btn-primary"
                                            onClick={ () => redirectAssessment() }
                                        >
                                            Try Now
                                        </button>
                                    )}
                                    {planSelectionHandler && (
                                        <ButtonLoader
                                            button={
                                                <Button
                                                    disabled={ selectedPlanId == plan?.id }
                                                    onClick={ () => currentPlanSelection(plan) }
                                                    variant="primary"
                                                >
                                                    {selectedPlanId == plan?.id
                                                        ? 'Active'
                                                        : 'Choose this plan'}
                                                </Button>
                                            }
                                            loading={
                                                subscriptionData.isLoading && currentPlanId === plan?.id
                                            }
                                            loadingText={ 'Changing' }
                                            className={ 'removeHover' }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            ) : null;
        });

        return plans;
    };

    return (
        <Row className="no-gutters">
            {plansLoading && _.isEmpty(planData) ? (
                <PlanListSkelton loading={ plansLoading } />
            ) : (
                renderPlanList()
            )}
        </Row>
    );
};

const PlanListSkelton = (props) => {
    const { loading } = props;
    if (loading) {
        const arr = [ ...Array(4).keys() ];
        return arr.map((item) => {
            return (
                <Col key={ item } sm={ 12 } xl={ 3 } md={ 3 }>
                    <div className={ 'new_plan_box' }>
                        <div className="plan-detail">
                            <div className="plan-detail-inner">
                                <h4 className="plan-name">
                                    <Skeleton count={ 1 } width={ '100px' } />
                                </h4>
                                <ul className="plan-feature">
                                    <Skeleton count={ 1 } width={ '100%' } />
                                    <Skeleton count={ 1 } width={ '100%' } />
                                    <Skeleton count={ 1 } width={ '100%' } />
                                    <Skeleton count={ 1 } width={ '100%' } />
                                </ul>
                                <div className="plan-price">
                                    <Skeleton count={ 1 } width={ '80px' } />
                                </div>
                                <div className="billBYyear">
                                    <Skeleton count={ 1 } width={ '100px' } />
                                </div>
                                <div className="plan-action">
                                    <Skeleton count={ 1 } width={ 200 } className="buttonSkel" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            );
        });
    }
    return null;
};
PlanListSkelton.propTypes = {
    loading: PropTypes.bool,
};

PlanList.propTypes = {
    redirectAssessment: PropTypes.func,
    planSelectionHandler: PropTypes.func,
    selectedPlanId: PropTypes.number,
    planType: PropTypes.string,
};

export default PlanList;
