export const ActionTypes = {
    GET_ASSESSMENT_REQUEST: 'GET_ASSESSMENT_REQUEST',
    GET_ASSESSMENT_SUCCESS: 'GET_ASSESSMENT_SUCCESS',
    GET_ASSESSMENT_FAILURE: 'GET_ASSESSMENT_FAILURE',
    CREATE_ASSESSMENT_REQUEST: 'CREATE_ASSESSMENT_REQUEST',
    CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
    CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',
    GET_UNSPLASH_REQUEST: 'GET_UNSPLASH_REQUEST',
    GET_UNSPLASH_SUCCESS: 'GET_UNSPLASH_SUCCESS',
    GET_UNSPLASH_ERROR: 'GET_UNSPLASH_ERROR',
    GET_VERIFIED_DOMAIN: 'GET_VERIFIED_DOMAIN',
    GET_VERIFIED_DOMAIN_REQUEST: 'GET_VERIFIED_DOMAIN_REQUEST',
    GET_VERIFIED_DOMAIN_ERROR: 'GET_VERIFIED_DOMAIN_ERROR',
    CLEAR_DOMAINS: 'CLEAR_DOMAINS',
    UPDATE_ASSESSMENT_SUCCESS: 'UPDATE_ASSESSMENT_SUCCESS',
    UPDATE_ASSESSMENT_REQUEST: 'UPDATE_ASSESSMENT_REQUEST',
    SITE_BUILD_REQUEST: 'SITE_BUILD_REQUEST',
    SITE_BUILD_FAILED: 'SITE_BUILD_FAILED',
    SITE_BUILD_SUCCESS: 'SITE_BUILD_SUCCESS',
    GET_ALL_SITES_REQUEST: 'GET_ALL_SITES_REQUEST',
    GET_ALL_SITES: 'GET_ALL_SITES',
    GET_ALL_SITES_FAILED: 'GET_ALL_SITES_FAILED',
    GET_SITE_BY_ID_REQUEST: 'GET_SITE_BY_ID_REQUEST',
    GET_SITE_BY_ID: 'GET_SITE_BY_ID',
    DOWNLOAD_CSV: 'DOWNLOAD_CSV',

    DELETE_SITE_REQUEST: 'DELETE_SITE_REQUEST',
    DELETE_SITE_SUCCESS: 'DELETE_SITE_SUCCESS',
    DELETE_SITE_FAILED: 'DELETE_SITE_FAILED',

    REMOVE_ALL_SITES_REQUEST: 'REMOVE_ALL_SITES_REQUEST',
    REMOVE_ALL_SITES_SUCCESS: 'REMOVE_ALL_SITES_SUCCESS',
    REMOVE_ALL_SITES_FAILED: 'REMOVE_ALL_SITES_FAILED',

    UPDATE_AUTHOR_DATA_REQUEST: 'UPDATE_AUTHOR_DATA_REQUEST',
    UPDATE_AUTHOR_DATA_SUCCESS: 'UPDATE_AUTHOR_DATA_SUCCESS',
    UPDATE_AUTHOR_DATA_FAILED: 'UPDATE_AUTHOR_DATA_FAILED',

    UPDATE_RETARGET_DATA_REQUEST: 'UPDATE_RETARGET_DATA_REQUEST',
    UPDATE_RETARGET_DATA_SUCCESS: 'UPDATE_RETARGET_DATA_SUCCESS',
    UPDATE_RETARGET_DATA_FAILED:  'UPDATE_RETARGET_DATA_FAILED',

    UPDATE_ACTIVECAMPAIGN_DATA_REQUEST: 'UPDATE_ACTIVECAMPAIGN_DATA_REQUEST',
    UPDATE_ACTIVECAMPAIGN_DATA_SUCCESS: 'UPDATE_ACTIVECAMPAIGN_DATA_REQUEST_SUCCESS',
    UPDATE_ACTIVECAMPAIGN_DATA_FAILED:  'UPDATE_ACTIVECAMPAIGN_DATA_REQUEST_FAILED',

    ACTIVECAMPAIGN_ACC_REQUEST: 'ACTIVECAMPAIGN_ACC_REQUEST',
    ACTIVECAMPAIGN_ACC_SUCCESS: 'ACTIVECAMPAIGN_ACC_REQUEST_SUCCESS',
    ACTIVECAMPAIGN_ACC_FAILED:  'ACTIVECAMPAIGN_ACC_REQUEST_FAILED',

    UPDATE_ACTIVECAMPAIGN_ACC_REQUEST: 'UPDATE_ACTIVECAMPAIGN_ACC_REQUEST',
    UPDATE_ACTIVECAMPAIGN_ACC_SUCCESS: 'UPDATE_ACTIVECAMPAIGN_ACC_SUCCESS',
    UPDATE_ACTIVECAMPAIGN_ACC_FAILED:  'UPDATE_ACTIVECAMPAIGN_ACC_FAILED',
    CREATE_SET_HOME_REQUEST: 'CREATE_SET_HOME_REQUEST'

}
