import React from 'react';
// import { SAMPLE_BLOG } from 'constants/app'
// import StylishView from 'components/edit-site/theme/stylish-view';
// import ModernView from 'components/edit-site/theme/modern-view';
import facebook from '../../../images/facebook-theme.svg';
import twitter from '../../../images/twitter-theme.svg';
import linkedIn from '../../../images/linkedin-theme.svg';
import instagram from '../../../images/instagram-theme.svg';
import userIcon from '../../../images/user-avatar.png';
// import blogImage from '../../../images/bk_.png';
import blogBanner from 'images/blog-banner.png';
import blog1 from '../../../images/theme-builder/stylish-upper-blog.svg';
import blog2 from '../../../images/theme-builder/blog2.svg';
import blog3 from '../../../images/theme-builder/blog3.svg';
import blog4 from '../../../images/theme-builder/blog4.svg';
// import blog5 from '../../../images/theme-builder/blog5.svg';
// import blog6 from '../../../images/theme-builder/blog6.svg';
import PropTypes from 'prop-types';

const ClassicView = ({ data }) => {
    const {
        headlineColor,
        headerData,
        optIn,
        headerTextColor,
        buttonTextColor,
        headerBgColor,
        buttonBgColor,
        overlay,
        previewFile,
        hideSubscribeButton,
        isHeaderTransparent,
        bannerBgColor,
        backgroundType
    } = data;

    const backgroundStyle = backgroundType === 'COLOR'
        ? { backgroundColor: bannerBgColor }
        : { backgroundImage: `url(${ previewFile || blogBanner })` };

    return (
        <>
            <div className="bg-light">
                <div style={ { position: 'relative' } }>
                    <div style={ {
                        ...backgroundStyle,
                        // backgroundImage: `url(${ previewFile || blogBanner })`,
                        backgroundSize: '100% 100%',
                        // backgroundPosition: '50%',
                        backgroundRepeat: 'no-repeat',
                        overflowWrap: 'anywhere',
                        minHeight: '210px'
                    } }>

                        <div
                            style={ { background: isHeaderTransparent ? 'transparent' :headerBgColor } }
                            className="theme-header d-flex justify-content-between"
                        >
                            <div className="header-left d-flex align-items-center">
                                <img
                                    className="header-profile"
                                    src={ userIcon }
                                    width={ '25px' }
                                    height={ '25px' }
                                />
                                <div
                                    style={ { color: headerTextColor } }
                                    className="header-text-classic "
                                >
                                    Logo
                                </div>
                            </div>
                            <div className="header-center d-flex align-items-center">
                                <div
                                    style={ { color: headerTextColor } }
                                    className="header-text-classic"
                                >
                                    Home
                                </div>
                                <div
                                    style={ { color: headerTextColor } }
                                    className="header-text-classic"
                                >
                                    Blogs
                                </div>
                                <div
                                    style={ { color: headerTextColor } }
                                    className="header-text-classic"
                                >
                                    About Us
                                </div>
                                <div
                                    style={ { color: headerTextColor } }
                                    className="header-text-classic"
                                >
                                    Contact
                                </div>
                            </div>
                            <div className="header-right d-flex align-items-center">
                                <img src={ facebook } width={ '15px' } height={ '15px' } />
                                <img src={ twitter } width={ '15px' } height={ '15px' } />
                                <img src={ linkedIn } width={ '15px' } height={ '15px' } />
                                <img src={ instagram } width={ '15px' } height={ '15px' } />
                                {!hideSubscribeButton ? (
                                    <button
                                        style={ { color: buttonTextColor, background: buttonBgColor } }
                                        className="subscribe-btn "
                                    >
                                        Subscribe
                                    </button>
                                ) : null}
                            </div>
                        </div>
                        <div
                            style={ {
                                // ...backgroundStyle,
                                // backgroundImage: `url(${ previewFile || blogBanner })`,
                                // backgroundSize: '100% 100%',
                                // backgroundRepeat: 'no-repeat',
                                padding: '20px 0',
                                overflowWrap: 'anywhere',
                                minHeight: 'inherit',
                                paddingLeft: '100px',
                                paddingRight: '100px'
                            } }
                            className={ 'banner-container d-flex flex-column justify-content-center' }
                        >
                            {headerData ? (
                                <div style={ { color: headlineColor, zIndex: 2 } } className='healine-text-responsive' dangerouslySetInnerHTML={ { __html: headerData } } />
                            ) : (
                                <h2 style={ { color: headlineColor, zIndex: 2 } } className="healine-text-responsive text-center ">
                                    Welcome to the most reliable source of healthy recipes!
                                </h2>
                            )}
                            <div style={ { zIndex: 2 } } className='d-flex align-items-center justify-content-center'>
                                {optIn ? (
                                    <div className="theme-form-wrapper d-flex mt-4 justify-content-start">
                                        <form className="theme-newsletter input-box-shadow m-0 ">
                                            <div
                                                style={ { background: 'transparent' } }
                                                className="theme-form-group d-flex align-items-center"
                                            >
                                                {/* <input
                                                    className="form-input"
                                                    placeholder="enter your email"
                                                    type="text"
                                                /> */}
                                                <button type="submit" style={ { color: buttonTextColor, background: buttonBgColor } } className="subscribe-btn ">
                                                    Subscribe
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className={ !overlay || backgroundType === 'COLOR' ? '' : headlineColor === '#FFFFFF' ? 'overlay-dark': 'overlay' }></div>
                </div>
                <div className="classic-view-container d-flex flex-column align-items-center justify-content-center">
                    <div className="classic-view-tabs d-flex">
                        <div className="tab-item active-tab">Latest</div>
                        <div className="tab-item">Most Popular</div>
                    </div>
                    <div className="classic-blog-container d-flex flex-column align-items-center justify-content-center">
                        <div className="blog d-flex ">
                            <div className="blog-image d-flex flex-column align-items-center justify-content-center"><img src={ blog1 } width={ '50%' } height={ '50%' }/> </div>
                            <div className="blog-details">
                                <h4 className="blog-title">
                                    Blog Post Title
                                </h4>
                                <div className="date-section d-flex align-items-center justify-content-between">
                                    <div className="">
                                        {/* <span className="author grey-text">EMIL KRISTENSIN</span> */}
                                        <br />
                                        <span className="date grey-text">Jan 1, 2024</span>
                                    </div>
                                    <div className="status">5 people read</div>
                                </div>
                            </div>
                        </div>
                        <div className="blog d-flex ">
                            <div className="blog-image d-flex flex-column align-items-center justify-content-center"><img src={ blog2 } width={ '50%' } height={ '50%' }/> </div>

                            <div className="blog-details">
                                <h4 className="blog-title">
                                    Blog Post Title
                                </h4>
                                <div className="date-section d-flex align-items-center justify-content-between">
                                    <div className="">
                                        {/* <span className="author grey-text">EMIL KRISTENSIN</span> */}
                                        <br />
                                        <span className="date grey-text">Jan 1, 2024</span>
                                    </div>
                                    <div className="status">5 people read</div>
                                </div>
                            </div>
                        </div>
                        <div className="blog d-flex ">
                            <div className="blog-image d-flex flex-column align-items-center justify-content-center"><img src={ blog3 } width={ '50%' } height={ '50%' }/> </div>

                            <div className="blog-details">
                                <h4 className="blog-title">
                                    Blog Post Title
                                </h4>
                                <div className="date-section d-flex align-items-center justify-content-between">
                                    <div className="">
                                        {/* <span className="author grey-text">EMIL KRISTENSIN</span> */}
                                        <br />
                                        <span className="date grey-text">Jan 1, 2024</span>
                                    </div>
                                    <div className="status">5 people read</div>
                                </div>
                            </div>
                        </div>
                        <div className="blog d-flex ">
                            <div className="blog-image d-flex flex-column align-items-center justify-content-center"><img src={ blog4 } width={ '50%' } height={ '50%' }/> </div>

                            <div className="blog-details">
                                <h4 className="blog-title">
                                    Blog Post Title
                                </h4>
                                <div className="date-section d-flex align-items-center justify-content-between">
                                    <div className="">
                                        {/* <span className="author grey-text">EMIL KRISTENSIN</span> */}
                                        <br />
                                        <span className="date grey-text">Jan 1, 2024</span>
                                    </div>
                                    <div className="status">5 people read</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-container d-flex flex-column align-items-center justify-content-center">
                    <div className="footer-wrapper d-flex align-items-center justify-content-between">
                        <div className="d-flex footer-content">
                            <img
                                className="footer-profile"
                                src={ userIcon }
                                width={ 'auto' }
                                height={ '60px' }
                            />
                            <div>
                                <div className="author-name">EMIL KRISTENSIN</div>
                                <div className="designation">
                                    Software Engineer and Web Enthusiast
                                </div>
                                <div className="social-icons d-flex align-items-center">
                                    <img src={ facebook } width={ '15px' } height={ '15px' } />
                                    <img src={ twitter } width={ '15px' } height={ '15px' } />
                                    <img src={ linkedIn } width={ '15px' } height={ '15px' } />
                                    <img src={ instagram } width={ '15px' } height={ '15px' } />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="subscribe-btn ">
                            Subscribe
                        </button>
                    </div>
                </div>
                <div className="footer-tab-wrapper d-flex align-items-center justify-content-center">
                    <div className="footer-tab d-flex align-items-center">
                        <div className="footer-text-classic">Home</div>
                        <div className="footer-text-classic footer-active-tab">Blogs</div>
                        <div className="footer-text-classic">Privacy Policy</div>
                        <div className="footer-text-classic">Terms & Conditions</div>
                    </div>
                </div>
            </div>
        </>
    );
};

ClassicView.propTypes = {
    data: PropTypes.object,
    headlineColor: PropTypes.string,
    headerData: PropTypes.string,
    headerTextColor: PropTypes.string,
    headerBgColor: PropTypes.string,
    buttonBgColor: PropTypes.string,
    previewFile: PropTypes.string,
    hideSubscribeButton: PropTypes.bool,
    isHeaderTransparent: PropTypes.bool,
    optIn: PropTypes.bool,
    overlay: PropTypes.bool,
    buttonTextColor: PropTypes.string,
    bannerBgColor: PropTypes.string,
    blogBanner: PropTypes.string,
};

export default ClassicView;
