export const ROUTES = {
    ROOT: '/',
    LOGIN: '/login',
    SITES: '/sites',
    DASHBOARD: '/dashboard',
    REGISTER: '/register',
    PRICING: '/pricing',
    REVIEWS: '/reviews',
    ASSESSMENT: '/assessment',
    FORGET_PASSWORD: '/forget-password',
    TERMS_CONDITIONS: '/terms-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    CONFIRM_ACCOUNT: '/confirm-account',
    AUTH_REDIRECT: '/oauth2/redirect',
    BLOG: '/blogs',
    BLOG_MARKETING: '/blog',
    ANALYTICS: '/analytics',
    BLOGS: '/blogs-content',
    PAGE: '/page',
    EDIT_BLOG: '/blogs/:id',
    EDIT_PAGE: '/pages/:id',
    EDIT_SITE: '/edit-site',
    MARKETING: '/marketing',
    USER_PROFILE: '/user-profile',
    PLAN_SELECTION: '/plan-selection',
    BILLING: '/billing',
    DOMAIN: '/domain',
    TRAVEL_AGENCY: '/travel-agency',
    NICHE:'/ideas',
    EMAIL_PAGE:'/email-page',
    EMAILS:'/emails',
    SEND_EMAILS:'/send-emails',
    CONTACTS:'/contacts',
    EMAIL_LISTING_PAGE:'/email-listing-page',
    DOMAIN_CONFIG_PAGE:'/domain-config-page',
    GRAPES:'/pagebuilder',
    EDIT_GRAPES:'/pagebuilder/:id',
}