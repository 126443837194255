import React, { useEffect } from 'react'
import { ROUTES } from '../constants/appRoutes';
import _ from 'lodash';
import { getItem, getLocalItem, getLocalItemParse } from './cache';
import { transform, isEqual, isObject } from 'lodash';
import { DOMAIN_CONNECT_STATUS, PLAN_STATUS, PLAN_TYPE } from 'constants/app';
import Resizer from 'react-image-file-resizer';
import { imageUpload } from '../middleware/assessments';
// import axios from 'axios';

export const isTravelTemplate = () => {
    return [ 'SKYBIRD' ].includes(process.env.REACT_APP_WEBSITE_TYPE)
}
export const addBodyClass = (pathname) => {
    const noHeaderAndFooter = [
        ROUTES.BLOG,
        ROUTES.DASHBOARD,
        ROUTES.SITES,
        ROUTES.USER_PROFILE,
        ROUTES.EDIT_SITE,
        ROUTES.PLAN_SELECTION,
        ROUTES.BILLING,
        // ROUTES.PAGE,
        ROUTES.BLOGS,
        ROUTES.NICHE,
        ROUTES.EMAIL_PAGE,
        ROUTES.CONTACTS,
        ROUTES.SEND_EMAILS,
        ROUTES.EMAIL_LISTING_PAGE,
        ROUTES.DOMAIN_CONFIG_PAGE,
        ROUTES.GRAPES,
        ROUTES.EDIT_GRAPES,
    ];
    const path = pathname
    document.body.className = ''

    if (path === '/') {
        document.body.classList.add('light-bg');
        document.body.classList.add('home-page');
    } else if (path === ROUTES.REGISTER) {
        document.body.classList.add('white-left');
        document.body.classList.add('no-footer');
    } else if (path === ROUTES.LOGIN) {
        document.body.classList.add('no-footer');
    } else if (path === ROUTES.FORGET_PASSWORD) {
        document.body.classList.add('blue-right');
        document.body.classList.add('no-footer');
    } else if (path === ROUTES.TERMS_CONDITIONS) {
        document.body.classList.add('light-bg');
    } else if (path === ROUTES.PRIVACY_POLICY) {
        document.body.classList.add('light-bg');
    } else if (path === '/404') {
        document.body.classList.add('light-bg');
    } else if (path === ROUTES.ASSESSMENT) {
        document.body.classList.add('no-footer');
        //document.body.classList.add('light-bg');
        //document.body.classList.add('grey-bg');
    } else if (path === ROUTES.CONFIRM_ACCOUNT) {
        document.body.classList.add('blue-left');
        document.body.classList.add('no-footer');
    } else if (noHeaderAndFooter.includes(path)) {
        document.body.classList.add('no-header');
        document.body.classList.add('no-footer');
    } else if (path.match(ROUTES.BLOGS)) {
        document.body.classList.add('no-header');
        document.body.classList.add('no-footer');
    } else if (path.match(ROUTES.PAGE)) {
        document.body.classList.add('no-header');
        document.body.classList.add('no-footer');
    } else if (path.match(ROUTES.PRICING)) {
        document.body.classList.add('light-bg');
        document.body.classList.add('home-page');
    }
    else if (path.match(ROUTES.MARKETING)) {
        document.body.classList.add('no-header');
        document.body.classList.add('no-footer');
    }
    else if (path.match(ROUTES.BLOG)) {
        document.body.classList.add('no-header');
        document.body.classList.add('no-footer');
    }
    if (isTravelTemplate()) {
        document.body.classList.add('wizrd-travel-template')
    }
}
export const truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
}

export const getUser = () => {
    return getLocalItem('user') && JSON.parse(getLocalItem('user')) || getItem('user') && JSON.parse(getItem('user'));
}

export const strToObj = (value) => {
    return typeof value === 'string' ? JSON.parse(value) : value
}
export const isLoggedIn = () => {
    const user = getUser();
    return user && Object.keys(user).length > 0 && user.constructor === Object;
}

export const getLabel = (value) => {
    return strToObj(value).label
}

export const isLoading = (state) => {
    const result = Object.keys(state)?.filter((item) => state[ item ].loading)
    return result?.length > 0
}

export const assessmentIntialValues = () => {
    const result = getItem('assessmentForm')
    return result ? JSON.parse(result) : {}
}

export const getSessionData = () => {
    return getLocalItemParse('sessionData').data?.data
}

export const togglePassword = (event) => {
    const show = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.12 14.12C13.8454 14.4147 13.5141 14.6511 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1961C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4858 9.58525 10.1546 9.88 9.87999M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68189 3.96914 7.6566 6.06 6.05999L17.94 17.94ZM9.9 4.23999C10.5883 4.07887 11.2931 3.99833 12 3.99999C19 3.99999 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.23999Z" stroke="#757575" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>`
    const hide = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.12 14.12C13.8454 14.4147 13.5141 14.6511 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1961C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4858 9.58525 10.1546 9.88 9.87999M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68189 3.96914 7.6566 6.06 6.05999L17.94 17.94ZM9.9 4.23999C10.5883 4.07887 11.2931 3.99833 12 3.99999C19 3.99999 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.23999Z" stroke="#757575" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 1L23 23" stroke="#757575" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>`
    const input = event.currentTarget.parentElement?.firstChild
    event.currentTarget.innerHTML = input.type === 'text' ? hide : show;
    input.type = input.type === 'text' ? 'password' : 'text'

}

export const createFileFromUrl = async (url, id) => {
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
        type: 'image/jpeg'
    };
    const file = new File([ data ], `${ id }.jpg`, metadata);
    return file
}

export const bytesToSize = (bytes) => {
    var sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[ i ];
}

export const assessmentSaved = (step, values) => {
    let assessment = getItem('assessmentForm')
    assessment = assessment ? JSON.parse(assessment) : {}
    switch (step) {
    case 'step1':
        return !_.isEmpty(assessment?.colors) && assessment.colors === values?.colors
    case 'step2':
        return !_.isEmpty(assessment?.websiteName) && assessment.websiteName === values?.websiteName
    default:
        return false
    }

}

export const queryStringToObject = (queryString) => {
    const pairs = queryString.substring(1).split('&');
    var array = pairs.map((el) => {
        const parts = el.split('=');
        return parts;
    });
    return Object.fromEntries(array);
}

export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[ 0 ].match(/:(.*?);/) && arr[ 0 ].match(/:(.*?);/)[ 1 ];
    if (mime) {
        const bstr = atob(arr[ 1 ]);
        let num = bstr.length;
        const u8arr = new Uint8Array(num);
        while (num--) {
            u8arr[ num ] = bstr.charCodeAt(num);
        }

        return new File([ u8arr ], filename, { type: mime });
    }
    return null

}

export const getRoute = () => {
    const sites = getLocalItemParse('site')
    return sites?.route
}
export const uId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & (0x3)) | (0x8));
        return v.toString(16);
    });
}

export const dataUrlToBase64 = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const getIdFromPath = (path) => {
    var regex = /\d+/g;
    return path.match(regex) && path.match(regex)[ 0 ];
}

export const getSlugFromPath = (path = '') => {
    const pathData = path?.split('/blogs/');
    return pathData.length ? pathData[ 1 ] : null;
}
export const getPageSlug = (path = '') => {
    const pathData = path?.split('/pages/');
    return pathData.length ? pathData[ 1 ] : null;
}
export const getDomain = () => {
    return getLocalItemParse('site')?.domain
}
export const getCustomDomain = () => {
    return getLocalItemParse('site')?.customDomain
}

export const getGjsSlug = (path = '') => {
    const pathData = path?.split('/pagebuilder/');
    return pathData.length ? pathData[ 1 ] : null;
}

export const getSite = () => {
    const site = getLocalItemParse('site')
    return site || {}
}
export const headerLinksTemplate = (links) => {
    var linksData = [ { name: 'Home', url: '#' }, { name: 'About', url: '#' }, { name: 'Contact', url: '#' } ] || links
    linksData = linksData.map((item, index) => <a key={ index } href='#'>{item.name}</a>)
    return linksData
}
export const debounce = (callback, event, delay) => {
    const timeout = setTimeout(() => {
        callback(event)
    }, delay);
    return () => {
        clearTimeout(timeout);
    }

}

export const isSubscriptionCancelled = (status) => {
    return status === PLAN_STATUS.SUBSCRIPTION_CANCELLED;
}

export const isBlockedSite = (status) => {
    return [ PLAN_STATUS.SUBSCRIPTION_CANCELLED, PLAN_STATUS.DORMANT, PLAN_STATUS.ARCHIVED, PLAN_STATUS.FROZEN ].includes(status)
}

export const isDisabledSite = (status) => {
    return [ PLAN_STATUS.ARCHIVED, PLAN_STATUS.FROZEN ].includes(status)
}

export const isSubscribed = (status) => {
    return status === PLAN_STATUS.SUBSCRIBED;
}

export const absoluteValue = (value) => {
    return value < 0 ? 0 : value
}

export const isAnnualPlan = (interval) => {
    return [ 'YEARLY', PLAN_TYPE.ANNUAL_PLAN ].includes(interval);
}

export const isBusinessPlan = (planName) => {
    return planName === 'Business';
}

export const convertPriceInDollerAndCent = (price = 0) => {
    const amount = (Math.round((price / 12) * 100) / 100).toFixed(2)
    return amount?.length ? amount.split('.') : [];
}

export const getCustomDomainStatus = (status = DOMAIN_CONNECT_STATUS.PENDING_VALIDATION) => {
    if (DOMAIN_CONNECT_STATUS[ status ] === DOMAIN_CONNECT_STATUS.ISSUED) {
        return 'Connected';
    }
    return DOMAIN_CONNECT_STATUS[ status ].split('_').join(' ');
}

export const getCurrentStep = (data) => {
    if (data?.status === DOMAIN_CONNECT_STATUS.CONNECTED ||
        data?.status == DOMAIN_CONNECT_STATUS.IN_PROGRESS ||
        data?.status == DOMAIN_CONNECT_STATUS.FAILED) {
        return 2;
    }
    return 0;
}

export const hexToHsl = (hex) => {
    if (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) {
            var r = parseInt(result[ 1 ], 16);
            var g = parseInt(result[ 2 ], 16);
            var b = parseInt(result[ 3 ], 16);

            r /= 255, g /= 255, b /= 255;

            var max = Math.max(r, g, b), min = Math.min(r, g, b);
            var h, s, l = (max + min) / 2;

            if (max == min) {
                h = s = 0; // achromatic
            } else {
                var d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
                }
                h /= 6;
            }
            s = s * 1
            l = l * 1;
            h = (360 * h);

            return { h: h, s: s, l: l, a: 1 }
        }
    }
    return { h: 0, s: s, l: 0, a: 0 }
}

export const hexToHsv = (hex) => {
    if (hex) {
        let rabs, gabs, babs, rr, gg, bb, h, s, v, diff
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) {
            rabs = parseInt(result[ 1 ], 16);
            gabs = parseInt(result[ 2 ], 16);
            babs = parseInt(result[ 3 ], 16);
            rabs /= 255, gabs /= 255, babs /= 255;
            v = Math.max(rabs, gabs, babs),
            diff = v - Math.min(rabs, gabs, babs);
            const diffc = c => (v - c) / 6 / diff + 1 / 2;
            if (diff == 0) {
                h = s = 0;
            } else {
                s = diff / v;
                rr = diffc(rabs);
                gg = diffc(gabs);
                bb = diffc(babs);

                if (rabs === v) {
                    h = bb - gg;
                } else if (gabs === v) {
                    h = (1 / 3) + rr - bb;
                } else if (babs === v) {
                    h = (2 / 3) + gg - rr;
                }
                if (h < 0) {
                    h += 1;
                } else if (h > 1) {
                    h -= 1;
                }
            }
            return {
                h: (h * 360),
                s: s,
                v: v,
                a: 1
            };
        } else {
            return {
                h: 0,
                s: 0,
                v: 0,
                a: 1
            }
        }
    }
}

export const arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[ 0 ]);
    return arr; // for testing
};

export function seo(data = {}) {

    const validString = (title) => {
        const str = title.replace(/\//g, ' | ').slice(2).trim('')
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    data.title =  data.title ? `${ validString(data.title) } | JetPage` : 'JetPage | Simple website builder for affiliates, bloggers, & SEO';
    data.metaDescription = data.metaDescription || 'The simple, fast WordPress alternative for affiliate marketers, bloggers, and SEO that gets more organic traffic. Setup in seconds. Try for free!';
    document.title = data.title;
    document.querySelector('meta[name="title"]').setAttribute('content', data?.customTitle ? data?.customTitle : 'JetPage | Simple website builder for affiliates, bloggers, & SEO');
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
    document.querySelector('meta[property="og:url"]').setAttribute('content', data.url);
    document.querySelector('link[rel="canonical"]').setAttribute('href', data?.canonical ?? 'https://jetpage.co/')
}

export const isValidDevEnv = () => {
    const host = window.location.host
    return host.match('local') || host.match('dev')
}

export const diffObjects = (object, base) => {
    return transform(object, (result, value, key) => {
        if (!isEqual(value, base[ key ])) {
            result[ key ] = isObject(value) && isObject(base[ key ]) ? diffObjects(value, base[ key ]) : value;
        }
    });
}
// export const isSiteChanges = (object, base) => {
//     const copyObj = { ...object }
//     copyObj &&  delete copyObj[ 'niche' ]
//     const site = {
//         colors: base?.colors,
//         logoUrl: base?.logoUrl,
//         coverImage: base?.coverImage,
//         faviconUrl: base?.faviconUrl,
//         header: base?.header,
//         menuLinks: base?.menuLinks

//     }
//     const result = base && diffObjects(copyObj, site)
// }
export const bodyScrollLock = (flag) => {
    if (flag) {
        document.getElementById('root').style.pointerEvents = 'none';
        document.getElementsByTagName('html')[ 0 ].style.overflow = 'hidden';
    } else {
        document.getElementById('root').style = null
        document.getElementsByTagName('html')[ 0 ].style = null
    }
}
export const setTour = (obj) => {
    const tour = _.isEmpty(obj) ? { dashboard: true, newBlog: true } : obj
    localStorage.setItem('tour', JSON.stringify(tour))
}

export const getTour = (name) => {
    const obj = localStorage.getItem('tour') && JSON.parse(localStorage.getItem('tour'))
    if (name) {
        return obj && obj[ name ]
    } else {
        return obj
    }
}
export const getDomainEnv = (domain) => {
    switch (process.env.REACT_APP_NODE_ENV) {
    case 'development':
        return `${ domain }-dev`
    case 'staging':
        return `${ domain }-uat`
    default:
        return domain
    }
}
export function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = [ '', 'k', 'm', 'b', 't' ];
        var suffixNum = Math.floor(('' + value).length / 3);
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[ suffixNum ];
    }
    return newValue;
}

export const trimStringLength = (str, maxLength) => {
    const trimmedString = str?.substr(0, maxLength)
    const remainString = str?.substr(trimmedString?.lastIndexOf(' '))
    const cutStr = remainString?.substring(0, remainString?.indexOf(' ', remainString?.indexOf(' ') + 1)) || remainString
    return str?.length > maxLength ? (trimmedString?.substr(0, trimmedString?.lastIndexOf(' ')) + cutStr) : trimmedString

}
export const trimStringLengthWithDots = (str, maxLength) => {
    const trimmedString = str?.substr(0, maxLength)
    const remainString = str?.substr(trimmedString?.lastIndexOf(' '))
    const cutStr = remainString?.substring(0, remainString?.indexOf(' ', remainString?.indexOf(' ') + 1)) || remainString
    const result = str?.length > maxLength ? (trimmedString?.substr(0, trimmedString?.lastIndexOf(' ')) + cutStr) : trimmedString
    return str === result ? result : result + '...'
}
export const mixpanelCommonData = (data) => {
    const user = getUser();
    const domain = getLocalItemParse('site')?.customDomain || getDomain()
    _.merge(data || {}, { 'user identifier': user?.email, domain: domain })
    return data
}
export const isProductonEnv = () => {
    return process.env.REACT_APP_NODE_ENV === 'production'
}
export const isStagingEnv = () => {
    return process.env.REACT_APP_NODE_ENV === 'staging'
}
export const isDevEnv = () => {
    return process.env.REACT_APP_NODE_ENV === 'development'
}
export const changeTitleToSlug = (value) => {
    const lowercase = value && value.toLowerCase();
    return lowercase.replace(/[^a-zA-Z0-9-_ ]/g, '').replace(/ /g, '-')
}

export const dayCount = (num) => {
    switch (num) {
    case 1:
        return <span className='red-msg'>Less than 24 hours</span>
    case 2:
        return <span className='red-msg'>48 hours left</span>
    default:
        return <span >{num} days left</span>
    }
}

export const closeWarningAleart = (event) => {
    event.currentTarget.parentElement.style.display = 'none'
}
export const isSuperAdmin = () => {
    return getUser()?.role === 'ROLE_SUPERADMIN'
}

export const getResizeImage = (file, maxWidth, maxHeight, inputType = 'PNG', outputType = 'file', quality = 80) => {
    console.log(quality)
    return new Promise((resolve) => {
        try{
            Resizer.imageFileResizer(file, maxWidth, maxHeight, inputType, 98, 0,
                (uri) => {
                    resolve(uri);
                },
                outputType
            );
        }catch(err){
            resolve(file)
            console.log(err)
        }
    });
}

const getImages = (string) => {
    const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
    const images = [];
    let img;
    while ((img = imgRex.exec(string))) {
        images.push(img[ 1 ]);
    }
    return images;
}

const convertToImageFile = async (url, filename, mimeType) => {
    if (url.startsWith('data:')) {
        var arr = url.split(','),
            mime = arr[ 0 ].match(/:(.*?);/)[ 1 ],
            bstr = atob(arr[ arr.length - 1 ]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[ n ] = bstr.charCodeAt(n);
        }
        var file = new File([ u8arr ], filename, { type:mime || mimeType });
        return Promise.resolve(file);
    }
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([ buf ], filename, { type: mimeType });
}

export const replaceImgScr = async (domain, htmlString) => {
    try{
        const imgUrls = getImages(htmlString);
        let compressedStr = htmlString;

        for (let i = 0; i < imgUrls.length; i++) {
            if(!imgUrls[ i ].includes('blog-image')){
                const file = await convertToImageFile(imgUrls[ i ], 'file.txt','image/png')
                if(file){
                    try{
                        const compressedFile = await getResizeImage(file, 624, 650, 'WEBP', 'file', 80);
                        const compressedUrl = await imageUpload(domain, 'blog-image', compressedFile);
                        compressedStr = compressedStr.replace(imgUrls[ i ], compressedUrl);
                    }catch(e){
                        console.log(e)
                    }
                }
            }
        }
        return compressedStr.replaceAll('<img', '<img loading="lazy"');
    }catch(e){
        console.log(e)
        return htmlString;
    }
}

export const createPageUrl = (url) => {
    if(![ 'http://','https://' ].some(prefix => url?.toString()?.startsWith(prefix))){
        const urlPrefix = 'https://';
        const urlSuffix = isSuperAdmin() ? `${ getCustomDomain() || getDomain() }/page${ url === '/' ? url : `/${ url }` }` : `${ getCustomDomain() || getDomain() }${ url === '/' ? url : `/${ url }` }`;
        return url ? `${ urlPrefix }${ urlSuffix }` : '';
    }else{
        return url;
    }
}

export const numericFormater = (val) => {
    return val?.toLocaleString() ?? val
}

export const getMaxTokensCount = () => {
    const user = getUser();
    return numericFormater(user?.planDetails?.textTokensPerMonth ?? 0)
}

export const getPendingTokensCount = () => {
    const user = getUser();
    return user?.textGenerationUsage?.tokensLeft < 0 ? 0 :numericFormater(user?.textGenerationUsage?.tokensLeft) ?? getMaxTokensCount();
}

export const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (evt) => {
            if (ref.current && !ref.current.contains(evt.target)) {
                callback(); //Do what you want to handle in the callback
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};

export const isValidEmail = (email) =>{
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
}

export default useOutsideClick;