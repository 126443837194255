import { getDomainData } from 'middleware/email';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DomainConfig from './domainConfig';
import { Spinner } from 'react-bootstrap';
import { ROUTES } from 'constants/appRoutes';
import { useHistory } from 'react-router-dom';

const EmailDomain = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const email = useSelector((state) => state.email);
    const userPlanId = useSelector((state) => state.user?.sessionData?.data?.data?.subscription?.planId);

    useEffect(() => {
        console.log('email-domain',email);
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: ROUTES.EMAIL_PAGE,
        });
        if(userPlanId === 1){
            history.replace(ROUTES.PLAN_SELECTION);
        }
        dispatch(getDomainData());
    }, []);

    useEffect(() => {
        if (email?.domainData?.data?.domain?.state === 'verified' || email?.domainData?.data?.domain?.state === 'active' ) {
            history.replace(ROUTES.EMAIL_LISTING_PAGE);
        }
    }, [ email ]);

    return email?.domainRequestInitiated ? (
        <main className="dashboard-data createNewBlog createNewPage">
            <section data-tut="reactour__iso" className="dashboard-body">
                <div
                    className="d-flex align-items-center justify-content-center loader-container"
                >
                    <Spinner variant="primary" animation="border" size={ 'md' } role="status" />
                </div>
            </section>
        </main>
    ) : (
        <DomainConfig/>
    )
};

export default EmailDomain;