/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import { getUser } from 'utils/helpers';
import { getCards } from 'middleware/card';
// import AddPaymentMethod  from '../user-profile/components/addPaymentMethod';
import { updateSubscription } from 'middleware/subscription'
import PlanDetail from 'components/homepage/planDetail';
// import { MixpanelContext } from 'utils/tracking';
// import {  MOST_OUT_WIZRD } from 'constants/app'
// import youtube from 'images/youtube-color.png'
// import facebook from 'images/facebook-color.png'
// import twitter from 'images/twitter-color.png'

// const ThankYouPage = () => {
//     return(<div className='dashboard-wrapper'>
//         <h3>Thank You For Choosing Wizrd!</h3>
//         <div className='data-box most-out-wrapper'>
//             <div className='most-out'>
//                 <h4>Refer a friend and get $25</h4>
//                 <ul>
//                     <li>
//                         <span>Copy Link</span>
//                         <input type='text' disabled={ true } />
//                     </li>
//                     <li><span >Or share from </span>
//                         <a href={ '#' } target='_blank' rel="noreferrer">
//                             <img src={ facebook } />
//                         </a>
//                         <a href={ '#' } target='_blank' rel="noreferrer">
//                             <img src={ twitter } />
//                         </a>
//                     </li>

//                 </ul>
//             </div>
//         </div>
//         <div className='data-box most-out-wrapper'>
//             <div className='most-out'>
//                 <h4>Get the most out of wizrd!</h4>
//                 <ul>
//                     <li><a href={ MOST_OUT_WIZRD.tutorial } target='_blank' rel="noreferrer">
//                         <img src={ youtube } /><br /><span className="img-lable">Watch Tutorials</span>
//                     </a>
//                     </li>
//                     <li><a href={ MOST_OUT_WIZRD.community } target='_blank' rel="noreferrer">
//                         <img src={ facebook } /><br/><span className="img-lable">Join Community</span>
//                     </a></li>
//                     <li><a href={ MOST_OUT_WIZRD.follow } target='_blank' rel="noreferrer">
//                         <img src={ twitter } /><br/><span className="img-lable">Follow For Updates </span>
//                     </a></li>
//                 </ul>
//             </div>
//         </div>
//     </div>)
// }

const PlanSelectionPage = () => {
    const dispatch = useDispatch();
    // const [ open, setOpen ] = useState(false)
    const history = useHistory();
    const cards = useSelector((state)=>state?.card?.cards);
    const [ selectedPlan, setSelectedPlan ] = useState(null);
    const [ modalShow, setModalShow ] = useState(false)
    const selectorData = getUser();
    // const mixpanel = useContext(MixpanelContext);

    useEffect(() => {
        if (!cards) {
            dispatch(getCards());
        }
    }, []);

    // const handleModal = () => {
    //     setOpen(!open)
    // }
    // const handleClose = () => {
    //     setOpen(false)
    // }

    // const handleEvent = (eventName, data) => {
    //     if(eventName=== 'Purchase plan'){
    //         debugger
    //         const tp = window.__tap;
    //         tp.create(process.env.REACT_APP_TAPFILIATE, { integration: 'stripe' });
    //         tp('conversion', data?.chargeId, data?.planAmount, { customer_id: data?.customerId, interval: data?.interval, email: selectorData?.email, planId: data?.planId, siteId: data?.siteId });
    //     }
    //     // if(eventName === 'Purchase plan' || eventName === 'Update Purchase plan'){
    //     //     setModalShow(true)
    //     // }
    //     mixpanel.track(eventName, mixpanelCommonData(data));
    // }

    // const manageSubscriptionHandler = (plan = selectedPlan) => {
    //     // const tp = Tap
    //     // tp.create(process.env.REACT_APP_TAPFILIATE, { integration: "stripe" });
    //     // tp.trial('cus_KBkZ0MpkF6mtOH')
    //     const payoad = { planId: plan?.id };
    //     if (selectorData.subscription) {
    //         payoad.id = selectorData?.subscription?.id;
    //         dispatch(updateSubscription(payoad, history, handleEvent ));
    //     } else {
    //         dispatch(createSubscription(payoad, history, handleEvent ));
    //     }
    //     handleClose();
    // }
    const manageSubscriptionHandler = (hostedId,plan) => {
        const payoad = { planId: plan?.id, hostedId: hostedId };
        payoad.subscriptionId = selectorData?.subscription?.id;
        dispatch(updateSubscription(payoad, history ));
        // handleClose()
    }

    const planSelectionHandler = (hostedId, plan ) => {
        setSelectedPlan(plan);
        console.log(selectedPlan)
        manageSubscriptionHandler(hostedId,plan);

    }
    const handleModalClose = () => {  setModalShow(false) }
    const getHeading = () => <>Switch to Annual and get<strong> up to 25% OFF</strong></>;

    return(
        <main className="dashboard-data upgradePlanSection website-plan ">
            <section className="dashboard-body">
                <div className={ 'website-plan pt-0 pb-0 container' }>
                    <Row>
                        <Col className={ 'text-left upgradePlanText' }>
                            <h4 className="managePlanTitle">Manage plan</h4>
                        </Col>
                    </Row>
                    <PlanDetail planSelectionHandler={ planSelectionHandler } selectedPlanId={ selectorData?.subscription?.planId } secondHeading={ getHeading() } />
                </div>
                <Modal show={ modalShow } onHide={ handleModalClose } className={ 'updateBill thPage' }>
                    {/*<ThankYouPage />*/}
                </Modal>
            </section>
        </main>
    )
}

export default PlanSelectionPage