import { ActionTypes } from '../actions/email/actionTypes';
const initialState = {
    loading: false,
    listLoading: false,
    groupList: [],
    subscribersList: [],
    allEmailsList: [],
    domainData: {},
    domainRequestSuccess: false,
    domainRequstFailed: false,
    domainRequestInitiated: false,
    error: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.PUBLISH_LIST_REQUEST:
        return { ...state, listLoading: true };
    case ActionTypes.PUBLISH_LIST_FAILURE:
        return { ...state, listLoading: false };
    case ActionTypes.PUBLISH_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.PUBLISH_FAILURE:
        return { ...state, loading: false };
    case ActionTypes.PUBLISH_SUCCESS:
        return { ...state, loading: false };
    case ActionTypes.PUBLISH_GROUP_LIST:
        return { ...state, listLoading: false, groupList: action.payload };
    case ActionTypes.PUBLISH_SUBSCRIBERS_LIST:
        return { ...state, listLoading: false, subscribersList: action.payload };
    case ActionTypes.PUBLISH_ALL_EMAIL_LIST:
        return { ...state, listLoading: false, allEmailsList: action.payload };
    case ActionTypes.INITIATE_DOMAIN_REQUEST:
        return { ...state, domainRequestInitiated: true };
    case ActionTypes.PUBLISH_DOMAIN_REQUEST_FAILURE:
        return { ...state, domainRequstFailed: true, domainRequestInitiated: false, domainRequestSuccess: false, domainData: {} };
    case ActionTypes.PUBLISH_DOMAIN_DATA:
        return { ...state, domainRequstFailed: false, domainRequestInitiated: false, domainRequestSuccess: true, domainData: action.payload };
    default:
        return state;
    }
};
