import React from 'react';
import '../contacts.sass';
import PropTypes from 'prop-types';
import moment from 'moment';

const ContactTable = ({
    extractFirstAndLastName,
    handleEdit,
    filteredItems,
    email,
    setSelectedList,
    selectedList
}) => {
    const onSelect = (e, id) => {
        if (e.target.checked) {
            setSelectedList([ ...selectedList, id ]);
        } else {
            const currentList = [ ...selectedList ];
            currentList.splice(selectedList?.indexOf(id), 1);
            setSelectedList([ ...currentList ]);
        }
    };
    const onSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedList(email.subscribersList.map((i) => i.id));
        } else {
            setSelectedList([]);
        }
    };
    return (
        <div
            className="mt-3 contacts-table-wrapper"
            style={ {
                height: '585px',
                width: '100%',
                borderRadius: '5px',
                border: '1px solid #C4C4C4',
            } }
        >
            <div style={ { width: 'fit-content' } }>
                <div className="contacts-table-header">
                    <span className="header-items">
                        <input
                            style={ { width: '25px', height: '25px' } }
                            onChange={ onSelectAll }
                            checked={ email?.subscribersList?.length === selectedList?.length }
                            type="checkbox"
                        />
                        <span>Select All</span>
                    </span>
                    <span className="header-items">S. No.</span>
                    <span className="header-items">User ID</span>
                    <span className="header-items">First Name</span>
                    <span className="header-items">Last Name</span>
                    <span className="header-items">Status</span>
                    <span className="header-items" style={ { width: '150px' } }>
                        Customer Email
                    </span>
                    <span className="header-items" style={ { width: '300px' } }>
                        Lists
                    </span>
                    <span className="header-items">Date Added</span>
                    <span className="header-items">Edit</span>
                </div>

                {email?.subscribersList?.length || filteredItems.length
                    ? (filteredItems.length ? filteredItems : email.subscribersList).map(
                        (item, index) => {
                            return (
                                <div
                                    key={ item?.id ? item?.id : index }
                                    className="contact-listing mt-3"
                                >
                                    <span className="header-items">
                                        <input
                                            style={ { width: '25px', height: '25px' } }
                                            checked={ selectedList?.indexOf(item.id) > -1 }
                                            type="checkbox"
                                            onChange={ (e) => onSelect(e, item.id) }
                                        />
                                    </span>
                                    <span className="header-items">{index + 1}</span>
                                    <span className="header-items">{item.id}</span>
                                    <span className="header-items">
                                        {extractFirstAndLastName(item.name)?.firstName ?? ''}
                                    </span>
                                    <span className="header-items">
                                        {extractFirstAndLastName(item.name)?.lastName ?? ''}
                                    </span>
                                    <span className="header-items">Active</span>
                                    <span className="header-items" style={ { width: '150px' } }>
                                        {item?.email}
                                    </span>
                                    <div
                                        className="header-items"
                                        style={ { width: '300px', gap: 5, flexWrap: 'wrap' } }
                                    >
                                        {
                                            item?.subscribersListNames?.length
                                                ? item?.subscribersListNames.map((list, i) => {
                                                    return (
                                                        <span
                                                            className="d-flex justify-content-center align-items-center"
                                                            key={ i }
                                                            style={ {
                                                                borderRadius: '5px',
                                                                padding: '3px 5px',
                                                                color: '#000',
                                                                background: '#C4C4C4',
                                                            } }
                                                        >
                                                            {list?.listName}
                                                        </span>
                                                    );
                                                })
                                                : null
                                        }
                                    </div>
                                    <span className="header-items">
                                        {item?.createdAt
                                            ? moment(item?.createdAt).format('MM-DD-YYYY')
                                            : ''}
                                    </span>
                                    <span className="header-items">
                                        <button
                                            onClick={ () => handleEdit(item) }
                                            className="list-edit-btn"
                                        >
                                            Edit
                                        </button>
                                    </span>
                                </div>
                            );
                        }
                    )
                    : null}
            </div>
        </div>
    );
};

ContactTable.propTypes = {
    extractFirstAndLastName: PropTypes.string,
    handleEdit: PropTypes.func,
    filteredItems: PropTypes.array,
    email: PropTypes.array,
    setSelectedList: PropTypes.func,
    selectedList: PropTypes.array,
    selsectedGrp: PropTypes.array,
    handleDelete: PropTypes.func,
};

export default ContactTable;
