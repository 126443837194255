import React from 'react'
import InputSelect from 'react-select-input'
import{  Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux'
import { sortableContainer, sortableElement , sortableHandle , arrayMove } from 'react-sortable-hoc';
import { createPageUrl } from 'utils/helpers';
import {
    SortIcon,
    //PlusIcon
} from 'utils/svg';
const Footer  = (props) => {
    const {
        urlError,
        addMenuLinks,
        setLinkType,
        linkType,
        setLoadingType,
        removeMenuLink,
        setMenuLinks,
        handleChangeMenuUrl,
        handleChangeMenuName,
        handleNewTab,
        loadingtype,
        menuLinks
    } = props
    const setLinkTypeFun = (index,event) => {
        event?.preventDefault()
        if(event?.target?.id !== 'remove'){
            linkType !== index && setLoadingType(true)
            setLinkType(index)
        }
    }
    const removeLinkTypeFun = (index,event) =>{
        event.preventDefault()
        setLoadingType(true)
        linkType === index && setLinkType(linkType-1)
        removeMenuLink(index)
        setLoadingType(false)
    }
    const renderItem = ( item, index ) =>{
        return(
            <li key={ index } style = { { zIndex: 100000 } } className={ `${ linkType === index ? 'active' :'' }` }>
                <div className="menu-order">{ index+1 }.</div>
                <div className="menu-detail" onClick={ (event) =>  setLinkTypeFun(index,event) }  >
                    <div className="menu-detail-inner" onClick={ (event) =>  setLinkTypeFun(index,event) } >
                        <DragHandle item={ item } />
                        <div className="">
                            <a className="menu-action" href='#'  >edit</a>&nbsp;
                            {item?.name !== 'home' && item?.url !=='contact' && <a className="menu-action" id='remove' href='#' onClick={ (event) => removeLinkTypeFun(index,event) }>remove</a>}
                        </div>
                    </div>
                </div>
            </li>)
    }
    const SortableItem = sortableElement(({ item, indexData }) => renderItem(item, indexData ));
    const SortableContainer = sortableContainer(({ children }) => {
        return <ol>{children}</ol>;
    });
    const onSortEnd = ({ oldIndex, newIndex })  => {
        let menuLinksCopy = [ ... menuLinks ]
        menuLinksCopy = arrayMove(menuLinksCopy, oldIndex, newIndex)
        setMenuLinks(menuLinksCopy)
    }
    const addLinks = () =>{
        addMenuLinks()
        setLinkTypeFun(menuLinks.length)
    }
    const getData = () => {
        return menuLinks[ linkType ]
    }

    const DragHandle = sortableHandle(({ item }) => {

        return(<div className="menu-name" >
            <span className="menu-drag">
                <SortIcon />
                <SortIcon />
            </span>
            <span>{ item?.name ? item?.name?.uppercase() : '...' }</span>
        </div>)
    })
    const allPages = useSelector((state) => state.page.allPages )
    return( <div className="edit-menu-listing">
        <div className="menu-listing">
            {menuLinks.length === 0 && <ol>
                <li >
                    <div className="menu-detail"   >
                        <div className="menu-detail-inner" >
                            <div className="">
                                <a href='#'  onClick={ (event) => {
                                    event.preventDefault()
                                    addLinks()
                                } }>Add footer menu </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
            }

            <SortableContainer   onSortStart={ (d) =>{
                setLinkTypeFun(d.index,undefined )
            } } helperClass='sortableHelper'  onSortEnd={ onSortEnd } useDragHandle>
                {menuLinks.map((item, index) => (

                    <SortableItem key={ `item-${ index }` } indexData={ index } index={ Number(index) } item={ item } />

                ))}
            </SortableContainer>

            {menuLinks.length < 7 && <button className='add-icon-btn' type='button' onClick={ addLinks }>+</button>}
        </div>

        <div className="add-menu">
            {!loadingtype &&
            <Form>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <input type='text'
                        name='name'
                        disabled={ getData()?.name === 'home' }
                        onChange={ (event) => handleChangeMenuName(event,linkType,getData()?.url) }
                        className='form-control ris-name'
                        defaultValue={ getData()?.name }
                        value={ getData()?.name  }

                    />

                </Form.Group>
                <Form.Group className="url-control">
                    <Form.Label>Link</Form.Label>
                    <InputSelect
                        className={ `input-select-menu-link ${ getData()?.name === 'home' ||  getData()?.url === 'contact' ? 'input-disabled' : '' }` }
                        clearable={ false }
                        onSelect={ (event) => handleChangeMenuUrl(event,linkType, 'click')  }
                        onChange={ (event) => handleChangeMenuUrl(event,linkType, 'change') }
                        options={ allPages.map((item) => ({ label: item.slug, value: item.slug })) || [] }
                        autoFocus={ false }
                        disableEnter={ getData()?.name === 'home' || getData()?.url === 'contact'  }
                        value = { getData()?.url }
                    />
                    { urlError[ linkType ] && <span className='field_error'>Please insert vaild url or ( use http | https | ftp )</span>}
                </Form.Group>
                { getData()?.name !== 'home' &&  <Form.Group className="url-control">
                    <Form.Label>
                        <div className="newtab_input">
                            <input
                                type='checkbox'
                                checked={ getData()?.newTab }
                                onChange={ (event) => handleNewTab(event,linkType) }
                                name='fsad' />
                            <span className="newtab_custom_input"></span>
                        </div>
                        Open link in a new tab</Form.Label>
                </Form.Group>}
                <Form.Group>
                    <Form.Label>Full URL</Form.Label>
                    <input
                        type="text"
                        name="fullUrl"
                        disabled={ true }
                        onChange={ () =>{} }
                        className="form-control url-link"
                        // defaultValue={ getData()?.fullUrl }
                        value={ getData()?.url ? createPageUrl(getData()?.url) : '' }
                    />
                </Form.Group>
            </Form>
            }
        </div>
    </div>)
}

Footer.propTypes = {
    setLinkType: PropTypes.func,
    linkType: PropTypes.string,
    handleRecentMenuLinks: PropTypes.func,
    handleNewTab: PropTypes.func,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    menuLinks: PropTypes.array,
    addMenuLinks: PropTypes.func,
    loadData: PropTypes.bool,
    assessmentData: PropTypes.object,
    handleChangeMenuName: PropTypes.func,
    handleChangeMenuUrl: PropTypes.func,
    loadText: PropTypes.bool,
    removeMenuLink: PropTypes.func,
    isValid: PropTypes.bool,
    loading: PropTypes.bool,
    setMenuLinks: PropTypes.func,
    setLoadingType: PropTypes.func,
    loadingtype: PropTypes.bool,
    urlError: PropTypes.object

};

export default Footer