import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { isTravelTemplate } from 'utils/helpers';
import { ROUTES } from 'constants/appRoutes';
import history from 'utils/history';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app';
import './style.sass';

const LandingHero = () => {
    const mixpanel = useContext(MixpanelContext);
    return (
        <section className="publisher-landing-hero">
            <Container>
                <div className="publisher-landings-content">
                    <div className="publisher-landing-hero-inner">
                        {isTravelTemplate() ? (
                            <h1>
                                The easiest way for travel agents to sell online and{' '}
                                <span>get more customers!</span>
                            </h1>
                        ) : (
                            <h1>
                                Say hello to the simplest all-in-one blogging platform that helps you
                                <span style={ { background:
                  'linear-gradient(to right, #F87700 0%, #E32093 38.96%)',
                                '-webkit-background-clip': 'text',
                                '-webkit-text-fill-color': 'transparent', } }> research, write,</span> and <span style={ { background:
                                    'linear-gradient(to right, #E32093 0%, #9747FF 50.19%)',
                                '-webkit-background-clip': 'text',
                                '-webkit-text-fill-color': 'transparent', } }> publish </span> content

                                <span style={ { background:
                  'linear-gradient(to right, #F87700 0%, #E32093 48.96%, #9747FF 92.19%)',
                                '-webkit-background-clip': 'text',
                                '-webkit-text-fill-color': 'transparent', } }> 10x faster with AI.</span>
                            </h1>
                        )}
                        {/* <p className="main-sub-head">
                            Get a website <b>in seconds </b>that simply works. So you can
                            focus on your business, not your website.
                        </p> */}
                        <button
                            onClick={ () => {
                                mixpanel.track(EVENTS.visitTryforFree, {});
                                history.push(ROUTES.ASSESSMENT);
                            } }
                            className="try-free-btn"
                        >
                            Try for Free
                        </button>
                        <p>Get started in seconds, no credit card required.</p>
                        {/* <br /> */}
                    </div>
                    <div className="publisher-landing-hero-youtube">
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube-nocookie.com/embed/Qd2ncpcFJUI?controls=0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                {/* <div className='row publisher-landings-content'>
                    <div className="publisher-landing-hero-inner">
                        {isTravelTemplate() ? <h1>The easiest way for travel agents to sell online and <span>get more customers!</span></h1> : <h1>
                            The simple, fast WordPress alternative for<span> affiliate marketers</span>,<span> bloggers</span> and<span> SEO</span> that gets more organic traffic.
                        </h1>}
                        <p className='main-sub-head'>
                            Get a website <b>in seconds </b>that simply works. So you can focus on your business, not your website.
                        </p>
                        <p>Start your 14-day free trial, no credit card required.</p>
                        <button onClick={ () => {
                            mixpanel.track(EVENTS.visitTryforFree, {})
                            history.push(ROUTES.ASSESSMENT)
                        } } className='btn btn-primary btn-primary-2 mt-2'>
                            Try for free
                        </button><br />
                        {/* <div className="main-sub-para">
                            <a href="#">or see a demo website <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg></a>
                        </div> */}
                {/* </div>
                    <div className='publisher-landing-hero-youtube'>
                        <iframe
                            width="400"
                            height="250"
                            src="https://www.youtube-nocookie.com/embed/6vu2Sf8GRuM"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div> */}
                {/* </div> */}
            </Container>
        </section>
    );
};

export default LandingHero;
