import * as React from 'react';
import PropTypes from 'prop-types'

import { EditorTools , EditorUtils } from '@progress/kendo-react-editor';
const fontSizeToolSettings = {
    style: 'font-size',
    defaultItem: { text: 'Small', value: '1pt' },
    items: [
        { text: 'Small', value: '1pt', selected: true },
        { text: 'Medium', value: '1.75pt' },
        { text: 'Large', value: '3pt' },
        { text: 'XL', value: '3.75pt' },
    ],
    commandName: 'FontSize'
};

// Creates FontSize tool
const MyFontSizeTool =
  EditorTools.createStyleDropDownList(fontSizeToolSettings);
// Styles the FontSize tool
const CustomFontSize = (props) => {
    const view = props.view
    return(
        <MyFontSizeTool
            onChange={ (event) => {
                // EditorUtils.formatBlockElements(view,'p','FontSize')
                EditorUtils.applyInlineStyle(view,{ style: fontSizeToolSettings.style , value: event.value.value })
                var syntheticEvent = event.syntheticEvent;
                if (syntheticEvent && syntheticEvent.type === 'click') {
                    view.focus();
                }
            } }
            className='select-font-size'
            { ...props }
            style={ {
                width: '100px',
                fontSize: '16px',
                height: '36px',
                // eslint-disable-next-line react/prop-types
                ...props.style,
            } }
        />)
}

CustomFontSize.propTypes = {
    view: PropTypes.any
}

export { CustomFontSize };