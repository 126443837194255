import axiosInstance from '../services/api';

import {
    createSubscriptionRequest,
    createSubscriptionSuccess,
    updateSubscriptionRequest,
    updateSubscriptionSuccess,
    cancelAccountRequest,
    freezeAccountRequest,
    setDiscountRequest,
    updateSubscriptionFailure,
    createSubscriptionFailure,
    cancelAccountSuccess,
    cancelAccountFailure,
    freezeAccountSuccess,
    freezeAccountFailure,
    setDiscountFailure,
    setDiscountSuccess,
    unFreezeAccountRequest,
    unFreezeAccountSuccess,
    unFreezeAccountFailure,
} from '../actions/subscription';
import { notification } from 'services/notification';
import { MESSAGE, NOTIFICATION_TYPES } from 'constants/app';
import { ROUTES } from 'constants/appRoutes';
import { getTransactions } from './transaction';
import { getCurrentUser } from './auth';
import { getUser, getSite } from 'utils/helpers';

export const createSubscription = (data, history, callback) => {
    const site = getSite()
    const user = getUser()
    return (dispatch) => {
        dispatch(createSubscriptionRequest())
        axiosInstance.post('/subscriptions', data).then((response)=>{
            const eventData = Object.assign({}, { siteId: site?.id ,'user identifier':user?.id },response.data)
            dispatch(createSubscriptionSuccess(response.data));
            callback && callback('Purchase plan',eventData)
            setTimeout(() => dispatch(getTransactions()), 5000);
            history.push(ROUTES.BILLING);
        }).catch((error) => {
            dispatch(createSubscriptionFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

export const updateSubscription = (data, history, callback ) => {
    const site = getSite()
    const user = getUser()
    return (dispatch) => {
        dispatch(updateSubscriptionRequest())
        axiosInstance.put('/subscriptions', data).then((response)=>{
            const eventData = Object.assign({}, { siteId: site?.id ,'user identifier':user?.id },response.data)
            callback && callback('Update Purchase plan',eventData)
            dispatch(updateSubscriptionSuccess(response.data.data))
            setTimeout(() => dispatch(getTransactions()), 5000);
            history.push(ROUTES.BILLING);
        }).catch((error) => {
            dispatch(updateSubscriptionFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

export const retrieveHostageId =(hostageId,type, callback,history,site,user) =>{
    return (dispatch) => {
        dispatch(updateSubscriptionRequest())
        axiosInstance.put(`hosted/${ hostageId }`).then((response)=>{
            const eventData = Object.assign({}, { siteId: site?.id ,'user identifier':user?.id },response.data)
            callback && callback(`${ type } Purchase plan`,eventData)
            dispatch(updateSubscriptionSuccess(response.data.data))
            setTimeout(() => dispatch(getTransactions()), 5000);
            history.push(ROUTES.BILLING);
        }).catch((error) => {
            dispatch(updateSubscriptionFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
}

export const cancelAccount = (id, callback) => {
    return (dispatch) => {
        const selectorData = getUser();
        dispatch(cancelAccountRequest())
        axiosInstance.patch(`/subscriptions/${ id }/_cancel`).then(()=>{
            callback && callback('Cancel Account', { id: selectorData?.id, email: selectorData?.email, userName: selectorData?.userName, 'user identifier': selectorData?.id })
            dispatch(cancelAccountSuccess())
            dispatch(getCurrentUser());
            notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.SUBSCRIPTION_CANCELLED_SUCCESS);
        }).catch((error) => {
            callback && callback('Failed Cancel Account', { id: selectorData?.id, email: selectorData?.email, userName: selectorData?.userName, 'user identifier': selectorData?.id })
            dispatch(cancelAccountFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

export const freezeAccount = (id) => {
    return (dispatch) => {
        dispatch(freezeAccountRequest())
        axiosInstance.patch(`/subscriptions/${ id }/_freeze-account`).then(()=>{
            dispatch(freezeAccountSuccess())
            dispatch(getCurrentUser());
            notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.SUBSCRIPTION_FREEZE_SUCCESS);
        }).catch((error) => {
            dispatch(freezeAccountFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

export const setDiscountAccount = (id) => {
    return (dispatch) => {
        dispatch(setDiscountRequest())
        axiosInstance.patch(`/subscriptions/${ id }/_set-discount`).then(()=>{
            dispatch(setDiscountSuccess())
            dispatch(getCurrentUser());
            notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.APPLIED_DISCOUNT_SUCCESS);
        }).catch((error) => {
            dispatch(setDiscountFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

export const unFreezeAccount = (id) => {
    return (dispatch) => {
        dispatch(unFreezeAccountRequest())
        axiosInstance.patch(`/subscriptions/${ id }/_unfreeze-account`).then(()=>{
            dispatch(unFreezeAccountSuccess())
            dispatch(getCurrentUser());
            notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.SUBSCRIPTION_UNFREEZE_SUCCESS);
        }).catch((error) => {
            dispatch(unFreezeAccountFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};