/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    Dropdown
} from 'react-bootstrap';
import webFinLogo from '../../images/header/jetpage-logo-black.png';
import webFinwhiteLogo from '../../images/header/jetpage-logo-white.svg';
import { isLoggedIn } from '../../utils/helpers'
import { logoutUser } from '../../middleware/auth';
import { useDispatch, useSelector } from 'react-redux'
import {
    ChevronRight,
    DashboardMenuIcon,
    BlogMenuIcon,
    EditSiteMenuIcon,
    MarketingMenuIcon,
    SupportMenuIcon,
    NotificationIcon,
    MobileDrawerArrowIcon,
    MobileDrawerCloseArrowIcon,
} from '../../utils/svg';
import { ROUTES } from 'constants/appRoutes';
import profilePic from 'images/user-avatar.webp';
import history from 'utils/history';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS, WHY_JETPAGE_LINK } from 'constants/app'
const Navbar = (props) => {
    const dispatch = useDispatch();
    const mixpanel = useContext(MixpanelContext)
    const isIpad = window.outerWidth <= 1024;
    const [ isSideBarActive, toggleSideBar ] = React.useState(true);
    const [ mobileHumberger, setMobileHumberger ] = useState(false)
    const data = useSelector(state => state.user.sessionData?.data?.data)
    const user = data?.user
    const [ navBarActiveClass, setNavBarActiveClass ] = useState('')
    const btAction = (pathname) => {
        if (pathname === '/register') {
            return 'logo-right no-login';
        } else if (pathname === '/confirm-account') {
            return 'logo-right no-login';
        } else if (pathname === '/login') {
            return 'no-login';
        } else {
            return '';
        }

    }
    const logout = () => {
        dispatch(logoutUser())
    }
    const { pathname, mobilieHamberger, toggleMobileSideBar } = props
    const LinkView = () => {
        if (isLoggedIn()) {
            return (
                <>
                </>
            )
        } else if (pathname !== '/forget-password' && pathname !== '/confirm-account') {
            return (
                <nav
                    className="navbar is-transparent"
                    role="navigation"
                    aria-label="main-navigation"
                >
                    <div
                        id="navMenu"
                        className={ `navbar-menu ${ navBarActiveClass }` }
                    >
                        <div className="">
                            <ul className="main-navigation">
                                <li style={ { alignItems: 'center',justifyContent: 'center' } }>
                                    <a className='arrowdown' onClick={ () => {
                                        mixpanel.track(EVENTS.clickOnBlogs, {})
                                        window.open(WHY_JETPAGE_LINK, '_blank')
                                    } }>
                                        Why JetPage?
                                    </a>
                                    <div className='home-nav-dropdown' >
                                        <a href="https://jetpage.co/blog/best-website-builder" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>Why JetPage?</a>
                                        <a href="https://jetpage.co/blog/best-website-builder-for-blogging" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>For Bloggers</a>
                                        <a href="https://jetpage.co/blog/best-website-builder-for-affiliate-marketing" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>for Affiliate Marketing</a>
                                        <a href="https://jetpage.co/blog/best-website-builder-for-seo" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>For SEO</a>
                                        <a href="/page/comparison" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>Comparisons</a>
                                        <a href="https://jetpage.co/blog/jetpage-vs-wordpress" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>vs WordPress</a>
                                        <a href="https://jetpage.co/blog/jetpage-vs-wix" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>Vs Wix</a>
                                        <a href="https://jetpage.co/blog/jetpage-vs-webflow" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>vs Webflow</a>
                                        <a href="https://jetpage.co/blog/jetpage-vs-squarespace" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>vs Squarespace</a>
                                        <a href="https://jetpage.co/blog/jetpage-vs-ghost" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>Vs Ghost</a>
                                        <a href="https://jetpage.co/blog/case-study-affiliate-marketing-website-1" target="_blank" rel="noreferrer" className='home-nav-dropdown-item'>Successful Case Study</a>
                                    </div>
                                </li>
                                <li>
                                    <Link to="#" onClick={ () => {
                                        mixpanel.track(EVENTS.clickOnReviews, {})
                                        history.push(ROUTES.REVIEWS)
                                    } }>
                                        Reviews
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={ () => {
                                        mixpanel.track(EVENTS.clickOnPricing, {})
                                        history.push(ROUTES.PRICING)
                                    } } className=''>
                                        Pricing
                                    </Link>
                                </li>
                                <li>
                                    <a onClick={ () => {
                                        mixpanel.track(EVENTS.clickOnBlogs, {})
                                        window.open(ROUTES.BLOG_MARKETING, '_blank')
                                    } } className=''>
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <Link to="#" onClick={ () => {
                                        mixpanel.track(EVENTS.clickOnLogin, {})
                                        history.push(ROUTES.LOGIN)
                                    } } className='btn btn-secondary btn-secondary-2'>
                                        login
                                    </Link>
                                </li>
                                <li>
                                    <Link style={ { whiteSpace:'nowrap' } } to="#" onClick={ () => {
                                        mixpanel.track(EVENTS.visitTryforFree, {})
                                        history.push(ROUTES.ASSESSMENT)
                                    } } className='btn btn-primary btn-primary-2'>
                                        Try for free
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav >

            )
        }
    }

    const handleMobileHumberger = () => {
        if (!localStorage.user) {
            setMobileHumberger(!mobileHumberger)
        } else {
            mobilieHamberger()
        }
    }
    return (
        // <header className={ `main-header ${ btAction(pathname) } ${ !isIpad  ? 'toggle-sidebar' : toggleMobileSideBar && 'toggle-sidebar'  }` }>
        <header style={ toggleMobileSideBar?{ marginBottom:'-4px' }:{} } className={ `main-header ${ isLoggedIn() ? 'admin-header' : 'landing-header' } ${ btAction(pathname) } ${ !isIpad ? 'toggle-sidebar' : toggleMobileSideBar && 'toggle-sidebar' }` }>
            <Container fluid>
                <Row className="header-top myAccount">
                    <Col sm={ 4 } className="header-logo">
                        <div className="navbar-brand">
                            <Link to={ '/' } className="navbar-item" title="Logo">
                                {/* <img src={ webFinwhiteLogo } alt="WebFin" className="dark-logo ml-5" /> */}
                                <img src={ webFinLogo } alt="WebFin" className="light-logo" />
                            </Link>
                        </div>
                    </Col>
                    <Col sm={ 8 } className="header-menu">
                        <div className={ `mobile-humberger ${ mobileHumberger ? 'change' : '' }` } onClick={ handleMobileHumberger }>
                            {/* <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div> */}
                            <span className="close-icon">
                                <MobileDrawerArrowIcon />
                            </span>
                            <span className="open-icon">
                                <MobileDrawerCloseArrowIcon />
                            </span>
                        </div>
                        {LinkView()}
                    </Col>
                </Row>
            </Container>
        </header>
    )
}
Navbar.prototype = {
    pathname: PropTypes.string,
}
export default (Navbar)