import React from 'react';
import { Spinner } from 'react-bootstrap';
import GenerateIcon from '../../images/Vector.png';
import PropTypes from 'prop-types';

const GenerateButton = (props) => {
    const { text = '', loading = false , onClick = () => {}, disabled = false } = props;
    return (
        <div className="generate-btn-wrapper">
            <div className={ disabled || loading ? 'not-allowed generate-btn' :'generate-btn' } onClick={ disabled || loading ? () => {} :onClick }>
                {loading ? (
                    <Spinner animation="border" size={ 'sm' } role="status" />
                ) : (
                    <div style={ { display: 'flex', gap: 10 } }>
                        <img width={ 18 } height={ 18 } src={ GenerateIcon } />
                        <span>{text}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GenerateButton;

GenerateButton.propTypes = {
    text: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}