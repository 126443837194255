import React, { useState, useEffect } from 'react';
import PropTypes  from 'prop-types'
// import { mixpanelCommonData } from 'utils/helpers'
import { useSelector } from 'react-redux'
// import { Field, reduxForm } from 'redux-form';
// import { retargetValidate as validate } from 'utils/validates';

import Integrations from './integrations';
const Email = (props)=> {
    const [ currentACStep, setCurrentACStep ] = useState(0);
    const { site } = props
    const siteData = useSelector((state) => state.assessment.site);

    useEffect(() => {
        if (siteData?.activeCampaignUrl) {
            setCurrentACStep(1);
        }
    }, [ siteData ]);

    return (
        <section className="dashboard-body">
            <div className="dashboard-header">
                <div className="dashboard-title edit-site-title">
                    <h1>Email Marketing</h1>
                </div>
            </div>
            <div>
                <Integrations
                    site={ site }
                    currentACStep={ currentACStep }
                    setCurrentACStep={ setCurrentACStep }
                />
            </div>
        </section>
    )
}

Email.propTypes = {
    handleSubmit: PropTypes.func,
    site: PropTypes.object,
    initialize: PropTypes.func
}

export default Email;