import React, { useEffect } from 'react'
//import Blogs from 'components/marketing/blogs'
import { useDispatch } from 'react-redux';
import { getStats } from 'middleware/auth'
import { getSite } from 'utils/helpers'
//import Analytics from 'components/dashboard/analytics'
import Retarget from 'components/marketing/retarget'
import EmailMarketing from 'components/marketing/email';
import { getSiteById, getACAccountList } from 'middleware/assessments'
const Marketing = (props) => {
    console.log(props)

    const site  = getSite()
    const dispatch = useDispatch()
    // const user = getUser();
    // const statsLoading = useSelector(state => state.user.statsLoading)
    // const statsData = useSelector(state => state.user.statsData)

    useEffect(() => {
        dispatch(getStats(getSite()?.id))
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/marketing'
        })
        dispatch(getSiteById(getSite()?.id))
        dispatch(getACAccountList({ data: { siteId: getSite()?.id } }))
    },[])

    return(
        <main className="dashboard-data blog-dashboard">
            <div className='row'>
                <section className="dashboard-body">
                    {/*<div className="dashboard-header">
                        <div className="dashboard-title">
                            <h1>Marketing</h1>
                        </div>
                    </div>
                    <Blogs site={ site }  user={ user } />*/}
                </section>
                <div className='col-md-4'>
                    {/*<div className='data-box'>
                        <Analytics site={ site } statsLoading={ statsLoading } statsData={ statsData } />
                    </div>*/}
                </div>
            </div>
            < Retarget site={ site } />
            <EmailMarketing site={ site } />
        </main>
    )
}

export default Marketing