import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { CloseIcon } from 'utils/svg';

const CancelModal = (props) => {
    const {
        handleCancelAccount,
        // handleFreezeAccount,
        // handleSetDiscountAccount,
        // discountTaken,
        // isAnnualPlan,
        onClose } = props;

    // let buttonLabel = 'Confirm';
    // if (isAnnualPlan) {
    //     buttonLabel = 'Not Applicable';
    // } else if(discountTaken) {
    //     buttonLabel = 'Applied';
    // }

    return(
        <div className="">
            <div className="confirm-modal-header justify-content-between d-flex">
                <h4 className="profile-subhead">Are you sure you want to cancel?</h4>
                <a className="confirm-modal-close" onClick={ onClose }>
                    <CloseIcon />
                </a>
            </div>
            <div className="profile-active-plan inline-container">
                {/* <div className="subscribed-plan-info">
                    <h6>Freeze Account</h6>
                    <p>Freeze Account for up to 3 months with NO charge</p>
                    <Button onClick={ handleFreezeAccount }>
                        Confirm
                    </Button>
                </div>
                <div className={ `subscribed-plan-info ${ (isAnnualPlan || discountTaken) ? 'disabledModalState' : '' }` }>
                    <h6>20% Discount <span className="subscription-subheading">over next 6 months</span></h6>
                    <p>We&apos;d love for you to stay, so here&apos;s a 20% discount for the next 6 months - just click Confirm!</p>
                    <Button onClick={ handleSetDiscountAccount } >
                        {buttonLabel}
                    </Button>
                </div> */}
                <div className="subscribed-plan-info">
                    <h6>Cancel Account</h6>
                    <p>WARNING: By clicking Confirm, I understand that all my websites data will be immediately deleted and domain disconnected. This is irreversible and can NEVER be undone.</p>
                    <Button className={ 'cancleBtn' } onClick={ handleCancelAccount }>
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    )
}

CancelModal.propTypes = {
    handleCancelAccount: PropTypes.func,
    handleFreezeAccount: PropTypes.func,
    handleSetDiscountAccount: PropTypes.func,
    onClose: PropTypes.func,
    discountTaken: PropTypes.bool,
    isAnnualPlan: PropTypes.bool
};

export default CancelModal