import React from 'react';
import {
    Editor,
    EditorTools,
    EditorUtils,
    ProseMirror,
} from '@progress/kendo-react-editor';
import PropTypes from 'prop-types';

const {
    Bold,
    Italic,
    Underline,
    // Strikethrough,
    AlignLeft,
    AlignCenter,
    // AlignRight,
    // AlignJustify,
    FontName,
} = EditorTools;
// import { CustomFontSize } from 'components/core/editor/custom-font-size';
import { CustomFontSize } from './theme-font-size';
import { InsertButtonTool } from 'components/core/editor/insert-button';
// import { CustomFontFormat } from 'components/core/editor/custom-font-format';
import { button, shortCode } from 'components/core/editor/new-node';
// import htmlToFormattedText from 'html-to-formatted-text';

const { Schema, EditorView, EditorState } = ProseMirror;

export const ContentEditor = (props) => {
    const { htmlData = '', setHtmlData = () => {} } = props;

    const onMount = (event) => {
        const { viewProps } = event;
        const { plugins, schema } = viewProps.state;

        // Adding the 'dir' attribute to paragraph node.
        const paragraph = {
            ...schema.spec.nodes.get('paragraph'),
        };
        paragraph.attrs = paragraph.attrs || {};
        // paragraph.attrs[ 'style' ] = {
        //     default: 'font-size: 1pt',
        // };
        paragraph.attrs[ 'dir' ] = {
            default: null,
        };
        let nodes = schema.spec.nodes.update('paragraph', paragraph);

        // Appending the new node.
        nodes = nodes.addToEnd('button', button);
        nodes = nodes.addToEnd('shortCode', shortCode);

        //creating marks
        const marks = schema.spec.marks;

        const mySchema = new Schema({
            nodes,
            marks,
        });

        const doc = EditorUtils.createDocument(mySchema, htmlData);

        return new EditorView(
            {
                mount: event.dom,
            },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: [ ...plugins ],
                }),
            }
        );
    };

    return (

        <Editor
            tools={ [
                [ Bold, Italic, Underline, AlignLeft, AlignCenter ],
                [ InsertButtonTool ],
                [ FontName ],
                CustomFontSize
                // [ ThemeFontSize ]
            ] }
            defaultEditMode="div"
            onChange={ (editor) => {
                setHtmlData(editor.html);
            } }
            contentStyle={ {
                height: 150,
            } }
            onMount={ onMount }
            value={ htmlData || '' }
            defaultContent={ htmlData || '' }
        />
    );
};
ContentEditor.propTypes = {
    htmlData: PropTypes.string,
    setHtmlData: PropTypes.func
}