import React from 'react';
import facebook from '../../../images/facebook-theme.svg';
import twitter from '../../../images/twitter-theme.svg';
import linkedIn from '../../../images/linkedin-theme.svg';
import instagram from '../../../images/instagram-theme.svg';
import userIcon from '../../../images/user-avatar.png';
import PropTypes from 'prop-types';
import blog1 from '../../../images/theme-builder/stylish-upper-blog.svg';
import blog2 from '../../../images/theme-builder/blog2.svg';
import blog3 from '../../../images/theme-builder/blog3.svg';
import blog4 from '../../../images/theme-builder/blog4.svg';
import blog5 from '../../../images/theme-builder/blog5.svg';
import blog6 from '../../../images/theme-builder/blog6.svg';

const StylishView = ({ data }) => {
    const {
        headerTextColor,
        buttonTextColor,
        headerBgColor,
        buttonBgColor,
        hideSubscribeButton,
        isHeaderTransparent,
    } = data;
    return (
        <div className="bg-light">
            <div
                style={ {
                    background: isHeaderTransparent ? 'transparent' : headerBgColor,
                } }
                className="theme-header d-flex justify-content-between"
            >
                <div className="header-left d-flex align-items-center">
                    <img
                        className="header-profile"
                        src={ userIcon }
                        width={ '25px' }
                        height={ '25px' }
                    />
                    <div
                        style={ { color: headerTextColor } }
                        className="header-text-classic"
                    >
                        Logo
                    </div>
                </div>
                <div className="header-center d-flex align-items-center">
                    <div
                        style={ { color: headerTextColor } }
                        className="header-text-classic"
                    >
                        Home
                    </div>
                    <div
                        style={ { color: headerTextColor } }
                        className="header-text-classic"
                    >
                        Blogs
                    </div>
                    <div
                        style={ { color: headerTextColor } }
                        className="header-text-classic"
                    >
                        About Us
                    </div>
                    <div
                        style={ { color: headerTextColor } }
                        className="header-text-classic"
                    >
                        Contact
                    </div>
                </div>
                <div className="header-right d-flex align-items-center">
                    <img src={ facebook } width={ '15px' } height={ '15px' } />
                    <img src={ twitter } width={ '15px' } height={ '15px' } />
                    <img src={ linkedIn } width={ '15px' } height={ '15px' } />
                    <img src={ instagram } width={ '15px' } height={ '15px' } />
                    {!hideSubscribeButton ? (
                        <button
                            style={ { color: buttonTextColor, background: buttonBgColor } }
                            className="subscribe-btn "
                        >
                            Subscribe
                        </button>
                    ) : null}
                </div>
            </div>
            <div className="m-3">
                <div className=" d-flex justify-content-center align-items-center stylish-upper-container">
                    <div className="stylish-upper-container-bg d-flex justify-content-center align-items-center">
                        <img src={ blog1 } width={ '50%' } height={ '50%' } />
                    </div>
                    <div style={ { overflowWrap: 'anywhere' } } className="width-45">
                        <span style={ { color: '#000000' } } className="status">
                            Related
                        </span>{' '}
                        <br />
                        {/* {headerData? <div dangerouslySetInnerHTML={ { __html: headerData } } /> :<h4 className='mt-2 ' >
                            Blog Post Title
                        </h4>} */}
                        <h4 style={ { color: '#000000', marginTop: '10px' } }>
                            Latest Blog Post Title
                        </h4>
                        {/* <span className='grey-text author'>by <span >Emil Kristensin</span></span> <br/> */}
                        <span className="grey-text">Jan 1, 2024</span> <br />
                    </div>
                </div>

                <div className=" justify-content-between mt-4 modern-view-cards-container">
                    <div className="card-wrapper">
                        <div className="card-bg d-flex justify-content-center align-items-center">
                            <img src={ blog2 } width={ '50%' } height={ '50%' } />
                        </div>
                        <span className="card-title">Blog Post Title</span> <br />
                        <span className="date">Jan 1, 2024</span> <br />
                        <span className="status">Related</span> <br />
                    </div>
                    <div className="card-wrapper">
                        <div className="card-bg d-flex justify-content-center align-items-center">
                            <img src={ blog3 } width={ '50%' } height={ '50%' } />
                        </div>
                        <span className="card-title">Blog Post Title</span> <br />
                        <span className="date">Jan 1, 2024</span> <br />
                        <span className="status">Related</span> <br />
                    </div>
                    <div className="card-wrapper">
                        <div className="card-bg d-flex justify-content-center align-items-center">
                            <img src={ blog4 } width={ '50%' } height={ '50%' } />
                        </div>
                        <span className="card-title">Blog Post Title</span> <br />
                        <span className="date">Jan 1, 2024</span> <br />
                        <span className="status">Related</span> <br />
                    </div>
                </div>

                <div className="  justify-content-between py-4 stylish-lower-container">
                    <div>
                        <div className="stylish-lower-container-bg d-flex justify-content-center align-items-center">
                            <img src={ blog5 } width={ '50%' } height={ '50%' } />
                        </div>
                        <span className="card-title">Blog Post Title</span> <br />
                        <span className="date">Jan 1, 2024</span> <br />
                        <span className="status">Related</span> <br />
                    </div>
                    <div>
                        <div className="stylish-lower-container-bg d-flex justify-content-center align-items-center">
                            <img src={ blog6 } width={ '50%' } height={ '50%' } />
                        </div>
                        <span className="card-title">Blog Post Title</span> <br />
                        <span className="date">Jan 1, 2024</span> <br />
                        <span className="status">Related</span> <br />
                    </div>
                </div>
            </div>
        </div>
    );
};
StylishView.propTypes = {
    data: PropTypes.object,
    headlineColor: PropTypes.string,
    headerData: PropTypes.string,
    headerTextColor: PropTypes.string,
    headerBgColor: PropTypes.string,
    buttonBgColor: PropTypes.string,
    hideSubscribeButton: PropTypes.boolean,
    isHeaderTransparent: PropTypes.boolean,
    buttonTextColor: PropTypes.string,
};

export default StylishView;
