import React, { Suspense } from 'react';
import mixpanel from 'mixpanel-browser';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { getUser } from 'utils/helpers'
import App from './App';
import { MixpanelContext } from './utils/tracking';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';
import browserHistory from './utils/history';
import Tap from '@tapfiliate/tapfiliate-js';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
// import NotificationService from './service/notification-service';

const store = configureStore();

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
Tap.init(process.env.REACT_APP_TAPFILIATE, { integration: 'chargebee' });
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [ new Integrations.BrowserTracing() ],
    environment: process.env.REACT_APP_NODE_ENV,
    tracesSampleRate: 1.0,
});
Sentry.setContext('extra', {
    email: getUser()?.email,
    env: process.env.REACT_APP_NODE_ENV
});
ReactDOM.render(
    <Provider store={ store }>
        <MixpanelContext.Provider value={ mixpanel }>
            <ToastContainer
                position="top-right"
                autoClose={ 5000 }
                hideProgressBar={ false }
                newestOnTop={ false }
                closeOnClick
                rtl={ false }
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Router history={ browserHistory }>
                <Suspense fallback={ '' }>
                    <App />
                </Suspense>
            </Router>
        </MixpanelContext.Provider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
