import React from 'react';
import InputSelect from 'react-select-input';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import {
    SortIcon,
    //PlusIcon
} from 'utils/svg';
import { createPageUrl } from 'utils/helpers';
import homeIcon from '../../../images/homeIcon.svg';

const Header = (props) => {
    const {
        urlError,
        addMenuLinks,
        setLinkType,
        linkType,
        setLoadingType,
        removeMenuLink,
        setMenuLinks,
        handleChangeMenuUrl,
        handleChangeMenuName,
        handleNewTab,
        loadingtype,
        menuLinks,
        setLoadData,
    } = props;

    const setLinkTypeFun = (index, event) => {
        event?.preventDefault();
        if (event?.target?.id !== 'remove') {
            linkType !== index && setLoadingType(true);
            setLinkType(index);
        }
    };
    const removeLinkTypeFun = (index, event) => {
        event.preventDefault();
        setLoadingType(true);
        linkType === index && setLinkType(linkType - 1);
        removeMenuLink(index);
        setLoadingType(false);
    };

    const handleAddSubMenu = (event, index) => {
        event.preventDefault();
        setLoadData(true);
        const menuLinksClone = [ ...menuLinks ];
        const obj = Object.assign({}, menuLinksClone[ index ]);
        const arr = obj[ 'subMenuLinks' ] || [];
        arr.push({ name: '', url: '' });
        obj[ 'subMenuLinks' ] = arr;
        menuLinksClone[ index ] = obj;
        setMenuLinks(menuLinksClone);
        setTimeout(() => {
            setLoadData(false);
        });
    };

    const handleRemoveSubmenu = (event, index, subMenuIndex) => {
        event.preventDefault();
        setLoadData(true);
        const menuLinksClone = Object.assign([], menuLinks);
        const obj = menuLinksClone[ index ];
        const arr = obj[ 'subMenuLinks' ] || [];
        arr.splice(subMenuIndex, 1);
        obj[ 'subMenuLinks' ] = arr;
        setMenuLinks(menuLinksClone);
        setTimeout(() => {
            setLoadData(false);
        });
    };

    const handleSetSubmenu = (event, index, index1) => {
        event?.preventDefault();
        if (event?.target?.id !== 'remove') {
            linkType !== `${ index }.${ index1 }` && setLoadingType(true);
            setLinkType(`${ index }.${ index1 }`);
        }
    };
    const getIndexSubmenu = (index, index1) => `${ index }.${ index1 }`;
    const onSortSubMenuEnd = ({ oldIndex, newIndex }) => {
        const menuIndex = oldIndex.split('')[ 0 ];
        const subMenuOldIndex = oldIndex.split('')[ 1 ];
        const subMenuNewIndex = newIndex.split('')[ 1 ];
        const menuLinksCopy = [ ...menuLinks ];
        const menuSubLinks = menuLinksCopy[ menuIndex ][ 'subMenuLinks' ];
        let menuSubLinksCopy = [ ...menuSubLinks ];
        menuSubLinksCopy = arrayMove(
            menuSubLinksCopy,
            subMenuOldIndex,
            subMenuNewIndex
        );
        menuLinksCopy[ menuIndex ][ 'subMenuLinks' ] = menuSubLinksCopy;
        setMenuLinks(menuLinksCopy);
    };
    const SortableSubMenuContainer = sortableContainer(({ children }) => {
        return <ol>{children}</ol>;
    });

    // const setAsHome = (index, event) => {
    //     event?.preventDefault();
    //     const _tempLinks = [ ...menuLinks ];

    //     //removing home route from current home
    //     const currentHomeIndex = _tempLinks.findIndex((item) => item.url === '/');
    //     if (currentHomeIndex > -1) {
    //         const _currentHomelink = _tempLinks[ currentHomeIndex ];
    //         _currentHomelink[ 'url' ] =
    //     _currentHomelink[ 'name' ] === 'home'
    //         ? 'blog-home'
    //         : _currentHomelink[ 'slug' ];
    //         _currentHomelink[ 'slug' ] = '';
    //         _currentHomelink[ 'isHome' ] = false;
    //         _tempLinks[ currentHomeIndex ] = _currentHomelink;
    //     }
    //     //setting home route
    //     const _templink = _tempLinks[ index ];
    //     _templink[ 'slug' ] = _templink[ 'url' ];
    //     _templink[ 'url' ] = '/';
    //     _templink[ 'isHome' ] = true;
    //     _tempLinks[ index ] = _templink;
    //     setMenuLinks([ ..._tempLinks ]);
    // };

    const handleHideMenuLink = (event, index) => {
        event.preventDefault();
        setLoadData(true);
        const menuLinksClone = [ ...menuLinks ];
        const obj = Object.assign({}, menuLinksClone[ index ]);
        obj[ 'isShow' ] = false;
        menuLinksClone[ index ] = obj;
        setMenuLinks(menuLinksClone);
        setTimeout(() => {
            setLoadData(false);
        });
    };

    const handleUnhideMenuLink = (event, index) => {
        event.preventDefault();
        setLoadData(true);
        const menuLinksClone = [ ...menuLinks ];
        const obj = Object.assign({}, menuLinksClone[ index ]);
        obj[ 'isShow' ] = true;
        menuLinksClone[ index ] = obj;
        setMenuLinks(menuLinksClone);
        setTimeout(() => {
            setLoadData(false);
        });
    };

    const DragHandle = sortableHandle(({ item }) => {
        return (
            <>
                <div className="menu-name">
                    <span className="menu-drag">
                        <SortIcon />
                        <SortIcon />
                    </span>
                    {(item?.isHome || item?.url === '/') && (
                        <img alt="" src={ homeIcon } width={ 15 } height={ 15 } />
                    )}
                    <span>{item?.name ? item?.name?.uppercase() : '...'}</span>
                </div>
            </>
        );
    });
    const DragSubMenuHandle = sortableHandle(({ item }) => {
        return (
            <>
                <div className="menu-name">
                    <span className="menu-drag">
                        <SortIcon />
                        <SortIcon />
                    </span>
                    <span>{item?.name ? item?.name?.uppercase() : '...'}</span>
                </div>
            </>
        );
    });
    const renderItem = (item, index) => {
        return (
            <li
                tabIndex={ 0 }
                key={ index }
                style={ { zIndex: 100000 } }
                className={ `${ linkType === index ? 'active' : '' }` }
            >
                <div className="menu-order">{index + 1}.</div>
                <div
                    className={
                        item?.isDefaultHome || item?.isDefaultContact ? item?.isShow ? 'menu-detail' : 'menu-detail menu-detail-disabled' : 'menu-detail'
                    }
                    onClick={ (event) => setLinkTypeFun(index, event) }
                >
                    <div className="menu-detail-inner">
                        <DragHandle item={ item } index={ index } />

                        <div className="">
              &nbsp;
                            <a className="menu-action" href="#">
                                edit
                            </a>
              &nbsp;
                            {/* {item?.name !== 'home' && item?.url !== 'contact' && ( */}
                            {item?.isDefaultHome || item?.isDefaultContact ? (
                                item?.isShow ? (
                                    <a
                                        className="menu-action"
                                        href="#"
                                        onClick={ (event) => handleHideMenuLink(event, index) }
                                    >
                                        Hide
                                    </a>
                                ) : (
                                    <a
                                        className="menu-action"
                                        href="#"
                                        onClick={ (event) => handleUnhideMenuLink(event, index) }
                                    >
                                        Unhide
                                    </a>
                                )
                            ) : !item?.isHome ? (
                                <a
                                    className="menu-action"
                                    id="remove"
                                    href="#"
                                    onClick={ (event) => removeLinkTypeFun(index, event) }
                                >
                                    remove
                                </a>
                            ) : null}
                        </div>
                    </div>
                </div>
                {Math.trunc(linkType) === index && item?.isShow && (
                    <div className={ `submenu-list submenu-list-${ index }` }>
                        <SortableSubMenuContainer
                            // updateBeforeSortStart={ (d,event) => {
                            //     handleSetSubmenu(event, index, d.index);
                            // } }
                            helperClass="sortableHelper-submenu"
                            lockAxis="y"
                            style={ { zIndex: 900000 } }
                            onSortEnd={ onSortSubMenuEnd }
                            useDragHandle
                            distance={ 1 }
                        >
                            <li>
                                <div
                                    className="submenu-detail add-sub-menu"
                                    style={ { zIndex: 100000 } }
                                >
                                    <div className="menu-detail-inner">
                                        <div className="menu-name">
                                            <span onClick={ (event) => handleAddSubMenu(event, index) }>
                                                <button className="add-submenu-btn" type="button">
                                                    +
                                                </button>
                                                Add sub menu item
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {(menuLinks[ index ]?.subMenuLinks || []).map((subMenu, index1) => {
                                return (
                                    <SortableSubMenuItem
                                        key={ `item-${ index }-${ index1 }` }
                                        indexData={ index }
                                        index={ `${ index.toString() }${ index1.toString() }` }
                                        index1={ index1 }
                                        item={ subMenu }
                                    />
                                );
                            })}
                        </SortableSubMenuContainer>
                    </div>
                )}
            </li>
        );
    };

    const renderSubMenuItem = (item, index, index1) => {
        return (
            <li
                tabIndex={ 0 }
                onClick={ (event) => handleSetSubmenu(event, index, index1) }
                style={ { zIndex: 900000 } }
                className={ getIndexSubmenu(index, index1) === linkType? 'active' : '' }
                key={ index1 }
            >
                <div className="submenu-detail" >
                    <div className="menu-detail-inner">
                        <DragSubMenuHandle item={ item } />
                        <div>
                            <a
                                className="menu-action"
                                href="#"
                                onClick={ (event) => handleSetSubmenu(event, index, index1) }
                            >
                                edit
                            </a>
              &nbsp;
                            <a
                                className="menu-action"
                                href="#"
                                onClick={ (event) => handleRemoveSubmenu(event, index, index1) }
                            >
                                remove
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        );
    };
    const SortableItem = sortableElement(({ item, indexData }) =>
        renderItem(item, indexData)
    );

    const SortableSubMenuItem = sortableElement(({ item, indexData, index1 }) =>
        renderSubMenuItem(item, indexData, index1)
    );

    const SortableContainer = sortableContainer(({ children }) => {
        return <ol>{children}</ol>;
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let menuLinksCopy = [ ...menuLinks ];
        menuLinksCopy = arrayMove(menuLinksCopy, oldIndex, newIndex);
        setMenuLinks(menuLinksCopy);
    };

    const addLinks = () => {
        addMenuLinks();
        setLinkTypeFun(menuLinks.length);
    };

    const getSubmenu = () => {
        const linkT = linkType?.toString();
        const indx = linkT?.split('.')[ 0 ];
        const indx1 = linkT?.split('.')[ 1 ];
        return (
            (menuLinks[ indx ] &&
        menuLinks[ indx ][ 'subMenuLinks' ] &&
        menuLinks[ indx ][ 'subMenuLinks' ][ indx1 ]) ||
      {}
        );
    };
    const getData = () => {
        if (linkType?.toString()?.includes('.')) {
            return getSubmenu();
        } else {
            return menuLinks[ linkType ];
        }
    };

    const allPages = useSelector((state) => state.page.allPages);

    return (
        <div className="edit-menu-listing">
            <div className="menu-listing">
                <SortableContainer
                    lockAxis="y"
                    helperClass="sortableHelper"
                    style={ { zIndex: 100000 } }
                    onSortEnd={ onSortEnd }
                    useDragHandle
                    distance={ 1 }
                >
                    {menuLinks.map((item, index) => (
                        <SortableItem
                            key={ `item-${ index }` }
                            indexData={ index }
                            index={ Number(index) }
                            item={ item }
                            on
                        />
                    ))}
                </SortableContainer>

                {menuLinks.length < 7 && (
                    <button className="add-icon-btn" type="button" onClick={ addLinks }>
                        +
                    </button>
                )}
            </div>

            <div className="add-menu">
                {!loadingtype && (
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <input
                                type="text"
                                name="name"
                                // disabled={ getData()?.name === 'home' }
                                onChange={ (event) =>
                                    handleChangeMenuName(event, linkType, getData()?.url)
                                }
                                className="form-control ris-name"
                                defaultValue={ getData()?.name }
                                value={ getData()?.name || getSubmenu()?.name }
                            />
                        </Form.Group>
                        <Form.Group className="url-control">
                            <Form.Label>Link</Form.Label>
                            <InputSelect
                                className={ `input-select-menu-link ${
                                    getData()?.isHome ||
                  getData()?.isDefaultHome ||
                  getData()?.isDefaultContact
                                        ? 'input-disabled'
                                        : ''
                                }` }
                                clearable={ false }
                                onSelect={ (event) =>
                                    handleChangeMenuUrl(event, linkType, 'click')
                                }
                                onChange={ (event) =>
                                    handleChangeMenuUrl(event, linkType, 'change')
                                }
                                options={
                                    allPages
                                        .map((item) => ({
                                            label: item.slug,
                                            value: item.slug,
                                        }))
                                        ?.filter((page) => page.label) || []
                                }
                                autoFocus={ false }
                                disableEnter={
                                    // getData()?.name === 'home' || getData()?.url === 'contact'
                                    getData()?.isHome ||
                  getData()?.isDefaultHome ||
                  getData()?.isDefaultContact
                                }
                                value={ getData()?.url || getSubmenu()?.url }
                            />
                            {urlError[ linkType ] && (
                                <span className="field_error">
                                    Please insert vaild url or ( use http | https | ftp )
                                </span>
                            )}
                        </Form.Group>
                        {getData()?.name !== 'home' && (
                            <Form.Group className="url-control">
                                <Form.Label>
                                    <div className="newtab_input">
                                        <input
                                            type="checkbox"
                                            checked={ getData()?.newTab || getSubmenu()?.newTab }
                                            onChange={ (event) => handleNewTab(event, linkType) }
                                            name="fsad"
                                        />
                                        <span className="newtab_custom_input"></span>
                                    </div>
                                    Open link in a new tab
                                </Form.Label>
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Form.Label>Full URL</Form.Label>
                            <input
                                type="text"
                                name="fullUrl"
                                disabled={ true }
                                onChange={ () => {} }
                                className="form-control url-link"
                                defaultValue={ getData()?.fullUrl }
                                value={
                                    getData()?.fullUrl ||
                  getSubmenu()?.fullUrl ||
                  createPageUrl(getData()?.url || getSubmenu()?.url)
                                }
                            />
                        </Form.Group>
                    </Form>
                )}
            </div>
        </div>
    );
};

Header.propTypes = {
    setLinkType: PropTypes.func,
    linkType: PropTypes.object,
    handleRecentMenuLinks: PropTypes.func,
    handleNewTab: PropTypes.func,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    menuLinks: PropTypes.array,
    addMenuLinks: PropTypes.func,
    loadData: PropTypes.bool,
    assessmentData: PropTypes.object,
    handleChangeMenuName: PropTypes.func,
    handleChangeMenuUrl: PropTypes.func,
    loadText: PropTypes.bool,
    removeMenuLink: PropTypes.func,
    isValid: PropTypes.bool,
    loading: PropTypes.bool,
    setMenuLinks: PropTypes.func,
    setLoadingType: PropTypes.func,
    loadingtype: PropTypes.bool,
    setLoadData: PropTypes.func,
    urlError: PropTypes.object,
};

export default Header;
