import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import {
    Button,
    Accordion,
    Card,
    Form,
} from 'react-bootstrap'
import { OpenArrow } from 'utils/svg'
import PropTypes from 'prop-types';

const characterSet = [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z' ];
const getStringFromHtml = (htmlString) => {
    const str = htmlString.toString().replace(/(<([^>]+)>)/ig, '');
    return str.replace(/&nbsp;/g, '')
}
const TableOfContent = (props) => {
    const { htmlData, isTableContent, setTableContentData = () => { }, setTableContent = () => { } } = props;
    const [ tableOfContent, setTableOfContent ] = useState(null);

    const [ updateKey, setUpdatedKey ] = useState(null);

    const getTableContent = (htmlString) => {
        const tableOfContentArr = [];
        const matchedArr = htmlString.toString().match(/<h2((.|\n)*?)(?=<h2|$)/g);
        console.log(htmlString.toString())
        console.log('matchedArr',matchedArr)

        const newTableOfContent = matchedArr && matchedArr.map((tagData, i) => {
            const tableOfContentObj = {};
            const headingMachedArr = tagData.toString().match(/<h2(.*?)<\/h2>/g);
            const subheadingMachedArr = tagData.toString().match(/<h3(.*?)<\/h3>/g);
            tableOfContentObj[ 'name' ] = getStringFromHtml(headingMachedArr);
            const subheadingArray = [];
            subheadingMachedArr && subheadingMachedArr.map((secondaryHeading) => {
                subheadingArray.push(getStringFromHtml(secondaryHeading));
            })
            tableOfContentObj[ 'subHeadings' ] = subheadingArray;
            tableOfContentArr.push(tableOfContentObj)

            return <>
                <p className="topic" key={ `${ updateKey }-${ i }` }>{`${ i + 1 }. ${ getStringFromHtml(headingMachedArr) }`}</p>
                {
                    subheadingMachedArr && subheadingMachedArr.map((secondaryHeading, subIndex) => {
                        return <span className="sub-topic" key={ `${ updateKey }-${ subIndex }` }>{`${ characterSet[ subIndex ] }. ${ getStringFromHtml(secondaryHeading) }`}</span>
                    })
                }
            </>

        })
        setUpdatedKey(new Date())
        setTableOfContent(newTableOfContent)
        setTableContentData(tableOfContentArr)
    }

    const handleTableContent = () => {
        setTableContent(!isTableContent)
        getTableContent(htmlData.toString())
    }
    useEffect(() => {
        if (!isTableContent) {
            setTableContentData(null)
        }
    }, [ isTableContent ])

    useEffect(() => {
        if (htmlData && isTableContent) {
            getTableContent(htmlData.toString())
        }
    }, [ htmlData ])

    return (
        <div className="table-of-content-section">

            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header style={ { padding: 0 } }>
                        <Accordion.Toggle as={ Button } variant="link" eventKey="0" className='accordion-header'>
                            <Form className="header-form">
                                <Form.Check type="switch" checked={ isTableContent } onChange={ handleTableContent } id="custom-switch" label="" />
                                <p>Table of Contents</p>
                            </Form>
                            <OpenArrow width={ 10 } height={ 7 } />
                        </Accordion.Toggle>
                    </Card.Header>
                    {isTableContent && <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="content-wrapper">
                                <h6 className="wrapper-header">Table of Contents</h6>
                                <div className="wrapper-body">
                                    {tableOfContent}
                                </div>
                            </div>
                            <span className="content-string">Table of Contents is automatically generated from your Heading 1s (main items) and Headings 2s (sub-bullets).</span>
                            <span className="content-string">You can adjust these from the &apos;paragraph&apos; drop-down menu in the toolbar.</span>
                        </Card.Body>
                    </Accordion.Collapse>}
                </Card>
            </Accordion>

        </div>
    )
}

export default TableOfContent

TableOfContent.propTypes = {
    htmlData: PropTypes.string,
    isTableContent: PropTypes.bool,
    setTableContentData: PropTypes.func,
    setTableContent: PropTypes.func
};