/* eslint-disable react/prop-types */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ROUTES } from 'constants/appRoutes'
import _ from 'lodash'
import { isLoggedIn, getUser , getSite, isBlockedSite } from '../../utils/helpers'
// import MaintainancePage from 'pages/maintainance-page'

export const PrivateRouteWOS = ({ component: Component, ...rest }) => {
    const user = getUser();
    const site = getSite();
    const whiteListPathname = [
        ROUTES.BILLING,
        ROUTES.USER_PROFILE,
        ROUTES.PLAN_SELECTION
    ]
    const valid =  !_.isEmpty(site) || !isBlockedSite(user?.status)
    return (
        <Route
            { ...rest }
            render={ (props) => {
                return !isLoggedIn() ?
                    <Redirect
                        to={ {
                            pathname: '/login',
                            state: { from: props.location },
                        } }
                    /> : (!user.enabled ? (
                        <Redirect
                            to={ {
                                pathname: '/confirm-account',
                                state: { from: props.location },
                            } }
                        />
                    ) :  (
                        !user.test ?
                            <Redirect
                                to={ {
                                    pathname: '/assessment',
                                    state: { from: props.location },
                                } }
                            />
                            : (!valid && !whiteListPathname.includes(props.location.pathname)
                                ? <Redirect
                                    to={ {
                                        pathname: '/billing',
                                        state: { from: props.location },
                                    } }
                                /> :

                                <Component { ...props } />)))

            } }
        />
    )
}
