import React from 'react';
import { Container } from 'react-bootstrap';
// import {
//     //VideoPlayicon,
//     HowWorkImgVector1,
//     HowWorkImgVector2,
//     HowWorkImgVector3,
//     HowWorkImgVector4
// } from '../../../utils/svg';
// import Heading from '../../../components/core/heading';
import howItWorksColorScheme from './images/how_it_works_color_scheme.svg';
import howItWorksNiche from './images/how_it_works_niche.svg';
import howItWorksBlogPostAI from './images/how_it_works_blog_post_ai.svg';
import howItWorksPublishView from './images/how_it_works_publish_view.svg';
import './style.sass';
import PropTypes from 'prop-types';

const HowItWork = () => {
    // const { redirectAssessment } = props;
    return (
        <section className="publisher-how-work">
            <Container>
                <div className="publisher-how-work-inner">
                    <h2>How it Works</h2>
                    <h5>1. Easy, simple website setup in just seconds</h5>
                    <div className="how-work-left">
                        <div className="how-work-img">
                            <img className='how-it-works-color-scheme'  src={ howItWorksColorScheme }/>
                        </div>
                    </div>
                    <h5>2. Do Keyword Research and get SEO-friendly Blog Titles and Ideas personalized to your niche</h5>
                    <div className="how-work-left">
                        <div className="how-work-img">
                            <img className='how-it-works-img-size'  src={ howItWorksNiche }/>
                        </div>
                    </div>
                    <h5>3. Write Blog Post drafts in minutes with 1-click, powered by AI</h5>
                    <div className="how-work-left">
                        <div className="how-work-img">
                            <img className='blog-post-ai'  src={ howItWorksBlogPostAI }/>
                        </div>
                    </div>
                    <h5>4. Publish and ready to view on the internet!</h5>
                    <div className="how-work-left">
                        <div className="how-work-img">
                            <img className='publish-view'  src={ howItWorksPublishView }/>
                        </div>
                    </div>

                </div>
            </Container>
        </section>
    );
};
HowItWork.propTypes = {
    redirectAssessment: PropTypes.func,
};
export default HowItWork;
