import React from 'react';
// import CustomReview from 'components/homepage/short-review/custom-review'
import './reviews.sass';
import PropTypes from 'prop-types';

const Reviews = ({ renderTitle = true }) => {

    return (
        <div className="container">
            {renderTitle && <div className="reviews-heading">
                <h1>JetPage reviews and testimonials</h1>
            </div>}
            <div className={ 'reviews-wall-of-love' }>
                <iframe id="testimonialto-wizrd-org-reviews-testimonials-tag-all-light" src="https://embed-v2.testimonial.to/w/wizrd-org-reviews-testimonials?theme=light&card=large&loadMore=on&initialCount=100&tag=all" frameBorder="0" scrolling="no" width="100%" onLoad={ ()=>iFrameResize({ log: true, warningTimeout: 1000000, heightCalculationMethod: 'lowestElement' }, this) }></iframe>
            </div>
        </div>
    )
}
export default Reviews;

Reviews.propTypes = {
    renderTitle: PropTypes.bool
};