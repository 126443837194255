/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import {
    Modal,
    Form,
    Button
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { renderMultipleCheckbox } from 'utils/formUtils'
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import ButtonLoader from 'components/core/loader/button-loader';
import { removeAllSites } from 'middleware/assessments'
const PlanDowngradeModal = (props) => {
    const dispatch = useDispatch()
    const { open, hideModal,handleSubmit, plan, sites ,chargeBeePlan } = props
    const [ openSiteView,  setSiteView ] = useState(false)
    const formData = useSelector((state) => state.form.planDowngrade)
    const loading = useSelector((state) => state.assessment.loading)

    const eventCallback = () => {
        hideModal()
        chargeBeePlan(plan)
    }
    const submitData = () => {
        const sitesData = formData?.values?.sites || []
        const data = sites.filter((item) => !sitesData.includes(item.id))
        const ids = _.map(data, 'id')
        dispatch(removeAllSites(ids,eventCallback))
    }
    return(<Modal show={ open } onHide={ hideModal } className="downgrade-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <Form onSubmit={ handleSubmit( submitData) }>
                <div className=''>
                    <h5>
                        <span>You have exceed the site limit for the {plan?.name} plan ({sites?.length} out of {plan?.numberOfSites})</span>
                        <span>Upgrade to keep all your sites <b>OR</b> Choose which to keep</span>
                    </h5>
                    <div className="downgrade-btns">
                        <button type='button' className='btn btn-secondary' onClick={ () => setSiteView(true) } >Choose Sites</button>
                        { /*<button type='button' className='btn btn-primary' >Upgrade to Pro</button>*/}
                    </div>

                    <div className="downgrade-plans">
                        <h6>{plan?.name} Site limit: {plan?.numberOfSites} out of {sites?.length} allowed</h6>
                        <p><b>important:</b>The site you don't choose will be deleted <u>forever</u></p>
                    </div>
                    {openSiteView && <div className="downgrade-site-list">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Domain</th>
                                    <th>Keep</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Field
                                    name="sites"
                                    options={ sites || []  }
                                    component={ renderMultipleCheckbox }
                                    isSites={ true }
                                />
                            </tbody>
                        </table>
                        <div className="text-center">
                            <ButtonLoader
                                button={ <Button type='submit' variant="primary">Confirm</Button> }
                                loading={ loading  }
                                loadingText={ 'Please wait..' }
                                className={ 'removeHover' }
                            />
                        </div>
                    </div>}
                </div>
            </Form>
        </Modal.Body>

    </Modal>)
}
function validate(values,props){
    const errors = {}
    const dsiteLength = props?.sites?.length
    const rsiteLength = props?.sites?.filter((item) => !values?.sites?.includes(item.id))?.length || 0
    const vsiteLength = values?.sites?.length || 0
    const limit = props?.plan?.numberOfSites
    if(vsiteLength === 0){
        errors.sites = 'Please keep at least one site'
    }else if((dsiteLength - rsiteLength) > limit ){
        errors.sites = `Site limit is ${ limit } you can't keep ${ vsiteLength > 1 ? `${ vsiteLength } sites` : `${ vsiteLength } site` }`
    }
    return errors
}
PlanDowngradeModal.propTypes = {
    open: PropTypes.bool,
    plan: PropTypes.object,
    planSelectionHandler: PropTypes.func,
    hideModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    sites: PropTypes.array[ PropTypes.object ],
    chargeBeePlan: PropTypes.any,
};
export default reduxForm({
    form: 'planDowngrade',
    validate
})(PlanDowngradeModal);
