import React from 'react';
import { Button, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types';
const ButtonLoader = (props)=> {
    const { loading , type, variant, className, button, loadingText, visible  } = props
    if(loading){
        return (
            <Button className={ className } disabled={ true } type={ type || 'button' } variant={ variant || 'secondary' }>
                { loadingText }
                { visible && <Spinner animation="border" size={ 'sm' } role="status"><span className="sr-only">Loading...</span></Spinner> }
            </Button>
        )
    }
    else{
        return button
    }
}
ButtonLoader.propTypes ={
    loading: PropTypes.bool,
    type: PropTypes.any,
    variant:PropTypes.any,
    className:PropTypes.object,
    button:PropTypes.bool,
    loadingText:PropTypes.bool,
    visible:true
};
export default ButtonLoader;

// return (
//     <Button className={ className } disabled={ loading } type={ type || 'button' } variant={ variant || 'secondary' }>
//         { buttonText }
//         { loading && <div className="small-up-loader btn-loader">
//             <div className="lds-facebook"><div></div><div></div><div></div></div>
//         </div> }
//     </Button>
// );