import React from 'react'
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
const RightSideLoader =() => {
    return(
        <div className="author-info" >
            <div className='row bloggerImgSection'>
                <div className="author-img">
                    <Skeleton circle={ true } height={ 80 } width={ 80 } />
                </div>
                <div className="author-name  col-md-9" >
                    <Skeleton count={ 1 } height={ 20 } width={ 180 } />
                </div>
            </div>
            <hr/>
            <div className='about'>
                <h6>About</h6>
                <Skeleton count={ 3 } height={ 12 } width={ '100%' } />
            </div>
            <hr/>
            <div className='connect-social'>
                <h6>Connect your social media accounts</h6>
                <Skeleton rectangle={ true } count={ 1 } height={ 25 } width={ 25 } />
            </div>
        </div>
    )
}
const LeftSideLoader = (props) => {
    const { loading } = props

    if(loading){

        return(
            <div className="dashboard-header">
                <div className='dashboard-title'>
                    <h1><Skeleton count={ 1 } height={ 20 } width={ 180 } /></h1>
                    <h5 className={ 'mb-2' }>
                        <Skeleton count={ 1 } width={ 350 } />
                    </h5>
                </div>
                <div className="blog-dashboard-report">
                    <div className="data-box">
                        <div className="data-box-title">
                            <h3>Next Steps</h3>
                        </div>
                        <ul className='blog-dashboard-steps customGrid'>
                            <li>
                                <Skeleton reactangle={ true } height={ 80 } width={ 80 } />
                            </li>
                            <li>
                                <Skeleton reactangle={ true } height={ 80 } width={ 80 } />
                            </li>
                            <li>
                                <Skeleton reactangle={ true } height={ 80 } width={ 80 } />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>)
    }else{
        return null
    }
}
LeftSideLoader.propTypes = {
    loading: PropTypes.bool,
};
export { RightSideLoader, LeftSideLoader }