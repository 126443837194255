import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { renderFileDrop, renderField } from 'utils/formUtils'
import { bytesToSize } from 'utils/helpers'
import ButtonLoader from 'components/core/loader/button-loader'
import { headerLinksTemplate } from 'utils/helpers'
import defaultFavIcon from 'images/default-favicon.svg'
import WebTemplatesPreview from 'components/assessment/WebTemplatePreview'
import
{
    Button,
    Col,
    Row,
    Modal,
    Form
} from 'react-bootstrap';
const UploadLogo = (props) => {
    const { allowExtenstions,form,site, handleClose,loading,previewFile,clearImage ,fieldName, title, fieldAltName, isAlt } = props
    // const handleSelect = async(id) => {
    //     setSelectedUnsplash(id)
    //     const image = unsplashImages.filter((item) => item.id == id)[ 0 ];
    //     image && dataUrlToBase64(image.urls.regular,function(result){
    //         getBase64(result)
    //     });
    // }
    const data = {
        colors: form?.values?.colors,
        header: form?.values?.header,
        logoUrl: form?.values?.logoUrl,
        logoText: form?.values?.websiteName || site?.websiteName,
        faviconUrl: form?.values?.faviconUrl || defaultFavIcon,
        coverImage: form?.values?.coverImage,
        headerLinks: headerLinksTemplate(site?.menuLinks),
        readOnly: true
    }

    const clearImageFun = (event) => {
        clearImage(event, fieldName)
    }
    const previewTemplate = () =>{
        return(<Col className="col-8 name-website-selector-preview wizard-preview">
            <h4>Preview</h4>
            <div className="wizrd-blog-preview wizrd-inner-preview">
                <div className='browser-tab-preview'>{data.faviconUrl && <img src={ data.faviconUrl } />}<span>{site?.websiteName}</span></div>
                <WebTemplatesPreview data={ data } noBlogs={ true } />
            </div>
        </Col>)
    }
    return(
        <div className="">
            <Form onSubmit={ () => {} }>
                <Modal.Header closeButton>
                    <div className="new-modal-header">
                        <Modal.Title>{title || `Site ${ fieldName === 'logoUrl' ? 'Logo' : 'Icon/ Favicon' }`}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className="site-icon-selector-row">
                        <Row>
                            <Col className="col-4">
                                <Field
                                    name={ fieldName }
                                    component={ renderFileDrop }
                                    isDrop={ ()=> {} }
                                    allowExtenstions={ allowExtenstions }
                                    placeholder={ "<a><i className='fa fa-plus'/> upload your logo</a>" }
                                    isDropText={ 'Recommended: 250 h x 100 w OR 2.5:1 aspect ratio. Crop out empty space.' }
                                />
                            </Col>
                            {fieldName === 'faviconUrl' ? previewTemplate() : <Col className="col-8 logo-preview-modal">
                                <div className="logo-preview">
                                    <h4>Preview</h4>

                                    {previewFile && <div className="preview-logo">
                                        {typeof(previewFile) !== 'string' ?
                                            <span>
                                                {previewFile.name}-{bytesToSize(previewFile.size)}
                                            </span> :
                                            <img src={ previewFile } />
                                        }
                                    </div>}
                                    {previewFile && <span onClick={ clearImageFun } className="clear-logo">clear</span>}

                                </div>
                            </Col>
                            }
                        </Row>
                        <Row>
                            <Col>
                                {isAlt && (
                                    <div className='col-12 col-lg-6'>
                                        <div className="alt-image-section">
                                            <Form.Group>
                                                <label className='form-label'>Recommended: Image Alt-text</label>
                                                {/* <input type="text" name='' className='form-control' placeholder="Describe this image for better SEO" /> */}
                                                <Field
                                                    name={ fieldAltName }
                                                    component={ renderField }
                                                    placeholder={ 'Describe this image for better SEO' }
                                                    maxLength={ 125 }
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-btns">
                        <ButtonLoader
                            button={ <Button type='button' onClick={ handleClose } variant="primary">confirm</Button> }
                            loadingText='Saving'
                            loading={ loading }

                        />
                    </div>
                </Modal.Body>
            </Form>
        </div>

    )
}

UploadLogo.propTypes = {
    form: PropTypes.object,
    site: PropTypes.object,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func,
    assessmentData: PropTypes.object,
    clearImage: PropTypes.func,
    getBase64: PropTypes.func,
    handleSearch: PropTypes.func,
    unsplashImages: PropTypes.array,
    previewFile: PropTypes.any,
    fieldName: PropTypes.string,
    loading: PropTypes.bool,
    allowExtenstions: PropTypes.array,
    title: PropTypes.string,
    fieldAltName: PropTypes.string,
    isAlt: PropTypes.bool,
};

export default UploadLogo
