import React from 'react';
import { Container } from 'react-bootstrap';
// import Heading from 'components/core/heading';
import caseStudy from './jet-page-info.json'
import affiliateMarketing from './image/affiliate-marketing.svg';
import blogging from './image/blogging.svg'
import seo from './image/seo.svg'
import './style.sass';

const img = {
    affiliateMarketing : affiliateMarketing,
    blogging : blogging,
    seo : seo
}

const CaseStudy = () => {
    return (
        <section className="publisher-who-jetpage">
            <Container>
                <div className='who-jetpage-brief'>
                    <div className='who-jetpage-main-header'>
                        <h2>Who is JetPage made for?</h2>
                    </div>
                    <div>
                        {
                            caseStudy.map(item => {
                                return <div className={ item.imagePosition === 'left' ? 'container' : 'reverse-container' } key={ item.highLightedTitle }>
                                    <div className="leftContainer">
                                        <div className="lineText">
                                            <div className="beforeHighlight">{item.title}</div>
                                            <div className="highlight">
                                                <div className="highlightText">{ item.highLightedTitle }</div>
                                            </div>
                                        </div>
                                        <img className='image' src={ img[ item.imageUrl ] } alt="Logo" />
                                    </div>
                                    <div className="rightContainer">
                                        <div>
                                            <ul className="list">
                                                {
                                                    item.points.map(point=>{
                                                        return <div className='list-items' key={ point }>&#8226;&nbsp;
                                                            {point}</div>
                                                    })
                                                }
                                            </ul>
                                            <div>
                                                <a href={ `${ item.hrefLink }` } className="link">{`${ item.link } ->`}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Container>
        </section>
    )
}
export default CaseStudy;
