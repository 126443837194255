import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
// import RichTextEditor from './rte';
import { Field , change } from 'redux-form';
import { renderFieldWG } from 'utils/formUtils'
import { getSite, getPageSlug } from 'utils/helpers'
import {
    AddImgIcon,
} from 'utils/svg';
import SEOSection from './seo-section'
// import TableOfContent from './table-of-content'
import {
    Form,
    Button
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm,stopAsyncValidation, reset } from 'redux-form';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from 'middleware/auth'
import { createPage , getPageById } from 'middleware/page';
import { getUnsplash } from 'middleware/assessments'
import { change as reduxChange } from 'redux-form'
import { trimStringLength ,changeTitleToSlug } from 'utils/helpers'
import { asyncValidatePageSlug }  from 'utils/asyncValidate';
import { pageValidate as validate } from 'utils/validates';
import UploadImageModal from 'components/assessment/shared/UploadImageModal'
import ButtonLoader from 'components/core/loader/button-loader'
import { PAGE_STATUS   } from 'constants/app';
import ShortAddImg from 'images/add-img.png';
import ShortAddImg1 from 'images/add1.png';
import ShortAddImg2 from 'images/add2.png';
import ShortAddImg3 from 'images/add3.png';
import ShortAddImg4 from 'images/add4.png';
// import { Node } from 'slate'
import _ from 'lodash'
import { mixpanelCommonData } from 'utils/helpers'
import { SkeletonTheme } from 'react-loading-skeleton';
import { MixpanelContext } from 'utils/tracking';
import KendoEditor from 'components/core/editor/kendo-editor'

const CreatePage =(props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ asyncLoad, setAsyncLoad ] =  useState(false)
    const [ seoDescription ,setSeoDescription ] = useState(null)
    const [ availablePage, setAvailablePage ] =  useState(false)
    const [ errorMessageContent, setErrorMessageContent ] = useState(false)
    const [ seoErrorMessage, setSeoErrorMessage ] = useState({})
    const [ openModal, setModalOpen ]  = useState(false);
    const pageForm = useSelector((state)=>state.form.pageForm)
    const loading = useSelector((state) => state.page.createLoading )
    const statusLoading = useSelector((state) => state.page.status )
    const page = useSelector((state) => state.page.page)
    const unsplashImages  = useSelector((state) => state.assessment.unsplashImages)
    const id = getPageSlug(history.location.pathname)
    const queryParams = props.location.search;
    const [ isEditUrl, setEditUrl ] = useState(id ? false : true )
    const slugRef = useRef();
    const site = getSite()
    const [ htmlData, setHtmlData ] = useState('')

    const mixpanel = useContext(MixpanelContext);
    //const isReadyPublish = useSelector((state) => state.page.isReadyPublish)

    const initialValue = [
        {
            type: 'paragraph',
            children: [
                { text: '' },
            ],
        }
    ]

    const { initialize } = props;

    const handleEvent = (eventName, data) => {
        mixpanel.track(eventName, mixpanelCommonData(data));
    }
    // const serialize = nodes => {
    //     return nodes.map(n => Node.string(n)).join('\n')
    // }
    const submitData = (formData ,status ) => {
        const obj = { ...seoErrorMessage }
        const dataHtml = htmlData || initialValue
        if (!dataHtml || dataHtml === '<p></p>') {
            setErrorMessageContent(true);
            return;
        }
        if(!formData?.seoDescription){
            obj[ 'description' ] = true
            setSeoErrorMessage(obj)
        }
        if(!formData?.seoTitle){
            obj[ 'title' ] = true
            setSeoErrorMessage(obj)
        }
        const data = {
            siteId: site?.id,
            content: formData.data ? formData.data : initialValue,
            imageUrl: formData.pageUrl,
            title: formData.title,
            slug: formData.slug,
            seoTitle: formData?.seoTitle,
            seoDescription: formData?.seoDescription,
            status: status,
            imageAltText: formData?.imageAltText,
        }
        if (id && page.title === formData.title) {
            delete data.title;
        }

        formData?.seoDescription && formData?.seoTitle && dispatch(createPage(site?.domain, data,page?.id, id, handleEvent))

    }

    useEffect(()=>{
        const query = 'pages'
        if(id){
            dispatch(getPageById(id, site?.id, queryParams))
        }
        dispatch(getUnsplash('/photos',query))
        dispatch(getCurrentUser())
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/edit-site'
        })
        return () => {
            dispatch(reset('pageForm'))
            dispatch(reset('gjsForm'))
            dispatch({
                type: 'CLEAR_PAGE_FORM'
            })
        }
    },[])

    // const [ , setRTEData ] = useState(initialValue)

    const asyncSlugValidate = (value) =>{
        asyncValidatePageSlug(site, value ).then((result) => {
            if(!result.status){
                setEditUrl(true)
                dispatch(stopAsyncValidation('pageForm', { slug: result.message }))
                setAvailablePage(false)
            }else{
                setEditUrl(false)
                setAvailablePage(true)
            }
            setAsyncLoad(false)
        })
    }

    useEffect(() => {
        if(page && ((page.home && page.slugHome) || (!page.home && page.slug))) {
            page[ 'pageUrl' ] = page.imageUrl;
            page[ 'data' ] = (page.content);
            setHtmlData(page.content)
            delete page.imageUrl;
            initialize(page);
            !id && asyncSlugValidate(page?.home ? page?.slugHome : page?.slug)
        }
    },[ page ])
    // const handleRTEdata = (data) => {
    //     let content = data;
    //     if (data && data.length === 1 && !data[ 0 ].text ) {
    //         content = data[ 0 ].children[ 0 ].text?.trim() === '' ? '' : data
    //     }
    //     if(data && !id){
    //         var str = serialize(data)
    //         str = str === '' ?  data[ 0 ]?.children[ 0 ]?.text  : str
    //         str = trimStringLength(str,230)
    //         str = str.trim()
    //         setSeoErrorMessage( { description: false } )
    //         setSeoDescription(str)
    //     }
    //     setErrorMessageContent(_.isEmpty(data))

    //     dispatch(change('pageForm', 'data', content))
    // }
    const handleRTEdata = (data,str) => {
        if(data && !id){
            setSeoErrorMessage( { description: false } )
            setSeoDescription(trimStringLength(str,230))
        }
        setErrorMessageContent(_.isEmpty(data))

        dispatch(change('pageForm', 'data', data))
    }
    useEffect(() => {
        if(pageForm?.values?.seoDescription === undefined || pageForm?.values?.seoDescription !== seoDescription ){
            dispatch(change('pageForm', 'seoDescription', seoDescription))
        }
    },[ seoDescription  ])

    const clearImage = () => {
        // dispatch(reduxChange('pageForm','imageAltText', ''))
    }

    const handleToggleModal = (type) => {
        setModalOpen(!openModal)
        if(type !== 'confirm'){
            clearImage()
        }
    }
    const handleSearch = (event) => {
        const query = event.target.value || 'cat'
        dispatch(getUnsplash('/photos',query))
    }

    const getBase64 = (base64) => {
        dispatch(reduxChange('pageForm', 'pageUrl', base64))
    }

    const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1)
    const handleChange = (value, pageData) => {
        const dd = id ? pageData?.slug !== value : true
        if(value && dd){
            setAsyncLoad(true)
            asyncSlugValidate(value)
        }else if(!value){
            dispatch(stopAsyncValidation('pageForm', { slug: 'Required' }))
        }
    }
    const saveData = () => {
        if(pageForm?.values?.slug){
            setEditUrl(!availablePage)
            slugRef.current?.focus()
            const validSlug = id ? page.slug !== pageForm?.values?.slug : true
            !validSlug && setEditUrl(false)
            !availablePage && validSlug && dispatch(stopAsyncValidation('pageForm', { slug: 'Page post URL not available. Try another.' }))
        }else{
            setEditUrl(true)
            dispatch(stopAsyncValidation('pageForm', { slug: 'Required' }))
        }
    }

    const handleTitleChange = (value) => {
        dispatch(reduxChange('pageForm','seoTitle', trimStringLength(value,70)))
        dispatch(reduxChange('pageForm','slug', changeTitleToSlug(trimStringLength(value,70))))
        if(value){
            asyncSlugValidate(changeTitleToSlug(value))
        }
    }
    const asyncValidateFunc = _.debounce(handleChange, 800);
    const asyncChangeCallback = useCallback(asyncValidateFunc, []);

    const asyncValidateTitle = _.debounce(handleTitleChange, 800);
    const asyncChangeTitleCallback = useCallback(asyncValidateTitle, []);
    return(

        <main className="dashboard-data createNewPage createNewPage">
            <section data-tut='reactour__iso' className="dashboard-body">
                <SkeletonTheme color="lightGray">
                    <div className="blog-creation">
                        <Form onSubmit={ () => {} }>
                            <div className="blog-creation-head">
                                <div className="blog-creation-head-left">
                                    {/* <div className="page-btns mb-3" data-tut="reactour__button">
                                    <ButtonLoader
                                        button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl && submitData(pageForm?.values,PAGE_STATUS.DRAFT) } variant="outline-primary" className="mr-3">Save As Draft</Button> }
                                        loadingText={ 'Save As Draft' }
                                        visible={ statusLoading === PAGE_STATUS.DRAFT }
                                        loading={ loading }
                                        variant="outline-primary"
                                        className="mr-3"
                                    />
                                    <ButtonLoader
                                        button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl && submitData(pageForm?.values,PAGE_STATUS.PUBLISHED) } variant="primary">Publish</Button> }
                                        loadingText={ 'Publish' }
                                        visible={ statusLoading === PAGE_STATUS.PUBLISHED }
                                        loading={ loading }
                                        className="mr-3"
                                    />
                                </div> */}
                                    <Form.Group data-tut='reactour__title' className="blog-title-group ">
                                        <Field
                                            name="title"
                                            component={ renderFieldWG }
                                            placeholder={ 'Page post title' }
                                            normalize={ capitalize }
                                            handleChange={ asyncChangeTitleCallback }
                                        />
                                        <div className="blog-creation-author-box" data-tut="reactour__image">
                                            <div className="upload-feature-img-wrap">
                                                <div className="upload-feature-img" onClick={ handleToggleModal }>
                                                    <div className="innerChanges">
                                                        <AddImgIcon />
                                                        {(pageForm?.values?.pageUrl) ? <img src={ (pageForm?.values?.pageUrl ) } /> : 'Add a photo OR choose from thousands of royalty-free images'}
                                                        {!pageForm?.values?.pageUrl &&  <ListGroup className={ 'addImgList' }>
                                                            <ListGroup.Item><img src={ ShortAddImg } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg1 } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg2 } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg3 } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg4 } alt="" /></ListGroup.Item>
                                                        </ListGroup>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="blog-categories right-side">
                                    <div className="dflexBox">
                                        <div className="blog-btns mb-3" data-tut="reactour__button">
                                            {errorMessageContent && <p><span className="field_error">Please insert content</span></p>}
                                            <ButtonLoader
                                                button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl  && submitData(pageForm?.values,PAGE_STATUS.DRAFT) } variant="secondary" className="mr-3 btn-secondary">Save As Draft</Button> }
                                                loadingText={ 'Save As Draft' }
                                                visible={ statusLoading === PAGE_STATUS.DRAFT }
                                                loading={ loading }
                                                variant="secondary"
                                                className=""
                                            />
                                            <ButtonLoader
                                                button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl && submitData(pageForm?.values,PAGE_STATUS.PUBLISHED) } variant="primary">Publish</Button> }
                                                loadingText={ 'Publish' }
                                                visible={ statusLoading === PAGE_STATUS.PUBLISHED }
                                                loading={ loading }
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-creation-content mt-2">

                                <div className="page-editor" data-tut="reactour__rte">

                                    <KendoEditor
                                        htmlData={ htmlData } handleSubmit={ props.handleSubmit } setHtmlData={ setHtmlData }  formName={ 'pageForm' } pageFormData={ pageForm?.values } readOnly={ false } handleRTEdata={ handleRTEdata } initialValue={ page && (page.content)  } />
                                    {/* {id && page && page.content &&  <RichTextEditor formName={ 'pageForm' } pageFormData={ pageForm.values } readOnly={ false } setRTEData={ handleRTEdata } initialValue={ page && deserializeFromHtml(page.content) || initialValue } /> }
                                    {!id && !rteLoading  &&  <RichTextEditor formName={ 'pageForm' } pageFormData={ pageForm.values } readOnly={ false } setRTEData={ handleRTEdata } initialValue={ pageForm?.values?.data || initialValue } />} */}
                                </div>

                            </div>

                            <UploadImageModal
                                formName={ 'pageForm' }
                                fieldName={ 'pageUrl' }
                                clearImage={ clearImage }
                                previewFile={ pageForm?.values?.pageUrl }
                                getBase64={ getBase64 }
                                handleSearch={ handleSearch }
                                unsplashImages={ unsplashImages }
                                openModal={ openModal }
                                handleToggleModal={ handleToggleModal }
                                isAlt = { true }
                                fieldAltName = 'imageAltText'
                            />
                        </Form>
                    </div>
                </SkeletonTheme>

                {/* <div className="dashboard-bottom-section"> */}
                <div className="seo">
                    <SEOSection slugRef={ slugRef } id={ id } isEditUrl={ isEditUrl } page={ page } saveData={ saveData } asyncLoad={ asyncLoad } asyncChangeCallback ={ asyncChangeCallback } setSeoErrorMessage={ setSeoErrorMessage }site={ site } seoErrorMessage={ seoErrorMessage }  pageForm ={ pageForm } />
                </div>
                {/* <div className="table-of-content">
                        <TableOfContent htmlData={ htmlData }/>
                    </div> */}
                {/* </div> */}
            </section>
        </main>

    )
}

CreatePage.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object,
    location: PropTypes.any,
};
String.prototype.toUsername = function(){
    return this?.split('@') && this?.split('@')[ 0 ];
}
export default reduxForm({
    form: 'pageForm',
    validate,
    asyncValidatePageSlug,
    asyncChangeFields: [ 'slug' ]
})(CreatePage);
