import React, { useEffect, useState } from 'react';
import {
    Button,
    Accordion,
    Card,
    Spinner
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    ViewsBlogListIcon,
    DeleteBlogListIcon,
    DateBlogListIcon,
    ChevronRight,
    CloneBlogListIcon,
    EditBlogListIcon
} from 'utils/svg';
import Pagination from 'react-js-pagination';
import settingIcon from 'images/setting-icon.svg';
import ListSkelton from 'components/core/loader/list-skelton';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ROUTES } from 'constants/appRoutes';
import { useDispatch } from 'react-redux';
import { deleteEmailList, emailPagination, getAllEmailById, getAllEmailList } from 'middleware/email';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const EmailListingPage = () => {
    const limit = 6;
    const dispatch = useDispatch();
    const history = useHistory();
    const isView = true;
    const [ isLoading, setIsLoading ] = useState(null);
    const [ isEmailLoading, setIsEmailLoading ] = useState(false);
    const [ activeEmail, setActiveEmail ] = useState(0);
    const [ activeEmailDraft, setActiveEmailDraft ] = useState(0);
    const [ emailList, setEmailList ] = useState([]);
    const [ emailDraftList, setEmailDraftList ] = useState([]);
    // const [ isView, setIsView ] = useState(false);

    const handleDelete = (bool,id) =>{
        setIsLoading(id);
        dispatch(deleteEmailList(bool,id,setIsLoading,setEmailList,setEmailDraftList))
    }
    const handleClone = (event, id) => {
        event.preventDefault();
        dispatch(getAllEmailById(id, history))
    }
    const handleViewEmail = (event, id) => {
        event.preventDefault();
        dispatch(getAllEmailById(id, history, isView))
    }

    const handlePageChange = (pageNumber) => {
        const args = `page=${ pageNumber - 1 }&size=${ limit }`
        dispatch(emailPagination(args, false,setEmailList));
        setActiveEmail(pageNumber);
    }
    const handleDraftPageChange = (pageNumber) => {
        const args = `page=${ pageNumber - 1 }&size=${ limit }`
        dispatch(emailPagination(args, true,setEmailDraftList));
        setActiveEmailDraft(pageNumber);
    }

    useEffect(() => {
        dispatch(getAllEmailList(false,setEmailList, setIsEmailLoading));
        dispatch(getAllEmailList(true,setEmailDraftList, setIsEmailLoading));
    }, []);
    console.log('emailList----------->',emailList);
    return (
        <main className="dashboard-data blog-dashboard">

            <section className="dashboard-body email-listing-container">
                <div className="dashboard-header" style={ { display:'flex' } }>
                    <div className="dashboard-title">
                        <h1>Emails</h1>
                        <div className="dashboard-body-actions">
                            <Link to={ ROUTES.SEND_EMAILS } className={ 'btn btn-primary add-new-blog ' }>
                                Add New+
                            </Link>

                        </div>
                    </div>
                    <Link to={ ROUTES.DOMAIN_CONFIG_PAGE }
                        onClick= { ()=>{history.push({
                            pathname: ROUTES.DOMAIN_CONFIG_PAGE,
                            state: { isUpadte: true }
                        });} }
                    >
                        <a style={ { color: 'blue',fontSize: 20 } }>
                            <img src={ settingIcon } width={ 50 } height={ 50 }/>
                        </a>
                    </Link>
                </div>
                <div className="blog-custom-list-table scroll-list">

                    <div className="blog-custom-list">
                        <div className="blog-list-header">
                            <div className="blog-list-column blog-list-title text-left">Subject</div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Date Created</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Date Sent</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Requests</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Delivered</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Bounces</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Open Rate</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Opens</span>
                            </div>

                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Click Rate</span>
                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Clicks</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Unsubscribes</span>

                            </div>
                            <div className={ 'blog-list-column blog-list-date headerSort' }>
                                <span className="sort-column">Complaints</span>

                            </div>
                            {/* <div className="blog-list-column blog-list-views">Views</div> */}
                            <div className="blog-list-column blog-list-delete">Delete</div>
                        </div>
                        {/* <ListSkelton loading={ isEmailLoading && emailList?.emailsData?.length == 0 } numCol={ 5 } numRow={ 3 } /> */}
                        {/* {isEmailLoading === true && (
                            <div
                                className="email-listing-loader d-flex align-items-center justify-content-center"
                            >
                                <Spinner
                                    variant="secondary"
                                    animation="border"
                                    size={ 'md' }
                                    role="status"
                                />
                            </div>
                        ) } */}
                        {emailList?.emailsData?.length? <div>
                            <div  className="blog-custom-list-table-data">
                                {emailList?.emailsData?.map((item)=>{
                                    return( <div key={ item?.id }
                                        className={
                                            'active blog-list-table blog-list-header  blog-list-publish'
                                        }
                                    >

                                        <div className="blog-list-column blog-list-title">
                                            <span className="table-post-title">
                                                {item?.subject}
                                                {
                                                    <>
                                                        <a onClick={ (e)=>handleViewEmail(e, item?.id) }>View</a>
                                                        <a className="toggle-blog-detail">
                                                            <ChevronRight />
                                                        </a>
                                                    </>
                                                }
                                            </span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.createdAt && moment(item?.createdAt).format('L')}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.sentAt && moment(item?.sentAt).format('L')}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span> {item?.requests ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span> {item?.deliveredCount ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span> {item?.bounces ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.openRate > 0 ? item?.openRate + '%' : item?.openRate ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.opens ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.clickRate > 0 ? item?.clickRate + '%' : item?.clickRate ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.clicks ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.unsubscribes ?? ''}</span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>{item?.complaints ?? ''}</span>
                                        </div>
                                        {/* <div className="blog-list-column blog-list-views">
                                            <ViewsBlogListIcon />
                                            <span>views</span>
                                        </div> */}

                                        <div className="blog-list-column blog-list-actions  blog-list-delete">
                                            <div className="hover-actions">
                                                <a onClick={ (e) => {handleClone(e, item?.id)} } className="table-action-btns active">
                                                    <CloneBlogListIcon />
                                                    <span>Clone</span>
                                                </a>
                                            </div>

                                            <a
                                                onClick={ () => handleDelete(false, item?.id) }
                                                className={
                                                    'table-action-btns table-action-btns-delete'
                                                }
                                            >
                                                {isLoading===item?.id?<Spinner animation="border" size={ 'sm' } role="status" />:<DeleteBlogListIcon />}
                                            </a>
                                        </div>
                                    </div>

                                    )})}
                            </div>
                            {emailList?.metaData?.totalPages > 1 && <div className='blogs-pagination'>
                                <Pagination
                                    activePage={ activeEmail == 0 ? 1 : activeEmail }
                                    itemsCountPerPage={ limit }
                                    totalItemsCount={ emailList?.metaData?.totalElements }
                                    pageRangeDisplayed={ 5 }
                                    onChange={ handlePageChange }
                                />

                            </div>}
                        </div>
                            : isEmailLoading === true ? (
                                <div
                                    className="email-listing-loader d-flex align-items-center justify-content-center"
                                >
                                    <Spinner
                                        variant="primary"
                                        animation="border"
                                        size={ 'md' }
                                        role="status"
                                    />
                                </div>
                            ) : <div className="blog-list-table blog-list-header no-post">No mail available</div>}
                    </div>
                </div>

                <div className="draft-posts">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ Button } variant="link" eventKey="0">
                                    Drafts
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="blog-custom-list-table scroll-list">
                                        <div className="blog-custom-list">
                                            <div className="blog-list-header">
                                                <div className="blog-list-column blog-list-title text-left">
                                                    <span className="sort-column">Subject</span>
                                                </div>
                                                <div className={ 'blog-list-column blog-list-date headerSort' } >

                                                    <span className="sort-column">Date Created</span>

                                                </div>
                                                <div className={ 'blog-list-column blog-list-date headerSort' } >

                                                    <span className="sort-column">Last Updated</span>

                                                </div>

                                                <div className="blog-list-column blog-list-delete">
                                                    Delete
                                                </div>
                                            </div>
                                            <ListSkelton  numCol={ 5 } numRow={ 3 } />
                                            <div className="blog-custom-list-table-data">

                                                {emailDraftList?.emailsData?.length? <div>
                                                    {emailDraftList?.emailsData?.map((item)=>{return (
                                                        isEmailLoading === true ? (
                                                            <div
                                                                className="email-listing-loader d-flex align-items-center justify-content-center"
                                                            >
                                                                <Spinner
                                                                    variant="primary"
                                                                    animation="border"
                                                                    size={ 'md' }
                                                                    role="status"
                                                                />
                                                            </div>
                                                        ) :
                                                            <div key={ item?.id } className={ 'active blog-list-table blog-list-header blog-list-publish' } >

                                                                <div className="blog-list-column blog-list-title">
                                                                    <span className="table-post-title">
                                                                        {item?.subject}
                                                                        {
                                                                            <>
                                                                                <a>View</a>
                                                                                <a className="toggle-blog-detail">
                                                                                    <ChevronRight />
                                                                                </a>
                                                                            </>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="blog-list-column blog-list-date">
                                                                    <DateBlogListIcon />
                                                                    <span>{item?.createdAt && moment(item?.createdAt).format('L')}</span>
                                                                </div>
                                                                <div className="blog-list-column blog-list-views">
                                                                    <ViewsBlogListIcon />
                                                                    <span>{item?.sentAt && moment(item?.sentAt).format('L')}</span>
                                                                </div>

                                                                <div className="blog-list-column blog-list-actions  blog-list-delete">
                                                                    <div className="hover-actions">
                                                                        <a onClick={ (e) => {handleClone(e, item?.id)} } className="table-action-btns active">
                                                                            <EditBlogListIcon />
                                                                            <span>Edit</span>
                                                                        </a>
                                                                        <a onClick={ (e) => {handleClone(e, item?.id)} } className="table-action-btns active">
                                                                            <CloneBlogListIcon />
                                                                            <span>Clone</span>
                                                                        </a>
                                                                    </div>
                                                                    <a onClick={ () =>  handleDelete(true, item?.id) } className={ 'table-action-btns table-action-btns-delete'  }>
                                                                        {isLoading=== item?.id ?<Spinner animation="border" size={ 'sm' } role="status" />:<DeleteBlogListIcon />}
                                                                    </a>
                                                                </div>
                                                            </div>)})}
                                                    {emailDraftList?.metaData?.totalPages > 1 && <div className='blogs-pagination'>
                                                        <Pagination
                                                            activePage={ activeEmailDraft == 0 ? 1 : activeEmailDraft }
                                                            itemsCountPerPage={ limit }
                                                            totalItemsCount={ emailDraftList?.metaData?.totalElements }
                                                            pageRangeDisplayed={ 5 }
                                                            onChange={ handleDraftPageChange }
                                                        />

                                                    </div>}
                                                </div>
                                                    : isEmailLoading === true ? (
                                                        <div
                                                            className="email-listing-loader d-flex align-items-center justify-content-center"
                                                        >
                                                            <Spinner
                                                                variant="primary"
                                                                animation="border"
                                                                size={ 'md' }
                                                                role="status"
                                                            />
                                                        </div>
                                                    ) : <div className="blog-list-table blog-list-header no-post">No Draft available</div>}

                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>

            </section>
        </main>
    );
};

export default EmailListingPage;
