import React from 'react';
import { Button } from 'react-bootstrap';
import './style.sass';
import PropTypes from 'prop-types';

const ONBoardingLine = (props) => {
    const { redirectAssessment } = props;
    return (
        <div className="publisher-pricing-detail">
            <h3>Setup your website now, pick a plan later</h3>
            <h6>Try JetPage FREE for 14-days. No Credit Card required.</h6>
            <div className="publisher-pricing-action">
                <Button
                    onClick={ () => redirectAssessment() }
                    className="home-try-for-free"
                >
                    Try for Free
                </Button>
                {/* <span>*No Credit Card Required</span> */}
            </div>
        </div>
    );
};

export default ONBoardingLine;
ONBoardingLine.propTypes = {
    redirectAssessment:PropTypes.func
}
