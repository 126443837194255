import React,{ useEffect ,useState, useContext } from 'react'
import { Field } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux'
import { renderStyleMultipleRadio } from '../../utils/formUtils'
import { headerLinksTemplate, assessmentIntialValues } from '../../utils/helpers'
import { assessmentFormValidate as validate } from '../../utils/validates'
import { reduxForm } from 'redux-form';
import { change as reduxChange } from 'redux-form'
import PropTypes from 'prop-types';
import CustomColor from 'components/assessment/shared/CustomColor'
import AssessmentHeader from 'pages/assessment/header'
import AssessmentFooter from 'pages/assessment/footer'
import { AllColors } from 'constants/theme'
import {
    Form,
    Modal,
    Container
} from 'react-bootstrap';
import './style.sass'
import AssessmentStepFooter from './shared/steps-footer';
import iphoneImage from './images/wizrd-iphone.png';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
import WebTemplatePreview from './WebTemplatePreview';
const StepTwo = (props) => {
    const mixpanel = useContext(MixpanelContext)
    const [ customColorOpen, setCustomColorOpen ] = useState(false)

    const [ isCustomColors , setisCustomColors ] = useState(false)
    const dispatch = useDispatch()
    // const { handleSubmit,onSubmit, prevPage, initialize, isHomePage } = props;
    const { handleSubmit, onSubmit, prevPage, initialize, isHomePage } = props;

    // niche changes=======
    useEffect(() => {
        initialize(assessmentIntialValues())
    },[])
    //==========
    const assessmentForm = useSelector((state) => state.form.assessmentForm)
    const data = {
        colors: assessmentForm?.values?.colors,
        header: assessmentForm?.values?.header,
        logoUrl: assessmentForm?.values?.logoUrl,
        logoText: assessmentForm?.values?.websiteName,
        coverImage: assessmentForm?.values?.coverImage,
        headerLinks: headerLinksTemplate(),
        readOnly: true
    }
    const setAllColors = () => {
        if(assessmentForm?.values?.colors){
            const colors = JSON.parse(assessmentForm?.values?.colors)
            const obj = { label: 'Custom Color', value: colors,imageUrl: undefined }
            const allColors = [ ... AllColors() ]
            if(colors.name === 'custom-color'){
                allColors.pop()
                allColors.push(obj)
            }
            return allColors
        }else{
            return AllColors()
        }
    }
    const [ colorPalette, setColorPalette ] = useState(setAllColors())
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const setIntialColors = () => {
        dispatch(reduxChange('assessmentForm','colors',JSON.stringify(colorPalette?.filter((item)=> item.label === 'Clean White')[ 0 ]?.value)))
    }

    useEffect(()=>{
        if(!assessmentForm?.values?.colors){
            setIntialColors()
        }
    },[ assessmentForm?.values ])

    const handleColorChange = ( event ) => {
        const obj = event.currentTarget.value &&  JSON.parse(event.currentTarget.value)
        obj.name === 'custom-color' ?  setCustomColorOpen(!customColorOpen) : null
        obj.name === 'custom-color'?  mixpanel.track(`${ EVENTS.clickonCustomColor } ${ isHomePage ? 'from Homepage' : '' }`,{ colors: obj }) :  mixpanel.track(`${ EVENTS.clickOnPremadeColor } ${ isHomePage ? 'from Homepage' : '' }`,{ colors: obj })
    }

    const handleColorsData = (colors) => {
        const obj = { label: 'Custom Color', value: colors,imageUrl: undefined }
        colorPalette.pop()
        colorPalette.push(obj)
        setColorPalette(colorPalette)
        dispatch(reduxChange('assessmentForm', 'colors', JSON.stringify(colors)))
        setCustomColorOpen(false)
        setisCustomColors(true)
    }

    useEffect(() => {
        if(isCustomColors && (assessmentForm?.values?.colors && JSON.parse(assessmentForm?.values?.colors).name === 'custom-color')){
            setisCustomColors(false)
            onSubmit && onSubmit()
        }

    },[ isCustomColors ])

    const handleClick = () => {
        setCustomColorOpen(!customColorOpen)
    }

    const hideModal = () => {
        setCustomColorOpen(false)
    }
    const handleNext = () => {
        mixpanel.track(EVENTS.clickonNext,{})
        onSubmit && onSubmit()
    }

    return(
        <div className={ `${ props.className } template-color-step template-steppers ${ isHomePage ? 'landing-color-stepper' : 'assesment-color-stepper' }` }>
            <Form className="form" onSubmit={ handleSubmit(onSubmit) }>
                {!isHomePage && <AssessmentHeader  prevPage={ prevPage } { ...props } />}
                <Container>
                    <div className="template-stepper-wrap">
                        {props?.title && <h2 className="stepper-title">{ props.title }</h2>}
                        <div className="template-color-selector">
                            <Modal show={ customColorOpen } onHide={ hideModal } className="new-modal custom-color-modal">
                                <CustomColor formName='assessmentForm' backFun={ () => setCustomColorOpen(false) } previewFile={ assessmentForm?.values?.coverImage } colorPalette={ colorPalette } handleColorsData={ (colors) => handleColorsData(colors) } data={ data } />
                            </Modal>
                            <div className="template-color-inputs">

                                <Field
                                    name="colors"
                                    options={ colorPalette }
                                    component={ renderStyleMultipleRadio }
                                    handleChange={ handleColorChange }
                                    handleClick={ handleClick }
                                    handleNext={ handleNext }
                                    isHomePage={ isHomePage }
                                    placeholder={ 'gaveCraving' }
                                    isColors={ true }
                                    className='styled-radio-btn btn-outline'
                                    imgWidth="30px"
                                />

                            </div>
                            <div className="template-color-preview">
                                <div className={ `wizrd-blog-preview ${ isHomePage ? 'wizrd-home-preview' : 'wizrd-inner-preview' }` }>
                                    <WebTemplatePreview data={ data } />
                                </div>
                                {isHomePage && <div className="template-mobile-preview">
                                    <div className="wizrd-blog-preview mobile-version">
                                        <img src={ iphoneImage } className="mobile-img" />
                                        <WebTemplatePreview isMobileView={ true } data={ data } isHomePage={ isHomePage } />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="template-step-action">
                            <AssessmentFooter prevPage={ prevPage } { ...props } />
                        </div>
                    </div>
                </Container>
            </Form>
            {!isHomePage && <AssessmentStepFooter />}
        </div>
    )
}
StepTwo.propTypes = {
    initialize: PropTypes.object,
    handleSubmit: PropTypes.func,
    submitData: PropTypes.func,
    saveData: PropTypes.func,
    colorPalette: PropTypes.object,
    valid: PropTypes.bool,
    prevPage: PropTypes.func,
    isHomePage: PropTypes.bool,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    nextPage: PropTypes.func,
    title: PropTypes.string
};
export default reduxForm({
    form: 'assessmentForm',
    destroyOnUnmount: false,
    validate
})(StepTwo);