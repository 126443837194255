import { templateFailure, templateRequest, templateSuccess } from 'actions/grapes';
import { NOTIFICATION_TYPES } from 'constants/app';
import axiosInstance from 'services/api';
import { notification } from 'services/notification';
import { getSite } from 'utils/helpers';

export const getTemplateList = (cb) => {
    return async () => {
        try {
            const site = getSite();
            const response = await axiosInstance.get(
                `/site/${ site?.id }/template/all`
            );
            if ([ 200, 203 ].includes(response.status)) {
                cb(response?.data?.data)
                console.log('Templates loaded', response?.data);
                // console.log('Templates loaded 1', response?.data?.data);
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Issue fetching template list, please refresh page and try again'
                );
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                e?.response?.data?.message ??
          'Issue fetching template list, please refresh page and try again'
            );
        }
    };
};

export const saveTemplate = (data, cb) => {
    return async (dispatch) => {
        cb(true);
        dispatch(templateRequest());
        try {
            const site = getSite();
            const response = await axiosInstance.post(
                `/site/${ site?.id }/template/create`,
                data
            );
            if ([ 200, 203 ].includes(response.status)) {
                dispatch(templateSuccess());
                cb(false);
            } else {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    'Something went wrong while saving template, please refresh page and try again'
                );
                dispatch(templateFailure());
                cb(false);
            }
        } catch (e) {
            notification(
                NOTIFICATION_TYPES.ERROR,
                'Something went wrong while saving template, please refresh page and try again'
            );
            dispatch(templateFailure(e));
            cb(false);
        }
    };
};