import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux'
import { headerLinksTemplate } from 'utils/helpers'
import { assessmentFormValidate as validate } from '../../utils/validates'
import WebTemplates ,{ Header,Home, Banner,Blogs, Card, Tabs,Tab } from 'web-templates';
import AssessmentHeader from 'pages/assessment/header'
import AssessmentFooter from 'pages/assessment/footer'
import BannerView from 'components/core/banner-view'
import { SAMPLE_BLOG } from 'constants/app'
import
{
    Row,
    Col,
    Container,
    Form,
} from 'react-bootstrap';

const Preview = (props) => {
    const { handleSubmit ,prevPage, finalSubmit } = props;
    const assessmentForm = useSelector((state) => state.form.assessmentForm)

    const data = {
        header: assessmentForm?.values?.header,
        colors: assessmentForm?.values?.colors || [],
        logoUrl: assessmentForm?.values?.logoUrl,
        logoText: assessmentForm?.values?.websiteName,
        coverImage: assessmentForm?.values?.coverImage,
        readOnly: true,
        headerLinks: headerLinksTemplate(),
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);

    return(
        <Form className="form" onSubmit={ handleSubmit(finalSubmit) }>
            <AssessmentHeader isFinalScreen={ true } prevPage={ prevPage } { ... props }/>
            <div className="assesment-step assesment-step-final">
                <Row  className="step-form">
                    <Col className="col-12">
                        <Container>

                            <div className="form-heading">
                                <h2>
                                    Congratulations! Here is your NEW website
                                </h2>
                            </div>
                            <Row className="color-palatte">
                                <Col className="col-12 final-blog-preview wizard-preview">
                                    <div className="color-preview wizard-home wizrd-blog-preview final-preview">
                                        {/*<TemplateLayoutOne />*/}
                                        <WebTemplates data={ data }>
                                            <Header>
                                                <Header.Left />
                                                <Header.Right />
                                            </Header>
                                            <Home>
                                                <Banner>
                                                    <BannerView data={ data?.header }  />
                                                </Banner>
                                                <Blogs>
                                                    <Tabs>
                                                        <Tab label="Recent">
                                                            <ul className="wizrd-blog-list">
                                                                <li>
                                                                    <a href='#'>
                                                                        <Card
                                                                            image={ SAMPLE_BLOG.BLOG_IMAGE }
                                                                        >
                                                                            <h2>{ SAMPLE_BLOG.BLOG_NAME }</h2>
                                                                            <div className="wizrd-blog-author">
                                                                                {/* <RichTextEditor readOnly={true} initialValue={blog?.content} /> */}
                                                                                <div className="wizrd-blog-author-img">
                                                                                    <img src={ SAMPLE_BLOG.USER_IMAGE } alt="" />
                                                                                </div>
                                                                                <div className="wizrd-blog-author-name">
                                                                                    { SAMPLE_BLOG.USER_NAME }
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizrd-blog-date">
                                                                                <span>{ SAMPLE_BLOG.DATE }</span>
                                                                            </div>
                                                                        </Card>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </Tab>
                                                        <Tab label="Popular"></Tab>
                                                    </Tabs>
                                                </Blogs>
                                            </Home>
                                        </WebTemplates>
                                    </div>
                                </Col>
                            </Row>
                            <AssessmentFooter isFinalScreen={ true } prevPage={ prevPage } { ... props } { ...props } />
                        </Container>
                    </Col>
                </Row>
            </div>
        </Form>

    )
}
Preview.propTypes = {
    handleSubmit: PropTypes.func,
    submitData: PropTypes.func,
    colorPalette: PropTypes.object,
    prevPage: PropTypes.func,
    finalSubmit: PropTypes.func
};
export default reduxForm({
    form: 'assessmentForm',
    destroyOnUnmount: false,
    validate
})(Preview);