import { ActionTypes } from '../actions/subscription/actionTypes';

const initialState = {
    subscription: null,
    isLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.CREATE_SUBSCRIPTION_REQUEST:
    case ActionTypes.UPDATE_SUBSCRIPTION_REQUEST:
    case ActionTypes.CANCEL_ACCOUNT_REQUEST:
    case ActionTypes.FREEZE_ACCOUNT_REQUEST:
    case ActionTypes.SET_DISCOUNT_ACCOUNT_REQUEST:
        return { ...state, isLoading: true };
    case ActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
    case ActionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
    case ActionTypes.CANCEL_ACCOUNT_SUCCESS:
    case ActionTypes.FREEZE_ACCOUNT_SUCCESS:
    case ActionTypes.SET_DISCOUNT_ACCOUNT_SUCCESS:
    case ActionTypes.CREATE_SUBSCRIPTION_FAILURE:
    case ActionTypes.UPDATE_SUBSCRIPTION_FAILURE:
    case ActionTypes.CANCEL_ACCOUNT_FAILURE:
    case ActionTypes.FREEZE_ACCOUNT_FAILURE:
    case ActionTypes.SET_DISCOUNT_ACCOUNT_FAILURE:
        return { ...state, isLoading: false };
    case ActionTypes.UNFREEZE_ACCOUNT_REQUEST:
    case ActionTypes.UNFREEZE_ACCOUNT_SUCCESS:
    case ActionTypes.UNFREEZE_ACCOUNT_FAILURE:
    default:
        return state;
    }
};