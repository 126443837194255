import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { renderFileDrop, renderField } from '../../../utils/formUtils'
import { dataUrlToBase64, debounce } from '../../../utils/helpers'
import { change as reduxChange } from 'redux-form'
import {
    Button,
    Modal,
    Form,
    Spinner
} from 'react-bootstrap';
import _ from 'lodash';
const UploadImageModal = (props) => {
    const dispatch = useDispatch()
    const { openModal, handleMixpanelEvents, isUploadImageLoading,handleToggleModal,submitData,formName, fieldName,unsplashImages ,getBase64,clearImage, handleSearch, previewFile,
        isAlt, fieldAltName,
    } = props
    const [ selectedUnsplash, setSelectedUnsplash ] = useState(null);
    const unsplashLoading = useSelector((state) => state.assessment.unsplashLoading);

    const [ imageUrl , setImage ] = useState(null)
    useEffect(() => {
        if(openModal && previewFile){
            setImage(previewFile)
        }
    },[ previewFile, openModal ])

    const handleSelect = async(id) => {
        setSelectedUnsplash(id)
        const image = unsplashImages.filter((item) => item.id == id)[ 0 ];
        image && dataUrlToBase64(image.urls.regular,function(result){
            formName ? setImage(result) :  getBase64(result)
        });
    }

    const clearImageFun = (event) => {
        setSelectedUnsplash(null)
        setImage(null)
        clearImage(event)
    }
    const handleConfirm = () => {
        handleMixpanelEvents && handleMixpanelEvents(formName, fieldName, imageUrl)
        formName && dispatch(reduxChange(formName, fieldName, imageUrl))
        submitData && submitData() || handleToggleModal && handleToggleModal('confirm', imageUrl)
        setImage(null)
    }

    const handleClose = () => {
        setImage(null)
        handleToggleModal()
    }
    return(
        <Modal show={ openModal } onHide={ handleClose } className="image-upload-modal">
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>Images</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="image-upload-modal-search">
                    <Form.Group>
                        <input onChange={ (event) => debounce(handleSearch,event,1000) } name='search' className='form-control' placeholder="Search images" />
                    </Form.Group>
                    <div className="powered-by-unsplash">
                        <a href="https://unsplash.com/" target="_blank"  rel="noreferrer" >Powered by Unsplash</a>
                    </div>
                </div>

                <div className="image-upload-modal-unsplash-gallery">
                    { unsplashLoading ?  <div className='unsplash-emtpy'><div className="small-up-loader btn-loader ">
                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                    </div></div> : null }
                    {_.isEmpty(unsplashImages) && !unsplashLoading ? <div className='unsplash-emtpy'><p>No Records found</p></div> : <>
                        <div className="unsplash-gallery">
                            {unsplashImages.slice(0,10).map((item)=>{
                                return( <div onClick={ () => handleSelect(item.id) } key={ item.id } className={ `${ selectedUnsplash === item.id ? 'active' : '' }` }>
                                    <img src={ item.urls.small } alt="media1" />
                                </div>)
                            })}
                            {unsplashImages.slice(10,20).map((item)=>{
                                return( <div onClick={ () => handleSelect(item.id) } key={ item.id } className={ `${ selectedUnsplash === item.id ? 'active' : '' }` }>
                                    <img src={ item.urls.small } alt="media1" />
                                </div>)
                            })}
                        </div>
                    </>
                    }
                </div>
                <div className="image-upload-modal-form">
                    <div className='row'>
                        <div className={ isAlt ? 'col-12 col-lg-6' : 'col-12' }>
                            <div className={ `image-upload-modal-select ${ (imageUrl) ? 'uploaded' : '' }` }>
                                <Field
                                    name={ fieldName }
                                    component={ renderFileDrop }
                                    imageUrl={ imageUrl }
                                    setImage={ setImage }
                                    formName={ formName }
                                    isDrop={ ()=> { setSelectedUnsplash(null)} }
                                    placeholder={ '<a> <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" width="2" height="14" rx="1" fill="#007BFF"/><rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="#007BFF"/></svg> upload your image</a>' }
                                    isDropText={ 'OR drag image here' }
                                />
                                {(imageUrl)  && <div className="preview-logo">

                                    <img src={ (imageUrl) } />

                                    <span onClick={ clearImageFun } className="clear-logo">clear</span>
                                </div>}
                            </div>
                        </div>
                        {isAlt && (
                            <div className='col-12 col-lg-6'>
                                <div className="alt-image-section">
                                    <Form.Group>
                                        <label className='form-label'>Recommended: Image Alt-text</label>
                                        {/* <input type="text" name='' className='form-control' placeholder="Describe this image for better SEO" /> */}
                                        <Field
                                            name={ fieldAltName }
                                            component={ renderField }
                                            placeholder={ 'Describe this image for better SEO' }
                                            maxLength={ 125 }
                                        />
                                    </Form.Group>
                                    <div className="modal-btns">
                                        { !props.children && <Button disabled={ isUploadImageLoading } variant="primary" onClick={ handleConfirm } className="btn-primary-2">{isUploadImageLoading ? (
                                            <Spinner animation="border" size={ 'sm' } role="status" />
                                        ) : (
                                            'Confirm'
                                        )}</Button>}
                                        { props.children }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )
}

UploadImageModal.propTypes = {
    formName: PropTypes.string,
    handleToggleModal: PropTypes.func,
    openModal: PropTypes.bool,
    handleSearch: PropTypes.func,
    getBase64: PropTypes.func,
    clearImage: PropTypes.func,
    previewImage: PropTypes.object,
    fieldName: PropTypes.string,
    unsplashImages: PropTypes.array,
    previewFile: PropTypes.object,
    submitData: PropTypes.func,
    children: PropTypes.children,
    handleMixpanelEvents: PropTypes.func,
    isAlt: PropTypes.func,
    fieldAltName: PropTypes.string,

};

export default UploadImageModal