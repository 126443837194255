import React , { useState,useEffect, useContext } from 'react'
import { Modal,Button, Form } from 'react-bootstrap'
import ActiveCampaignImg from 'images/Active_Campaign.png'
import PropTypes  from 'prop-types'
import { mixpanelCommonData } from 'utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import { emailMarketingFormValidate as validate } from 'utils/validates';
import ButtonLoader from 'components/core/loader/button-loader'
import { renderField, renderSelectField } from 'utils/formUtils'
import { updateActiveCampaignIntegration, getACAccountList, updateActiveCampaignAccount } from 'middleware/assessments'
import { MixpanelContext } from 'utils/tracking';
import { EMAIL_MARKETING_INTEGRATIONS, ACTIVE_CAMPAIGN_STEPS } from 'constants/app';

const EmailIntegrations = ( props ) => {
    const { handleSubmit, initialize , site, currentACStep, setCurrentACStep } = props;
    const dispatch = useDispatch()
    const mixpanel = useContext(MixpanelContext);

    const activeCampLoading = useSelector((state) => state.assessment.isActiveCampaignLoading);
    const activeCampAccountsData = useSelector((state) => state.assessment.activeCampaignAccounts);
    const siteData = useSelector((state) => state.assessment.site);

    const [ open, setOpen ] = useState(false)
    const [ modalType , setModalType ] = useState(null);
    const [ connectionStatus, setConnectionStatus ] = useState({});
    const [ acAccountList, setAcAccountList ] = useState([]);
    const [ selectedAccount, setSelectedAccount ] = useState(null);

    const isModalTypeActiveCampaign = modalType === 'ACTIVE_CAMPAIGN';

    const modalTitles = {
        'ACTIVE_CAMPAIGN': 'Connect Active Campaign',
    };

    useEffect(() => {
        initialize({
            'url': siteData?.activeCampaignUrl || '',
            'key': siteData?.activeCampaignToken || '',
        });

        setConnectionStatus({
            ...connectionStatus,
            ACTIVE_CAMPAIGN: siteData?.activeCampaignListId || null,
        })
    },[ siteData ]);

    useEffect(() => {
        let modAccountList = [];
        if (activeCampAccountsData?.activeCampaignList?.length) {
            modAccountList = activeCampAccountsData?.activeCampaignList.map(acc => {
                return { ...acc.id, label: acc.listName, value: acc.id };
            })
        }

        const selAcc = activeCampAccountsData?.activeCampaignList?.filter(acc => acc.id === siteData.activeCampaignListId);

        if (selAcc?.length) {
            setSelectedAccount({ label: (selAcc && selAcc[ 0 ] && selAcc[ 0 ].listName) || '', value: (selAcc && selAcc[ 0 ] && selAcc[ 0 ].id) || ''  });
        } else {
            setSelectedAccount(null);
        }

        setAcAccountList(modAccountList);
    }, [ activeCampAccountsData, siteData ]);

    const handleModal = (event, type) => {
        event.preventDefault()
        setOpen(!open)
        setModalType(type)
    }

    const handleCloseModal = () =>{
        setOpen(false)
        setModalType(null)
    }

    const handleEvent = (eventName,data) => {
        mixpanel.track(eventName, mixpanelCommonData( data ));
        if (!(eventName === 'Upadate Active Campaign Integration')){
            handleCloseModal();
        } else {
            setCurrentACStep(1);
        }
    }

    const handleDeactivateEvent = (eventName,data) => {
        mixpanel.track(eventName, mixpanelCommonData( data ));
        handleCloseModal();
        setCurrentACStep(0);
    }

    const handleACDeactivate = () => {
        const data = {
            'siteId': siteData?.id || '',
            'url': '',
            'apiToken': '',
            'listId': ''
        };
        dispatch(updateActiveCampaignIntegration(siteData.id, data, handleDeactivateEvent, 'Deactivate Active Campaign Integration', false));
    }

    const handleAccountSelect = (e) => {
        setSelectedAccount(e);
    }

    const submitData = (formData) => {
        if (isModalTypeActiveCampaign) {
            if (currentACStep == 0) {
                const data = {
                    'siteId': site?.id || '',
                    'url': formData?.url || '',
                    'apiToken': formData?.key || '',
                    'listId': 1
                };
                dispatch(updateActiveCampaignIntegration(site.id, data, handleEvent, 'Upadate Active Campaign Integration', getACAccountList, true));
            } else if(currentACStep == 1) {
                const data = {
                    'listId': formData?.activeCampaignAccount,
                }
                dispatch(updateActiveCampaignAccount({ id: site.id, data, callback: handleEvent, eventName: 'Upadate Active Campaign Account' }))
            }
        }
    }

    const encryptACKey = (apiKey) => {
        let encryptedKey = '';
        if (apiKey && apiKey.length) {
            encryptedKey = apiKey.slice(0,5) + '**********' + apiKey.slice(-5);
        }
        return encryptedKey;
    }

    const ACTIVE_CAMPAIGN_STEP_0 = () => {
        return (
            <>
                {
                    ACTIVE_CAMPAIGN_STEPS.map(step => {
                        return (
                            <div className='campaign-steps' key={ step }>
                                <p>{step}</p>
                            </div>
                        );
                    })
                }
                <div className='campaign-steps'>
                    <label>URL</label>
                    <Field
                        name={ 'url' }
                        component={ renderField }
                        placeholder='username.api-us1.com'
                    />
                </div>
                <div className='campaign-steps'>
                    <label>Key</label>
                    <Field
                        name={ 'key' }
                        component={ renderField }
                        placeholder='4454471f73ccb734aa893bd6c7d9e657346eb'
                    />
                </div>
                <div className="paste-code-modal-footer pt-2">
                    <a className="btn btn-outline-secondary" onClick={ () => handleCloseModal() }>cancel</a>
                    <ButtonLoader
                        button={ <Button type='submit' className="btn btn-primary" variant="primary" >confirm</Button> }
                        loadingText={ 'Connecting...' }
                        loading={ activeCampLoading }
                    />
                </div>
            </>
        );
    }

    const ACTIVE_CAMPAIGN_STEP_1 = () => {
        return (
            <>
                <div className="campaign-links">
                    <a className="mr-3" onClick={ () => setCurrentACStep(0) }>Edit</a>
                    <a className="" onClick={ () => handleACDeactivate() }>Deactivate</a>
                </div>
                <div className='campaign-steps'>
                    <label>URL</label>
                    <p>{ siteData?.activeCampaignUrl }</p>
                </div>
                <div className='campaign-steps'>
                    <label>Key</label>
                    <p>{ encryptACKey(siteData?.activeCampaignToken) }</p>
                </div>
                <div>
                    <label>Select Active Campaign Account</label>
                    <Field
                        name="activeCampaignAccount"
                        selectedOption={ selectedAccount || null }
                        options={ acAccountList|| []  }
                        handleChange = { handleAccountSelect }
                        component={ renderSelectField }
                        placeholder='Select...'
                        isSearchable={ false }
                        noOptionsMessage={ 'Please Select an Account' }
                    />
                </div>
                <div className="paste-code-modal-footer pt-2">
                    <a className="btn btn-outline-secondary" onClick={ () => handleCloseModal() }>cancel</a>
                    <ButtonLoader
                        button={ <Button type='submit' className="btn btn-primary" variant="primary" >confirm</Button> }
                        loadingText={ 'Connecting...' }
                        // loading={ activeCampLoading }
                    />
                </div>
            </>
        );
    }

    const RenderActiveCampaign = () =>{
        return (
            <>
                { currentACStep === 0 && <ACTIVE_CAMPAIGN_STEP_0 /> }
                { currentACStep === 1 && <ACTIVE_CAMPAIGN_STEP_1 /> }
            </>
        )
    }

    const modalBody = {
        'ACTIVE_CAMPAIGN': <RenderActiveCampaign />,
    };

    const renderModalView = () => {
        return <div className={ modalType }>
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>
                        {modalTitles[ modalType ]}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={ handleSubmit(submitData) }>
                    <div className='connect-campaign-wrapper'>
                        {modalBody[ modalType ]}
                    </div>
                </Form>
            </Modal.Body>
        </div>
    }

    const getImage =  {
        'ACTIVE_CAMPAIGN': ActiveCampaignImg,
    }

    const renderFields = (item, index) => {
        return(
            <Form.Group key={ index } controlId="formBasicEmail">
                <div className={ 'analytics-site-btn' } onClick={ (event) => handleModal(event,item) }>
                    <img src={ getImage[ item ] } />
                </div>
                <span className='text-green'>
                    { connectionStatus[ item ] && 'connected' }
                </span>
            </Form.Group>
        )
    }
    return(
        <section className="dashboard-body mt-3">
            <div className='analytics-site-panel'>
                <div className="dashboard-header">
                    <div className="dashboard-title">
                        <h5>Integrations</h5>
                    </div>
                </div>
                <div className="analytics-site-btns">
                    { EMAIL_MARKETING_INTEGRATIONS.map((item, index) => {
                        return renderFields(item, index);
                    })}

                </div>
                <Modal show={ open } onHide={ handleCloseModal } className= 'new-modal custom-color-modal paste-code-modal'>
                    {renderModalView()}
                </Modal>
            </div>
        </section>
    )
}

EmailIntegrations.propTypes = {
    handleSubmit: PropTypes.func,
    site: PropTypes.object,
    initialize: PropTypes.func,
    currentACStep: PropTypes.number,
    setCurrentACStep: PropTypes.func,
}

export default reduxForm({
    form: 'emailIntegrationForm',
    destroyOnUnmount: false,
    validate,
})(EmailIntegrations);
