/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ConfirmAlert from 'components/core/confirm-alert';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from 'react-js-pagination';
import { absoluteValue, isDisabledSite, getSite } from 'utils/helpers';
import ListSkelton from 'components/core/loader/list-skelton';
import _ from 'lodash';
import {
    Form,
    Button,
    Accordion,
    Card,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {
    getDraftPages,
    getPublishedPages,
    getPageById,
    deletePage,
    callPublish,
} from 'middleware/page';
import { ROUTES } from 'constants/appRoutes';
import { PAGE_STATUS, SHARING_URLS } from 'constants/app';
import { getDynamicURL } from 'services/api';
import {
    OpenArrow,
    EditBlogListIcon,
    CloneBlogListIcon,
    ShareBlogListIcon,
    ViewsBlogListIcon,
    DeleteBlogListIcon,
    SortBlogIcon,
    DateBlogListIcon,
    ChevronRight,
    MobileHomeIcon,
    MobileSAddNewIcon,
    MobileSearchIcon,
    BlogSortIcon,
} from 'utils/svg';
import { isSuperAdmin } from 'utils/helpers';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getSiteById, setHome } from 'middleware/assessments';
import homeIcon from '../../../images/homeIcon.svg';

const searchInput = React.createRef();
const PageList = () => {
    const limit = 6;
    const dispatch = useDispatch();
    const history = useHistory();
    const [ sortToggle, setSortToggle ] = useState(false);
    const [ activePagePublish, setActivePagePublish ] = useState(0);
    const [ activePages, setActivePages ] = useState([]);
    const [ copySuccess, setCopySuccess ] = useState('');
    const [ activePageDraft, setActivePageDraft ] = useState(0);
    const publishPages = useSelector((state) => state.page.publishPages);
    const loading = useSelector((state) => state.page.loading);
    const publishMetaData = useSelector((state) => state.page.publishMetaData);
    const draftPages = useSelector((state) => state.page.draftPages);
    const draftMetaData = useSelector((state) => state.page.draftMetaData);
    const [ sortPublish, setSortPublish ] = useState({
        title: '',
        createdAt: 'desc',
    });
    const [ sortDraft, setSortDraft ] = useState({ title: '', createdAt: 'desc' });
    const data = useSelector((state) => state.user.sessionData?.data?.data);
    const site = getSite();
    const isDisabled = isDisabledSite(data?.user?.status);
    const [ isSettingHome, setSettingHome ] = useState(false);
    const [ pageClicked, setPageClicked ] = useState(null);

    useEffect(() => {
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/page',
        });
        //dispatch(allPagesCount())
        dispatch(getDraftPages(`page=${ activePageDraft }&size=${ limit }`));
        dispatch(getPublishedPages(`page=${ activePagePublish }&size=${ limit }`));
    }, [ dispatch ]);

    // const handleFilter = () => {
    //     // const filterData = `_where[title_contains]=${ filter }&_limit=${ limit }`
    //     // dispatch(getDraftPages(filterData));
    //     // dispatch(getPublishedPages(filterData));
    //     // setActivePagePublish(1)
    //     // setActivePageDraft(1)

    // }

    const handleEdit = (event, page) => {
        event.preventDefault();
        const slug = page.home ? page.slugHome : page.slug;
        let route = getDynamicURL(!page?.defaultTemplate ? ROUTES.EDIT_GRAPES : ROUTES.EDIT_PAGE, { id: slug });

        route = `${ route }?isHome=${ page.home }`;

        history.push(route);
    };
    const handleClone = (event, page) => {
        event.preventDefault();
        dispatch(getPageById(page?.home ? page.slugHome : page.slug, null, `?isHome=${ page?.home ? true : false }`));
        history.push(ROUTES.PAGE);
    };

    const handlePageChangePublish = (pageNumber) => {
        const args = `page=${ pageNumber - 1 }&size=${ limit }`;
        dispatch(getPublishedPages(args));
        setActivePagePublish(pageNumber);
    };
    const handlePageChangeDraft = (pageNumber) => {
        const startWith = (pageNumber - 1) * limit;
        const args = `_start=${ startWith }&_limit=${ limit }`;
        dispatch(getDraftPages(args));
        setActivePageDraft(pageNumber - 1);
    };

    const handleDelete = (event, page) => {
        if(page.defaultContactUs || page.defaultHome || page.home) return
        event.preventDefault();
        const countPublish =
      page.status == PAGE_STATUS.PUBLISHED && publishPages.length === 1
          ? activePagePublish - 1
          : activePagePublish;
        const publishArgs = `page=${ absoluteValue(countPublish) }&size=${ limit }`;
        const countDraft =
      page.status == PAGE_STATUS.DRAFT && draftPages.length === 1
          ? activePageDraft - 1
          : activePageDraft;
        const draftArgs = `page=${ absoluteValue(countDraft) }&size=${ limit }`;

        confirmAlert({
            // eslint-disable-next-line react/display-name
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlert
                        key={ 'box' }
                        onClose={ onClose }
                        handleAction={ () =>
                            dispatch(deletePage(page.id, draftArgs, publishArgs))
                        }
                        subHeading="By confirming this page & information will be deleted forever."
                    />
                );
            },
        });
    };

    const handlePublish = (event, page) => {
        event.preventDefault();
        const publishPage =
      !event.target.checked && publishPages.length === 1
          ? activePagePublish - 1
          : activePagePublish;
        const publishArgs = `page=${ absoluteValue(publishPage) }&size=${ limit }`;
        const draftPage =
      event.target.checked && draftPages.length === 1
          ? activePageDraft - 1
          : activePageDraft;
        const draftArgs = `page=${ absoluteValue(draftPage) }&size=${ limit }`;
        dispatch(callPublish(page, event.target.checked, publishArgs, draftArgs));
    };

    const redirectToPage = (event, page) => {
        event.preventDefault();
        const domain = site?.customDomain || site?.domain;
        const url = isSuperAdmin()
            ? `https://${ domain }/page/${ page?.slug }`
            : `https://${ domain }/${ page?.slug }`;
        window.open(url, '_blank');
    };

    const sortData = (type, pageType) => {
        const obj = {};
        if (PAGE_STATUS.PUBLISHED === pageType) {
            obj[ type ] =
        sortPublish && sortPublish[ type ] === 'asc' ? 'desc' : 'asc' || 'desc';
            setSortPublish(obj);
        } else {
            obj[ type ] =
        sortDraft && sortDraft[ type ] === 'asc' ? 'desc' : 'asc' || 'desc';
            setSortDraft(obj);
        }
        const args = `page=${
            pageType === PAGE_STATUS.PUBLISHED ? activePagePublish : activePageDraft
        }&size=${ limit }&sort=${ type }&order=${ obj[ type ]?.toUpperCase() }`;
        pageType === PAGE_STATUS.PUBLISHED
            ? dispatch(getPublishedPages(args))
            : dispatch(getDraftPages(args));
    };
    const sortDataMobile = (type, order) => {
        sortPublish[ type ] = order;
        sortDraft[ type ] = order;
        setSortPublish(sortPublish);
        setSortDraft(sortDraft);
        const publishArgs = `page=${ activePagePublish }&size=${ limit }&sort=${ type }&order=${ order?.toUpperCase() }`;
        const draftArgs = `page=${ activePageDraft }&size=${ limit }&sort=${ type }&order=${ order?.toUpperCase() }`;
        dispatch(getPublishedPages(publishArgs));
        dispatch(getDraftPages(draftArgs));
        setSortToggle(!sortToggle);
    };

    const copyToClipBoard = (event, page) => {
        event.preventDefault();
        const domain = site?.customDomain || site?.domain;
        navigator.clipboard.writeText(
            isSuperAdmin()
                ? `https://${ domain }/page/${ page?.slug }`
                : `https://${ domain }/${ page?.slug }`
        );
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 1000);
    };
    const sharePage = (type, event, page) => {
        event.preventDefault();
        const domain = site?.customDomain || site?.domain;
        const url = isSuperAdmin()
            ? `https://${ domain }/page/${ page?.slug }`
            : `https://${ domain }/${ page?.slug }`;
        window.open(`${ SHARING_URLS[ type ] } ${ url }`);
    };

    const toggleActivePages = (id) => {
        const pagesData = [ ...activePages ];
        if (pagesData.includes(id)) {
            delete pagesData[ pagesData.indexOf(id) ];
        } else {
            pagesData.push(id);
        }
        setActivePages(pagesData);
    };

    const toggleSearch = () => {
        searchInput?.current?.focus();
    };

    const handleAfterSettingHome = () => {
        setPageClicked(null);
        setSettingHome(false);
        // dispatch(getPublishedPages(`page=${ activePagePublish }&size=${ limit }`));
        dispatch(getSiteById(getSite()?.id))
        dispatch(getDraftPages(`page=${ activePageDraft }&size=${ limit }`));
        dispatch(getPublishedPages(`page=${ activePagePublish }&size=${ limit }`));
    };

    const handleSetHome = (event, pageId) => {
        event.preventDefault();
        setPageClicked(pageId);
        setSettingHome(true);
        dispatch(setHome(pageId, handleAfterSettingHome));
    };
    return (
        <main className="dashboard-data blog-dashboard">
            <section className="dashboard-body">
                <div className="dashboard-header">
                    <div className="dashboard-title">
                        <h1>Pages</h1>
                        <div className="dashboard-body-actions">
                            <Link
                                to={ ROUTES.PAGE }
                                className={ `btn btn-primary add-new-blog ${
                                    isDisabled ? ' disabled-link' : ''
                                }` }
                            >
                                Add New+
                            </Link>
                            <div className="sort-blog-wrap">
                                <Link
                                    to="#"
                                    onClick={ () => setSortToggle(!sortToggle) }
                                    className="btn btn-primary sort-blogs"
                                >
                                    <SortBlogIcon />
                                    Sort By
                                </Link>
                                {sortToggle && (
                                    <ul className="wrap-drop drop">
                                        <li onClick={ () => sortDataMobile('title', 'asc') }>
                                            <a>Title -asc</a>
                                        </li>
                                        <li onClick={ () => sortDataMobile('createdAt', 'asc') }>
                                            <a>Created At -asc</a>
                                        </li>
                                        <li onClick={ () => sortDataMobile('title', 'asc') }>
                                            <a>Title -desc</a>
                                        </li>
                                        <li
                                            className={
                                                sortPublish[ 'createdAt' ] === 'desc' ? 'active' : ''
                                            }
                                            onClick={ () => sortDataMobile('createdAt', 'desc') }
                                        >
                                            <a>Created At -desc</a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {/*<div className="dashboard-actions">
                        <Form className="search-form">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control ref={ searchInput } onChange={ () => {} }  className="form-control" placeholder="Search" />
                            </Form.Group>
                            <Button onClick={ handleFilter } className="btn-search" type="button">
                                <img src={ searchIcon } alt={ 'searchIcon' } />
                            </Button>
                        </Form>
                    </div>*/}
                </div>
                <div>{copySuccess}</div>
                <div className="blog-custom-list-table">
                    <div className="blog-custom-list">
                        <div className="blog-list-header">
                            <div className="blog-list-column blog-list-live">Live</div>
                            <div
                                className={ 'blog-list-column blog-list-title headerSort' }
                                onClick={ () => sortData('title', PAGE_STATUS.PUBLISHED) }
                            >
                                <span className="sort-column">Title</span>
                                {!_.isEmpty(publishPages) && (
                                    <span className="sort-icon">
                                        <span
                                            className={ `sort-up ${
                                                sortPublish[ 'title' ] === 'asc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                        <span
                                            className={ `sort-down ${
                                                sortPublish[ 'title' ] === 'desc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                    </span>
                                )}
                            </div>
                            <div
                                className={ 'blog-list-column blog-list-date headerSort' }
                                onClick={ () => sortData('createdAt', PAGE_STATUS.PUBLISHED) }
                            >
                                <span className="sort-column">Date Created</span>
                                {!_.isEmpty(publishPages) && (
                                    <span className="sort-icon">
                                        <span
                                            className={ `sort-up ${
                                                sortPublish[ 'createdAt' ] === 'asc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                        <span
                                            className={ `sort-down ${
                                                sortPublish[ 'createdAt' ] === 'desc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                    </span>
                                )}
                            </div>
                            <div className="blog-list-column blog-list-views">Views</div>
                            <div className="blog-list-column blog-list-delete">Delete</div>
                        </div>
                        <ListSkelton
                            numRow={ 3 }
                            numCol={ 5 }
                            loading={ loading && publishPages?.length === 0 }
                        />
                        {publishPages?.length ? (
                            <div className="blog-custom-list-table-data">
                                {publishPages?.map((page, index) => (
                                    <div
                                        className={
                                            (activePages?.includes(page?.id) ? 'active ' : '') +
                      'blog-list-table blog-list-header' +
                      (!isDisabled ? ' blog-list-publish' : '')
                                        }
                                        key={ page?.slug }
                                    >
                                        <div
                                            className="blog-list-column blog-list-live"
                                            key={ index }
                                        >
                                            <Form.Check
                                                type="switch"
                                                id={ 'custom-switch-' + page.id }
                                                label=""
                                                onChange={ (e) => handlePublish(e, page) }
                                                checked={ page.status === PAGE_STATUS.PUBLISHED }
                                                disabled={ isDisabled || page.home || page.defaultHome }
                                            />
                                        </div>
                                        <div className="blog-list-column blog-list-title">
                                            <span className="table-post-title">
                                                {page?.home ? (
                                                    <img
                                                        width={ 20 }
                                                        height={ 20 }
                                                        style={ { marginBottom: 5 } }
                                                        src={ homeIcon }
                                                    />
                                                ) : null}
                        &nbsp;
                                                {page?.title}
                                                {!isDisabled && (
                                                    <a onClick={ (event) => redirectToPage(event, page) }>
                                                        View
                                                    </a>
                                                )}
                                                <a
                                                    onClick={ () => toggleActivePages(page?.id) }
                                                    className="toggle-blog-detail"
                                                >
                                                    <ChevronRight />
                                                </a>
                                            </span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>
                                                {' '}
                                                {page.createdAt && moment(page.createdAt).format('L')}
                                            </span>
                                        </div>
                                        <div className="blog-list-column blog-list-views">
                                            <ViewsBlogListIcon />
                                            <span>{page.views}</span>
                                        </div>
                                        {/*<div className="page-list-column page-list-comments">
                                    <CommentsPageListIcon />
                                    <span>32</span>
                                </div>*/}
                                        <div className="blog-list-column blog-list-actions  blog-list-delete">
                                            <div className="hover-actions">
                                                {!page.defaultContactUs && !page.defaultHome && (
                                                    <>
                                                        <a
                                                            onClick={ (e) => handleEdit(e, page) }
                                                            className="table-action-btns"
                                                            href="/#"
                                                        >
                                                            <EditBlogListIcon />
                                                            <span>Edit</span>
                                                        </a>

                                                        <a
                                                            onClick={ (e) => handleClone(e, page) }
                                                            className="table-action-btns active"
                                                            href="/#"
                                                        >
                                                            <CloneBlogListIcon />
                                                            <span>Clone</span>
                                                        </a>
                                                    </>
                                                )}
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        as={ Link }
                                                        className="table-action-btns"
                                                    >
                                                        <ShareBlogListIcon />
                                                        <span>Share</span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={ (e) => copyToClipBoard(e, page) }
                                                        >
                                                            Copy to clipboard
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={ (e) => sharePage('FACEBOOK', e, page) }
                                                        >
                                                            Share on Facebook
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={ (e) => sharePage('TWITTER', e, page) }
                                                        >
                                                            Share on Twitter
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href={ SHARING_URLS.EMAIL(
                                                                page?.title,
                                                                isSuperAdmin()
                                                                    ? 'https://' +
                                      (site?.customDomain || site?.domain) +
                                      '/page' +
                                      page.slug
                                                                    : 'https://' +
                                      (site?.customDomain || site?.domain) +
                                      '/' +
                                      page.slug
                                                            ) }
                                                        >
                                                            Share via Email
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {page?.slug && (
                                                    <a
                                                        onClick={
                                                            isSettingHome
                                                                ? () => {}
                                                                : (event) => handleSetHome(event, page?.id)
                                                        }
                                                        className="table-action-btns active"
                                                        href="/#"
                                                    >
                                                        {isSettingHome && pageClicked === page.id ? (
                                                            <Spinner
                                                                animation="border"
                                                                size={ 'sm' }
                                                                role="status"
                                                            />
                                                        ) : (
                                                            <span>Set Home</span>
                                                        )}
                                                    </a>
                                                )}
                                            </div>
                                            <a
                                                onClick={ (e) => handleDelete(e, page) }
                                                className={
                                                    'table-action-btns table-action-btns-delete' +
                          (isDisabled || page.defaultContactUs || page.defaultHome || page.home ? ' disabled-link' : '')
                                                }
                                                href="/#"
                                            >
                                                <DeleteBlogListIcon />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                                {limit < publishMetaData?.totalElements && (
                                    <div className="blogs-pagination">
                                        <Pagination
                                            activePage={
                                                activePagePublish == 0 ? 1 : activePagePublish
                                            }
                                            itemsCountPerPage={ limit }
                                            totalItemsCount={ publishMetaData?.totalElements }
                                            pageRangeDisplayed={ 5 }
                                            onChange={ handlePageChangePublish }
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            !loading && (
                                <div className="blog-list-table blog-list-header no-post">
                                    No Pages available
                                </div>
                            )
                        )}
                    </div>
                </div>

                <div className="draft-posts">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ Button } variant="link" eventKey="0">
                                    Drafts
                                    <OpenArrow />
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="blog-custom-list-table">
                                        <div className="blog-custom-list">
                                            <div className="blog-list-header">
                                                <div className="blog-list-column blog-list-live">
                                                    Live
                                                </div>
                                                <div
                                                    className={
                                                        'blog-list-column blog-list-title headerSort'
                                                    }
                                                    onClick={ () => sortData('title', PAGE_STATUS.DRAFT) }
                                                >
                                                    <span className="sort-column">Title</span>
                                                    {!_.isEmpty(draftPages) && (
                                                        <span className="sort-icon">
                                                            <span
                                                                className={ `sort-up ${
                                                                    sortDraft[ 'title' ] === 'asc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                            <span
                                                                className={ `sort-down ${
                                                                    sortDraft[ 'title' ] === 'desc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        'blog-list-column blog-list-date headerSort'
                                                    }
                                                    onClick={ () =>
                                                        sortData('createdAt', PAGE_STATUS.DRAFT)
                                                    }
                                                >
                                                    <span className="sort-column">Date Created</span>
                                                    {!_.isEmpty(draftPages) && (
                                                        <span className="sort-icon">
                                                            <span
                                                                className={ `sort-up ${
                                                                    sortDraft[ 'createdAt' ] === 'asc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                            <span
                                                                className={ `sort-down ${
                                                                    sortDraft[ 'createdAt' ] === 'desc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="blog-list-column blog-list-views">
                                                    Views
                                                </div>
                                                {/*<div className="page-list-column page-list-comments">
                                                    Comments
                            </div>*/}
                                                <div className="blog-list-column blog-list-delete">
                                                    Delete
                                                </div>
                                            </div>
                                            <ListSkelton
                                                numRow={ 3 }
                                                numCol={ 5 }
                                                loading={ loading && draftPages?.length == 0 }
                                            />
                                            {draftPages?.length ? (
                                                <div className="blog-custom-list-table-data">
                                                    {draftPages?.map((page, index) => (
                                                        <div
                                                            className={
                                                                (activePages?.includes(page?.id)
                                                                    ? 'active '
                                                                    : '') +
                                'blog-list-table blog-list-header' +
                                (!isDisabled ? ' blog-list-publish' : '')
                                                            }
                                                            key={ page?.slug }
                                                        >
                                                            <div
                                                                className="blog-list-column blog-list-live"
                                                                key={ index }
                                                            >
                                                                <Form.Check
                                                                    type="switch"
                                                                    id={ 'custom-switch-' + page.id }
                                                                    label=""
                                                                    onChange={ (e) => handlePublish(e, page) }
                                                                    checked={ page.status !== 'DRAFT' }
                                                                    disabled={ isDisabled }
                                                                />
                                                            </div>
                                                            <div className="blog-list-column blog-list-title">
                                                                <span className="table-post-title">
                                                                    {page?.title}
                                                                    {/* <a onClick={ (event) => redirectToPage(event, page) }>View</a> */}
                                                                    <a
                                                                        onClick={ () => toggleActivePages(page?.id) }
                                                                        className="toggle-blog-detail"
                                                                    >
                                                                        <ChevronRight />
                                                                    </a>
                                                                </span>
                                                            </div>
                                                            <div className="blog-list-column blog-list-date">
                                                                <DateBlogListIcon />
                                                                <span>
                                                                    {' '}
                                                                    {page.createdAt &&
                                    moment(page.createdAt).format('L')}
                                                                </span>
                                                            </div>
                                                            <div className="blog-list-column blog-list-views">
                                                                <ViewsBlogListIcon />
                                                                <span>{page.views}</span>
                                                            </div>
                                                            {/*<div className="page-list-column page-list-comments">
                                                        <CommentsPageListIcon />
                                                        <span></span>
                        </div>*/}
                                                            <div className="blog-list-column blog-list-actions  blog-list-delete">
                                                                <div className="hover-actions">
                                                                    <a
                                                                        onClick={ (e) => handleEdit(e, page) }
                                                                        className="table-action-btns"
                                                                        href="/#"
                                                                    >
                                                                        <EditBlogListIcon />
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a
                                                                        onClick={ (e) => handleClone(e, page) }
                                                                        className="table-action-btns"
                                                                        href="/#"
                                                                    >
                                                                        <CloneBlogListIcon />
                                                                        <span>Clone</span>
                                                                    </a>
                                                                    {/* <a className="table-action-btns" href="/#" onClick={ (e) => copyToClipBoard(e, page) }>
                                                                <SharePageListIcon />
                                                                <span>Share</span>
                                                            </a> */}
                                                                </div>
                                                                <a
                                                                    onClick={ (e) => handleDelete(e, page) }
                                                                    className={
                                                                        'table-action-btns table-action-btns-delete' +
                                    (isDisabled ? ' disabled-link' : '')
                                                                    }
                                                                    href="/#"
                                                                >
                                                                    <DeleteBlogListIcon />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {limit < draftMetaData?.count && (
                                                        <Pagination
                                                            activePage={
                                                                activePageDraft == 0 ? 1 : activePageDraft
                                                            }
                                                            itemsCountPerPage={ limit }
                                                            totalItemsCount={ draftMetaData?.count }
                                                            pageRangeDisplayed={ 5 }
                                                            onChange={ handlePageChangeDraft }
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                !loading && (
                                                    <div className="blog-list-table blog-list-header no-post">
                                                        No Drafts available
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
                <div className="blog-mobile-action">
                    <ul>
                        <li>
                            <Link to={ ROUTES.DASHBOARD }>
                                <MobileHomeIcon />
                            </Link>
                        </li>
                        <li>
                            <Link to={ ROUTES.BLOG }>
                                <MobileSAddNewIcon />
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ toggleSearch }>
                                <MobileSearchIcon />
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        </main>
    );
};

export default PageList;
