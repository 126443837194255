import React, { useCallback, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { registrationUser } from '../../middleware/auth'
import { togglePassword,isProductonEnv, mixpanelCommonData } from '../../utils/helpers'
import { MixpanelContext } from 'utils/tracking';

import ButtonLoader from 'components/core/loader/button-loader'
import _ from 'lodash'
import
{
    Row,
    Col,
    Form,
    Button
} from 'react-bootstrap';
import { renderFieldChangeWG, renderField } from '../../utils/formUtils'
import { registerValidate as validate } from '../../utils/validates'
import { reduxForm , stopAsyncValidation } from 'redux-form';
import { Field } from 'redux-form';
import googleLogin from '../../public/images/google-login.svg';
import facebookLogin from '../../public/images/facebook-login.svg';
import { useDispatch, useSelector } from 'react-redux'
import { assessmentIntialValues, headerLinksTemplate } from '../../utils/helpers'
import { asyncWildCardEmail }  from 'utils/asyncValidate';
import congratesAnimation from '../../images/congrates-animation.gif';
import WebTemplatesPreview from 'components/assessment/WebTemplatePreview'
import { EVENTS } from 'constants/app'
import { getItemParse } from 'utils/cache'
const RegisterPage = (props) => {
    const mixpanel = useContext(MixpanelContext)
    const dispatch = useDispatch();
    const [ rLoading, setLoading ] = useState(isProductonEnv())
    const assessmentForm = useSelector((state)=>state.form.assessmentForm)
    const loading = useSelector((state)=> state.user.loading || state.assessment.loading)
    const assessmentData = getItemParse('assessmentForm')
    const templateView = {
        header: assessmentData?.header,
        colors: assessmentData?.colors || [],
        logoUrl: assessmentData?.logoUrl,
        logoText: assessmentData?.websiteName,
        coverImage: assessmentData?.coverImage,
        readOnly: true,
        headerLinks: headerLinksTemplate(),
    }
    const { handleSubmit } = props

    const handleEvents = (data) => {
        mixpanel.track(EVENTS.completeRegistration,mixpanelCommonData(data))
    }
    const submitData = (data) => {
        const assessmentFormData = assessmentIntialValues() || assessmentForm?.values;
        data[ 'email' ] = data?.email?.toLowerCase()
        dispatch(registrationUser(data, assessmentFormData, handleEvents ))
    }
    const handleChange = (value) => {
        asyncWildCardEmail(value).then((result) => {
            !result ? dispatch(stopAsyncValidation('registerForm', { email: 'Proxy email address not allowed.' })) : setLoading(false)
        })

    }
    const asyncValidateFunc = _.debounce(handleChange, 800);
    const asyncChangeCallback = useCallback(asyncValidateFunc, []);
    const handleEmailChange = (value) => {
        if(value && isProductonEnv()){
            setLoading(true)
            asyncChangeCallback(value)
        }
    }
    const redirectTo = (path) =>{
        window.location.href = path
    }
    const getHostName = () => {
        return `${ window.location.origin }/oauth2/redirect`
    }

    return(
        <section className="signup-section main-section">
            <div className="signup-container">
                <Row className="align-items-center">
                    <Col className="content-side col-6 sigunp-content-side ">
                        <div className="signup-preview-wrap">
                            <div className="content-side-inner">
                                <h4>Congrats! You&apos;re almost there... </h4>
                                <ul>
                                    <li>Start Writing Your First Blog Post In Seconds</li>
                                    <li>No Credit Card Required  </li>
                                    <li>14-Day Free Trial</li>
                                </ul>
                            </div>
                            <div className='wizrd-blog-preview wizrd-inner-preview'>
                                <div className="congrats-animation">
                                    <img src={ congratesAnimation } className="congrats-animation-img1" />
                                    <img src={ congratesAnimation } className="congrats-animation-img2"  />
                                    <img src={ congratesAnimation } className="congrats-animation-img3" />
                                </div>
                                <WebTemplatesPreview data={ templateView } />
                            </div>

                        </div>
                    </Col>
                    <Col className="col-6 form-side sigunp-form-side">
                        <div className="small-wrapper">
                            <Row className="login-forms signup-forms">
                                <Col className="col-12">
                                    <h1 className="section-heading">
                                        Register
                                    </h1>
                                    <p className="heading-detail">
                                        Already have an account?  <Link to="/login">Sign In</Link>
                                    </p>
                                    <div className="social-logins">
                                        <div className="social-btn">
                                            <button onClick={ () => redirectTo(`${ process.env.REACT_APP_API_URL }/v1/oauth2/authorize/google?redirectTo=${ getHostName() }&origin=${ process.env.REACT_APP_WEBSITE_TYPE }`) } className="btn btn-primary btn-google">
                                                <img src={ googleLogin } alt="Google" />
                                                <a href='#'>Sign up with Google</a>
                                            </button>
                                        </div>
                                        <div className="social-btn">
                                            <button onClick={ () => redirectTo(`${ process.env.REACT_APP_API_URL }/v1/oauth2/authorize/facebook?redirectTo=${ getHostName() }&origin=${ process.env.REACT_APP_WEBSITE_TYPE }`) }  className="btn btn-primary btn-facebook">
                                                <img src={ facebookLogin } alt="facebook" />
                                                <a href='#'>Sign up with Facebook</a>
                                            </button>
                                        </div>
                                        <div className="social-btn">
                                            <p className="login-or"><span>Or</span></p>
                                        </div>
                                    </div>
                                    <Form className="form" onSubmit={ handleSubmit(submitData) }>
                                        <Field
                                            name="email"
                                            label="Email:"
                                            type="text"
                                            handleChange={ handleEmailChange }
                                            component={ renderFieldChangeWG }
                                            changeType='normal'
                                            maxLength="150"

                                            placeholder='Enter your email'
                                        />

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Password:</Form.Label>
                                            <div className="password-wrap">
                                                <Field
                                                    name="password"
                                                    label="password"
                                                    type="password"
                                                    component={ renderField }
                                                    maxLength="150"
                                                    placeholder='Enter your password'
                                                />

                                                <span onClick={ togglePassword } className="password-swap">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.12 14.12C13.8454 14.4147 13.5141 14.6511 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1961C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4858 9.58525 10.1546 9.88 9.87999M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68189 3.96914 7.6566 6.06 6.05999L17.94 17.94ZM9.9 4.23999C10.5883 4.07887 11.2931 3.99833 12 3.99999C19 3.99999 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.23999Z" stroke="#757575" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M1 1L23 23" stroke="#757575" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </span>
                                            </div>
                                        </Form.Group>
                                        { rLoading ?
                                            <Button className="btn btn-primary" type="button" disabled={ true }>Sign Up</Button> : <ButtonLoader
                                                button={  <Button className="btn btn-primary" type="submit">
                                                    Sign Up
                                                </Button> }
                                                loadingText={ 'Sign Up' }
                                                loading={ loading }
                                            />
                                        }

                                    </Form>

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </section>
    )}

RegisterPage.propTypes = {
    handleSubmit: PropTypes.func
};

export default reduxForm({
    form: 'registerForm',
    validate,
    asyncWildCardEmail,
    asyncChangeFields: [ 'email' ]
})(RegisterPage);
