import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';
import ButtonLoader from 'components/core/loader/button-loader';
import UploadImageModal from 'components/assessment/shared/UploadImageModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUnsplash } from 'middleware/assessments';
import { change as reduxChange } from 'redux-form';
import htmlToFormattedText from 'html-to-formatted-text';
// import { headerLinksTemplate } from 'utils/helpers';
import blogBanner from 'images/blog-banner.png';
import ColorPicker from 'components/core/color-picker';
// import WebTemplatesPreview from 'components/assessment/WebTemplatePreview';
import '../../assessment/style.sass';
// import ThemeTemplate from 'components/assessment/ThemeTemplate';
import { ContentEditor } from './content-editor';
import ModernView from './modern-view';
import StylishView from './stylish-view';
import ClassicView from './classic-view';
import { trimStringLength } from 'utils/helpers';

const templateOption = {
    Classic: 'CLASSIC',
    Modern: 'MODERN',
    Stylish: 'STYLISH',
};

const backgroundOption = {
    Color: 'COLOR',
    Image: 'IMAGE',
};

const getCurrentTemplate = (template, data) => {
    switch (template) {
    case 'CLASSIC':
        return <ClassicView data={ data } />;
    case 'MODERN':
        return <ModernView data={ data } />;
    case 'STYLISH':
        return <StylishView data={ data } />;
    default:
        return <ClassicView data={ data } />;
    }
};

const HomeTheme = (props) => {
    const { formValues, previewFile, loading, onClose } = props;
    const isIpad = window.outerWidth <= 1024;

    const [ template, setSelectTemplate ] = useState(
    // formValues?.templateName   ?? 'CLASSIC'
        formValues?.templateName === 'LISTING'
            ? 'CLASSIC'
            : formValues?.templateName
    );

    // console.log('template', template);
    const [ htmlData, setHtmlData ] = useState(
        formValues?.header?.heading
            ?.replace(/(\d+)\s*em/g, '$1pt')
            ?.replace(/<br\s*\/>/g, '<p></p>') ?? ''
    );
    const [ optIn, setOptIn ] = useState(formValues?.optIn ?? true);
    const [ subVisible, setSubButtonVisible ] = useState(
        formValues?.subscriptionButton ?? false
    );
    const [ overlay, setOverlay ] = useState(formValues?.headerOverlay ?? true);
    const [ backgroundType, setBackgroundType ] = useState(
        formValues?.backgroundType ?? 'COLOR'
    );
    const [ isHeaderTransparent, setHeaderTransparent ] = useState(
        formValues?.topMenuTransparent ?? false
    );
    const [ hsl, setHsl ] = useState(null);
    const [ hsv, setHsv ] = useState(null);
    const [ isEdit, setIsEdit ] = useState(false);
    const [ activeCustomColor, setActiveCustomColor ] = useState(null);
    const [ seoTitle, setTitle ] = useState(formValues.header?.seoTitle ?? '');
    const [ seoDescription, setDescription ] = useState(
        formValues.header?.seoDescription ?? ''
    );

    const unsplashImages = useSelector(
        (state) => state.assessment.unsplashImages
    );

    const [ colors, setColors ] = useState(
        (formValues?.colors && JSON.parse(formValues.colors)) || {}
    );
    // const [colors,setColors] = useState({
    //     'top-menu' : '',
    //     'top-menu-font' : '',
    //     'button' : '',
    //     'button-font' : '',
    //     'home-background' : '',
    //     'home-background-font' : '',
    //     'background' : '',
    //     'background-font' : '',
    //     'header-color' : '',
    //     'box-shadow' : '',
    //     'header-background' : '',
    //     'name' : ''
    // })

    const data = {
        headlineColor: colors?.[ 'header-color' ] ?? '',
        headerTextColor: colors?.[ 'top-menu-font' ] ?? '',
        headerBgColor: colors?.[ 'top-menu' ] ?? '',
        backgroundType: backgroundType,
        bannerBgColor: colors?.[ 'header-background' ] ?? '',
        buttonTextColor: colors?.[ 'button-font' ] ?? '',
        buttonBgColor: colors?.[ 'button' ] ?? '',
        headerData: formValues?.header?.heading ?? htmlData,
        optIn: optIn,
        overlay: overlay,
        previewFile: previewFile,
        hideSubscribeButton: subVisible,
        isHeaderTransparent: isHeaderTransparent,
        formValues: formValues,
    };
    // const [ headerValues, setHeaderValues ] = useState({
    //     heading: data?.header?.heading,
    //     subHeading: data?.header?.subHeading,
    // });
    const [ openImageModal, setOpenImage ] = useState(false);
    const dispatch = useDispatch();
    const disableBodyScroll = () => {
        const element = document.querySelectorAll(
            '.custom-color-modal .modal-content'
        )[ 0 ];
        element.classList.add('stop-scroll');
    };
    useEffect(() => {
        const query = 'gradient';
        dispatch(getUnsplash('/photos', query));
    }, []);
    const getBase64 = (base64) => {
        dispatch(reduxChange('assessmentUpdateForm', 'coverImage', base64));
    };
    const handleSearch = (event) => {
        const query = event.target.value;
        dispatch(getUnsplash('/photos', query));
    };
    const toggleImageModal = () => {
        setActiveCustomColor(false);
        setOpenImage(!openImageModal);
    };
    const clearImage = () => {
        dispatch(reduxChange('assessmentUpdateForm', 'coverImage', ''));
        dispatch(reduxChange('assessmentUpdateForm', 'imageAltText', ''));
    };
    const handleHeaderChange = (propData) => {
        const d = propData
        // .replace(/<p(?:\s+[^>]+)?\s+style="font-size: 1\.5em">([^<]*)(?=<span)/g, '<p><span style="font-size: 1.5em;">$1</span></p>')
        // .replace(/<p\s+style=['"][^'"]*['"]>(.*?)<\/p>/,'<p>$1</p>')
        // .replace(/<p>(.*?)<\/p>/,'<h1>$1</h1>')
            // .replace(/<p[^>]*>/g, function (match) {
            //     return match.replace(/style="[^"]*"/g, '');
            // })
            // .replace(/<p[^>]*>/g, function (match) {
            //     return match.replace(/style="[^"]font-size:[^"];?[^"]*"/g, 'style=""');
            // })
            // .replace(/(<p[^>]*style\s*=\s*['"]?[^'"]*)font-size:\s*16em;([^'"]*['"]?[^>]*>)/g, '$1$2')
            // .replace(/(<p[^>]style\s=\s*['"]?[^'"])font-size:\s*16em;([^'"]['"]?[^>]*>)/g, '$1font-size: 1em;$2')
            .replace(/(<p[^>]style\s=\s*['"]?[^'"])font-size:\s*16em;([^'"]['"]?[^>]*>)/g, '$1$2')
            .replace(/(\d+)\s*pt/g, '$1em')
        // .replace(/(<p><\/p>)+/g, '<br />')
            .replace(/<p><\/p>/g, (match, offset) => {
                // If this is the first match, keep it as it is, otherwise replace with <br>
                return offset === 0 ? match : '<br>';
            })
            .replace(/<img>/g, '')
            .replace(/<button([^>]*)\s+disabled\s*=\s*["']true["']([^>]*)>/gi, '<button$1$2>')
            .replace(/(<p[^>]*\sstyle="[^"]*\b)(font-size:[^;"]*;?)([^"]*"[^>]*>)/g, '$1$3');
            // .replace(/(<p[^>]*\sstyle="[^"]*\b)(font-size:\s*16pc[^;"]*;?)([^"]*"[^>]*>)/g, '$1$3');

        // console.log(propData)
        console.log(d);
        const seoDesc = htmlToFormattedText(propData);
        const headerObj = {
            heading: d,
            seoDescription: seoDescription ? seoDescription : trimStringLength(seoDesc, 230),
            seoTitle: seoTitle ? seoTitle : trimStringLength(seoDesc, 50),
        };
        setHtmlData(propData);
        dispatch(reduxChange('assessmentUpdateForm', 'header', headerObj));
    };
    const handleChange = (event, name, fromRadio) => {
        const colorsData = Object.assign({}, colors);
        colorsData[ name ] = event.target.value;
        setColors(colorsData);
        !fromRadio && setActiveCustomColor(name);
        dispatch(
            reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colorsData))
        );
    // dispatch(reduxChange('assessmentUpdateForm', 'coverImage', ''));
    };
    const handleChangeColor = (d) => {
        isIpad && disableBodyScroll();
        const colorData = Object.assign({}, colors);
        colorData[ activeCustomColor ] = d.hex;
        setColors(colorData);
        // setActiveCustomColor
        setHsl(d.hsl);
        setHsv(d.hsv);
        dispatch(
            reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colorData))
        );
    // dispatch(reduxChange('assessmentUpdateForm', 'coverImage', ''));
    };
    const handleClick = (name) => {
        setActiveCustomColor(name);
    };

    const toggleHandler = (name, bool, setter) => {
        setter(bool);
        dispatch(reduxChange('assessmentUpdateForm', name, bool));
    };

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    const element = document.querySelectorAll(
                        '.custom-color-modal .modal-content'
                    )[ 0 ];
                    element.classList.remove('stop-scroll');
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }
    const radioView = (option1, option2, name) => {
        return (
            <div className="font-color-switcher">
                <div
                    className={ `custom-radio white-color-font ${
                        colors[ name ] === option1 && 'active'
                    }` }
                >
                    <div className="radio-item">
                        <input
                            type="radio"
                            onChange={ (e) => handleChange(e, name, true) }
                            value={ option1 }
                            name={ 'white' }
                            id={ 'white' }
                            checked={ colors[ name ] === option1 }
                            // required
                        />
                        <label className="label-icon option-white" htmlFor={ 'white' }>
                            <span>A</span>
                        </label>
                    </div>
                </div>
                <div
                    className={ `custom-radio black-color-font ${
                        colors[ name ] === option2 && 'active'
                    }` }
                >
                    <div className="radio-item">
                        <input
                            type="radio"
                            onChange={ (e) => handleChange(e, name, true) }
                            value={ option2 }
                            name={ 'black' }
                            id={ 'black' }
                            checked={ colors[ name ] === option2 }
                            // required
                        />
                        <label className="label-icon option-black" htmlFor={ 'black' }>
                            <span>A</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const handleTabChange = (name, setter, value) => {
        setter(value);
        dispatch(reduxChange('assessmentUpdateForm', name, value));
    };

    const setDefaultTemplate = () => {
        dispatch(reduxChange('assessmentUpdateForm', 'templateName', template));
        onClose();
    };

    const tabView = (
        options = [],
        currentValue,
        onOptionChange = () => {},
        optionObject,
        name
    ) => {
        return (
            <div className="new-theme-tab-view">
                {options.map((option, i) => {
                    return (
                        <div
                            key={ `${ option }-${ i }` }
                            className={ `${
                                optionObject[ option ] === currentValue ? 'active tab' : 'tab'
                            }` }
                            onClick={ () =>
                                handleTabChange(name, onOptionChange, optionObject[ option ])
                            }
                        >
                            {option}
                        </div>
                    );
                })}
            </div>
        );
    };

    const colorView = (stateName) => {
        return (
            <div className="header-color-selector">
                <div
                    onClick={ () => handleClick(stateName) }
                    className={ `color-box-view ${
                        stateName === activeCustomColor ? 'active' : ''
                    }` }
                >
                    <span
                        className="color-selector-preview"
                        style={ { background: colors[ stateName ] } }
                    ></span>
                    <input
                        type="text"
                        onChange={ (event) => handleChange(event, stateName) }
                        className="form-control"
                        defaultValue={ colors[ stateName ] ?? '#ffffff' }
                        value={ colors[ stateName ] }
                    />
                </div>
            </div>
        );
    };

    const updateSEO = () => {
        const d = htmlData
        //     .replace(/<p[^>]*>/g, function (match) {
        //         return match.replace(/style="[^"]*"/g, '');
        //     })
        //     .replace(/(\d+)\s*pt/g, '$1em')
        // // .replace(/<p\s+style="font-size:\s*24pt">\s*<\/p>/g, '<br />')
        //     .replace(/<p><\/p>/g, (match, offset) => {
        //         // If this is the first match, keep it as it is, otherwise replace with <br>
        //         return offset === 0 ? match : '<br>';
        //     })
        //     .replace(/<img>/g, '');
            .replace(/(<p[^>]style\s=\s*['"]?[^'"])font-size:\s*16em;([^'"]['"]?[^>]*>)/g, '$1$2')
            .replace(/(\d+)\s*pt/g, '$1em')
        // .replace(/(<p><\/p>)+/g, '<br />')
            .replace(/<p><\/p>/g, (match, offset) => {
                // If this is the first match, keep it as it is, otherwise replace with <br>
                return offset === 0 ? match : '<br>';
            })
            .replace(/<img>/g, '')
            .replace(/<button([^>]*)\s+disabled\s*=\s*["']true["']([^>]*)>/gi, '<button$1$2>')
            .replace(/(<p[^>]*\sstyle="[^"]*\b)(font-size:[^;"]*;?)([^"]*"[^>]*>)/g, '$1$3');
        const headerObj = {
            heading: d,
            seoDescription: seoDescription,
            seoTitle: seoTitle,
        };
        dispatch(reduxChange('assessmentUpdateForm', 'header', headerObj));
        setIsEdit(!isEdit);
    };

    return (
        <Form onSubmit={ () => {} }>
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>Theme Builder</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-btns pb-4 d-flex justify-content-end">
                    <ButtonLoader
                        button={
                            <Button
                                className="btn-primary-2"
                                type="button"
                                onClick={ () => setDefaultTemplate() }
                                variant="primary"
                            >
                                confirm
                            </Button>
                        }
                        loadingText="Saving"
                        loading={ loading }
                    />
                </div>
                <div className="custom-color-selection template-form-group">
                    <div className="custom-color-template-selection theme-responsive pr-md-2">
                        <div className="custom-theme-configration">
                            <div>
                                {tabView(
                                    Object.keys(templateOption),
                                    template,
                                    setSelectTemplate,
                                    templateOption,
                                    'templateName'
                                )}
                            </div>
                            {template !== 'STYLISH' ? (
                                <>
                                    {' '}
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div
                                            style={ { width: 'fit-content' } }
                                            className="d-flex align-items-center font-color-selector py-3"
                                        >
                                            <label className="form-heading">Headline</label>
                                            {radioView('#FFFFFF', '#000000', 'header-color')}
                                        </div>
                                        <div className="common-color d-flex align-items-center">
                                            <span>Opt In</span>
                                            <label className="switch">
                                                <input
                                                    onClick={ () =>
                                                        toggleHandler('optIn', !optIn, setOptIn)
                                                    }
                                                    type="checkbox"
                                                    checked={ optIn }
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="header-editor custom-theme-editor">
                                        <ContentEditor
                                            htmlData={ htmlData }
                                            setHtmlData={ handleHeaderChange }
                                        />
                                    </div>
                                    <div className="mt-4">
                                        {activeCustomColor && (
                                            <ColorPicker
                                                useOutsideClick={ useOutsideClick }
                                                active={ activeCustomColor }
                                                obj={ { hsl: hsl, hsv: hsv } }
                                                colors={ colors }
                                                onChange={ handleChangeColor }
                                            />
                                        )}
                                    </div>
                                    <div
                                        style={ { gap: 10, width: 'fit-content' } }
                                        className="py-3 d-flex align-items-center justify-content-between"
                                    >
                                        <label className="form-heading">Background</label>
                                        <div className="my-2">
                                            {tabView(
                                                Object.keys(backgroundOption),
                                                backgroundType,
                                                setBackgroundType,
                                                backgroundOption,
                                                'backgroundType'
                                            )}
                                        </div>
                                    </div>
                                    {backgroundType === 'COLOR' ? (
                                        <div>{colorView('header-background')}</div>
                                    ) : (
                                        <div className="config-theme-edit-site-hero-upload">
                                            <div className="hero-bgcolor-img">
                                                <div className="hero-img">
                                                    <div className="cover-img-upload-select">
                                                        <p
                                                            onClick={ toggleImageModal }
                                                            className="preview-logo d-flex flex-column align-items-center justify-content-center"
                                                        >
                                                            <img
                                                                src={
                                                                    data.coverImage || previewFile || blogBanner
                                                                }
                                                                alt="cover"
                                                            />
                                                            <div className="image-upload-plus-icon">
                                                                <span className="plus-icon">+</span>
                                                            </div>
                                                        </p>

                                                        <UploadImageModal
                                                            formName={ 'assessmentUpdateForm' }
                                                            getBase64={ getBase64 }
                                                            handleSearch={ handleSearch }
                                                            clearImage={ clearImage }
                                                            previewFile={ previewFile }
                                                            fieldName={ 'coverImage' }
                                                            unsplashImages={ unsplashImages }
                                                            openModal={ openImageModal }
                                                            handleToggleModal={ toggleImageModal }
                                                            isAlt={ true }
                                                            fieldAltName="imageAltText"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="common-color d-flex align-items-center">
                                                <span>Overlay</span>
                                                <label className="switch">
                                                    <input
                                                        onClick={ () =>
                                                            toggleHandler(
                                                                'headerOverlay',
                                                                !overlay,
                                                                setOverlay
                                                            )
                                                        }
                                                        type="checkbox"
                                                        checked={ overlay }
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    )}{' '}
                                </>
                            ) : null}
                            {template === 'STYLISH' ? (
                                <div className="mt-4">
                                    {activeCustomColor && (
                                        <ColorPicker
                                            useOutsideClick={ useOutsideClick }
                                            active={ activeCustomColor }
                                            obj={ { hsl: hsl, hsv: hsv } }
                                            colors={ colors }
                                            onChange={ handleChangeColor }
                                        />
                                    )}
                                </div>
                            ) : null}
                            <div
                                style={ { gap: 10 } }
                                className="py-3 d-flex align-items-center"
                            >
                                <label className="form-heading">Top Menu</label>

                                <div
                                    className="d-flex align-items-center font-color-selector"
                                    style={ { gap: 10, width: 'fit-content' } }
                                >
                                    {colorView('top-menu')}{' '}
                                    {radioView('#FFFFFF', '#000000', 'top-menu-font')}
                                </div>
                            </div>
                            <div
                                style={ { gap: 10 } }
                                className="common-color d-flex align-items-center"
                            >
                                <input
                                    onClick={ () =>
                                        toggleHandler(
                                            'topMenuTransparent',
                                            !isHeaderTransparent,
                                            setHeaderTransparent
                                        )
                                    }
                                    className="make-transparent"
                                    type="checkbox"
                                    checked={ isHeaderTransparent }
                                />
                                <span>Make Transparent</span>
                            </div>
                            <div
                                style={ { gap: 10 } }
                                className="d-flex py-3 align-items-center"
                            >
                                <label className="form-heading">Button</label>

                                <div
                                    className="d-flex align-items-center font-color-selector"
                                    style={ { gap: 10, width: 'fit-content' } }
                                >
                                    {colorView('button')}{' '}
                                    {radioView('#FFFFFF', '#000000', 'button-font')}
                                </div>
                            </div>
                            <div className="common-color d-flex align-items-center">
                                <input
                                    onClick={ () =>
                                        toggleHandler(
                                            'subscriptionButton',
                                            !subVisible,
                                            setSubButtonVisible
                                        )
                                    }
                                    className="make-transparent"
                                    type="checkbox"
                                    checked={ subVisible }
                                />
                                <span>No Button</span>
                            </div>
                            <div className="form-heading mt-4">SEO</div>
                            <div className="theme-seo-container">
                                {!isEdit ? (
                                    <div>
                                        <h3 className="theme-seo-heading mb-2">
                                            {seoTitle ? seoTitle :formValues.header?.seoTitle ?? 'SEO Title'}
                                        </h3>
                                        <div className="theme-seo-description mb-2">
                                            {seoDescription ? seoDescription : formValues.header?.seoDescription ?? 'SEO Description'}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="">
                                        <div className="">
                                            <div>Meta Title</div>
                                            <input
                                                value={ seoTitle }
                                                className="seo-input"
                                                onChange={ (e) => setTitle(e.target.value) }
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <div>Meta Description</div>
                                            <textarea
                                                className="seo-input"
                                                value={ seoDescription }
                                                onChange={ (e) => setDescription(e.target.value) }
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex theme-seo-btn-wrapper mt-4">
                                    <button
                                        onClick={
                                            !isEdit ? () => setIsEdit(!isEdit) : () => updateSEO()
                                        }
                                        className="seo-edit-btn"
                                        type="button"
                                    >
                                        {!isEdit ? 'Edit' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-color-template-preview theme-responsive">
                        <div className="wizrd-blog-preview wizrd-inner-preview">
                            {getCurrentTemplate(template, data)}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Form>
    );
};

HomeTheme.propTypes = {
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func,
    formValues: PropTypes.object,
    previewFile: PropTypes.string,
    loading: PropTypes.boolean,
};

export default HomeTheme;
