import React from 'react';
import {
    Editor,
    EditorTools,
    EditorUtils,
    ProseMirror,
} from '@progress/kendo-react-editor';
import PropTypes from 'prop-types';
import { InsertImage } from '../../../components/core/editor/insert-image';

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontName,
    Link,
    Unlink,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
    ForeColor,
    BackColor,
} = EditorTools;
import { CustomFontSize } from 'components/core/editor/custom-font-size';
import { InsertButtonTool } from 'components/core/editor/insert-button';
import { button, shortCode } from 'components/core/editor/new-node';
import { CustomFontFormat } from 'components/core/editor/custom-font-format';
import { WordCounter } from 'components/core/editor/word-count';
import { InsertVideo } from 'components/core/editor/insert-video';
import { InsertTweet } from 'components/core/editor/insert-tweet';
import { InsertEmoji } from 'components/core/editor/insert-emoji';

const { Schema, EditorView, EditorState } = ProseMirror;

export const EmailEditor = (props) => {
    const {
        htmlData = '',
        setHtmlData = () => {},
        formName,
        handleSubmit,
        pageFormData
    } = props;

    const onMount = (event) => {
        const { viewProps } = event;
        const { plugins, schema } = viewProps.state;
        const paragraph = {
            ...schema.spec.nodes.get('paragraph'),
        };
        paragraph.attrs = paragraph.attrs || {};
        paragraph.attrs[ 'dir' ] = {
            default: null,
        };
        let nodes = schema.spec.nodes.update('paragraph', paragraph);

        // Appending the new node.
        nodes = nodes.addToEnd('button', button);
        nodes = nodes.addToEnd('shortCode', shortCode);

        //creating marks
        const marks = schema.spec.marks;

        const mySchema = new Schema({
            nodes,
            marks,
        });

        const doc = EditorUtils.createDocument(mySchema, htmlData);

        return new EditorView(
            {
                mount: event.dom,
            },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: [ ...plugins ],
                }),
            }
        );
    };

    return (
        <>
            {/* { isReadOnly && <div
                style={ {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 9999,
                    pointerEvents: 'auto'
                } }
            /> } */}
            <Editor
                dir={ {
                    formData: pageFormData,
                    formName: formName,
                    handleSubmit: handleSubmit,
                } }
                tools={ [
                    [ Bold, Italic, Underline, Strikethrough ],
                    [ Subscript, Superscript ],
                    [ AlignLeft, AlignCenter, AlignRight, AlignJustify ],
                    [ Indent, Outdent ],
                    [ OrderedList, UnorderedList ],
                    [ InsertButtonTool ],
                    [ FontName ],
                    CustomFontSize,
                    CustomFontFormat,
                    ForeColor,
                    BackColor,
                    [ Undo, Redo ],
                    [
                        Link,
                        Unlink,
                        InsertImage,
                        InsertVideo,
                        InsertTweet,
                        InsertEmoji,
                        ViewHtml,
                    ],
                    [ InsertTable ],
                    [ AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter ],
                    [ DeleteRow, DeleteColumn, DeleteTable ],
                    [ MergeCells, SplitCell ],
                    [ WordCounter ],
                ] }
                defaultEditMode="div"
                onChange={ (editor) => {
                    setHtmlData(editor.html);
                } }
                contentStyle={ {
                    height: 750,
                } }
                onMount={ onMount }
                value={ htmlData || '' }
                defaultContent={ htmlData || '' }
            />
        </>
    );
};
EmailEditor.propTypes = {
    htmlData: PropTypes.string,
    setHtmlData: PropTypes.func,
    formName: PropTypes.string,
    handleSubmit: PropTypes.func,
    pageFormData: PropTypes.any,
};
