import { reset } from 'redux-form';
import { NOTIFICATION_TYPES, MESSAGE } from '../../constants/app';
import { notification } from '../../services/notification';
import axiosInstance from '../../services/api';
import unsplashClient from '../../services/unsplashClient';
import { ROUTES } from '../../constants/appRoutes';
import history from '../../utils/history';
import { setLocalItem, removeItem, setItem } from '../../utils/cache';
import {
    dataURLtoFile,
    uId,
    getUser,
    getDomainEnv,
    getDomain,
    isTravelTemplate,
    getSite,
    getResizeImage,
} from '../../utils/helpers';
import axios from 'axios';
import { DEFAULT_TRAVEL_SCRIPT } from 'constants/app';
import {
    getAssessmentRequest,
    getAssessmentSuccess,
    getAssessmentFailure,
    createAssessmentRequest,
    createAssessmentSuccess,
    createAssessmentFailure,
    getUnsplashSuccess,
    getVerifiedDomainSuccess,
    getVerifiedDomainRequest,
    getVerifiedDomainError,
    updateAssessmentSuccess,
    getUnsplashRequest,
    updateAssessmentRequest,
    // setHome,
    // siteBuildRequest,
    // siteBuildFailed,
    // siteBuildSuccess,
    getAllSitesRequest,
    getAllSitesSuccess,
    getAllSitesFailed,
    getSiteByIdRequest,
    getSiteByIdSuccess,
    deleteAssessmentRequest,
    deleteAssessmentSuccess,
    deleteAssessmentFailed,
    removeAllSitesRequest,
    removeAllSitesSuccess,
    removeAllSitesFailed,
    updateAuthorDataRequest,
    updateAuthorDataSuccess,
    updateAuthorDataFailed,
    updateRetargetDataRequest,
    updateRetargetDataSuccess,
    updateRetargetDataFailed,
    updateActiveCampaignDataRequest,
    updateActiveCampaignDataSuccess,
    updateActiveCampaignDataFailed,
    getACAccounts,
    getACAccountsSuccess,
    getACAccountsFailed,
    updateACAccountRequest,
    updateACAccountSuccess,
    updateACAccountFailed,
} from '../../actions/assessments';

// export const siteBuild = (id,setPublished) => {
//     return(dispatch) => {
//         dispatch(siteBuildRequest())
//         axiosInstance.put(`/user/site/${ id }/publish`).then(()=>{
//             setPublished(true)
//             dispatch(siteBuildSuccess())
//         }).catch(()=>{
//             dispatch(siteBuildFailed())
//         })
//     }
// }
export const getAssessment = () => {
    return (dispatch) => {
        dispatch(getAssessmentRequest());
        axiosInstance
            .get('/assessment')
            .then((response) => {
                const result = response.data.data;
                const niches = result.niches.map((item) => ({
                    label: item.label,
                    value: item.id.toString(),
                    icon: item.icon,
                }));
                dispatch(getAssessmentSuccess({ niches }));
            })
            .catch((error) => {
                notification(NOTIFICATION_TYPES.ERROR, MESSAGE.SOMETHING_WRONG);
                dispatch(getAssessmentFailure(error?.response?.data?.message));
            });
    };
};
export const setHome = (pageId, callback) => {
    return () => {
        axiosInstance
            .patch(`/pages/home/${ pageId }?isHome=true`)
            .then((response) => {
                // console.log(response)
                notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.SET_HOME_SUCCESSFULL);
                callback(response);
            })
            .catch((error) => {
                console.log(error)
                notification(NOTIFICATION_TYPES.ERROR, MESSAGE.SOMETHING_WRONG);
                // callback();
            });
    };
};

export const imageUpload = async (domain, type, file) => {
    try {
        const result = await axiosInstance.get(
            `/generate?domain=${ getDomainEnv(domain) }&type=${ type }`
        );
        if ([ 200, 203 ].includes(result.status)) {
            try {
                const finalResult = await axios.put(result.data.signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Access-Control-Allow-Origin': '*',
                    },
                });
                if ([ 200, 203 ].includes(finalResult.status)) {
                    return result.data.path;
                }
            } catch (error) {
                notification(
                    NOTIFICATION_TYPES.ERROR,
                    error?.response?.data?.message || MESSAGE.SOMETHING_WRONG
                );
                return null;
            }
        }
    } catch (error) {
        notification(
            NOTIFICATION_TYPES.ERROR,
            error?.response?.data?.message || MESSAGE.SOMETHING_WRONG
        );
        return null;
    }
    return null;
};
export const getSiteById = (id, callback) => {
    return (dispatch) => {
        dispatch(getSiteByIdRequest(id));
        axiosInstance
            .get(`/user/sites/${ id }`)
            .then((response) => {
                setLocalItem('site', response.data);
                dispatch(getSiteByIdSuccess(response));
                callback && callback(response);
            })
            .catch((error) => {
                callback && callback(error);
            });
    };
};
export const setWebsite = (assessment) => {
    if (!getSite()) {
        setLocalItem('site', assessment);
    }
};
export const createAssessment = (data, isCreate, viaProvider) => {
    return async (dispatch) => {
        dispatch(createAssessmentRequest());
        if (data.logoUrl) {
            const file = dataURLtoFile(data.logoUrl, uId() + '.png');
            const compressedFile = await getResizeImage(
                file,
                300,
                60,
                'WEBP',
                'file',
                100
            );
            data[ 'logoUrl' ] = await imageUpload(data.domain, 'logo', compressedFile);
        }
        if (data.coverImage) {
            const file = dataURLtoFile(data.coverImage, uId() + '.png');
            data[ 'coverImage' ] = await imageUpload(data.domain, 'cover-image', file);
        }
        if (isTravelTemplate()) {
            data[ 'templateData' ] = { headerScript: DEFAULT_TRAVEL_SCRIPT.script1 };
        }
        data[ 'menuLinks' ] = [
            { name: 'home', url: '/' },
            { name: 'contact', url: 'contact' },
        ];
        data[ 'footerLinks' ] = [ { name: 'home', url: '/' } ];

        if(!data [ 'templateName' ]){
            data [ 'templateName' ] = 'LISTING',
            data [ 'backgroundType' ] = 'IMAGE',
            data [ 'headerOverlay' ] = true,
            data [ 'topMenuTransparent' ] = false,
            data [ 'makeTransparent' ] = false,
            data [ 'optIn' ] = true,
            data [ 'subscriptionButton' ] = true,
            data[ 'header' ] = { ...data[ 'header' ],seoTitle: '', seoDescription: '' }
        }

        axiosInstance
            .post('/assessment', data)
            .then((response) => {
                //notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.CREATE_ASSESSMENT);
                const user = getUser();
                isCreate &&
          dispatch({
              type: 'DASHBOARD_TUTORIAL_VIDEO',
              open: '0',
          });
                setWebsite(response.data?.data);
                user[ 'test' ] = true;
                viaProvider ? setLocalItem('user', user) : setItem('user', user);
                removeItem('assessmentForm');
                dispatch(reset('assessmentForm'));
                dispatch(createAssessmentSuccess(response.data));
                history.push(ROUTES.DASHBOARD);
            })
            .catch((error) => {
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
                dispatch(createAssessmentFailure(error?.response?.data?.message));
                history.push(ROUTES.ASSESSMENT);
            });
    };
};

export const updateAssessment = (
    id,
    data,
    domain,
    handleClose,
    setPublished,
    callback
) => {
    return async (dispatch) => {
        dispatch(updateAssessmentRequest());
        if (data.logoUrl && !data.logoUrl.match('^(http|https)://')) {
            const file = dataURLtoFile(data.logoUrl, uId() + '.png');
            const compressedFile = await getResizeImage(
                file,
                1000,
                250,
                'WEBP',
                'file',
                100
            );
            data[ 'logoUrl' ] = await imageUpload(domain, 'logo', compressedFile);
        }
        if (data.faviconUrl && !data.faviconUrl.match('^(http|https)://')) {
            const file = dataURLtoFile(data.faviconUrl, uId() + '.png');
            const compressedFile = await getResizeImage(
                file,
                40,
                40,
                'WEBP',
                'file',
                70
            );
            data[ 'faviconUrl' ] = await imageUpload(
                domain,
                'favicon-icon',
                compressedFile
            );
        }
        if (data.coverImage && !data.coverImage.match('^(http|https)://')) {
            const file = dataURLtoFile(data.coverImage, uId() + '.png');
            const compressedFile = await getResizeImage(
                file,
                1080,
                2000,
                'WEBP',
                'file',
                70
            );
            data[ 'coverImage' ] = await imageUpload(
                domain,
                'cover-image',
                compressedFile
            );
        }
        axiosInstance
            .put(`/assessment/${ id }`, data)
            .then((response) => {
                notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.UPDATE_ASSESSMENT);
                callback && callback(true);
                dispatch(getSiteById(response.data.data.id));
                setPublished(false);
                dispatch(updateAssessmentSuccess(response.data));
                handleClose();
            })
            .catch((error) => {
                callback && callback(false);
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
                handleClose();
            });
    };
};

export const getUnsplash = (url, query) => {
    return async (dispatch) => {
        dispatch(getUnsplashRequest());
        const result = await unsplashClient.search.getPhotos({
            query: query || 'blog',
            page: 1,
            perPage: 20,
        });
        dispatch(getUnsplashSuccess(result?.response?.results));
    };
};

export const getVerifiedDomain = (name) => {
    const origin = process.env.REACT_APP_WEBSITE_TYPE;
    return (dispatch) => {
        dispatch(getVerifiedDomainRequest());
        axiosInstance
            .get(`/check-domain?name=${ name }&origin=${ origin }`)
            .then((response) => {
                dispatch(getVerifiedDomainSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getVerifiedDomainError(error));
            });
    };
};

export const getAllSites = (args) => {
    return (dispatch) => {
        dispatch(getAllSitesRequest());
        axiosInstance
            .get(`/user/sites${ args }`)
            .then((response) => {
                dispatch(getAllSitesSuccess(response.data));
                // setting site in local storage if only 1 site
                if (response?.data?.sites?.length === 1) {
                    setLocalItem('site', response?.data?.sites[ 0 ]);
                }
            })
            .catch((error) => {
                dispatch(getAllSitesFailed());
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            });
    };
};
export const getAllSitesWP = () => {
    return (dispatch) => {
        dispatch(getAllSitesRequest());
        axiosInstance
            .get('/sites')
            .then((response) => {
                dispatch(getAllSitesSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getAllSitesFailed());
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            });
    };
};
export const deleteAssessment = (id, args, page, callback) => {
    return (dispatch) => {
        dispatch(deleteAssessmentRequest());
        axiosInstance
            .delete(`/sites/${ id }`)
            .then((response) => {
                dispatch(getAllSites(args));
                dispatch(deleteAssessmentSuccess(response.data));
                callback && callback(page);
            })
            .catch((error) => {
                dispatch(deleteAssessmentFailed(error));
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            });
    };
};

const removeLocalSite = (ids) => {
    const id = localStorage.site && JSON.parse(localStorage.site)?.id;
    ids.includes(id) && localStorage.removeItem('site');
};
export const removeAllSites = (ids, callback) => {
    return (dispatch) => {
        dispatch(removeAllSitesRequest());
        axiosInstance
            .delete('/sites', { data: { ids: ids } })
            .then((response) => {
                removeLocalSite(ids);
                dispatch(removeAllSitesSuccess(response.data));
                callback && callback();
            })
            .catch((error) => {
                dispatch(removeAllSitesFailed(error));
            });
    };
};
export const downloadCSV = async (site) => {
    return await axiosInstance.get(`/email-subscribers/sites/${ site?.id }?isDownload=true`);
};

export const AsyncGetSite = async (id) => {
    const response = await axiosInstance.get(`/user/sites/${ id }`);
    return response.data;
};

export const updateAuthorData = (id, data, callback, eventName) => {
    return async (dispatch) => {
        dispatch(updateAuthorDataRequest());
        if (data.profilePicture && !data.profilePicture.match('^(http|https)://')) {
            const file = dataURLtoFile(data.profilePicture, uId() + '.png');
            const compressedFile = await getResizeImage(
                file,
                500,
                500,
                'WEBP',
                'file',
                100
            );
            data[ 'profilePicture' ] = await imageUpload(
                getDomain(),
                'profile',
                compressedFile
            );
        }
        axiosInstance
            .put(`sites/${ id }/author`, data)
            .then((response) => {
                dispatch(updateAuthorDataSuccess(response.data));
                dispatch(getSiteById(id));
                callback && callback(eventName, data);
            })
            .catch((error) => {
                dispatch(updateAuthorDataFailed(error));
            });
    };
};

export const updateRetargetData = (id, data, callback, eventName) => {
    return async (dispatch) => {
        dispatch(updateRetargetDataRequest());
        axiosInstance
            .patch(`sites/${ id }/analytics`, { headers: data })
            .then((response) => {
                dispatch(updateRetargetDataSuccess(response.data));
                dispatch(getSiteById(id));
                callback && callback(eventName, data);
            })
            .catch((error) => {
                dispatch(updateRetargetDataFailed(error));
            });
    };
};

export const getACAccountList = (requestData) => {
    const { data } = requestData;
    const { siteId } = data;
    return (dispatch) => {
        dispatch(getACAccounts());
        axiosInstance
            .get(`/activeCampaignlists/${ siteId }`)
            .then((response) => {
                dispatch(getACAccountsSuccess(response.data));
            })
            .catch(() => {
                dispatch(getACAccountsFailed());
            });
    };
};
export const updateActiveCampaignIntegration = (
    id,
    data,
    callback,
    eventName,
    isCallGetAPI
) => {
    return async (dispatch) => {
        dispatch(updateActiveCampaignDataRequest());
        axiosInstance
            .post('saveACDetails', data)
            .then((response) => {
                dispatch(updateActiveCampaignDataSuccess(response.data));
                dispatch(getSiteById(id));
                isCallGetAPI && dispatch(getACAccountList({ data: { siteId: id } }));
                callback && callback(eventName, data);
            })
            .catch((error) => {
                dispatch(updateActiveCampaignDataFailed(error));
                notification(NOTIFICATION_TYPES.ERROR, error?.message);
            });
    };
};

export const updateActiveCampaignAccount = (requestParams) => {
    const { id, data, callback, eventName } = requestParams;
    return (dispatch) => {
        dispatch(updateACAccountRequest());
        axiosInstance
            .put(`activecampaignList/${ id }`, data)
            .then((response) => {
                dispatch(updateACAccountSuccess(response.data));
                dispatch(getSiteById(id));
                callback && callback(eventName, data);
            })
            .catch((error) => {
                dispatch(updateACAccountFailed(error));
                notification(NOTIFICATION_TYPES.ERROR, error?.message);
            });
    };
};
