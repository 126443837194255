import React, { useState , useEffect , useRef , useContext } from 'react'
import { mixpanelCommonData, numericFormater } from 'utils/helpers';
import { CSVLink } from 'react-csv';
import { downloadCSV } from 'middleware/assessments'
// import {  SmallLoader } from 'components/dashboard'
import PropTypes from 'prop-types';
import { EVENTS } from 'constants/app'
import _ from 'lodash'
// import {
//     TopArrowSubscriberIcon,
//     DownArrowSubscriberIcon
// } from 'utils/svg'
import { MixpanelContext } from 'utils/tracking';

const Analytics = ( props ) => {
    const csvRef= useRef(null)
    const [ csvData, setCsvData ] = useState([ ])
    const mixpanel = useContext(MixpanelContext);

    const { site, user  } = props
    const domain  = site?.customDomain || site?.domain
    // const viewPercent = statsData?.viewsPercentageChange
    // const subscribePercent = statsData?.subscribersPercentageChange
    useEffect(() => {
        if(!_.isEmpty(csvData)){
            setTimeout(() => {
                csvRef?.current?.link?.click()
                setCsvData([])
            })
        }
    },[ csvData ])

    const handleEvent = () => {
        mixpanel.track('Display name updated', mixpanelCommonData( { displayName: site?.displayName } ));
    }

    const handleDownloadCSV = async (event) => {
        event.preventDefault()
        const commonData =  [ [ 'Website', domain ],
            [ ],
            [ 'S.No', 'Name', 'Email', 'Date/Time' ] ]
        const response = await downloadCSV(site)
        const data = [ ...commonData , ...response.data.emails ]
        setCsvData(data)
        handleEvent(EVENTS.download, { siteId: site?.id, 'user identifier': user?.id });
        //window.location.href = `${ process.env.REACT_APP_API_URL }/v1/user/sites/${ site?.id }/email-subscriber/download`
    }

    const dateString = () =>{
        var d = new Date();
        return d.getDate()  + '-' + (d.getMonth()+1) + '-' + d.getFullYear() + '-' +
        d.getHours() + ':' + d.getMinutes();

    }
    // const getPercentDisplay = (value) => {
    //     const isEmpty = value === ''
    //     return(<>
    //         {!isEmpty && (parseInt(value) > 0 &&  <TopArrowSubscriberIcon /> || parseInt(value) < 0 && <DownArrowSubscriberIcon /> ) }
    //         <span>{ value === 0 ? '' : value }</span>
    //     </>)
    // }
    return (  <>
        <ul className="dashboard-view-subscribe">
            <li>
                <h5>Views</h5>
                {
                    <h3>
                        {/* {  viewPercent > site?.views ? viewPercent : abbreviateNumber(site?.views) || (viewPercent < 0 ? '' : viewPercent) || 0 }
                        <sub>
                            { statsLoading ? <SmallLoader /> : viewPercent < 0 ? '' : getPercentDisplay(viewPercent) }
                        </sub> */}
                        {numericFormater(site?.views ?? 0)}
                    </h3>
                }
            </li>

            <li>
                <h5>Subscribers</h5>
                <h3>
                    {/* { abbreviateNumber(site?.subscriberCount || 0 ) }
                    <sub>
                        { statsLoading ? <SmallLoader /> : getPercentDisplay(subscribePercent) }
                    </sub> */}
                    {numericFormater(site?.subscriberCount ?? 0)}
                </h3>
                {site?.subscriberCount > 0 && <>
                    <a href='#' onClick={ handleDownloadCSV }>Export CSV File</a>
                    <CSVLink
                        data={ csvData }
                        ref={ csvRef }
                        filename={ `email-subscriber-${ dateString() }.csv` }
                    />
                </>}

            </li>
        </ul>
    </>)
}

Analytics.propTypes = {
    site: PropTypes.object,
    user: PropTypes.object,
    statsLoading: PropTypes.bool,
    statsData: PropTypes.any
};
export default Analytics