import React, { useEffect, useState } from 'react';
import '../contacts.sass';
import { isValidEmail } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { EmailEditor } from './email-editor';
import { Modal, Spinner } from 'react-bootstrap';
import {
    getGroupList,
    getSubscribersList,
    sendMail,
    sendMailToList,
} from 'middleware/email';
import closeIcon from 'images/close.svg';
import { updateDomainData } from 'actions/email';
import { getItemParse } from 'utils/cache';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/appRoutes';
import EmailDatePicker from './date-picker';
// import Select from 'react-select';
import EmojiPicker from 'emoji-picker-react';
import { reduxForm } from 'redux-form';
import { getUnsplash } from 'middleware/assessments';

const SendEmails = () => {
    const dispatch = useDispatch();
    const emailData = useSelector((state) => state.email);
    const location = useLocation();
    const emailSendData = location?.state?.responseData;
    const isEmailView = location?.state?.isEmailView;
    const history = useHistory();
    const emailForm = useSelector((state) => state.form.emailForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ sendLoading, setSendLoading ] = useState(false);
    // const [ domainInformation, setDomainInformation ] = useState(null);
    const [ draftLoading, setDraftLoading ] = useState(false);
    const [ htmlData, setHtmlData ] = useState(emailSendData?.htmlTemplate ?? '');
    const [ subject, setSubject ] = useState(emailSendData?.subject ?? '');
    // const [ reply, setReply ] = useState('');
    // const [ displayName, setDisplayName ] = useState('');
    const [ address, setAddress ] = useState({})
    // const [ replyToDomain, setReplyToDomain ] = useState('');
    // const [ subDomain, setSubDomain ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ open, setOpen ] = useState(false);
    const [ chooseList, setChooseList ] = useState([]);
    const [ groupList, setGroupList ] = useState([]);
    const [ chooseDate, setChooseDate ] = useState(null);
    const [ openDate, setOpenDate ] = useState(false);
    // const [ selectedOption, setSelectedOption ] = useState('')
    // const [ options, setOptions ] = useState([])
    // const [ selected, setSelected ] = useState(options[ 1 ]);
    const [ chooseEmoji, setChooseEmoji ] = useState(null);

    const onEmojiClick = (event, emojiObject) => {
        setSubject((prevValue) => prevValue + emojiObject.emoji);
    };

    // const handleSelectChange = (selectedOption) => {
    //     // setSelected(selectedOption);
    //     console.log('Option selected:', selectedOption);
    // };
    // console.log('selected',selected?.value);
    // console.log('subDomain',subDomain);

    const handleDateChange = (date) => {
        setChooseDate(date);
    };

    const [ errors, setErrors ] = useState({
        subject: '',
        reply: '',
        email: '',
    });

    const addToCurrentList = (item) => {
        setChooseList([ ...chooseList, item ]);
        setGroupList(groupList.filter((listItem) => listItem?.id !== item?.id));
    };
    const removeFromCurrentList = (item) => {
        setGroupList([ ...groupList, item ]);
        setChooseList(chooseList.filter((listItem) => listItem?.id !== item?.id));
    };
    const toggleModal = (e) => {
        e.preventDefault();
        // add validation
        const newErrors = {};
        if (subject.trim() === '') {
            newErrors.subject = 'Subject is required';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }
        setOpen(!open);
    };

    const handleEmailImageSubmit = () =>{

    }

    const handleModal = () => {
        setOpen(!open);
    };

    const resetEmail = () => {
        setEmail('');
        history.push(ROUTES.EMAIL_LISTING_PAGE);
    };

    const sendSingleMail = (e) => {
        setIsLoading(true);
        e.preventDefault();
        // add validation
        const newErrors = {};
        if (email.trim() === '') {
            newErrors.email = 'Email is required';
        }
        if (email.trim() && !isValidEmail(email)) {
            newErrors.email = 'Please enter valid email address';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('to', email);
        formData.append('subject', subject);
        chooseDate !== null && formData.append('scheduleTime', chooseDate);
        formData.append('html', `<html><body>${ htmlData }<br/> <p>${ address?.businessAddress }, ${ address?.city }, ${ address?.state }, ${ address?.zipcode }, ${ address?.country }</p></body></html>`);
        formData.append('amp_html', `<html><body>${ htmlData }<br/> <p>${ address?.businessAddress }, ${ address?.city }, ${ address?.state }, ${ address?.zipcode }, ${ address?.country }</p></body></html>`);

        dispatch(sendMail(formData, () => setIsLoading(false)));
    };

    const handleSubmit = (e) => {
        // setSendLoading(true);
        e.preventDefault();
        // add validation
        const newErrors = {};
        // if (!displayName?.trim()) {
        //     newErrors.displayName = 'Please enter display name';
        // }
        if (!chooseList?.length) {
            newErrors.chooseList = 'Please choose list';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            // setSendLoading(false);
            return;
        }
        const listIds = chooseList.map((i) => i?.id)?.join(',');
        const formData = new FormData();
        // formData.append('emailDisplayName', displayName);
        // formData.append('replyTo', selected !== undefined ? reply + selected?.value : reply + options[ 1 ]?.value);
        formData.append('subject', subject);
        chooseDate !== null && formData.append('scheduleTime', chooseDate === null ?  '' : chooseDate);
        formData.append('html', `<html><body>${ htmlData } <br/> <p>${ address?.businessAddress }, ${ address?.city }, ${ address?.state }, ${ address?.zipcode }, ${ address?.country }</p> </body></html>`);
        formData.append('listId', listIds);
        dispatch(sendMailToList(formData, resetEmail, setSendLoading));
    };
    const handleHtmlChange = (propData) => {
        setHtmlData(propData);
    };

    const getSubscribers = (id) => {
        dispatch(getSubscribersList(id));
    };

    const handleDomainData = (data) => {
        console.log('data',data);
        if (data && Object.keys(data)?.length && data?.data?.domain) {
            if (data?.data?.domain?.state) {
                // const domainEmail = data?.data?.domain?.domainEmail.split('@')[ 0 ];
                // const domain = data?.data?.domain?.domainEmail.split('@')[ 1 ];
                console.log('log domain',data?.data?.domain);
                setAddress(data?.data?.domain?.address)
                // setDomainInformation({
                //     // domain: domain ?? '',
                //     domain: data?.data?.domain?.domainName ?? '',
                //     name: data?.data?.domain?.name ?? '',
                //     domainEmail: domainEmail ?? '',
                //     // emailDisplayName: data?.data?.domain?.emailDisplayName ?? '',
                //     // replyTo: data?.data?.domain?.replyTo ?? '',
                //     // subDomain:
                // });
            }
        }
    };

    useEffect(() => {
        if (emailData?.groupList) {
            setGroupList(emailData?.groupList);
        }
        if (emailData) {
            // const replyDomain = domainInformation?.domain;
            // const replyName = domainInformation?.domainEmail;
            // setDisplayName(domainInformation?.replyTo);

            // setEmail(domainInformation?.domain);
            // setReply(replyName);
            // setReplyToDomain(replyDomain);
            // setSubDomain(domainInformation?.name)
            // setOptions([
            //     { value: `@${ domainInformation?.name }`, label: `@${ domainInformation?.name }` },
            //     { value: `@${ domainInformation?.domain }`, label: `@${ domainInformation?.domain }` }
            // ])
        }
    }, [ emailData ]);

    useEffect(() => {
        const domainData = email?.domainData ?? getItemParse('domain');
        if (!email?.domainData) {
            dispatch(updateDomainData(domainData));
        }
        handleDomainData(domainData);
    }, [ email?.domainRequestSuccess ]);

    useEffect(() => {
        dispatch(getGroupList(getSubscribers));
        if(isEmailView && emailSendData?.subject){
            setSubject(emailSendData?.subject)
            console.log('useEffect', subject);
        }
    }, []);
    console.log('useEffect outside', subject);
    console.log('isEmailView', isEmailView);
    const handleDraft = (e) => {
        e.preventDefault();
        // add validation
        const newErrors = {};
        if (subject.trim() === '') {
            newErrors.subject = 'Subject is required';
        }

        setErrors(newErrors);
        const listIds = chooseList.map((i) => i?.id)?.join(',');
        const formData = new FormData();
        // formData.append('domainName', displayName);
        // formData.append('text', reply);
        formData.append('subject', subject);
        formData.append('html', `<html><body>${ htmlData }</body></html>`);
        formData.append('listId', listIds);
        formData.append('draft', true);
        if (Object.keys(newErrors).length > 0) {
            return
        }
        dispatch(sendMailToList(formData, resetEmail, setDraftLoading));
    };

    const getTotalRecipient = () => {
        const total = chooseList.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.subscribersCount);
        }, 0);
        return total;
    };

    useEffect(() => {
        dispatch(getUnsplash('/photos' ))
    },[ ])

    return (
        <>
            <main className="dashboard-data createNewBlog createNewPage">
                <section data-tut="reactour__iso" className="dashboard-body">
                    <div className="email-editor-modal">
                        <Modal
                            show={ open }
                            onHide={ handleModal }
                            className="image-upload-modal new-modal embed-link-modal contact-modal-width"
                        >
                            <Modal.Header closeButton>
                                <div className="new-modal-header">
                                    <Modal.Title>Send</Modal.Title>
                                </div>
                            </Modal.Header>
                            <Modal.Body className="content-generator-wrapper">
                                {emailData?.loading ? (
                                    <Spinner animation="border" size={ 'sm' } role="status" />
                                ) : (
                                    <form onSubmit={ handleSubmit } className="send-email-popup">
                                        <div className="mt-1">
                                            <div className="send-test-email-title">
                                                Send Test Email
                                            </div>
                                            <input
                                                value={ email }
                                                name={ 'email' }
                                                onChange={ (e) => setEmail(e.target.value) }
                                                className="test-email-input"
                                                placeholder="Enter email address"
                                            />
                                            {errors.email && (
                                                <span style={ { fontSize: '14px', color: 'red' } }>
                                                    {errors.email}
                                                </span>
                                            )}
                                        </div>
                                        <button disabled={ isLoading ? true : false } className="send-test-btn" onClick={ sendSingleMail }>
                                            {isLoading ? (
                                                <Spinner animation="border" size={ 'sm' } role="status" />
                                            ) : (
                                                'Send Test'
                                            )}
                                        </button>
                                        <hr />
                                        {/* <div className="">
                                            <div className="reply-to">Reply to</div>
                                            <div className="d-flex align-items-end reply-to-input-dropdown">
                                                <div className="w-100">
                                                    <input
                                                        className="test-email-input"
                                                        placeholder="Display name"
                                                        value={ displayName }
                                                        name={ 'Display Name' }
                                                        onChange={ (e) => setDisplayName(e.target.value) }
                                                    />
                                                    {errors.displayName && (
                                                        <span style={ { fontSize: '14px', color: 'red' } }>
                                                            {errors.displayName}
                                                        </span>
                                                    )}
                                                    <div className="d-flex align-items-center justify-content-between reply-to-input mt-3">
                                                        <input
                                                            className=" "
                                                            placeholder=""
                                                            value={ reply }
                                                            name={ 'reply' }
                                                            onChange={ (e) => setReply(e.target.value) }
                                                            required
                                                        />
                                                        {errors.reply && (
                                                            <span style={ { fontSize: '14px', color: 'red' } }>
                                                                {errors.reply}
                                                            </span>
                                                        )}
                                                        <Select
                                                            // className={ 'reply-to-dropdown' }
                                                            defaultValue={ options[ 1 ] }
                                                            options={ options }
                                                            onChange={ handleSelectChange }
                                                            // value={ selectedOption }
                                                            // // options !== ''
                                                            // //         ? `@${ replyToDomain }`
                                                            // //         : replyToDomain
                                                            // onChange={ (e)=>setSelectedOption(e.target.value) }
                                                            // formatGroupLabel={formatGroupLabel}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr /> */}
                                        <div className="total-recipients">
                                            Total Recipients: {getTotalRecipient()}
                                        </div>

                                        <div className="select-list d-flex align-items-self flex-column">
                                            <div>
                                                <div className="select-list-label mb-2">
                                                    Send to Lists
                                                </div>
                                                {/* <div className="select-list-label">
                                                    {chooseList.length > 1
                                                        ? 'User will be added to these products'
                                                        : 'Enrolled In'}
                                                </div>  */}
                                                <ul
                                                    className="d-flex mb-0"
                                                    style={ { gap: '8px', flexWrap: 'wrap' } }
                                                >
                                                    {chooseList.map((item, index) => {
                                                        return (
                                                            <li
                                                                className="d-flex align-items-center"
                                                                style={ { gap: 5 } }
                                                                key={ index }
                                                            >
                                                                {`${ item?.listName } (${
                                                                    item?.subscribersCount ?? ''
                                                                })`}
                                                                <img
                                                                    className="list-close-icon"
                                                                    src={ closeIcon }
                                                                    width={ 14 }
                                                                    height={ 14 }
                                                                    onClick={ () => removeFromCurrentList(item) }
                                                                />
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                {errors?.chooseList && (
                                                    <span style={ { fontSize: '14px', color: 'red' } }>
                                                        {errors?.chooseList}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="select-list-label">
                                                Available Lists{' '}

                                            </div>
                                            <ul
                                                className="d-flex mb-0"
                                                style={ { gap: '8px', flexWrap: 'wrap' } }
                                            >
                                                {groupList.map((item, index) => {
                                                    return (
                                                        <li
                                                            key={ index }
                                                            onClick={ () => addToCurrentList(item) }
                                                        >
                                                            {`${ item?.listName } (${
                                                                item?.subscribersCount ?? ''
                                                            })`}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center"
                                            style={ { gap: '10px', marginTop: '21px' } }
                                        >
                                            {/* <button className="schedule-btn" type='button' onClick={ ()=>{ setOpenDate(true) } }>Schedule</button> */}
                                            <button className=" schedule-btn" type='submit' disabled={ sendLoading }>
                                                {sendLoading ? (
                                                    <Spinner
                                                        animation="border"
                                                        size={ 'sm' }
                                                        role="status"
                                                    />
                                                ) : (
                                                    'Send Now'
                                                )}
                                            </button>
                                        </div>
                                        {openDate && <div className='mt-3 d-flex justify-content-center date-picker-main-wrapper'><EmailDatePicker handleDateChange={ handleDateChange } setOpenDate={ setOpenDate }/></div>}
                                    </form>
                                )}
                            </Modal.Body>
                        </Modal>
                        <div className="create-email-wrapper d-flex justify-content-start align-items-start">
                            <div>
                                <div className="email-subject-input-wrapper d-flex align-items-center justify-content-between">
                                    <input
                                        value={ subject }
                                        disabled={ isEmailView ? true : false }
                                        name="subject"
                                        onChange={ (e) => setSubject(e.target.value) }
                                        className="email-subject-input"
                                        placeholder="Email Subject Line"
                                    />
                                    <div className='d-flex flex-column align-items-center'>
                                        <span style={ { cursor: 'pointer' } } onClick={ ()=>setChooseEmoji(!chooseEmoji) }>😀</span>
                                        {chooseEmoji && <div style={ { position:'absolute',zIndex: 100, top: '90px' } }>
                                            <EmojiPicker onEmojiClick={ onEmojiClick } />
                                        </div>}
                                    </div>
                                </div>
                                {errors.subject && (
                                    <span style={ { fontSize: '14px', color: 'red' } }>
                                        {errors.subject}
                                    </span>
                                )}
                            </div>
                            <div className="save-and-send">
                                <button
                                    disabled={ isEmailView ? true : false }
                                    className="save-btn justify-content-center align-items-center"
                                    onClick={ handleDraft }
                                >
                                    {draftLoading ? (
                                        <Spinner animation="border" size={ 'sm' } role="status" />
                                    ) : (
                                        'Save As Draft'
                                    )}
                                </button>
                                <button disabled={ isEmailView ? true : false } className="send-btn" onClick={ toggleModal }>
                                    Send
                                </button>
                            </div>
                        </div>
                        <div className="mt-4">
                            <EmailEditor htmlData={ htmlData }  setHtmlData={ handleHtmlChange } handleSubmit={ handleEmailImageSubmit } pageFormData={ emailForm?.values } formName={ 'emailForm' } />
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

// export default SendEmails;

export default reduxForm({
    form: 'emailForm',
    // validate,
    // asyncValidatePageSlug,
    // asyncChangeFields: [ 'slug' ]
})(SendEmails);
