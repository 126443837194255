import React from 'react'
import WebTemplates ,{ Header,Home, Banner,Blogs, Card, Tabs, Tab, Travel  } from 'web-templates';
import BannerView from 'components/core/banner-view'
import { SAMPLE_BLOG } from 'constants/app'
import PropTypes from 'prop-types';
import { isTravelTemplate } from 'utils/helpers'
import visa from 'images/visa.png';
import masterCard from 'images/master-card.png';
import amex from 'images/amex.png';
import discover from 'images/discover.png';
import payPal from 'images/paypal.png';
import bookingEngine from 'images/booking-engine.png';
import venmo from 'images/venmo.png';
import safeCheckout from 'images/safe-checkout.png'
import { HEADER } from 'constants/app'

const WebTemplatePreview = (props) => {
    const { data, isHomePage, isMobileView, noBlogs, noScriptImage } = props
    //var timeoutData = null
    if(isTravelTemplate()){
        data[ 'templateType' ] = process.env.REACT_APP_WEBSITE_TYPE
    }

    // const loadScript  = () => {
    //     if(window.SSTWidget){
    //         const wrapper = document.querySelector('#root');
    //         const range = document.createRange();
    //         range.setStart(wrapper, 0);
    //         wrapper.appendChild(
    //             range.createContextualFragment(`${ DEFAULT_TRAVEL_SCRIPT.script2 }`)
    //         );
    //         clearInterval(timeoutData)

    //     }

    // }
    // useEffect(async() => {
    //     const wrapper = document.querySelector('#root');
    //     const range = document.createRange();
    //     range.setStart(wrapper, 0);
    //     await wrapper.appendChild(
    //         range.createContextualFragment(`${ DEFAULT_TRAVEL_SCRIPT.script1 }`)
    //     )
    //     timeoutData = setTimeout(() => {
    //         document.getElementById('TabButtonHolder') == null && loadScript()
    //     },100)
    //     return () => {
    //         clearInterval(timeoutData)
    //     }
    // },[])

    return(<>
        <WebTemplates data={ data }>
            <Header>
                <Header.Left/>
                <Header.Center/>
                {isTravelTemplate() ? <Header.Right  contactLink={ <a href='#' className='btn btn-primary'>Contact</a> }/> : <Header.Right />}
            </Header>
            <Home>
                <Banner>
                    { isTravelTemplate() ? <><h2>
                        {data?.header?.heading || HEADER.TRAVEL_HEADING}
                    </h2><span className="wizrd-description">
                        {data?.header?.subHeading || HEADER.TRAVEL_SUBHEADING}
                    </span><div className="wizrd-iframe">
                        <div className='widgetWrap' id='widgetWrap'>
                            {!noScriptImage && <img src={ bookingEngine } />}
                        </div>
                    </div><div className="wizrd-payment">
                        <div className="payment-button">
                            <div className="payment-btn-group">
                                <a href={ '#' } rel='noreferrer' target='_blank'>Book Now</a>
                                <a href={ '#' } rel='noreferrer' target='_blank'>pay Later</a>
                            </div>
                        </div>
                        <div className="payment-option">
                            <h6>payments accepted:</h6>
                            <ul>
                                <li>
                                    <img src={ visa } />
                                </li>
                                <li>
                                    <img src={ masterCard } />
                                </li>
                                <li>
                                    <img src={ amex } />
                                </li>
                                <li>
                                    <img src={ discover } />
                                </li>
                                <li>
                                    <img src={ payPal } />
                                </li>
                                <li>
                                    <img src={ venmo } />
                                </li>
                                <li>
                                    <img src={ safeCheckout } />
                                </li>
                            </ul>
                        </div>
                    </div>
                    </> : <BannerView data={ data?.header } />}
                </Banner>
                {isMobileView && isTravelTemplate() &&
                    <Travel>
                        <Travel.Newsletter emailError={ null } userState={ null } inputEl={ null } handleSubscribeSite={ () =>{} }  handleChangeSubscribeEvent={ () =>{} }   />
                        <Travel.Services TRAVEL_SERICES_URLS={ { } }/>
                    </Travel>
                }
                {!noBlogs && !isTravelTemplate() &&
                <Blogs>
                    <Tabs >
                        <Tab label="Latest">
                            <ul className="wizrd-blog-list">
                                <li>
                                    <a href='#'>
                                        <Card
                                            image={ SAMPLE_BLOG.BLOG_IMAGE }
                                        >
                                            <h2>{ SAMPLE_BLOG.BLOG_NAME }</h2>
                                            <div className="wizrd-blog-excerpt-detail">
                                                <div className="wizrd-blog-author">

                                                    <div className="wizrd-blog-author-name">
                                                        { SAMPLE_BLOG.USER_NAME }
                                                    </div>
                                                    <div className="wizrd-blog-date">
                                                        <span>{SAMPLE_BLOG.DATE}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="wizrd-blog-view">
                                                New Blog
                                            </div> */}
                                            </div>
                                        </Card>
                                    </a>
                                </li>
                                {isHomePage &&
                                <li>
                                    <a href='#'>
                                        <Card
                                            image={ SAMPLE_BLOG.BLOG_IMAGE2 }
                                        >
                                            <h2>{ SAMPLE_BLOG.BLOG_NAME }</h2>
                                            <div className="wizrd-blog-excerpt-detail">
                                                <div className="wizrd-blog-author">

                                                    <div className="wizrd-blog-author-name">
                                                        { SAMPLE_BLOG.USER_NAME }
                                                    </div>
                                                    <div className="wizrd-blog-date">
                                                        <span>{SAMPLE_BLOG.DATE}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="wizrd-blog-view">
                                                New Blog
                                            </div> */}
                                            </div>
                                        </Card>
                                    </a>
                                </li>}
                            </ul>
                        </Tab>
                        <Tab label="Most Popular"></Tab>
                    </Tabs>

                </Blogs>}
            </Home>
        </WebTemplates>
    </>)
}
WebTemplatePreview.propTypes = {
    data: PropTypes.object,
    isHomePage: PropTypes.bool,
    noBlogs: PropTypes.bool,
    noScriptImage: PropTypes.bool,
    isMobileView: PropTypes.bool
};
export default WebTemplatePreview