export const ActionTypes = {
    CREATE_SUBSCRIPTION_REQUEST: 'CREATE_SUBSCRIPTION_REQUEST',
    CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
    CREATE_SUBSCRIPTION_FAILURE: 'CREATE_SUBSCRIPTION_FAILURE',
    UPDATE_SUBSCRIPTION_REQUEST: 'UPDATE_SUBSCRIPTION_REQUEST',
    UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAILURE: 'UPDATE_SUBSCRIPTION_FAILURE',
    CANCEL_ACCOUNT_REQUEST: 'CANCEL_ACCOUNT_REQUEST',
    CANCEL_ACCOUNT_SUCCESS: 'CANCEL_ACCOUNT_SUCCESS',
    CANCEL_ACCOUNT_FAILURE: 'CANCEL_ACCOUNT_FAILURE',
    FREEZE_ACCOUNT_REQUEST: 'FREEZE_ACCOUNT_REQUEST',
    FREEZE_ACCOUNT_SUCCESS: 'FREEZE_ACCOUNT_SUCCESS',
    FREEZE_ACCOUNT_FAILURE: 'FREEZE_ACCOUNT_FAILURE',
    SET_DISCOUNT_ACCOUNT_REQUEST: 'SET_DISCOUNT_ACCOUNT_REQUEST',
    SET_DISCOUNT_ACCOUNT_SUCCESS: 'SET_DISCOUNT_ACCOUNT_SUCCESS',
    SET_DISCOUNT_ACCOUNT_FAILURE: 'SET_DISCOUNT_ACCOUNT_FAILURE',
    UNFREEZE_ACCOUNT_REQUEST: 'UNFREEZE_ACCOUNT_REQUEST',
    UNFREEZE_ACCOUNT_SUCCESS: 'UNFREEZE_ACCOUNT_SUCCESS',
    UNFREEZE_ACCOUNT_FAILURE: 'UNFREEZE_ACCOUNT_FAILURE',
}
