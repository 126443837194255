import React from 'react'
import PropTypes from 'prop-types';
import { HEADER } from 'constants/app'

const BannerView = (props) => {
    const { data, isHideDefaultSubHeading, bgColor, color } = props
    const renderSubHeading = () => {
        if (data?.subHeading) {
            return data?.subHeading;
        }

        return isHideDefaultSubHeading ? '' : HEADER.SUB_HEADING;
    }

    return(
        <>
            {/<[^>]*>/.test(data?.heading) ? <div dangerouslySetInnerHTML={ { __html: data?.heading } } />  : <h2>
                {  data?.heading ? <span>
                    { data?.heading}
                </span> : <span dangerouslySetInnerHTML={ { __html: HEADER.HEADING.replace(/\n/g, '<br />') } } />}
            </h2>}
            <span className='wizrd-description'>{ renderSubHeading()  }</span>
            <div className="wizrd-form-wrapper">
                <form className="wizrd-newsletter">
                    <div className="form-group">
                        <input className="form-control" placeholder="Enter your email" type="text" />
                    </div>
                    <button style={ { background: bgColor, color: color } } type="submit" className="btn btn-primary">Subscribe!</button>
                </form>
            </div>
        </>
    )
}

BannerView.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.object,
    isHideDefaultSubHeading: PropTypes.bool
}
export default BannerView