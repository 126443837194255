export const ActionTypes = {

    PUBLISH_LIST_REQUEST : 'PUBLISH_LIST_REQUEST',
    PUBLISH_LIST_FAILURE : 'PUBLISH_LIST_FAILURE',

    PUBLISH_REQUEST : 'PUBLISH_REQUEST',
    PUBLISH_FAILURE : 'PUBLISH_FAILURE',
    PUBLISH_SUCCESS : 'PUBLISH_SUCCESS',

    PUBLISH_GROUP_LIST : 'PUBLISH_GROUP_LIST',
    PUBLISH_SUBSCRIBERS_LIST : 'PUBLISH_SUBSCRIBERS_LIST',
    PUBLISH_ALL_EMAIL_LIST : 'PUBLISH_ALL_EMAIL_LIST',

    PUBLISH_DOMAIN_DATA : 'PUBLISH_DOMAIN_DATA',
    INITIATE_DOMAIN_REQUEST : 'INITIATE_DOMAIN_REQUEST',
    PUBLISH_DOMAIN_REQUEST_FAILURE : 'PUBLISH_DOMAIN_REQUEST_FAILURE',
}
