import { addNewList } from 'middleware/email';
import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ALPHANUMERICREG } from 'constants/validatorRegex';

const NewList = ({ open, handleModal }) => {
    const dispatch = useDispatch();
    const email = useSelector((state) => state.email);

    const [ listName, setListName ] = useState('');
    const [ errors, setErrors ] = useState({
        listName: '',
    });

    const callback = () => {
        setListName('');
        handleModal(null);
    };

    const handleReset = () =>{
        handleModal();
        setListName('');
    }

    const handleSubmit = () => {
    // add validation
        const newErrors = {};
        if (listName.trim() === '') {
            newErrors.listName = 'List name is required';
        }

        const newListData = {
            listName: listName
        };

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            dispatch(addNewList(newListData, callback));
        }
    };

    return (
        <Modal
            show={ open }
            onHide={ handleReset }
            className="image-upload-modal contact-modal-width new-modal embed-link-modal"
            backdrop={ email?.loading ? 'static' : true }
        >
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>Add new list</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="content-generator-wrapper">
                <div className="add-user-form-container">
                    <div className="w-100 add-form-content d-flex flex-column justify-content-center align-items-start">
                        <div className="form-fields w-100">
                            <div
                                className="d-flex align-items-start flex-column w-100"
                                style={ { gap: '8px' } }
                            >
                                <div className="field-label">List name</div>
                                <input
                                    className="field-input"
                                    value={ listName }
                                    name="listName"
                                    placeholder="Enter list name"
                                    onChange={ (e) => setListName(e.target.value) }
                                    onKeyDown={ (e)=>{
                                        if (!ALPHANUMERICREG.test(e.key) && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    } }
                                />
                                {errors.listName && (
                                    <span style={ { fontSize: '14px', color: 'red' } }>
                                        {errors.listName}
                                    </span>
                                )}
                            </div>

                        </div>
                    </div>
                    <button onClick={ handleSubmit } className="confirm-btn">
                        {email?.loading ? (
                            <Spinner animation="border" size={ 'sm' } role="status" />
                        ) : (
                            'Confirm'
                        )}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

NewList.propTypes = {
    open: PropTypes.bool,
    handleModal: PropTypes.func
};

export default NewList;
