import { deleteList, updateGroupList } from 'middleware/email';
import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmAlert from 'components/core/confirm-alert';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';

const EditList = ({ open, handleModal, emailGroupList }) => {
    const dispatch = useDispatch();
    const [ isEdit, setIsEdit ] = useState(null);
    const [ isDeleteLoading, setIsDeleteLoading ] = useState(null);
    const [ isSaveLoading, setIsSaveLoading ] = useState(false);
    const email = useSelector((state) => state.email);
    const [ editedListName, setEditedListName ] = useState('');

    const handleEdit = (id) => {
        const editItem = emailGroupList.find((item) => item.id === id);
        setEditedListName(editItem.listName);
        setIsEdit(id);
    };

    const handleReset=()=>{
        handleModal();
        setIsEdit(null)
    }

    const handleDeleteList = (id) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlert
                        key={ 'box' }
                        onClose={ onClose }
                        handleAction={ () =>
                            dispatch(deleteList(id,setIsDeleteLoading))
                        }
                        subHeading="By confirming this page & information will be deleted forever."
                    />
                );
            },
        });
    };
    const handleSaveList = (id) => {
        const saveItem = emailGroupList.filter((item) => item.id === id);
        if (editedListName !== saveItem.listName) {
            const request = {
                listName: editedListName
            }
            dispatch(updateGroupList(request, id, setIsEdit, setIsSaveLoading));
        }
    };

    return (
        <Modal
            show={ open }
            onHide={ handleReset }
            className="image-upload-modal contact-modal-width new-modal embed-link-modal"
            backdrop={  email?.loading ? 'static' : true }
        >
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>Edit List</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="content-generator-wrapper">
                <div
                    className="add-user-form-container"
                    style={ { padding: '20px 50px' } }
                >
                    <div className="w-100 add-form-content d-flex flex-column justify-content-center align-items-start">
                        <div className="form-fields w-100">
                            {emailGroupList?.length
                                ? emailGroupList.map((item, i) => {
                                    return (
                                        <div
                                            className="d-flex align-items-center w-100"
                                            style={ { gap: '8px' } }
                                            key={ `${ item.listName }-${ i }` }
                                        >
                                            <div className="d-flex align-items-start flex-column w-100">
                                                {isEdit == item?.id ? (
                                                    <input
                                                        className="field-input"
                                                        defaultValue={ editedListName }
                                                        name="listName"
                                                        placeholder="Enter list name"
                                                        onChange={ (e) => setEditedListName(e.target.value) }
                                                    />
                                                ) : (
                                                    <span className="field-input" style={ { color: '#616161', background: '#dedede' } }>{item.listName}</span>
                                                )}
                                            </div>
                                            {isEdit == item?.id ? (
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={ { gap: '8px' } }
                                                >
                                                    <button className="edit-btn"
                                                        disabled={ editedListName === item.listName ? true : false }
                                                        onClick={ ()=> handleSaveList(item?.id) }
                                                        style={ editedListName === item.listName ? { background: 'grey' } : { } }
                                                    >
                                                        {isSaveLoading ? <Spinner
                                                            variant="light"
                                                            animation="border"
                                                            size={ 'sm' }
                                                            role="status"
                                                        />  :'Save'}
                                                    </button>
                                                    <button
                                                        className="delete-btn"
                                                        onClick={ () => setIsEdit(null) }
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            ) : (
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={ { gap: '8px' } }
                                                >
                                                    <button
                                                        className="edit-btn"
                                                        onClick={ () => handleEdit(item?.id) }
                                                    >
                                                        Edit
                                                    </button>
                                                    <button className="delete-btn" onClick={ ()=>handleDeleteList(item?.id) }>
                                                        {isDeleteLoading === item?.id ? <Spinner
                                                            variant="light"
                                                            animation="border"
                                                            size={ 'sm' }
                                                            role="status"
                                                        /> : 'Delete'}</button>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

EditList.propTypes = {
    open: PropTypes.bool,
    handleModal: PropTypes.func,
    emailGroupList: PropTypes.array,
};

export default EditList;
