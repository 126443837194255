import { ActionTypes } from './actionTypes';
export const templateFailure = () => {
    return {
        type: ActionTypes.TEMPLATE_FAILURE,
    };
};

export const templateSuccess = (error) => {
    return {
        type: ActionTypes.TEMPLATE_SUCCESS,
        error: error
    };
};

// add and edit
export const templateRequest = () => {
    return {
        type: ActionTypes.TEMPLATE_REQUEST,
    };
};
