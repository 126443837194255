/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ConfirmAlert from 'components/core/confirm-alert';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from 'react-js-pagination';
import _ from 'lodash';
import {
    absoluteValue,
    getUser,
    getSite,
    isDisabledSite,
    mixpanelCommonData,
} from 'utils/helpers';
import {
    Form,
    Button,
    Accordion,
    Card,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {
    getDraftBlogs,
    callPublish,
    getPublishedBlogs,
    getBlogById,
    deleteBlog,
} from '../../middleware/blog';
import { ROUTES } from '../../constants/appRoutes';
import { BLOG_STATUS, SHARING_URLS } from 'constants/app';
import { getDynamicURL } from '../../services/api';
import { MixpanelContext } from 'utils/tracking';
import {
    OpenArrow,
    EditBlogListIcon,
    CloneBlogListIcon,
    ShareBlogListIcon,
    ViewsBlogListIcon,
    DeleteBlogListIcon,
    SortBlogIcon,
    DateBlogListIcon,
    ChevronRight,
    MobileHomeIcon,
    MobileSAddNewIcon,
    MobileSearchIcon,
    BlogSortIcon,
} from '../../utils/svg';
import ListSkelton from 'components/core/loader/list-skelton';
import searchIcon from '../../images/search.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import closeIcon from 'images/close.svg';
const searchInput = React.createRef()

export const toggleActiveBlogs = (id, activeBlogs, setActiveBlogs) => {
    const blogsData = [ ...activeBlogs ];
    if (blogsData.includes(id)) {
        delete blogsData[ blogsData.indexOf(id) ];
    } else {
        blogsData.push(id);
    }
    setActiveBlogs(blogsData);
};

export const handleEdit = (event, blog, history) => {
    event.preventDefault();
    const route = getDynamicURL(ROUTES.EDIT_BLOG, { id: blog.slug });
    history.push(route);
};
export const handleClone = (event, blog, site, dispatch, history) => {
    event.preventDefault();
    dispatch(getBlogById(blog?.slug, site?.id));
    history.push(ROUTES.BLOG);
};
export const redirectToBlog = (event, blog, site, user, handleEvent) => {
    event.preventDefault();
    const domain = site?.customDomain || site?.domain;
    handleEvent('View Blog Details', {
        siteId: site?.id,
        domain,
        slug: blog.slug,
        'user identifier': user?.id,
    });
    window.open(`https://${ domain }/blog/${ blog.slug }`, '_blank');
};

export const copyToClipBoard = (
    event,
    blog,
    handleEvent,
    site,
    user,
    setCopySuccess
) => {
    handleEvent('Share Blog', {
        type: 'Copy to Clip Board',
        id: blog?.id,
        slug: blog?.slug,
        'user identifier': user?.id,
    });
    event.preventDefault();
    const domain = site?.customDomain || site?.domain;
    navigator.clipboard.writeText(`https://${ domain }/blog/${ blog.slug }`);
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 1000);
};

export const shareBlog = (type, event, blog, handleEvent, site, user) => {
    handleEvent('Share Blog', {
        type,
        id: blog?.id,
        slug: blog?.slug,
        'user identifier': user?.id,
    });
    event.preventDefault();
    const domain = site?.customDomain || site?.domain;
    window.open(`${ SHARING_URLS[ type ] } https://${ domain }/blog/${ blog.slug }`);
};

export const shareEmailBlogEvent = (event, blog, handleEvent, site, user) => {
    event.preventDefault();
    handleEvent('Share Blog', {
        type: 'Email',
        id: blog?.id,
        slug: blog?.slug,
        'user identifier': user?.id,
    });
    window.open(
        SHARING_URLS.EMAIL(
            blog?.title,
            'https://' + site.domain + '/blog/' + blog.slug
        )
    );
};
const BlogsPage = () => {
    const limit = 6;
    const dispatch = useDispatch();
    const history = useHistory();
    const [ sortToggle, setSortToggle ] = useState(false);
    const [ activeBlogPublish, setActiveBlogPublish ] = useState(0);
    const [ activeBlogs, setActiveBlogs ] = useState([]);
    const [ copySuccess, setCopySuccess ] = useState('');
    const [ activeBlogDraft, setActiveBlogDraft ] = useState(0);
    const publishBlogs = useSelector((state) => state.blog.publishBlogs);
    const publishMetaData = useSelector((state) => state.blog.publishMetaData);
    const loading = useSelector((state) => state.blog.loading);
    const draftBlogs = useSelector((state) => state.blog.draftBlogs);
    const draftMetaData = useSelector((state) => state.blog.draftMetaData);
    const [ sortPublish, setSortPublish ] = useState({
        title: '',
        createdAt: 'desc',
    });
    const [ sortDraft, setSortDraft ] = useState({ title: '', createdAt: 'desc' });
    const [ searchFilter, setSearchFilter ] = useState('');
    const mixpanel = useContext(MixpanelContext);
    const site = getSite();
    const user = getUser();
    const isDisabled = isDisabledSite(user?.status);
    useEffect(() => {
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: ROUTES.BLOGS,
        });
        //dispatch(allBlogsCount())
        dispatch(getDraftBlogs(`page=${ activeBlogDraft }&size=${ limit }`));
        dispatch(getPublishedBlogs(`page=${ activeBlogPublish }&size=${ limit }`));
    }, [ dispatch ]);

    const handleFilter = (_, val) => {
        const filterData = val ||  searchFilter ? `page=0&size=${ limit }&title=${ val || searchFilter }` : `page=0&size=${ limit }`;
        dispatch(getDraftBlogs(filterData));
        dispatch(getPublishedBlogs(filterData));
        setActiveBlogPublish(0);
    // setActiveBlogDraft(0)
    };

    const debounceFn = useCallback(_.debounce(handleFilter, 400), []);

    const removeFilter = () => {
        setSearchFilter('');
        const filterData = `page=0&size=${ limit }`;
        dispatch(getDraftBlogs(filterData));
        dispatch(getPublishedBlogs(filterData));
        setActiveBlogPublish(0);
        setActiveBlogDraft(0);
    };

    const handleEvent = (eventName, data) => {
        mixpanel.track(eventName, mixpanelCommonData(data));
    };

    const handlePageChangePublish = (pageNumber) => {
        const args = `page=${ pageNumber - 1 }&size=${ limit }`;
        dispatch(getPublishedBlogs(args));
        setActiveBlogPublish(pageNumber);
    };
    const handlePageChangeDraft = (pageNumber) => {
        const args = `page=${ pageNumber - 1 }&size=${ limit }`;
        // const startWith = (pageNumber - 1) * limit
        // const args = `_start=${ startWith }&_limit=${ limit }`
        dispatch(getDraftBlogs(args));
        setActiveBlogDraft(pageNumber);
    };

    const handleDelete = (event, blog) => {
        event.preventDefault();
        const countPublish =
      blog.status == BLOG_STATUS.PUBLISHED && publishBlogs.length === 1
          ? activeBlogPublish - 1
          : activeBlogPublish;
        const publishArgs = `page=${ absoluteValue(countPublish) }&size=${ limit }`;
        const countDraft =
      blog.status == BLOG_STATUS.DRAFT && draftBlogs.length === 1
          ? activeBlogDraft - 1
          : activeBlogDraft;
        const draftArgs = `page=${ absoluteValue(countDraft) }&size=${ limit }`;

        confirmAlert({
            // eslint-disable-next-line react/display-name
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlert
                        key={ 'box' }
                        onClose={ onClose }
                        handleAction={ () =>
                            dispatch(deleteBlog(blog?.id, draftArgs, publishArgs))
                        }
                        subHeading="By confirming this blog & information will be deleted forever."
                    />
                );
            },
        });
    };

    const handlePublish = (event, blog) => {
        event.preventDefault();
        const publishPage =
      !event.target.checked && publishBlogs.length === 1
          ? activeBlogPublish - 1
          : activeBlogPublish;
        const publishArgs = `page=${ absoluteValue(publishPage) }&size=${ limit }`;
        const draftPage =
      event.target.checked && draftBlogs.length === 1
          ? activeBlogDraft - 1
          : activeBlogDraft;
        const draftArgs = `page=${ absoluteValue(draftPage) }&size=${ limit }`;
        dispatch(callPublish(blog, event.target.checked, publishArgs, draftArgs));
    };

    const sortData = (type, blogType) => {
        const obj = {};
        if (BLOG_STATUS.PUBLISHED === blogType) {
            obj[ type ] =
        sortPublish && sortPublish[ type ] === 'asc' ? 'desc' : 'asc' || 'desc';
            setSortPublish(obj);
        } else {
            obj[ type ] =
        sortDraft && sortDraft[ type ] === 'asc' ? 'desc' : 'asc' || 'desc';
            setSortDraft(obj);
        }
        const args = `page=${
            blogType === BLOG_STATUS.PUBLISHED ? activeBlogPublish : activeBlogDraft
        }&size=${ limit }&sort=${ type }&order=${
            BLOG_STATUS.PUBLISHED ? obj[ type ].toUpperCase() : obj[ type ].toUpperCase()
        }`;
        blogType === BLOG_STATUS.PUBLISHED
            ? dispatch(getPublishedBlogs(args))
            : dispatch(getDraftBlogs(args));
    };
    const sortDataMobile = (type, order) => {
        sortPublish[ type ] = order;
        sortDraft[ type ] = order;
        setSortPublish(sortPublish);
        setSortDraft(sortDraft);
        const publishArgs = `page=${ activeBlogPublish }&size=${ limit }&sort=${ type }&order=${ order?.toUpperCase() }`;
        const draftArgs = `page=${ activeBlogDraft }&size=${ limit }&sort=${ type }&order=${ order?.toUpperCase() }`;
        dispatch(getPublishedBlogs(publishArgs));
        dispatch(getDraftBlogs(draftArgs));
        setSortToggle(!sortToggle);
    };

    const toggleSearch = () => {
        searchInput?.current?.focus();
    };

    const handleNavigation = (event) => {
        event.preventDefault();
        handleEvent('Click Add New Blog', {
            siteId: site?.id,
            siteName: site?.websiteName,
            domain: site?.customDomain || site?.domain,
            'user identifier': user?.id,
        });
        history.push(ROUTES.BLOG);
    };

    return (
        <main className="dashboard-data blog-dashboard">
            <section className="dashboard-body">
                <div className="dashboard-header d-flex align-items-center">
                    <div className="dashboard-title">
                        <h1>Posts</h1>
                        <div className="dashboard-body-actions">
                            <Link
                                to="#"
                                onClick={ handleNavigation }
                                className={ `${
                                    isDisabled ? 'disabled-link' : ''
                                } btn btn-primary add-new-blog` }
                            >
                                Add New+
                            </Link>
                            <div className="sort-blog-wrap">
                                <Link
                                    to="#"
                                    onClick={ () => setSortToggle(!sortToggle) }
                                    className="btn btn-primary sort-blogs"
                                >
                                    <SortBlogIcon />
                                    Sort By
                                </Link>
                                {sortToggle && (
                                    <ul className="wrap-drop drop">
                                        <li onClick={ () => sortDataMobile('title', 'asc') }>
                                            <a>Title -asc</a>
                                        </li>
                                        <li onClick={ () => sortDataMobile('createdAt', 'asc') }>
                                            <a>Created At -asc</a>
                                        </li>
                                        <li onClick={ () => sortDataMobile('title', 'desc') }>
                                            <a>Title -desc</a>
                                        </li>
                                        <li
                                            className={
                                                sortPublish[ 'createdAt' ] === 'desc' ? 'active' : ''
                                            }
                                            onClick={ () => sortDataMobile('createdAt', 'desc') }
                                        >
                                            <a>Created At -desc</a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-actions d-flex justify-content-end">
                        <Form className="search-form d-flex align-items-center">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                    value={ searchFilter }
                                    onChange={ (e) => {
                                        setSearchFilter(e.target.value)
                                        debounceFn(e, e.target.value);
                                    } }
                                    className="form-control"
                                    placeholder="Search"
                                    onKeyDown={ (e)=> {if (e.key === 'Enter') {
                                        handleFilter(e, e.target.value);
                                        e.preventDefault();
                                    }} }
                                />
                            </Form.Group>
                            <Button
                                onClick={ handleFilter }
                                className="btn-search"
                                type="button"
                                disabled={ !searchFilter }
                            >
                                <img src={ searchIcon } alt={ 'searchIcon' } />
                            </Button>
                            {searchFilter && (
                                <Button
                                    className="btn-close"
                                    type="button"
                                >
                                    {!loading ?
                                        <img
                                            className="list-close-icon"
                                            src={ closeIcon }
                                            onClick={ removeFilter }
                                            width={ 22 }
                                            height={ 22 }
                                        />
                                        :
                                        <Spinner
                                            variant="primary"
                                            animation="border"
                                            size={ 'sm' }
                                            role="status"
                                        />}
                                </Button>
                            )}
                        </Form>
                    </div>
                </div>
                <div>{copySuccess}</div>
                <div className="blog-custom-list-table">
                    <div className="blog-custom-list">
                        <div className="blog-list-header">
                            <div className="blog-list-column blog-list-live">Live</div>
                            <div
                                className={ 'blog-list-column blog-list-title headerSort' }
                                onClick={ () => sortData('title', BLOG_STATUS.PUBLISHED) }
                            >
                                <span className="sort-column">Title</span>
                                {!_.isEmpty(publishBlogs) && (
                                    <span className="sort-icon">
                                        <span
                                            className={ `sort-up ${
                                                sortPublish[ 'title' ] === 'asc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                        <span
                                            className={ `sort-down ${
                                                sortPublish[ 'title' ] === 'desc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                    </span>
                                )}
                            </div>
                            <div
                                className={ 'blog-list-column blog-list-date headerSort' }
                                onClick={ () => sortData('createdAt', BLOG_STATUS.PUBLISHED) }
                            >
                                <span className="sort-column">Date Created</span>
                                {!_.isEmpty(publishBlogs) && (
                                    <span className="sort-icon">
                                        <span
                                            className={ `sort-up ${
                                                sortPublish[ 'createdAt' ] === 'asc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                        <span
                                            className={ `sort-down ${
                                                sortPublish[ 'createdAt' ] === 'desc' && 'active'
                                            }` }
                                        >
                                            <BlogSortIcon />
                                        </span>
                                    </span>
                                )}
                            </div>
                            <div className="blog-list-column blog-list-views">Views</div>
                            <div className="blog-list-column blog-list-delete">Delete</div>
                        </div>
                        <ListSkelton
                            loading={ loading && publishBlogs?.length == 0 }
                            numCol={ 5 }
                            numRow={ 3 }
                        />
                        {publishBlogs?.length ? (
                            <div className="blog-custom-list-table-data">
                                {publishBlogs?.map((blog, index) => (
                                    <div
                                        className={
                                            (activeBlogs?.includes(blog?.id) ? 'active ' : '') +
                      'blog-list-table blog-list-header' +
                      (!isDisabled ? ' blog-list-publish' : '')
                                        }
                                        key={ blog?.slug }
                                    >
                                        <div
                                            className="blog-list-column blog-list-live"
                                            key={ index }
                                        >
                                            <Form.Check
                                                type="switch"
                                                id={ 'custom-switch-' + blog.id }
                                                label=""
                                                onChange={ (e) => handlePublish(e, blog) }
                                                checked={ blog.status === BLOG_STATUS.PUBLISHED }
                                                disabled={ isDisabled }
                                            />
                                        </div>
                                        <div className="blog-list-column blog-list-title">
                                            <span className="table-post-title">
                                                {blog?.title}
                                                {!isDisabled && (
                                                    <>
                                                        <a
                                                            onClick={ (event) =>
                                                                redirectToBlog(
                                                                    event,
                                                                    blog,
                                                                    site,
                                                                    user,
                                                                    handleEvent
                                                                )
                                                            }
                                                        >
                                                            View
                                                        </a>
                                                        <a
                                                            onClick={ () =>
                                                                toggleActiveBlogs(
                                                                    blog?.id,
                                                                    activeBlogs,
                                                                    setActiveBlogs
                                                                )
                                                            }
                                                            className="toggle-blog-detail"
                                                        >
                                                            <ChevronRight />
                                                        </a>
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                        <div className="blog-list-column blog-list-date">
                                            <DateBlogListIcon />
                                            <span>
                                                {' '}
                                                {blog.createdAt && moment(blog.createdAt).format('L')}
                                            </span>
                                        </div>
                                        <div className="blog-list-column blog-list-views">
                                            <ViewsBlogListIcon />
                                            <span>{blog?.views}</span>
                                        </div>
                                        {/*<div className="blog-list-column blog-list-comments">
                                    <CommentsBlogListIcon />
                                    <span>32</span>
                                </div>*/}
                                        <div className="blog-list-column blog-list-actions  blog-list-delete">
                                            <div className="hover-actions">
                                                <a
                                                    onClick={ (e) => handleEdit(e, blog, history) }
                                                    className="table-action-btns"
                                                    href="/#"
                                                >
                                                    <EditBlogListIcon />
                                                    <span>Edit</span>
                                                </a>
                                                <a
                                                    onClick={ (e) =>
                                                        handleClone(e, blog, site, dispatch, history)
                                                    }
                                                    className="table-action-btns active"
                                                    href="/#"
                                                >
                                                    <CloneBlogListIcon />
                                                    <span>Clone</span>
                                                </a>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        as={ 'a' }
                                                        className="table-action-btns"
                                                    >
                                                        <ShareBlogListIcon />
                                                        <span>Share</span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={ (e) =>
                                                                copyToClipBoard(
                                                                    e,
                                                                    blog,
                                                                    handleEvent,
                                                                    site,
                                                                    user,
                                                                    setCopySuccess
                                                                )
                                                            }
                                                        >
                                                            Copy to clipboard
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={ (e) =>
                                                                shareBlog(
                                                                    'FACEBOOK',
                                                                    e,
                                                                    blog,
                                                                    handleEvent,
                                                                    site,
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            Share on Facebook
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={ (e) =>
                                                                shareBlog(
                                                                    'TWITTER',
                                                                    e,
                                                                    blog,
                                                                    handleEvent,
                                                                    site,
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            Share on Twitter
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={ (e) =>
                                                                shareEmailBlogEvent(
                                                                    e,
                                                                    blog,
                                                                    handleEvent,
                                                                    site,
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            Share via Email
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <a
                                                onClick={ (e) => handleDelete(e, blog) }
                                                className={
                                                    'table-action-btns table-action-btns-delete' +
                          (isDisabled ? ' disabled-link' : '')
                                                }
                                                href="/#"
                                            >
                                                <DeleteBlogListIcon />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                                {limit < publishMetaData?.totalElements && (
                                    <div className="blogs-pagination">
                                        <Pagination
                                            activePage={
                                                activeBlogPublish == 0 ? 1 : activeBlogPublish
                                            }
                                            itemsCountPerPage={ limit }
                                            totalItemsCount={ publishMetaData?.totalElements }
                                            pageRangeDisplayed={ 5 }
                                            onChange={ handlePageChangePublish }
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            !loading && (
                                <div className="blog-list-table blog-list-header no-post">
                                    No Posts available
                                </div>
                            )
                        )}
                    </div>
                </div>

                <div className="draft-posts">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ Button } variant="link" eventKey="0">
                                    Drafts
                                    <OpenArrow />
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="blog-custom-list-table">
                                        <div className="blog-custom-list">
                                            <div className="blog-list-header">
                                                <div className="blog-list-column blog-list-live">
                                                    Live
                                                </div>
                                                <div
                                                    className={
                                                        'blog-list-column blog-list-title headerSort'
                                                    }
                                                    onClick={ () => sortData('title', BLOG_STATUS.DRAFT) }
                                                >
                                                    <span className="sort-column">Title</span>
                                                    {!_.isEmpty(draftBlogs) && (
                                                        <span className="sort-icon">
                                                            <span
                                                                className={ `sort-up ${
                                                                    sortDraft[ 'title' ] === 'asc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                            <span
                                                                className={ `sort-down ${
                                                                    sortDraft[ 'title' ] === 'desc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        'blog-list-column blog-list-date headerSort'
                                                    }
                                                    onClick={ () =>
                                                        sortData('createdAt', BLOG_STATUS.DRAFT)
                                                    }
                                                >
                                                    <span className="sort-column">Date Created</span>
                                                    {!_.isEmpty(draftBlogs) && (
                                                        <span className="sort-icon">
                                                            <span
                                                                className={ `sort-up ${
                                                                    sortDraft[ 'createdAt' ] === 'asc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                            <span
                                                                className={ `sort-down ${
                                                                    sortDraft[ 'createdAt' ] === 'desc' && 'active'
                                                                }` }
                                                            >
                                                                <BlogSortIcon />
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="blog-list-column blog-list-views">
                                                    Views
                                                </div>
                                                {/*<div className="blog-list-column blog-list-comments">
                                                    Comments
                            </div>*/}
                                                <div className="blog-list-column blog-list-delete">
                                                    Delete
                                                </div>
                                            </div>
                                            <ListSkelton
                                                loading={ loading && draftBlogs?.length === 0 }
                                                numCol={ 5 }
                                                numRow={ 3 }
                                            />
                                            {draftBlogs?.length ? (
                                                <div className="blog-custom-list-table-data">
                                                    {draftBlogs?.map((blog, index) => (
                                                        <div
                                                            className={
                                                                (activeBlogs?.includes(blog?.id)
                                                                    ? 'active '
                                                                    : '') +
                                'blog-list-table blog-list-header' +
                                (!isDisabled ? ' blog-list-publish' : '')
                                                            }
                                                            key={ blog?.slug }
                                                        >
                                                            <div
                                                                className="blog-list-column blog-list-live"
                                                                key={ index }
                                                            >
                                                                <Form.Check
                                                                    type="switch"
                                                                    id={ 'custom-switch-' + blog.id }
                                                                    label=""
                                                                    onChange={ (e) => handlePublish(e, blog) }
                                                                    checked={ blog.status !== 'DRAFT' }
                                                                    disabled={ isDisabled }
                                                                />
                                                            </div>
                                                            <div className="blog-list-column blog-list-title">
                                                                <span className="table-post-title">
                                                                    {blog?.title}
                                                                    {!isDisabled && (
                                                                        <a
                                                                            onClick={ () =>
                                                                                toggleActiveBlogs(blog?.id)
                                                                            }
                                                                            className="toggle-blog-detail"
                                                                        >
                                                                            <ChevronRight />
                                                                        </a>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="blog-list-column blog-list-date">
                                                                <DateBlogListIcon />
                                                                <span>
                                                                    {' '}
                                                                    {blog.createdAt &&
                                    moment(blog.createdAt).format('L')}
                                                                </span>
                                                            </div>
                                                            <div className="blog-list-column blog-list-views">
                                                                <ViewsBlogListIcon />
                                                                <span>{blog?.views}</span>
                                                            </div>
                                                            {/*<div className="blog-list-column blog-list-comments">
                                                        <CommentsBlogListIcon />
                                                        <span></span>
                        </div>*/}
                                                            <div className="blog-list-column blog-list-actions  blog-list-delete">
                                                                <div className="hover-actions">
                                                                    <a
                                                                        onClick={ (e) =>
                                                                            handleEdit(e, blog, history)
                                                                        }
                                                                        className="table-action-btns"
                                                                        href="/#"
                                                                    >
                                                                        <EditBlogListIcon />
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a
                                                                        onClick={ (e) =>
                                                                            handleClone(
                                                                                e,
                                                                                blog,
                                                                                site,
                                                                                dispatch,
                                                                                history
                                                                            )
                                                                        }
                                                                        className="table-action-btns active"
                                                                        href="/#"
                                                                    >
                                                                        <CloneBlogListIcon />
                                                                        <span>Clone</span>
                                                                    </a>
                                                                    {/* <a className="table-action-btns" href="/#" onClick={ (e) => copyToClipBoard(e, blog) }>
                                                                <ShareBlogListIcon />
                                                                <span>Share</span>
                                                            </a> */}
                                                                </div>
                                                                <a
                                                                    onClick={ (e) => handleDelete(e, blog) }
                                                                    className={
                                                                        'table-action-btns table-action-btns-delete' +
                                    (isDisabled ? ' disabled-link' : '')
                                                                    }
                                                                    href="/#"
                                                                >
                                                                    <DeleteBlogListIcon />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {limit < draftMetaData?.totalElements && (
                                                        <div className="blogs-pagination">
                                                            <Pagination
                                                                activePage={
                                                                    activeBlogDraft == 0 ? 1 : activeBlogDraft
                                                                }
                                                                itemsCountPerPage={ limit }
                                                                totalItemsCount={ draftMetaData?.totalElements }
                                                                pageRangeDisplayed={ 5 }
                                                                onChange={ handlePageChangeDraft }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                !loading && (
                                                    <div className="blog-list-table blog-list-header no-post">
                                                        No Drafts available
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
                {!isDisabled && (
                    <div className="blog-mobile-action">
                        <ul>
                            <li>
                                <Link to={ ROUTES.DASHBOARD }>
                                    <MobileHomeIcon />
                                </Link>
                            </li>
                            <li>
                                <Link to={ ROUTES.BLOG }>
                                    <MobileSAddNewIcon />
                                </Link>
                            </li>
                            <li>
                                <Link onClick={ toggleSearch }>
                                    <MobileSearchIcon />
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}
            </section>
        </main>
    );
};

export default BlogsPage;
