// import axios from 'axios';
import axiosInstance from '../../services/api';
import { NOTIFICATION_TYPES, MESSAGE } from '../../constants/app';
import { notification } from '../../services/notification';
import { getMaxTokensCount, getSite, getUser } from 'utils/helpers';
import { setLocalItem } from 'utils/cache';

const getSavedContent = (id, callback,dispatch, successNotification) => {
    return () => {
        axiosInstance
            .get(`suggestions/get-text?textGenId=${ id }`)
            .then((response) => {
                if (response.data.code === 102) {
                    setTimeout(() => {
                        dispatch(getSavedContent(id, callback,dispatch));
                    }, 5000);
                } else {
                    const user = getUser();
                    successNotification && notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.GPT_SUCCESSFULL);
                    try {
                        const previousTextGenerationUsage =
              user?.[ 'textGenerationUsage' ] ?? {};
                        previousTextGenerationUsage[ 'tokensLeft' ] =
              response?.data?.textgenerationUsage?.tokensLeft ??
              getMaxTokensCount();
                        user[ 'textGenerationUsage' ] = previousTextGenerationUsage;
                    } catch (e) {
                        console.log(e);
                    }
                    setLocalItem('user', user);
                    callback(response);
                }
            })
            .catch((error) => {
                console.log(error);
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message ?? MESSAGE.SOMETHING_WRONG);
                callback(null);
            });
    };
};

export const contentGenerator = (params, callback, dispatch, temperature = 7, successNotification = true) => {
    const requestData = {
        text: params,
        userSiteId: getSite()?.id ?? null,
        temperature: temperature/10 ?? 0.7
        // apiKey: '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'
    };
    return () => {
        axiosInstance
            .post('/suggestions/text', requestData)
            .then((response) => {
                if (response?.data?.textGenId) {
                    setTimeout(() => {
                        dispatch(getSavedContent(response.data.textGenId, callback, dispatch, successNotification));
                    }, 5000);
                } else {
                    const user = getUser();
                    successNotification && notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.GPT_SUCCESSFULL);
                    try {
                        const previousTextGenerationUsage =
              user?.[ 'textGenerationUsage' ] ?? {};
                        previousTextGenerationUsage[ 'tokensLeft' ] =
              response?.data?.textgenerationUsage?.tokensLeft ??
              getMaxTokensCount();
                        user[ 'textGenerationUsage' ] = previousTextGenerationUsage;
                    } catch (e) {
                        console.log(e);
                    }
                    setLocalItem('user', user);
                    callback(response);
                }
            })
            .catch((error) => {
                console.log(error);
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message ?? MESSAGE.SOMETHING_WRONG);
                callback(null);
            });
    };
};

const getLongSavedContent = (id, callback,dispatch) => {
    return () => {
        axiosInstance
            .get(`suggestions/get-text/blog?textGroupId=${ id }`)
            .then((response) => {
                if (response.data.code === 102) {
                    setTimeout(() => {
                        dispatch(getLongSavedContent(id, callback,dispatch));
                    }, 5000);
                } else {
                    const user = getUser();
                    notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.GPT_SUCCESSFULL);
                    try {
                        const previousTextGenerationUsage =
              user?.[ 'textGenerationUsage' ] ?? {};
                        previousTextGenerationUsage[ 'tokensLeft' ] =
              response?.data?.textgenerationUsage?.tokensLeft ??
              getMaxTokensCount();
                        user[ 'textGenerationUsage' ] = previousTextGenerationUsage;
                    } catch (e) {
                        console.log(e);
                    }
                    setLocalItem('user', user);
                    callback(response);
                }
            })
            .catch((error) => {
                console.log(error);
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message ?? MESSAGE.SOMETHING_WRONG);
                callback(null);
            });
    };
};

export const longContentGenerator = (request, callback,dispatch) => {
    return () => {
        axiosInstance
            .post('/suggestions/text/blog', request)
            .then((response) => {
                if (response?.data?.textGroupId) {
                    setTimeout(() => {
                        dispatch(getLongSavedContent(response.data.textGroupId, callback, dispatch));
                    }, 5000);
                } else {
                    const user = getUser();
                    notification(NOTIFICATION_TYPES.SUCCESS, MESSAGE.GPT_SUCCESSFULL);
                    try {
                        const previousTextGenerationUsage =
              user?.[ 'textGenerationUsage' ] ?? {};
                        previousTextGenerationUsage[ 'tokensLeft' ] =
              response?.data?.textgenerationUsage?.tokensLeft ??
              getMaxTokensCount();
                        user[ 'textGenerationUsage' ] = previousTextGenerationUsage;
                    } catch (e) {
                        console.log(e);
                    }
                    setLocalItem('user', user);
                    callback(response);
                }
            })
            .catch((error) => {
                console.log(error);
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message ?? MESSAGE.SOMETHING_WRONG);
                callback(null);
            });
    };
}