/* eslint-disable no-useless-escape */
import React, { useState , useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { change as reduxChange } from 'redux-form'
import { Field } from 'redux-form';
import { renderField } from 'utils/formUtils'
import{ Modal, Button, Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import { getAllPages, getRecentPages } from 'middleware/page'
import Skeleton  from 'react-loading-skeleton';
import Header from './header'
import Footer from './footer'
import _ from 'lodash'
import ButtonLoader from 'components/core/loader/button-loader'

export const normalizeCopyRight = (value, previousValue) => {
    if(!previousValue){
        return '©'+value
    }
    return value
}

const MenuLinks = (props) => {
    const dispatch = useDispatch()
    const [ loadData, setLoadData ] = useState(false)
    const [ linkType, setLinkType ] = useState(0)
    const [ urlError, setUrlError ] = useState( {} )
    const [ foooterUrlError, setFooterUrlError ] = useState( {  } )
    const [ tab, setTab ] = useState('header');

    const page = useSelector((state) => state.page )
    const [ loadingtype , setLoadingType ] = useState(false)
    const { footerLinks, setFooterLinks, handleClose,validUrls, isValidMenu,isValidFooterMenu, loading,setMenuLinks, menuLinks,isValid  } = props
    const menuLinksData = tab === 'header' ? menuLinks : footerLinks
    const setMenuLinksData = tab === 'header' ? setMenuLinks : setFooterLinks

    useEffect(() =>{
        dispatch(getAllPages())
        dispatch(getRecentPages())
        setLinkType(0)
    },[])

    useEffect(()=>{
        setLoadingType(false)
    },[ linkType ])

    const handleCloseMenu = () => {
        if(validUrls()  && isValidMenu() && isValidFooterMenu()){
            dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinks))
            dispatch(reduxChange('assessmentUpdateForm', 'footerLinks', footerLinks))
            handleClose()
        }
    }

    const addMenuLinks = () =>{
        const obj = {}
        setLoadData(true)
        const menuLinksClone = Object.assign([],menuLinksData)
        menuLinksClone.push(obj)
        setMenuLinksData(menuLinksClone)
        setTimeout(()=>{
            setLoadData(false)
        })
    }

    const removeMenuLink = (index) => {
        const obj = [ ...menuLinksData ]
        obj.splice(index, 1);
        setMenuLinksData(obj)
    }

    const handleNewTab = (event,index) => {
        event.preventDefault()
        setLoadData(true)
        if(index?.toString().includes('.') ){
            const menuLinksClone = Object.assign([],menuLinksData)
            const indx = index?.split('.')[ 0 ]
            const indx1 = index?.split('.')[ 1 ]
            const obj = { ...menuLinksClone[ indx ] }
            obj[ 'subMenuLinks' ][ indx1 ][ 'newTab' ] = event.target.checked
            menuLinksClone[ indx ] = obj
            setMenuLinksData(menuLinksClone)
        }else{
            const menuLinksClone = Object.assign([],menuLinksData)
            const obj = { ...menuLinksClone[ index ] }
            obj[ 'newTab' ] = event.target.checked
            menuLinksClone[ index ] = obj
            setMenuLinksData(menuLinksClone)

        }

        //dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinksClone))
        setTimeout(()=> setLoadData(false))
    }

    const handleChangeMenuName = (event, index,url) =>{
        event.preventDefault();
        if(!event.target.value.match(/[&/\\#,+()@$~%.'"*?<>{}]/g)){
            setLoadData(true)
            const name = event.target?.value
            if(index?.toString().includes('.') ){
                const obj = { name: name, url: url }
                const indx = index?.split('.')[ 0 ]
                const indx1 = index?.split('.')[ 1 ]
                const menuLinksDataClone = Object.assign([],menuLinksData)
                const menudata = Object.assign({}, menuLinksDataClone[ indx ])
                menudata[ 'subMenuLinks' ][ indx1 ] = obj
                setMenuLinksData( [ ...menuLinksData ] )
            }else{
                const obj = { ...menuLinksData[ index ], name: name  }
                menuLinksData[ index ] = obj
                setMenuLinksData(menuLinksData)
            }
            // obj.url === '' && obj.name === 'home' ? null :  validUrls()
            // dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinks))
            setTimeout(()=> setLoadData(false))
        }
    }

    const checkValidUrl = ( index, result ) => {
        const urlErrorClone = { ...urlError }
        const foooterUrlErrorClone = { ... foooterUrlError }
        if(tab === 'header'){
            urlErrorClone[ index ] = result
            setUrlError(urlErrorClone)
        }else{
            foooterUrlErrorClone[ index ] = result
            setFooterUrlError(foooterUrlErrorClone)
        }

    }
    const handleChangeMenuUrl = ( event, index, type ) => {
        checkValidUrl(index,false)
        const httpsRegex = new RegExp('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')
        const value = type === 'click' ? event.value : event?.target?.value
        checkValidUrl(index,value.match(/[&\\#,+!()^@$~%'";*?<>{} ]/g) ? true : false)
        if(!value.match(/[&\\#,+!()^@$~%'";*?<>{}]/g)){
            setLoadData(true)
            if(index?.toString().includes('.') ){
                const indx = index?.split('.')[ 0 ]
                const indx1 = index?.split('.')[ 1 ]
                const menuLinksDataClone = Object.assign([],menuLinksData)
                const menudata = Object.assign({}, menuLinksDataClone[ indx ])
                const obj = Object.assign({}, menudata[ 'subMenuLinks' ][ indx1 ])
                const url = value.trim() && value?.trim().replace(/[&\\#,+!()@$~%'";*?<>{}]/g,'')
                if(url.includes('.')){
                    if(httpsRegex.test(url))
                    {
                        obj[ 'url' ] = url
                        checkValidUrl(index,false)

                    }else{
                        checkValidUrl(index,true)
                    }
                }
                obj[ 'url' ] = url

                menuLinksDataClone[ indx ][ 'subMenuLinks' ][ indx1 ] = obj
                setMenuLinksData( [ ...menuLinksDataClone ] )

            }else{
                const obj = menuLinksData[ index ]
                const url = value.trim() && value?.trim().replace(/[&\\#,+!()@$~%'";*?<>{}]/g,'')
                if(url.includes('.')){
                    if(httpsRegex.test(url))
                    {
                        obj[ 'url' ] = url
                        checkValidUrl(index,false)
                    }else{
                        checkValidUrl(index,true)
                    }
                }
                obj[ 'url' ] = url;
                obj[ 'isShow' ] = true;
                menuLinksData[ index ] = obj

            }
            // setMenuLinks(menuLinks)
            // obj.url === '' && obj.name === 'home' ? null :  validUrls()
            //dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinks))
            setTimeout(()=> setLoadData(false))
        }
    }

    const handleRecentMenuLinks = (event, item) =>{
        event.preventDefault()
        setLoadData(true)
        if(linkType?.toString().includes('.') ){
            const indx = linkType?.split('.')[ 0 ]
            const menuLinksDataClone = [ ...menuLinksData ]
            const menudata = menuLinksDataClone[ indx ]
            const name = item.title
            const obj = { name: name, url: item.slug }
            menudata[ 'subMenuLinks' ].push(obj)
            setMenuLinksData([ ...menuLinksDataClone ])
        }else{
            const menuLinksClone = Object.assign([],menuLinksData)
            const name = item.title
            const obj = { name: name, url: item.home ? '/' :item.slug }
            menuLinksClone.push(obj)
            setLinkType(menuLinksData.length)
            setMenuLinksData(menuLinksClone)

        }

        // obj.url === '' && obj.name === 'home' ? null :  validUrls()
        //dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinksClone))
        setTimeout(()=> setLoadData(false))
    }
    const skeltonData = () => {
        return(
            <>
                <li>
                    <Skeleton width='100%' count={ 1 } />
                </li>
                <li>
                    <Skeleton width='100%' count={ 1 } />
                </li>
            </>
        )
    }

    const validUrlsResult = () => {
        const urlErrorArr = Object.values(urlError)
        const footerUrlErrorArr = Object.values(foooterUrlError)
        const errorArr = _.concat(urlErrorArr,footerUrlErrorArr)
        return !errorArr.includes(true)
    }
    return(
        <div className="">
            <Form onSubmit={ () => {} }>
                <Modal.Header closeButton>
                    <div className="new-modal-header menu-edit-header">
                        <Row>
                            <Col className="col-4">
                                <Modal.Title>Menu / Footer</Modal.Title>
                            </Col>
                            <Col className="col-8">
                                { _.isObject(isValid) && !isValid.result && <span className='alert alert-danger' style={ { fontSize: '13px' } }>Error: { isValid?.type}  Name and url should be unique or can&apos;t blank</span>}
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="site-menu-tab">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={ tab }
                            onSelect={ (k) => setTab(k) }
                            className="mb-3"
                        >
                            <Tab eventKey="header" title="Header">
                                <Header
                                    urlError={ urlError }
                                    addMenuLinks={ addMenuLinks }
                                    removeMenuLink={ removeMenuLink }
                                    setMenuLinks={ setMenuLinks }
                                    handleChangeMenuUrl={ handleChangeMenuUrl }
                                    handleChangeMenuName={ handleChangeMenuName }
                                    handleNewTab={ handleNewTab }
                                    loadingtype={ loadingtype }
                                    setLoadingType={ setLoadingType }
                                    setLinkType = { setLinkType }
                                    linkType = { linkType }
                                    menuLinks={ menuLinks }
                                    loadData = { loadData }
                                    setLoadData={ setLoadData }
                                />
                            </Tab>
                            <Tab eventKey="footer" title="Footer">
                                <Footer
                                    urlError={ foooterUrlError }
                                    addMenuLinks={ addMenuLinks }
                                    removeMenuLink={ removeMenuLink }
                                    setMenuLinks={ setFooterLinks }
                                    handleChangeMenuUrl={ handleChangeMenuUrl }
                                    handleChangeMenuName={ handleChangeMenuName }
                                    handleNewTab={ handleNewTab }
                                    loadingtype={ loadingtype }
                                    setLoadingType={ setLoadingType }
                                    setLinkType = { setLinkType }
                                    linkType = { linkType }
                                    menuLinks={ footerLinks }
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="menu-link-footer">
                        <div className="recent-pages">
                            <h4>Recent Pages</h4>
                            <ul>
                                { page.recentPageLoading && skeltonData() }
                                { !page.recentPageLoading && page.recentPages && page.recentPages.map((item, index) => {
                                    return(<li
                                        className={ 'badge ' }
                                        onClick={ (event) => handleRecentMenuLinks(event,item) }  key={ index } >
                                        { item.title }
                                    </li>)
                                })}

                            </ul>
                        </div>

                        <div className="modal-btns text-right">
                            { validUrlsResult() ?
                                <ButtonLoader
                                    button={ <Button type='button' onClick={ handleCloseMenu } variant="primary">confirm</Button> }
                                    loadingText='Saving'
                                    loading={ loading }

                                /> :  <Button type='button' disabled={ true }  variant="primary">confirm</Button>

                            }
                        </div>
                    </div>
                    { tab === 'footer' &&
                        <div className='footer-text-div'>
                            <h4>Footer text</h4>
                            <Field
                                name="footerText"
                                component={ renderField }
                                placeholder={ '© Site Name Here 2021' }
                                normalize={ normalizeCopyRight }

                            />
                        </div>
                    }
                </Modal.Footer>
            </Form>
        </div>

    )
}
String.prototype.uppercase = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
};
MenuLinks.propTypes = {
    setLinkType: PropTypes.func,
    linkType: PropTypes.object,
    handleRecentMenuLinks: PropTypes.func,
    handleNewTab: PropTypes.func,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    footerLinks: PropTypes.array,
    setFooterLinks: PropTypes.func,
    menuLinks: PropTypes.array,
    addMenuLinks: PropTypes.func,
    loadData: PropTypes.bool,
    assessmentData: PropTypes.object,
    handleChangeMenuName: PropTypes.func,
    handleChangeMenuUrl: PropTypes.func,
    loadText: PropTypes.bool,
    footerText: PropTypes.string,
    setFooterText: PropTypes.func,
    removeMenuLink: PropTypes.func,
    isValid: PropTypes.bool,
    isValidFooterMenu: PropTypes.func,
    loading: PropTypes.bool,
    setMenuLinks: PropTypes.func,
    validUrls: PropTypes.func,
    isValidMenu: PropTypes.func
};
export default MenuLinks;